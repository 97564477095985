import DoubleHung from "./images/plygem_double_hung.jpg";
import PictureWindow from "./images/plygem_picture_window.jpg";
import Awning from "./images/Awning.jpg";
import Eyebrow from "./images/Eyebrow.jpg";
import EyebrowWLegs from "./images/Eyebrow w Legs.jpg";
import FixedCasement from "./images/Fixed Casement.jpg";
import FullOval from "./images/Full Oval.jpg";
import FullRound from "./images/Full Round.jpg";
import HalfRound from "./images/Half Round.jpg";
import HalfRoundWLegs from "./images/Half Round w Legs.jpg";
import OperatingCasement from "./images/Operating Casement.jpg";
import QuarterRound from "./images/Quarter Round.jpg";
import QuarterRoundWLegs from "./images/Quarter Round w Legs.jpg";
import EyeSegmentWLegs from "./images/Eye Segment with Legs.jpg";
import TwoPanelSlider from "./images/2 Panel Slider.jpg";
import Trapezoid from "./images/Trapezoid.jpg";
import RightAngleTriangle from "./images/Right Angle Triangle.jpg";
import IsoscelesTriangle from "./images/Isosceles Triangle.jpg";
import OneThirdPanelSlider from "./images/3 Panel Slider 1-3 1-3 1-3.jpg";
import OneFourthPanelSlider from "./images/3 Panel Slider 1-4 1-2 1-4.jpg"



const imageMap = new Map();
// Adding [key, value] pair to the map
imageMap.set("Double Hung", DoubleHung);
imageMap.set("Picture Window", PictureWindow);
imageMap.set("Awning", Awning);
imageMap.set("Eyebrow", Eyebrow);
imageMap.set("Eyebrow with Legs", EyebrowWLegs);
imageMap.set("Fixed Casement", FixedCasement);
imageMap.set("Full Oval", FullOval);
imageMap.set("Full Round", FullRound);
imageMap.set("Half Round", HalfRound);
imageMap.set("Half Round with Legs", HalfRoundWLegs);
imageMap.set("Operating Casement", OperatingCasement);
imageMap.set("Quarter Round", QuarterRound);
imageMap.set("Quarter Round with Legs", QuarterRoundWLegs);
imageMap.set("Eye Segment with Legs", EyeSegmentWLegs);
imageMap.set("2 Panel Slider", TwoPanelSlider);
imageMap.set("Trapezoid", Trapezoid);
imageMap.set("Right Angle Triangle", RightAngleTriangle);
imageMap.set("Isosceles Triangle", IsoscelesTriangle);
imageMap.set("3 Panel Slider 1/3 - 1/3 - 1/3", OneThirdPanelSlider);
imageMap.set("3 Panel Slider 1/4 - 1/2 - 1/4", OneFourthPanelSlider);

export default imageMap;
