export const getUTCTimestamp = () => {

    var td=new Date();
    var z = {
      Y: td.getUTCFullYear(),
      M: td.getUTCMonth() + 1,
      d: td.getUTCDate(),
      h: td.getUTCHours(),
      m: td.getUTCMinutes(),
      s: td.getUTCSeconds()
    };
    var t=z.Y+"-"+z.M+"-"+z.d+" "+z.h+":"+z.m+":"+z.s;
    return t;
}

export const needsDataRefresh = (localDate, apiDate) => {
    // console.log('localDate', localDate);
    // console.log('apiDate', apiDate);
    if(localDate && apiDate){
        console.log('localDate', new Date(localDate.replace(/-/g, "/")).valueOf());
        console.log('apiDate', new Date(apiDate.replace(/-/g, "/")).valueOf());
        return (new Date(localDate.replace(/-/g, "/")).valueOf() < new Date(apiDate.replace(/-/g, "/")).valueOf()) 
    }
    return true;
    
}

