import {
  PageHeader,
  Input,
  List,
  Card,
  Image,
  Modal,
  InputNumber,
  Button,
  Space,
  TreeSelect,
  Row,
  Col,
  Spin,
  Select,
  Divider,
  Switch,
} from "antd";
import { v4 as uuid } from "uuid";
import jwt_decode from "jwt-decode";
import { DeleteFilled } from "@ant-design/icons";
import imageMap from "./images";
import LogoName from "./images/home-depot.svg";
import "./index.css";
import { getUTCTimestamp, needsDataRefresh } from "./Utilities";
import { errorHandler } from "./gcpLogger";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;
const { TextArea } = Input;
var today = new Date();
var date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
var time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//"2021-12-14T14:26:57+0530",
const dateTime = date + "T" + time + "-0500";
const packageUuid = uuid();
const quoteUuid = uuid();
// let errorMessage = "";
export default class EnterLeadInformation extends Component {
  constructor(props) {
    super(props);

    console.log("final windows object" + this.props.location.state?.windows);
    console.log("final labor object", this.props.location.state);

    this.state = {
      existingOrderJSON: {},
      isNoData: false,
      configuredWindows: this.props.location.state?.windows,
      configuredLabors: this.props.location.state?.labors,
      itemMiscNotes: this.props.location.state?.jobMiscNotes
        ? this.props.location.state.jobMiscNotes
        : "",
      isEdit: this.props.location.state?.isEdit,
      lineId: this.props.location.state?.lineId,
      existingItemToEdit: this.props.location.state?.existingItemToEdit,
      isMounted: false,
      showLabor: false,
      generateVOS: false,
      showWrapColor: false,
      outerJson: {
        favorited: true,
        creditOfferLegalText:
          "With a credit approval for qualifying purchases made on The Home Depot or EXPO Design Center Consume Credit Card." +
          "APR: 17.99%-26.99%.  Minimum interest charge: $2. See card agreement for details including APR applicable to you. Offer valid for consumer" +
          "accounts in good standing; 6 months everyday credit offer is subject to change without notice; see store for details.",
        isUpSell: false,
        basePricePerMonth: 0,
        monthlyPaymentCalculationLegalText: "Equal to Project Amount X 0.02*",
        created: dateTime,
        projectLoanLegalText:
          "*Loan product has four tiers with Term and APR subject to qualifying credit approval: 66 monthly payments at 7.42% APR; 78 monthly payments at 12.86% APR; 90 monthly payments at 16.24% APR; or 114 monthly payments at 19.96% APR. Monthly payments will not exceed $20 per $1000 spent (e.g., $160 per month for an $8000 purchase). APR fixed for the life of the loan. No down payment. See loan agreement for further details.",
        updated: dateTime,
        version: "4",
        packageUuid: packageUuid,
        salesTax: 0,
        name: "Package A",
        status: "valid",
        price: 0,
        letter: "A",
        readOnly: false,
        pricePerMonth: 0.0, //TODO we need to calculate this
        quotes: [
          {
            favorited: false,
            basePricePerMonth: 0,
            commonVendor: "Ply Gem Windows",
            lmsProductId: 2,
            created: dateTime,
            promotionId: 0,
            updated: dateTime,
            groups: [],
            windows: this.props.location.state?.windows,
            roofs: [],
            quoteId: 0,
            salesTax: 0,
            source: "APP",
            name: "Windows Quote",
            leadNumber: this.props.match.params.leadId,
            gutters: [],
            quoteUuid: quoteUuid,
            insulation: [],
            price: 0,
            readOnly: false,
            productName: "Windows",
            projectId: 0,
            pricePerMonth: 0,
            labors: this.props.location.state?.labors
              ? this.props.location.state.labors
              : [],
            lmsVendorIds: "28,985",
            notes: this.props.location.state?.jobMiscNotes
              ? this.props.location.state.jobMiscNotes
              : "",
          },
        ],
      },
      //Below are regular State variables, we may not need leadNumber here
      labors: [], //TODO: we may need to remove this
      orderSubmitted: this.props.location.state?.orderSubmitted,
      current: 0,
      isModalVisible: false,
      leadNumber: this.props.match.params.leadId,
      branch: this.props.match.params.branchNumber,
      leadSafeFlag: this.props.match.params.leadSafeFlag,
      priceList: this.props.match.params.priceList,
      coastalFlag: this.props.match.params.coastalFlag,
      userName: this.props.match.params.userName,
      userSFID: this.props.match.params.userSFID,
      openingUuid: this.props.match.params.openingUuid,
      token: this.props.match.params.token,
      user: this.props.location.state?.user,
      items: [],
      miscLabor: [],
      quantity: Array(20)
        .fill()
        .map((_, idx) => 1 + idx),
      orderId: 0,
      redirect: false,
      isLoading: false,
      isAddWindow: false,
      value: [],
      //Need to get rid of below
      categoryAttributeMap: JSON.parse(
        localStorage.getItem("categoryAttributeMap")
      ),

      isWrapsSelected: this.props.location.state?.selectedWrapColor
        ? true
        : false,
      selectedWrapColor: this.props.location.state?.selectedWrapColor,
      itemLaborData: this.props.location.state?.itemLaborData,
      defaultLaborsData: this.props.location.state?.defaultLaborsData,
      modifiedTimeStampObj: this.props.location.state?.modifiedTimeStampObj,
    };
    this.getOrderPrice();

    this.checkValidToken = this.checkValidToken.bind(this);
    console.log("Final outer JSON", this.state.outerJson);
  }
  async getToken () {
  
    const fullUrl = new URL(process.env.REACT_APP_NODE_URL).href;
    //const data = new URLSearchParams();
      const accessTokenResponse = await fetch(fullUrl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
      });
      return accessTokenResponse
  };
 
    async componentDidMount() {
      let accessToken;
      const accessTokenResponse = await this.getToken()
      if(accessTokenResponse.ok){
        accessToken = await accessTokenResponse.json()
      }
    this.setState({ accessToken: accessToken['access_token'] });   
    this.setState({ isMounted: true })
    this.setState({ isLoading: true });
    let userName = this.state.userName;
    let user = "";
    if (userName.includes(".hdx")) {
      user = userName.match(/^(.+).hdx/)[1];
    } else {
      user = userName;
    }
    console.log("User", user);
    this.setState({ user: user });
    // this.getOrderItems(this.state.outerJson.quotes);

    await this.getCatalogData(user);
    if (!this.state.isEdit && !this.props.location.state) {
      this.setState({ isAddWindow: false });

      await this.getExistingOrder(this.state.leadNumber, true);
    } else {
      await this.getOrderItems(this.state.outerJson.quotes);
      this.setState({ isLoading: false });
    }
    if (this.state.addedWindowInExistingOrder) {
      let itemsCopy = this.state.items;
      console.log("itemsCopy", itemsCopy);

      let configuredWindowCopy = this.state.configuredWindows;
      console.log("configuredWindowCopy in first load", configuredWindowCopy);
      itemsCopy[0].windows = configuredWindowCopy;

      this.setState({ items: itemsCopy });
    }
  }

  toCloseAlert() {
    console.log("called timeout 2 sec");

    this.setState({ isNoData: false });
  }

  getOrderPrice() {
    let quotePrice = 0;
    let quoteBasePrice = 0;
    if (this.state.outerJson.quotes[0].windows) {
      for (let k = 0; k < this.state.outerJson.quotes[0].windows.length; k++) {
        quoteBasePrice +=
          this.state.outerJson.quotes[0].windows[k]["priceBase"];
        quotePrice += this.state.outerJson.quotes[0].windows[k]["priceTotal"];
        // this.state.selectedWrapColor =
        //   this.state.outerJson.quotes[0].windows[0]["customWrapColor"];
        // this has to be outside this function
        // this.state.isWrapsSelected = this.state.outerJson.quotes[0].windows[0][
        //   "customWrapColor"
        // ]
        //   ? true
        //   : false;
        // if (this.state.isWrapsSelected) {
        //   this.state.showWrapColor = true;
        // }
      }

      //this.state.outerJson.basePricePerMonth = quoteBasePrice * 0.02;
      let configuredWinLaborTotal = 0;
      if (this.state.outerJson.quotes[0].labors) {
        for (var j = 0; j < this.state.outerJson.quotes[0].labors.length; j++) {
          configuredWinLaborTotal +=
            this.state.outerJson.quotes[0].labors[j].priceTotal;
        }
      }
      // assumption only 1 quote per lead

      let newQuote = Object.assign({}, this.state.outerJson.quotes[0], {
        price: Math.round((quotePrice + configuredWinLaborTotal) * 100) / 100,
      });
      this.setState({
        outerJson: {
          ...this.state.outerJson,
          basePricePerMonth: quoteBasePrice * 0.02,
          price: Math.round((quotePrice + configuredWinLaborTotal) * 100) / 100,
          quotes: newQuote
        },
      });
    }
  }
  generateVOS = () => {
    this.setState({
      generateVOSFlag: true,
    });
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  deleteWindow = (item, lineId) => {
    console.log("Delete Window:", item, lineId);
    let itemsCopy = this.state.items;

    let configuredWindowCopy = this.state.configuredWindows;
    console.log("configuredWindowCopy", configuredWindowCopy);
    for (let i = 0; i < configuredWindowCopy.length; i++) {
      if (configuredWindowCopy[i].line === lineId) {
        configuredWindowCopy.splice(i, 1);
      }
    }
    // RXM8QHE: Updating line number as for remaining windows
    for (let i = 0; i < configuredWindowCopy.length; i++) {
      configuredWindowCopy[i].line = i + 1;
    }
    // all windows are deleted
    if (configuredWindowCopy.length === 0) {
    }
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].windows = configuredWindowCopy;
    itemsCopy[0].windows = configuredWindowCopy;

    this.setState({ items: itemsCopy });
    // deleted window price is removed from total price
    this.getOrderPrice();
  };

  checkValidToken = () => {
    var token = this.props.match.params.token;
    var tokenIsNotExpired = false;
    if (token) {
      var body = jwt_decode(token);
      var decodedHeader = jwt_decode(token, { header: true });
      if (decodedHeader.typ === "JWT" && body.scope === "scope name") {
        var currentTimestamp = new Date().getTime() / 1000;
        tokenIsNotExpired = body.exp > currentTimestamp;
        return tokenIsNotExpired;
        //return true;
      }
    }
    return true;
  };

  onChangeWrapColor = (selectedColor) => {
    console.log("onChange selected options::", selectedColor);
    this.setState({ selectedWrapColor: selectedColor });

    for (let k = 0; k < this.state.configuredWindows.length; k++) {
      let wrapExist = false;
      // eslint-disable-next-line
      this.state.configuredWindows[k].customWrapColor = selectedColor;
      if (
        this.state.configuredWindows[k].labors &&
        this.state.configuredWindows[k].labors.length > 0
      ) {
        for (
          let l = 0;
          l < this.state.configuredWindows[k].labors.length;
          l++
        ) {
          // Checking if already wrap labor exist
          if (this.state.configuredWindows[k].labors[l].specCode === "WRAP") {
            wrapExist = true;
            break;
          }
        }
        if (!wrapExist && this.state.configuredWindows[k].babWrapLabor) {
          // eslint-disable-next-line
          this.state.configuredWindows[k].labors.push(
            this.state.configuredWindows[k].babWrapLabor
          );
          // eslint-disable-next-line
          this.state.configuredWindows[k].lmsWrapId =
            this.state.configuredWindows[k].babWrapLabor.lmsLaborId;
          // eslint-disable-next-line
          this.state.configuredWindows[k].priceTotal =
            this.state.configuredWindows[k].priceTotal +
            this.state.configuredWindows[k].babWrapLabor.priceTotal;
        }
      } else {
        if (this.state.configuredWindows[k].babWrapLabor) {
          let laborArr = [];
          laborArr.push(this.state.configuredWindows[k].babWrapLabor);
          // eslint-disable-next-line
          this.state.configuredWindows[k].labors = laborArr;
          // eslint-disable-next-line
          this.state.configuredWindows[k].lmsWrapId =
            this.state.configuredWindows[k].babWrapLabor.lmsLaborId;
          // eslint-disable-next-line
          this.state.configuredWindows[k].priceTotal =
            this.state.configuredWindows[k].priceTotal +
            this.state.configuredWindows[k].babWrapLabor.priceTotal;
        }
      }
    }
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].windows = this.state.configuredWindows;

    console.log("add color", this.state.outerJson.quotes[0].windows);
    this.getOrderPrice();
  };

  onLaborChange = (newValue) => {
    console.log("onChange selected options::", newValue);
    let childrenData = {};
    let labors = {};
    let laborCopy = this.state.items;
    let configuredLaborsTotal = 0;
    let joblaborRatesData = this.state.jobLaborData;
    let miscLabor = [];
    let showLabor = false;
    let value = [];
    let defaultLaborValue = [];
    laborCopy[0]["labors"] = [];
    let initialLabor = [];

    console.log("onChange laborCopy1::", laborCopy);
    for (var i = 0; i < joblaborRatesData.length; i++) {
      childrenData = joblaborRatesData[i].children;
      console.log("onChange::childrenData", childrenData);
      for (var j = 0; j < childrenData.length; j++) {
        for (var k = 0; k < newValue.length; k++) {
          if (newValue[k] === childrenData[j].key) {
            value.push(newValue[k]);
            defaultLaborValue.push(newValue[k]);
            console.log("onChange::price", childrenData[j].price);

            labors = {
              quantity: 1,
              laborId: 0,
              modifiedProperty: "1",
              desc: childrenData[j].title,
              priceBase: childrenData[j].price,
              detailId: 0,
              specCode: childrenData[j].specCode,
              laborRule: "",
              unitOfMeasurement: childrenData[j].uom,
              quoteId: 0,
              lmsLaborId: childrenData[j].lmsItemNumber,
              priceTotal: childrenData[j].price,
            };
            initialLabor.push(childrenData[j].title);
            if (
              joblaborRatesData[i].title === "MISCELLANEOUS LABOR OPTIONS" &&
              childrenData[j].title ===
                "VinylLB Miscellaneous Labor - Job Level"
            ) {
              miscLabor.push(childrenData[j].title);

              showLabor = true;
            }

            if (laborCopy[0]["labors"].length >= 0) {
              console.log("onChange labor copy ", laborCopy[0]["labors"]);
              configuredLaborsTotal = configuredLaborsTotal + labors.priceTotal;
              laborCopy[0]["labors"].push(labors);
            }
            break;
          }
        }
      }
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].labors = laborCopy[0]["labors"];
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].notes = this.state.itemMiscNotes;
    }
    this.setState({ initialLabor: initialLabor });
    this.setState({ miscLabor: miscLabor });
    this.setState({
      showLabor: showLabor,
      value: value,
      defaultLaborValue: defaultLaborValue,
    });
    this.getOrderPrice();
  };

  onLaborDeselect(currentNode, selectedNodes) {
    console.log("on labor deselect ::", currentNode, selectedNodes);
    // let addOnlaborCost = this.state.addOnlaborCost;
    // let updatedItemLabor = this.state.selectedItemLabor;
    // console.log("before removing it", updatedItemLabor);
    // for (let i = 0; i < this.state.selectedItemLabor.length; i++) {
    //   let labor = this.state.selectedItemLabor[i];
    //   console.log("iterated labor", labor);
    //   if (labor.desc === selectedNodes.title) {
    //     addOnlaborCost = addOnlaborCost - labor.priceTotal;
    //     updatedItemLabor.splice(i, 1);
    //   }
    // }
    // console.log("updatedItemLabor", updatedItemLabor);
    // this.setState({
    //   selectedItemLabor: updatedItemLabor,
    //   addOnlaborCost: addOnlaborCost,
    // });
    // this.props.othersToStepper(
    //   this.state.units,
    //   this.state.selectedItemLabor,
    //   this.state.inHomeData,
    //   this.state.addOnlaborCost,
    //   this.state.isSubmitEnable,
    //   this.state.itemMiscNotes
    // );
  }

  deleteLabor() {
    let itemsCopy = this.state.items;
    itemsCopy[0].labors = [];

    this.setState({ items: itemsCopy });
    this.setState({
      itemMiscNotes: "",
      showLabor: false,
      defaultLaborValue: [],
      value: [],
    });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].labors = [];
    this.getOrderPrice();
  }

  deleteIndividualLabor(labor) {
    //console.log('this.state.outerJson', this.state.outerJson);
    let itemsCopy = this.state.items;
    let laborCopy = this.state.items[0].labors;
    console.log("this.state.value", this.state.value);
    let valueCopy = this.state.value;
    //let defaultLaborValue = this.state.defaultLaborValue;
    let initialLabor = this.state.initialLabor;
    let laborKey = "";
    let joblaborRatesData = this.state.jobLaborData;
    // to fetch key of the labor thats deleted, comparing with desc, assumption desc is unique
    for (var i = 0; i < joblaborRatesData.length; i++) {
      let childrenData = joblaborRatesData[i].children;

      for (var j = 0; j < childrenData.length; j++) {
        if (labor.desc === childrenData[j].title) {
          laborKey = childrenData[j].key;
          break;
        }
      }
    }

    for (var k = 0; k < valueCopy.length; k++) {
      if (valueCopy[k] === laborKey) {
        valueCopy.splice(k, 1);
        // defaultLaborValue.splice(k,1);
        break;
      }
    }
    console.log("process delete", valueCopy);
    this.setState({ value: valueCopy });
    for (let i = 0; i < laborCopy.length; i++) {
      if (laborCopy[i].desc === labor.desc) {
        console.log("price after diff", this.state.outerJson.quotes[0].price);
        if (laborCopy[i].desc === "VinylLB Miscellaneous Labor - Job Level") {
          this.setState({
            itemMiscNotes: "",
            showLabor: false,
          });
        }
        laborCopy.splice(i, 1);
        break;
      }
    }

    for (let i = 0; i < initialLabor.length; i++) {
      if (initialLabor[i] === labor.desc) {
        initialLabor.splice(i, 1);
        break;
      }
    }

    this.setState({ initialLabor: initialLabor });
    console.log("deleted", labor.desc);
    console.log("itemsCopy", itemsCopy);

    itemsCopy[0].labors = laborCopy;

    this.setState({ items: itemsCopy });
    this.getOrderPrice();

    console.log(this.state.value, "after update", valueCopy);
  }

  submitOrder = async () => {
    console.log("submitOrder updatedOuterJson", this.state.outerJson);
    this.setState({ isLoading: true });
    this.setState({ orderSubmitted: "true" });
    await this.createOrderInBackend(
      this.state.leadNumber,
      this.state.outerJson
    );
  };

  editOrder = (item, lineId, accCst, totalPrice) => {
    this.setState({ existingItemToEdit: item });
    if (item.line === lineId) {
      this.setState({ oldBabData: item.babData });
    }

    this.setState({ babWindowId: item.babWindowId });

    this.setState({ isEdit: true });

    this.setState({ lineId: lineId });
    this.setState({ redirect: true });
  };

  showModal = () => {
    this.setState({ isModalVisible: true, isEdit: false });
  };

  showLaborModal = () => {
    this.setState({ isLaborModalVisible: true });
  };

  handleOk = () => {
    //this.props.history.push('/combo-unit');
    this.setState({ redirect: true, isModalVisible: false });
  };

  handleLaborOk = () => {
    this.setState({ isLaborModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleLaborCancel = () => {
    this.setState({ isLaborModalVisible: false });
  };

  handleComboVsSingleUnitToggle = () => {};

  searchChanged = (value) => {
    this.setState({ leadNumber: value });
  };

  onSearch = (value) => {
    console.log(value);
    this.setState({ leadNumber: value });
    localStorage.setItem("leadNumber", JSON.stringify(value));
    console.log(this.state.leadNumber);
    this.getOrderedItemsForLead(value);
    console.log(this.state.orderId);
  };

  addToCart = () => {
    this.setState({ redirect: true });
    console.log("Inside Edit Order");
  };

  onQuantityChange = (labeledValue, Option) => {
    console.log("labeledValue ", labeledValue, "Option ", Option);
    if (labeledValue.split("---").length > 1) {
      let selectLabor = labeledValue.split("---")[0];
      let qty = labeledValue.split("---")[1];
      let laborObjCopy = this.state.items;

      for (let i = 0; i < laborObjCopy[0]["labors"].length; i++) {
        console.log("iterated labor", laborObjCopy[0]["labors"]);
        if (laborObjCopy[0]["labors"][i].desc === selectLabor) {
          laborObjCopy[0]["labors"][i].quantity = parseInt(qty);
          laborObjCopy[0]["labors"][i].priceTotal =
            laborObjCopy[0]["labors"][i].priceBase * parseInt(qty);
        }

        console.log("laborObjCopy", laborObjCopy);
        this.setState({ items: laborObjCopy });
      }
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].labors = laborObjCopy[0]["labors"];
      this.getOrderPrice();
    }
  };

  async getCatalogData(user) {
    let modifiedTimeStampObj = {};
    if (this.state.modifiedTimeStampObj) {
      modifiedTimeStampObj = this.state.modifiedTimeStampObj;
    } else {
      const url = process.env.REACT_APP_BASE_URL + "/getDataModifiedTimestamp"
      const modifiedTimeStampResponse = await fetch(
        url,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
            User: user ? user : this.state.user,
            'Authorization': 'Bearer ' + this.state.accessToken
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
        }
      );
      console.log("modifiedTimeStampResponse ok", modifiedTimeStampResponse);
      if (modifiedTimeStampResponse.ok) {
        modifiedTimeStampObj = await modifiedTimeStampResponse.json();
        console.log("modifiedTimeStampResponseJson", modifiedTimeStampObj);
        this.setState({ modifiedTimeStampObj: modifiedTimeStampObj });
      }
    }

    // Get series
    let responseString = "";
    let series;
    let material;
    let products = new Set();
    let productSeries = {};

    console.log("current timestamp in UTC " + getUTCTimestamp());
    let reloadData = true;
    let seriesData = localStorage.getItem("series");
    console.log(
      "localstorage enabled",
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API
    );
    console.log(" what localstorage has", localStorage);

    if (
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" &&
      seriesData
    ) {
      seriesData = JSON.parse(seriesData);
      console.log("series localstorage timestamp " + seriesData["timestamp"]);
      console.log(
        " series api timestamp" +
          modifiedTimeStampObj["productSeriesModifiedOn"]
      );

      reloadData = needsDataRefresh(
        seriesData["timestamp"],
        modifiedTimeStampObj["productSeriesModifiedOn"]
      );
      console.log("series data refresh" + reloadData);

      if (!reloadData) {
        series = seriesData["data"];
        material = JSON.parse(localStorage.getItem("material"));
        products = JSON.parse(localStorage.getItem("products"));
        productSeries = JSON.parse(localStorage.getItem("productSeries"));

        this.setState({ material: Array.from(material) });
        this.setState({ series: Array.from(series) });
        this.setState({ productSeries: productSeries });
        this.setState({ products: products });
      }
    }
    if (reloadData) {
      const url = process.env.REACT_APP_BASE_URL + "/getSeries"
      const response = await fetch(
        url,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
            User: user ? user : this.state.user,
            'Authorization': 'Bearer ' + this.state.accessToken
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
        }
      );
      //console.log("Product Series RESPONSE: ", response);

      if (response.ok) {
        // if HTTP-status is 200-299
        // get the response body (the method explained below)
        responseString = await response.json();
        productSeries = responseString["records"];
        this.setState({ productSeries: productSeries });
        console.log(" productsSeries response ", productSeries);
        let material = new Set();
        for (let record in productSeries) {
          console.log(" record ", productSeries[record]["Series_Material__c"]);
          if (productSeries[record]["Series_Material__c"] !== null) {
            material.add(productSeries[record]["Series_Material__c"]);
          }
        }
        localStorage.setItem("material", JSON.stringify(Array.from(material)));
        this.setState({ material: Array.from(material) });

        //Need to remove the hardcoding and handle it for all series
        //For now we have only one series in DB

        for (let record in productSeries) {
          if (productSeries[record]["Series__c"] !== "") {
            products.add(productSeries[record]["Description"]);
          }
        }
        var set = new Set(products);
        localStorage.setItem("products", JSON.stringify(Array.from(set)));
        this.setState({ products: products });
        let series = new Set();
        /* awd01o Translate material code to string value and get specific series of that material*/
        // if (this.state.vinylOrWood === 1) {
        //   this.setState({ selectedMaterial: "Vinyl" });

        //   //let productSeries = this.state.ProductSeries;
        //   //      let series = new Set();
        //   for (let record in productSeries) {
        //     //console.log("Product Series: ", productSeries[record]["Series_Material__c"]);
        //     if (productSeries[record]["Series_Material__c"] === "Vinyl") {
        //       series.add(productSeries[record]["Series__c"]);
        //     }
        //   }
        //   this.setState({ series: Array.from(series) });
        // }

        for (let record in productSeries) {
          //console.log(" record ", productSeries[record]["Series_Material__c"]);
          // if (
          //   productSeries[record]["Series_Material__c"] ===
          //   this.state.selectedMaterial
          // ) {
          series.add(productSeries[record]["Series__c"]);
          // }
        }
        //console.log(" Series ", Array.from(series));
        localStorage.setItem(
          "series",
          JSON.stringify({
            timestamp: getUTCTimestamp(),
            data: Array.from(series),
          })
        );
        this.setState({ series: Array.from(series) });

        localStorage.setItem(
          "productSeries",
          JSON.stringify(Array.from(productSeries))
        );
        this.setState({ productSeries: productSeries });
      } else {
        if (response.status === 403) {
          // this.setState({ isLoading: true });
          // this.setState({ isAddWindow: false });
          let errorJson = {
            appName: "BAB",
            userName: user ? user : this.state.user,
            functionName: "getCatalogData Series",
            statusCode: response.status,
            statusMessage: "Access Denied",
          };
          await errorHandler.report(JSON.stringify(errorJson));
        }
        if (response.status === 408) {
          // this.setState({ isLoading: true });
          // this.setState({ isAddWindow: false });
          let errorJson = {
            appName: "BAB",
            userName: user ? user : this.state.user,
            functionName: "getCatalogData Series",
            statusCode: response.status,
            statusMessage: "Request Time Out",
          };
          await errorHandler.report(JSON.stringify(errorJson));
        }
        if (response.status === 204) {
          // this.setState({ isNoData: true });
          // this.setState({ isLoading: false });
          // this.setState({ isAddWindow: false });
          //  errorMessage = "No data found.";
          // setTimeout(() => this.toCloseAlert(), 2000);
        }
      }
    }
    // get job labor data
    reloadData = true;
    var jobLaborData = [];
    var itemLabors = [];
    var defaultLabors = [];
    let miscLabor = [];
    let laborsData = localStorage.getItem(
      "labors-" + this.state.branch + "-" + this.state.priceList
    );
    if (
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" &&
      laborsData
    ) {
      laborsData = JSON.parse(laborsData);
      console.log("labor localstorage timestamp " + laborsData["timestamp"]);
      console.log(
        " labor api timestamp" +
          modifiedTimeStampObj["priceListLaborsModifiedOn"]
      );

      reloadData = needsDataRefresh(
        laborsData["timestamp"],
        modifiedTimeStampObj["priceListLaborsModifiedOn"]
      );
      console.log("labor data refresh", reloadData);
      if (!reloadData) {
        jobLaborData = laborsData["data"]["jobLaborData"];
        itemLabors = laborsData["data"]["itemLabors"];
        defaultLabors = laborsData["data"]["defaultLabors"];
        miscLabor = laborsData["data"]["miscLabor"];

        this.setState({
          jobLaborData: jobLaborData,
          itemLaborData: itemLabors,
          defaultLaborsData: defaultLabors,
          miscLabor: miscLabor,
        });
      }
    }
    if (reloadData) {
      const url = process.env.REACT_APP_BASE_URL +
      "/getLaborRates?" +
      "branchNumber=" +
      this.state.branch +
      "&priceListNumber=" +
      this.state.priceList
      const jobLaborResponse = await fetch(
        url,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
            User: user ? user : this.state.user,
            'Authorization': 'Bearer ' + this.state.accessToken
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
        }
      );
      console.log("labor ok", jobLaborResponse);
      if (jobLaborResponse.ok && jobLaborResponse.status === 200) {
        var jobAddOnLaborResponseJson = await jobLaborResponse.json();
        console.log("jobAddOnLaborResponseJson", jobAddOnLaborResponseJson);
        var jobLabors = jobAddOnLaborResponseJson["jobLabors"];
        itemLabors = jobAddOnLaborResponseJson["itemLabors"];
        defaultLabors = jobAddOnLaborResponseJson["defaultLabors"];

        var jobLabor;

        var objectMap = {};

        var children = [];
        var i = 0;

        for (jobLabor in jobLabors) {
          children = [];
          var subLabors = jobLabors[jobLabor];
          var subLabor;

          var j = 0;
          for (subLabor in subLabors) {
            //console.log("subLabor ", subLabors )
            //if (subLabors[subLabor].toDimension == 0){
            children.push({
              title: subLabors[subLabor].jobDesc,
              value: 0 + "-" + i + "-" + j,
              key: 0 + "-" + i + "-" + j,
              price: subLabors[subLabor].laborRate,
              lmsItemNumber: subLabors[subLabor].lmsItemNumber,
              specCode: subLabors[subLabor].specCode,
              uom: subLabors[subLabor].uom
                ? subLabors[subLabor].uom
                : "Per Opening",
            });

            if (
              jobLabor === "MISCELLANEOUS LABOR OPTIONS" &&
              children[j].title === "VinylLB Miscellaneous Labor - Job Level"
            ) {
              miscLabor.push(subLabors[subLabor].jobDesc);
            }
            j++;
            // }
          }

          objectMap = {
            title: jobLabor,
            value: 0 + "-" + i,
            key: 0 + "-" + i,
            children: children,
            checkable: false,
          };
          jobLaborData.push(objectMap);
          i++;
        }

        console.log("jobLaborData", jobLaborData);
        console.log("itemLabors", itemLabors);
        if (this.state.isMounted === true) {
          this.setState({
            jobLaborData: jobLaborData,
            itemLaborData: itemLabors,
            defaultLaborsData: defaultLabors,
            miscLabor: miscLabor,
          });
        }
        if (
          jobLaborData.length > 0 &&
          itemLabors !== {} &&
          defaultLabors !== {}
        ) {
          localStorage.setItem(
            "labors-" + this.state.branch + "-" + this.state.priceList,
            JSON.stringify({
              timestamp: getUTCTimestamp(),
              data: {
                jobLaborData: jobLaborData,
                itemLabors: itemLabors,
                defaultLabors: defaultLabors,
                miscLabor: miscLabor,
              },
            })
          );
        }
      } else {
        if (jobLaborResponse.status === 403) {
          // this.setState({ isLoading: true });
          // this.setState({ isAddWindow: false });
          let errorJson = {
            appName: "BAB",
            userName: user ? user : this.state.user,
            functionName: "getCatalogData Labors",
            statusCode: jobLaborResponse.status,
            statusMessage: "Access Denied",
          };
          await errorHandler.report(JSON.stringify(errorJson));
        }
        if (jobLaborResponse.status === 408) {
          // this.setState({ isLoading: true });
          // this.setState({ isAddWindow: false });
          let errorJson = {
            appName: "BAB",
            userName: user ? user : this.state.user,
            functionName: "getCatalogData Labors",
            statusCode: jobLaborResponse.status,
            statusMessage: "Request Time Out",
          };
          await errorHandler.report(JSON.stringify(errorJson));
        }
        if (jobLaborResponse.status === 204) {
          // this.setState({ isNoData: true });
          // this.setState({ isLoading: false });
          // this.setState({ isAddWindow: false });
          // errorMessage = "No data found.";
          // setTimeout(() => this.toCloseAlert(), 2000);
        }
      }
    }

    // get pricing API
    reloadData = true;
    let priceTaxImuResponseJson;
    let pricingTaxForAllProducts = {};

    let pricingTaxData = localStorage.getItem(
      "pricingTaxForAllProducts-" +
        this.state.branch +
        "-" +
        this.state.priceList
    );
    if (
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" &&
      pricingTaxData
    ) {
      pricingTaxData = JSON.parse(pricingTaxData);
      reloadData = needsDataRefresh(
        pricingTaxData["timestamp"],
        modifiedTimeStampObj["priceListLaborsModifiedOn"]
      );
      if (!reloadData) {
        pricingTaxForAllProducts = pricingTaxData["data"];
      }
    }

    if (reloadData) {
      const url = process.env.REACT_APP_BASE_URL +
      "/getPricing?" +
      "branchNumber=" +
      this.state.branch +
      "&priceListNumber=" +
      this.state.priceList

      let priceTaxImuResponse = await fetch(url,

        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
            User: user ? user : this.state.user,
            'Authorization': 'Bearer ' + this.state.accessToken
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
        }
      );
      if (priceTaxImuResponse.ok && priceTaxImuResponse.status === 200) {
        priceTaxImuResponseJson = await priceTaxImuResponse.json();
        let pricingTaxApi = priceTaxImuResponseJson["products"];

        console.log("pricingTaxApi ", priceTaxImuResponseJson);

        products.forEach((product) => {
          for (let taxImu in pricingTaxApi) {
            if (pricingTaxApi[taxImu]["name"] === product) {
              let imu = pricingTaxApi[taxImu]["pricing"]["imu"];
              let tax = pricingTaxApi[taxImu]["pricing"]["tax"];
              let discount = pricingTaxApi[taxImu]["pricing"]["discount"];
              pricingTaxForAllProducts[product] = {
                imu: imu,
                tax: tax,
                discount: discount,
              };
            }
          }
        });
        console.log("pricingTaxForAllProducts ", pricingTaxForAllProducts);
        if (pricingTaxForAllProducts !== {}) {
          localStorage.setItem(
            "pricingTaxForAllProducts-" +
              this.state.branch +
              "-" +
              this.state.priceList,
            JSON.stringify({
              timestamp: getUTCTimestamp(),
              data: pricingTaxForAllProducts,
            })
          );
        }
      } else {
        if (priceTaxImuResponse.status === 403) {
          // this.setState({ isLoading: true });
          // this.setState({ isAddWindow: false });
          let errorJson = {
            appName: "BAB",
            userName: user ? user : this.state.user,
            functionName: "getCatalogData Pricing",
            statusCode: priceTaxImuResponse.status,
            statusMessage: "Access Denied",
          };
          await errorHandler.report(JSON.stringify(errorJson));
        }
        if (priceTaxImuResponse.status === 408) {
          // this.setState({ isLoading: true });
          // this.setState({ isAddWindow: false });
          let errorJson = {
            appName: "BAB",
            userName: user ? user : this.state.user,
            functionName: "getCatalogData Pricing",
            statusCode: priceTaxImuResponse.status,
            statusMessage: "Request Time Out",
          };
          await errorHandler.report(JSON.stringify(errorJson));
        }
        if (priceTaxImuResponse.status === 204) {
          // this.setState({ isNoData: true });
          // this.setState({ isLoading: false });
          // this.setState({ isAddWindow: false });
          // errorMessage = "No data found.";
          // setTimeout(() => this.toCloseAlert(), 2000);
        }
      }
    }
    this.setState({ pricingTaxForAllProducts: pricingTaxForAllProducts });
  }

  /* awd01o get existing order for from Salesforce for Change Order Flow */
  async getExistingOrder(leadId, isFirstLoad) {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    let path =  "/ContentVersion?leadId=" + leadId
    const url = new URL(path, apiUrl).href;
    const existingOrderResponse = await fetch(
      url,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
          User: this.state.user,
          'Authorization': 'Bearer ' + this.state.accessToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
      }
    );

    if (existingOrderResponse.ok && existingOrderResponse.status === 200) {
      var responseJSON = await existingOrderResponse.json();
      this.setState({ isAddWindow: false });
      console.log("Existing Order JSON : ", responseJSON);
      this.setState({ existingOrderJSON: responseJSON });
      if (isFirstLoad) {
        this.setState({ configuredWindows: responseJSON.quotes[0].windows });
        this.setState({ configuredLabors: responseJSON.quotes[0].labors });
        this.setState({ outerJson: responseJSON });
        await this.getOrderItems(responseJSON.quotes);
      }

      if (this.props.location.state) {
        for (let k = 0; k < this.props.location.state.windows.length; k++) {
          // eslint-disable-next-line
          this.state.selectedWrapColor =
            this.props.location.state.windows[0]["customWrapColor"];
          // eslint-disable-next-line
          this.state.isWrapsSelected = this.props.location.state.windows[0][
            "customWrapColor"
          ]
            ? true
            : false;
          if (this.state.isWrapsSelected) {
            this.setState({showWrapColor: true})
          }
        }

        //  this.setState({ items: this.state.outerJson.quotes });
        await this.getOrderItems(this.state.outerJson.quotes);
      }
      console.log("in first load", this.state.outerJson.quotes);
      console.log("after search", this.state.configuredWindows);

      // this.getChangeOrderSummary();
      this.setState({ isLoading: false });
    } else {
      if (existingOrderResponse.status === 403) {
        // this.setState({ isLoading: false });
        // this.setState({ isAddWindow: false });

        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "getExistingOrder",
          statusCode: existingOrderResponse.status,
          statusMessage: "Access Denied",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (existingOrderResponse.status === 408) {
        // this.setState({ isLoading: false });
        // this.setState({ isAddWindow: false });

        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "getExistingOrder",
          statusCode: existingOrderResponse.status,
          statusMessage: "Request Time Out",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (existingOrderResponse.status === 204) {
        this.setState({ isLoading: false });

        // if data is there we need to load if not then it must be a new lead
      }
    }
  }
  /************************************/

  componentWillUnmount() {
    this.setState({isMounted: false})
  }

  async createOrderInBackend(leadNumber, outerJson) {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    let path =  "/ContentVersion"
    const url = new URL(path, apiUrl).href;

    const string = JSON.stringify(outerJson); //

    let orderBody = {};

    orderBody.leadId = leadNumber;
    orderBody.versionData = Buffer.from(string).toString("base64");
    orderBody.userSFID = this.state.userSFID;

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Api-Key": process.env.REACT_APP_API_KEY,
        User: this.state.user,
        'Authorization': 'Bearer ' + this.state.accessToken
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
      body: JSON.stringify(orderBody),
    });

    console.log("body ", orderBody);
    if (response.ok && response.status === 200) {
      // if HTTP-status is 200-299
      // get the response body (the method explained below)
      //responseString = await response.json();
      //localStorage.setItem('orderJson',JSON.stringify(responseString));

      console.log(" Response ", await response.json());
      this.setState({
        isDisabled: true,
        isLoading: false,
      });

      // await this.getOrderedItemsForLead(leadNumber);
    } else {
      if (response.status === 403) {
        // this.setState({ isLoading: true });
        // this.setState({ isAddWindow: false });

        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "createOrderInBackend",
          statusCode: response.status,
          statusMessage: "Access Denied",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (response.status === 408) {
        // this.setState({ isLoading: true });
        // this.setState({ isAddWindow: false });

        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "createOrderInBackend",
          statusCode: response.status,
          statusMessage: "Request Time Out",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
    }
  }

  async getOrderItems(response) {
    console.log("quotes Response ", response);
    console.log("configuredOrderData", this.props.location.state?.windows);
    console.log("configured labors", this.props.location.state?.labors);
    console.log("updatedresponse" + response);
    var orderItems = [];

    let initialLabor = [];
    let defaultLaborValue = [];
    var configuredWindowsTotal = 0;
    var configuredWinLaborTotal = 0;
    var total = 0;
    //orderItems = responseString;
    if (response) {
      for (var i = 0; i < response.length; i++) {
        var cardObject1 = {};
        var cardObject2 = {};
        cardObject1.windows = response[i].windows;
        cardObject1.labors = response[i].labors;
        console.log("windows Response ", cardObject1.windows);
        if (cardObject1.windows) {
          for (var z = 0; z < cardObject1.windows.length; z++) {
            cardObject1.productStyle = cardObject1.windows[z].productStyle;
            configuredWindowsTotal += cardObject1.windows[z].priceTotal;
            //+ (cardObject1.windows[z].babDefaultLabor /
            // (1 - cardObject1.windows[z].babLaborImu ))

            if (cardObject1.windows[z].customWrapColor !== "") {
              this.setState({ showWrapColor: true });
              this.setState({
                selectedWrapColor: cardObject1.windows[z].customWrapColor,
              });

              this.setState({ isWrapsSelected: true });
            } else {
              this.setState({ showWrapColor: true });
            }
          }
        }
        if (cardObject1.windows && cardObject1.labors) {
          if (response[0].notes) {
            console.log("notes from JSON", response[0].notes);
            this.setState({
              itemMiscNotes: response[0].notes,
            });
          }

          for (var j = 0; j < cardObject1.labors.length; j++) {
            // cardObject1.priceTotal = cardObject1.labors[j].priceTotal;
            // total = total + cardObject1.priceTotal;
            configuredWinLaborTotal += cardObject1.labors[j].priceTotal;

            if (
              cardObject1.labors[j].desc ===
              "VinylLB Miscellaneous Labor - Job Level"
            ) {
              let miscLabor = [];
              miscLabor.push(cardObject1.labors[j].desc);
              this.setState({ miscLabor: miscLabor });
              this.setState({ showLabor: true });
            }
            initialLabor.push(cardObject1.labors[j].desc);

            //this.onLaborChange(cardObject1.labors[j].desc);
          }
          console.log("set defaultLaborValue", initialLabor);
          this.setState({
            initialLabor: initialLabor,
          });
        }

        //cardObject.json =  responseString[i].ordersEntity.guid;
        console.log("configuredWindowsTotal ", configuredWindowsTotal);
        console.log("configuredWinLaborTotal ", configuredWinLaborTotal);
        console.log("card Response ", cardObject2);

        orderItems.push(cardObject1);
        // laborItems.push(cardObject2);
      }
    }

    total = configuredWindowsTotal + configuredWinLaborTotal;
    this.setState({ items: orderItems });
    // eslint-disable-next-line
    this.state.outerJson.price = Math.round(total * 100) / 100;

    if (
      orderItems.length > 0 &&
      orderItems[0].labors &&
      this.state.jobLaborData
    ) {
      let joblaborRatesData = this.state.jobLaborData;

      for (let i = 0; i < joblaborRatesData.length; i++) {
        let childrenData = joblaborRatesData[i].children;
        console.log("onChange::childrenData", childrenData);

        for (let j = 0; j < childrenData.length; j++) {
          for (var k = 0; k < initialLabor.length; k++) {
            if (initialLabor[k] === childrenData[j].title) {
              defaultLaborValue.push(childrenData[j].key);
              break;
            }
          }
        }
      }
      console.log("final defaultLaborValue", defaultLaborValue);
      this.setState({
        value: defaultLaborValue,
        defaultLaborValue: defaultLaborValue,
      });
    }
    this.setState({ isLoading: false });
    console.log("items final", orderItems);
    // this.setState({ labor: laborItems });
  }
  onAddWraps = (checked) => {
    this.setState({isWrapsSelected: checked})
    if (checked) {
      this.setState({ showWrapColor: true });
    } else {
      console.log(checked);
      this.setState({ showWrapColor: false, selectedWrapColor: "" });

      let windows = this.state.outerJson.quotes[0].windows;
      for (let k = 0; k < windows.length; k++) {
        windows[k]["customWrapColor"] = "";
        if (windows[k].labors && windows[k].labors.length > 0) {
          for (let l = 0; l < windows[k].labors.length; l++) {
            // Removing wrap labor
            if (windows[k].labors[l].specCode === "WRAP") {
              // Subtracting labor price from window price
              windows[k].priceTotal =
                windows[k].priceTotal - windows[k].labors[l].priceTotal;
              windows[k].labors.splice(l, 1);
              windows[k].lmsWrapId = 0;
            }
          }
        }
      }
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].windows = windows;

      this.setState({ isEdit: true });
      console.log("wrap toggle", this.state.outerJson.quotes[0].windows);
    }
    this.getOrderPrice();
  };
  onMiscNotesUpdate = (notes) => {
    console.log("notes", notes.target.value);

    this.setState({
      itemMiscNotes: notes.target.value,
    });
    this.setState({ isEdit: true });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].notes = notes.target.value;
  };
  // RXM8QHE: To calculate total accessory cost from babdata
  getAccessoryCost(item) {
    let accessoryCost = 0;
    if (item.babData) {
      item.babData.forEach((unit, i) => {
        console.log("unit", unit);
        if (unit["accessoryCost"]) {
          accessoryCost += unit["accessoryCost"];
        }
      });
    }
    return Math.round(accessoryCost * 100) / 100;
  }
  // Added this value to total in stepper
  // getLaborRateAndImuCalculation(item) {
  //   let laborRate = item.babDefaultLabor;
  //   let laborImu = item.babLaborImu;
  //   console.log(" getLaborRateAndImuCalculation  ", laborRate," , ", laborImu )
  //   //this.setState({laborRate, laborImu})
  //   return (laborRate /
  //   (1 - laborImu ))
  // }

  render() {
    console.log(
      "render showWrapColor",
      this.state.value,
      this.state.selectedWrapColor
    );
    console.log("match params ", this.props.match.params);
    //let installationAccessories = this.state.categoryAttributeMap['Installation Accessories']
    if (this.checkValidToken()) {
      return (
        <div>
          <Spin tip="Please wait..." spinning={this.state.isLoading}>
            <PageHeader>
              <img className="homedepot-logo" alt="homedepot-logo" src={LogoName}></img>
              HDE Configurator V1.3.3{" "}
              {this.state.leadNumber !== ""
                ? "(" + this.state.leadNumber + ")"
                : ""}
            </PageHeader>

            {/* {this.state.isNoData ? (
                <Alert
                    message="Error"
                    description={errorMessage}
                    type="error"
                    showIcon
                />
            ) : (
                <div></div>
            )} */}

            <div className="main">
              <br />

              {this.state.items.length > 0 && this.state.items[0].windows ? (
                <div>
                  <br />
                  <br />
                  <List
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={this.state.items}
                    renderItem={(item) => (
                      <List.Item>
                        {item.windows.map((item) => (
                          <Card
                            title={
                              <span>
                                <b>Line#</b>
                                {item.line}: {item.productStyle}
                                <br />
                                <b>Room:</b>
                                {"   "}
                                {item.room}
                                <br />
                                <b>Floor:</b>
                                {"   "}
                                {item.floor}
                                <br />
                                <b>Interior Color:</b>
                                {"   "}
                                {item.interiorWindowColorDesc}
                                <br />
                                <b>Exterior Color:</b>
                                {"   "}
                                {item.exteriorWindowColorDesc}
                              </span>
                            }
                          >
                            <h3>Base Window Price:${item.priceBase}</h3>

                            <h3>Accessories:${this.getAccessoryCost(item)}</h3>
                            <h3>Discount: TBD</h3>
                            <h3>
                              Total Price:$
                              {Math.round(item.priceTotal * 100) / 100}
                            </h3>
                            {item.productStyle.includes("Transom") ? (
                              <Col gutter={24}>
                                {item.productStyle
                                  .replace("Transom", "")
                                  .trim()
                                  .split("|")
                                  .reverse()
                                  .map((img) => (
                                    <Row
                                      span={
                                        this.state.items.length > 2
                                          ? 8
                                          : this.state.items.length === 1
                                          ? 24
                                          : 12
                                      }
                                    >
                                      <Image
                                        width={50}
                                        src={imageMap.get(img)}
                                      />
                                    </Row>
                                  ))}
                              </Col>
                            ) : (
                              item.productStyle
                                .trim()
                                .split("|")
                                .map((img) => (
                                  <Image width={50} src={imageMap.get(img)} />
                                ))
                            )}
                            <br />
                            <br />
                            <InputNumber min={1} max={10} defaultValue={1} />
                            <Button
                              type="primary"
                              style={{ margin: "0 8px" }}
                              disabled={
                                this.state.orderSubmitted &&
                                this.state.orderSubmitted === "true"
                              }
                              onClick={(e) =>
                                this.editOrder(
                                  item,
                                  item.line,
                                  item.priceTotal - item.priceBase,
                                  item.priceTotal
                                )
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              type="primary"
                              shape="circle"
                              icon={<DeleteFilled />}
                              disabled={
                                this.state.orderSubmitted &&
                                this.state.orderSubmitted === "true"
                              }
                              onClick={(e) =>
                                this.deleteWindow(item, item.line)
                              }
                            ></Button>
                          </Card>
                        ))}
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
              {this.state.items.length > 0 &&
              this.state.items[0].labors &&
              this.state.items[0].labors.length > 0 ? (
                <div>
                  <Card>
                    {" "}
                    <h2
                      style={{
                        fontWeight: 700,
                        fontSize: "1.6em",
                        color: "#f96302",
                      }}
                    >
                      {" "}
                      Job Labor
                    </h2>
                    <br />
                    <div>
                      <List
                        grid={{ gutter: 16, column: 2 }}
                        dataSource={this.state.items}
                        renderItem={(items) => (
                          <List.Item>
                            {items.labors.map((labor) => (
                              <Card title={labor.desc}>
                                <h3>
                                  ${labor.priceTotal}
                                  &nbsp;
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    style={{ marginTop: "-180px" }}
                                    onClick={(e) =>
                                      this.deleteIndividualLabor(labor)
                                    }
                                    icon={<DeleteFilled />}
                                  ></Button>
                                </h3>

                                <br />
                                {this.state.miscLabor.includes(labor.desc) ? (
                                  <Select
                                    style={{ width: 50 }}
                                    optionFilterProp="children"
                                    defaultValue={labor.quantity}
                                    onChange={this.onQuantityChange.bind(this)}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSearch={this.onSearch}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.quantity.map((index) => {
                                      return (
                                        <Option
                                          key={index}
                                          value={labor.desc + "---" + index}
                                        >
                                          {index}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <div>&nbsp;</div>
                                )}
                              </Card>
                            ))}
                          </List.Item>
                        )}
                      />
                    </div>
                    &nbsp;&nbsp;
                    <Button
                      type="primary"
                      icon={<DeleteFilled />}
                      onClick={this.deleteLabor.bind(this)}
                    >
                      {" "}
                      Clear All
                    </Button>{" "}
                  </Card>
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
              <br />

              {this.state.showLabor ? (
                <div>
                  <Row>
                    <h4 style={{ marginRight: "8px", marginLeft: "4px" }}>
                      Misc. Labor Notes{" "}
                    </h4>

                    <TextArea
                      id="miscText"
                      defaultValue={this.state.itemMiscNotes}
                      style={{
                        width: 700,
                        marginTop: "60px",
                        marginLeft: "-130px",
                      }}
                      onBlur={this.onMiscNotesUpdate.bind(this)}
                      allowClear={true}
                      rows={4}
                    >
                      {" "}
                    </TextArea>
                  </Row>
                </div>
              ) : (
                <br />
              )}

              <Space>
                <br />

                <div>&nbsp;</div>
                {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items[0].windows ? (
                  <Row justify="space-between">
                    <h3 style={{ marginRight: "8px", marginTop: "60px" }}>
                      {" "}
                      Add Wraps{" "}
                    </h3>
                    <Switch
                      style={{ marginTop: "60px" }}
                      checked={this.state.isWrapsSelected}
                      size="large"
                      onChange={this.onAddWraps.bind(this)}
                    />
                  </Row>
                ) : (
                  <div>&nbsp;</div>
                )}

                {this.state.isWrapsSelected ? (
                  <Row justify="space-between">
                    <h3 style={{ marginRight: "18px", marginTop: "60px" }}>
                      {" "}
                      Select Wrap{" "}
                    </h3>
                    <Select
                      placeholder="Select a Wrap color"
                      optionFilterProp="children"
                      onChange={this.onChangeWrapColor.bind(this)}
                      value={
                        this.state.selectedWrapColor
                          ? this.state.selectedWrapColor
                          : []
                      }
                      style={{ width: 200, marginTop: "60px" }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value="White">White</Option>
                      <Option value="Jefferson Tan">Jefferson Tan</Option>
                      <Option value="Aspen Gray">Aspen Gray</Option>
                      <Option value="Autumn Gold">Autumn Gold</Option>
                      <Option value="Autumn Harvest">Autumn Harvest</Option>
                      <Option value="Black">Black</Option>
                      <Option value="Brandy Wood">Brandy Wood</Option>
                      <Option value="Cameo">Cameo</Option>
                      <Option value="Cedar">Cedar</Option>
                      <Option value="Classic Cream">Classic Cream</Option>
                      <Option value="Colonial Yellow">Colonial Yellow</Option>
                      <Option value="Cape Grey">Cape Grey</Option>
                      <Option value="Corn Silk">Corn Silk</Option>
                      <Option value="Deep Granite">Deep Granite</Option>
                      <Option value="Dark Bronze">Dark Bronze</Option>
                      <Option value="Desert Sand">Desert Sand</Option>
                      <Option value="English Wedgewood">
                        English Wedgewood
                      </Option>
                      <Option value="Everest">Everest</Option>
                      <Option value="Evergreen">Evergreen</Option>
                      <Option value="Glacier Blue">Glacier Blue</Option>
                      <Option value="Harbor Grey">Harbor Grey</Option>
                      <Option value="Hazelwood">Hazelwood</Option>
                      <Option value="London Brown">London Brown</Option>
                      <Option value="Linen">Linen</Option>
                      <Option value="Lakeshore Fern">Lakeshore Fern</Option>
                      <Option value="Mahogany">Mahogany</Option>
                      <Option value="Misty Shadow">Misty Shadow</Option>
                      <Option value="Montana Suede">Montana Suede</Option>
                      <Option value="Musket Brown">Musket Brown</Option>
                      <Option value="Natural Slate">Natural Slate</Option>
                      <Option value="Newport Bay">Newport Bay</Option>
                      <Option value="Pebblestone Clay">Pebblestone Clay</Option>
                      <Option value="Portsmouth Blue">Portsmouth Blue</Option>
                      <Option value="Quirt Willow">Quirt Willow</Option>
                      <Option value="Red Brick">Red Brick</Option>
                      <Option value="Red Cedar">Red Cedar</Option>
                      <Option value="Russett Red">Russett Red</Option>
                      <Option value="Rugged Canyon">Rugged Canyon</Option>
                      <Option value="Sage">Sage</Option>
                      <Option value="Scottish Thistle">Scottish Thistle</Option>
                      <Option value="Silver Grey">Silver Grey</Option>
                      <Option value="Sandstone">Sandstone</Option>
                      <Option value="Timber">Timber</Option>
                      <Option value="Terra Cotta">Terra Cotta</Option>
                      <Option value="Tuscan Olive">Tuscan Olive</Option>
                      <Option value="Victorian Grey">Victorian Grey</Option>
                      <Option value="Vineyard Grove">Vineyard Grove</Option>
                      <Option value="Walnut">Walnut</Option>
                      <Option value="Window Almond">Window Almond</Option>
                      <Option value="Window Brown">Window Brown</Option>
                      <Option value="Window White">Window White</Option>
                      <Option value="Wicker">Wicker</Option>
                      <Option value="Woodland Green">Woodland Green</Option>
                    </Select>
                  </Row>
                ) : (
                  <div></div>
                )}
              </Space>

              <Divider />
              <div>&nbsp;</div>
              {this.state.items && this.state.items.length > 0 ? (
                <h1>Total Cart Price: ${this.state.outerJson.price}</h1>
              ) : (
                <div>&nbsp;</div>
              )}

              <Button
                type="primary"
                size="large"
                style={{ margin: "0 8px" }}
                onClick={this.showModal}
              >
                {" "}
                Add Window
              </Button>
              {this.state.items.length > 0 && this.state.items[0].windows ? (
                <Button
                  disabled={!this.state.isDisabled}
                  type="primary"
                  size="large"
                  style={{ float: "right", marginLeft: "10px" }}
                  onClick={this.generateVOS.bind(this)}
                >
                  {" "}
                  Generate VOS
                </Button>
              ) : (
                <div>&nbsp;</div>
              )}
              {this.state.items.length > 0 && this.state.items[0].windows ? (
                <Button
                  type="primary"
                  size="large"
                  style={{ margin: "0 8px" }}
                  onClick={this.showLaborModal}
                >
                  {" "}
                  {this.state.items[0].labors &&
                  this.state.items[0].labors.length > 0
                    ? "Update Job Labor"
                    : "Add Job Labor"}
                </Button>
              ) : (
                <div>&nbsp;</div>
              )}

              {this.state.items.length > 0 && this.state.items[0].windows ? (
                <Button
                  //disabled={this.state.isDisabled}
                  disabled={
                    this.state.orderSubmitted &&
                    this.state.orderSubmitted === "true"
                  }
                  type="primary"
                  size="large"
                  style={{ float: "right" }}
                  onClick={this.submitOrder.bind(this)}
                >
                  {" "}
                  Submit Order
                </Button>
              ) : (
                <div>&nbsp;</div>
              )}

              <br />
              <br />

              <Modal
                title="Configure New Product"
                visible={this.state.isModalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <p>Let's configure a new product! Redirecting new page !!</p>
                {/* <Switch checkedChildren="Combo Unit" unCheckedChildren="Single Unit" onChange={this.handleComboVsSingleUnitToggle} /> */}
                <br />
              </Modal>

              <Modal
                title="Add Labor"
                visible={this.state.isLaborModalVisible}
                destroyOnClose={true}
                onOk={this.handleLaborOk}
                onCancel={this.handleLaborCancel}
              >
                <p>Select Job Labor</p>
                <Space>
                  {
                    <Row justify="space-between">
                      <TreeSelect
                        style={{ width: "Auto", minWidth: 450 }}
                        treeDefaultExpandAll
                        treeData={this.state.jobLaborData}
                        value={this.state.value}
                        onChange={this.onLaborChange.bind(this)}
                        onDeselect={this.onLaborDeselect.bind(this)}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_PARENT}
                        placeholder="Select Labor"
                      />
                    </Row>
                  }
                </Space>
                <br />
              </Modal>
            </div>
            {this.state.generateVOSFlag ? (
              <Redirect
                push
                to={{
                  pathname: "/vosGenerate",
                  accessToken: this.state.token,
                  branchNumber: this.state.branch,
                  storeNumber: this.state.storeNumber,
                  priceList: this.state.priceList,
                  leadNumber: this.state.leadNumber,
                  leadSafeFlag: this.state.leadSafeFlag,
                  coastalFlag: this.state.coastalFlag,
                  userName: this.state.userName,
                  userSFID: this.state.userSFID,
                  openingUuid: this.state.openingUuid,
                  windows: this.state.configuredWindows,
                  itemLaborData: this.state.itemLaborData,
                  defaultLaborsData: this.state.defaultLaborsData,
                  oldBabData: this.state.oldBabData,
                  existingItemToEdit: this.state.existingItemToEdit,
                  isEdit: this.state.isEdit,
                  orderSubmitted: this.state.orderSubmitted,
                  selectedWrapColor: this.state.selectedWrapColor,
                  lineId: this.state.lineId,
                  /************************************/
                }}
              />
            ) : null}

            {this.state.redirect ? (
              <Redirect
                push
                to={{
                  pathname: "/combo-unit",
                  accessToken: this.state.accessToken,
                  branchNumber: this.state.branch,
                  leadSafeFlag: this.state.leadSafeFlag,
                  coastalFlag: this.state.coastalFlag,
                  priceList: this.state.priceList,
                  userName: this.state.userName,
                  userSFID: this.state.userSFID,
                  user: this.state.user,
                  leadNumber: this.state.leadNumber,
                  openingUuid: this.state.openingUuid,
                  windows: this.state.configuredWindows,
                  labors:
                    this.state.items && this.state.items.length > 0
                      ? this.state.items[0].labors
                      : [],
                  itemLaborData: this.state.itemLaborData,
                  defaultLaborsData: this.state.defaultLaborsData,
                  oldBabData: this.state.oldBabData,
                  existingItemToEdit: this.state.isEdit
                    ? this.state.existingItemToEdit
                    : undefined,
                  isEdit: this.state.isEdit,
                  lineId: this.state.lineId,
                  resetOptions: false,
                  selectedWrapColor: this.state.selectedWrapColor,
                  token: this.state.token,
                  babWindowId: this.state.babWindowId,
                  jobMiscNotes: this.state.itemMiscNotes,
                  series: this.state.series,
                  material: this.state.material,
                  products: this.state.products,
                  productSeries: this.state.productSeries,
                  pricingTaxForAllProducts: this.state.pricingTaxForAllProducts,
                  modifiedTimeStampObj: this.state.modifiedTimeStampObj,
                  state: {
                    branchNumber: this.state.branch,
                    priceList: this.state.priceList,
                    leadNumber: this.state.leadNumber,
                  },
                }}
              />
            ) : null}
          </Spin>
        </div>
      );
    } else {
      return (
        <div>
          <PageHeader>
            <img className="homedepot-logo" alt="homedepot-logo" src={LogoName}></img>
            HDE Configurator
          </PageHeader>
          <div
            style={{
              marginTop: 100,
              marginLeft: 500,
              fontWeight: 700,
              fontSize: "1.8em",
              color: "#f96302",
            }}
          >
            ACCESS DENIED !
          </div>
        </div>
      );
    }
  }
}
