import React, { Component } from "react";
import {
  Form,
  Select,
  Col,
  Row,
  Space,
  Divider,
  Card,
  Button,
  Checkbox,
  Alert,
  Spin,
} from "antd";

import imageMap from "../images";

import "./Window.css";
import { getUTCTimestamp, needsDataRefresh } from "../Utilities";

const rooms = {
  rooms: [
    "Basement",
    "Bathroom",
    "Bedroom",
    "Dining Room",
    "Family Room",
    "Kitchen",
    "Living Room",
    "Master Bedroom",
    "Office/Computer",
    /** RXM8QHE  - Updating Room list based on what is available in iPad.
    *  "Attic",
    "Balcony",
    "Basement",
    "Bathroom",
    "Bedroom",
    "Bedroom 1",
    "Bedroom 2",
    "Bedroom 3",
    "Bedroom 4",
    "Bonus Room",
    "Closet",
    "Court",
    "Deck",
    "Den",
    "Dining Room",
    "Dressing Room",
    "Entry/Foyer",
    "Exercise Room",
    "Family Room",
    "Game Room",
    "Garage",
    "Hall/Stairway",
    "Kitchen",
    "Lanai",
    "Laundry",
    "Living Room",
    "Loft",
    "Master Bath",
    "Master Bedroom",
    "Nook/Breakfast",
    "Nursery",
    "Office/Computer",
    "Porch",
    "Storage Room",
    "Study",
    "Sun Room",
    "Utility Room", **/
  ],
};
const floors = { floors: ["1st", "2nd", "3rd", "Attic", "Basement"] };
const COMMON_VALUE = "Double Hung";

const { Option } = Select;

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

export default class ChangeOrderStyles extends Component {
  constructor(props) {
    super(props);
    this.unitCardElement = React.createRef();

    this.state = {
      /* awd01o existing window values */
      existingOrderJSON: this.props.isEdit?this.props.existingOrderJSON:undefined,
      existingItemToEdit: this.props.isEdit?this.props.existingItemToEdit:undefined,
      existingFloor: this.props.isEdit?this.props.existingFloor:undefined,
      existingRoom: this.props.isEdit?this.props.existingRoom:undefined,
      room: this.props.isEdit? this.props.existingRoom:undefined,
      floor: this.props.isEdit? this.props.existingFloor:undefined,
      vinylOrWood: this.props.isEdit?this.props.vinylOrWood:undefined,
      currentNoOfUnitSelected: this.props.isEdit?this.props.currentNoOfUnitSelected: undefined,
      isEdit: this.props.isEdit,
      userNameUpdate: this.props.userNameUpdate,
      lineId: this.props.lineId,
      branchNumber: this.props.branchNumber,
      priceListNumber: this.props.priceListNumber,
      user: this.props.user,
      
      currentWindowStyleSelected: this.props.isEdit?this.props.currentWindowStyleSelected:undefined,
      coastalTypeReinforcement: false,
      currentSelection: "",
      lastPositionClicked: false,
      hidden: false,
      singleUnitDimensions: [],
      numberOfUnit: this.props.isEdit ? this.props.numberOfUnit : "",
      currentSeriesSelected: this.props.isEdit
        ? this.props.currentSeriesSelected
        : undefined,
      //currentSeriesSelected: "",
      radioCheckTag: "",
      disabled: false,
      units: this.props.units,
      unitList: this.props.units, //[{ unitNumber: 0, windowStyle: "", position: "" }],
      seriesTexture: [],
      productStyle: "",
      comboProductIdMap: {},
      productDimensions: {},
      unitCount: [],
      unitCountObj: {},
      uiMinMax: {},
      series: this.props.series,
      material: this.props.material,
      products: this.props.products,
      productSeries: this.props.productSeries,
      //selectedMaterial: "",
      modalVisible: false,
      showErrorIcon: false,
      showSuccessIcon: false,
      showUnits: this.props.isEdit ? this.props.showUnits : false,
      categoryAttributeMap: {},
      installType: ["Insert"],
      categories: [],
      compatibleAttributes: [],
      seriesProductResponse: [],
      comboProductStyleMap: {},
      panelsInCombo: [],
      offset: 0,
      leadNumber: "",
      pricingMap: [],
      accessToken: this.props.accessToken,
      productDimensionsMap: {},
      commonCategories: this.props.commonCategories,
      windowStyleLoadingFlag: true,
      productCategories: {},
      productCategoriesSettings: {},
      isSeries: "",
      isUnits: "",
      isFloor: "",
      isRoom: "",
      isStyle: "",
      isLoading: false,
      isNextBtnDisable: this.props.isEdit ? false : true,
      pricingTaxForAllProducts: this.props.pricingTaxForAllProducts,
      currentOpeningWidth: this.props.currentOpeningWidth,
      currentOpeningHeight: this.props.currentOpeningHeight,
      modifiedTimeStampObj: this.props.modifiedTimeStampObj,
       spinner: false,
    };
    this.listAttributesOfCategory = this.listAttributesOfCategory.bind(this);
    this.onChangeNumberOfUnits = this.onChangeNumberOfUnits.bind(this);
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleOk = () => {
    this.setState({ modalVisible: false });
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({ modalVisible: false });
  };

  async onChangeMaterial(value) {
    //TODO: Remove xyz from series
    this.setState({isSeries:"error"})
    this.setState({isUnits:"error"})
    this.setState({isFloor:"error"})
    this.setState({isRoom:"error"})
    this.setState({isStyle:"error"})
    let productSeries = this.state.productSeries;
    this.setState({ selectedMaterial: value });
    console.log("Selected Material", value);
    this.setState({
      currentSeriesSelected: null,
      currentNoOfUnitSelected: null,
      currentWindowStyleSelected: null,
    });

    /*i) Call regenerate list of Series
    ii) Regenerate number ofunits
    iii) Generate a list of products
    iv) Generate min max of individual products for this and UI dimensions to be loaded
    v) Remove xyz from the series
    */
    this.setState({ series: null });
    let series = new Set();
    for (let record in productSeries) {
      console.log(" record ", productSeries[record]["Series_Material__c"]);
      if (productSeries[record]["Series_Material__c"] === value) {
        series.add(productSeries[record]["Series__c"]);
      }
    }
    //series.add("xyz");
    console.log(" Series ", Array.from(series));
    this.setState({ series: Array.from(series) });
  }

  async onChangeSeries(value) {
    //TODO: Change the productDimensionsMap name later
    console.info("Entering Change series");
    this.setState({isSeries:""})
    let productSeries = this.state.productSeries;
    let productDimensionsMap = {};
    let products = new Set();
    /* Remove some of the logic below and move to component did mount)*/
    for (let record in productSeries) {
      console.log(" record ", productSeries[record]["Series_Material__c"]);
      if (productSeries[record]["Series__c"] === value) {
        products.add(productSeries[record]["Description"]);
        productDimensionsMap[productSeries[record]["Description"]] = {
          minHeight: productSeries[record]["Minimum_Height__c"],
          minWidth: productSeries[record]["Minimum_Width__c"],
          maxHeight: productSeries[record]["Maximum_Height__c"],
          maxWidth: productSeries[record]["Maximum_Width__c"],
          maxUI: productSeries[record]["Maximum_UI__c"],
          id: productSeries[record]["Id"],
          styleCommon1: productSeries[record]["Style_Common_1__c"],
          styleCommon2: productSeries[record]["Style_Common_2__c"],
          styleCommon3: productSeries[record]["Style_Common_3__c"],
          productCode: productSeries[record]["ProductCode"],
          productStyleCode: productSeries[record]["Abbreviation__c"],
          lmsCode: productSeries[record]["LMS_Code__c"],
        };
      }
    }
    let unitCountObj = {};
    let productList = Array.from(products);
    for (let product in productList) {
      let parsedArray = productList[product].includes('Transom')? productList[product].split("|").reverse() : productList[product].split("|");
      console.log(productList[product],'logic',productList[product].includes('Transom'), parsedArray);
      let unitLength = parsedArray.length;

      if (!unitCountObj.hasOwnProperty(unitLength)) {
        unitCountObj[unitLength] = [];
      }
      unitCountObj[unitLength].push(productList[product]);
    }

    console.log(" unitCountObj ", unitCountObj);
    this.setState(
      {
        currentSeriesSelected: value,
        unitCount: Object.keys(unitCountObj),
        unitCountObj: unitCountObj,

        productDimensionsMap: productDimensionsMap,
      },
      () => {
        if (this.state.isEdit) {
          this.onChangeNumberOfUnits(this.props.currentNoOfUnitSelected);
        }
      }
    );

    // this.setState(
    //   {newState: 'whatever'},
    //   () => {/*do something after the state has been updated*/}
    // )

    /*

      //replace the call process productAttributeJson
      for (product in products){
        let productPricingMap = {};
          productPricingMap["productName"] = products[product]["ProductName"]
          productPricingMap["price"] = [];
          console.log("--------productName-----", productPricingMap["productName"]);
          let categories = products[product]["Categories"];
          let category = []
          for (category in categories) {
            let attribute = []
            let attributeList = categories[category]["Attributes"]
            let categoryName = categories[category]["CategoryName"]
            console.log(" Inside Category ");

            for(attribute in attributeList ){
              let objectMap = {}

              console.log(" Inside Attribute ");
              objectMap["value"] = attributeList[attribute]["AttributeName"]
              objectMap["price"] = attributeList[attribute]["Price"]
              objectMap["height"] = attributeList[attribute]["height"]
              objectMap["width"] = attributeList[attribute]["width"]
              objectMap["category"] = categoryName
              productPricingMap["price"].push(objectMap);
            }
          }
      productsPricingCopy.push(productPricingMap);
      }
      this.setState({
        pricingMap: productsPricingCopy,
      });
      console.log("-------------------productPricingMap------- ", productsPricingCopy );
  */
    //TODO: Series is not passed yet
    // Compatible Attribute API call
  }

  onChangeNumberOfUnits = (value) => {
    this.setState({isUnits:""})
    this.setState({ seriesProductResponse: this.state.unitCountObj[value] });
    this.setState({ currentNoOfUnitSelected: value });
    this.setState({ currentWindowStyleSelected: "" });
    this.setState({ showUnits: false });
    console.log(" seriesProductResponse ", this.state.unitCountObj[value]);
  };

  onChangeRoom = (value) => {
    console.log("onChangeRoom", this.state.unitList);
      let unitListCopy = this.state.units;
      let x;
      let unitCopy = {};
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let roomUpdated = {
      room: value,
      floor: this.state.floor,
      updatedDate: currDate,
      updatedTime: currTime,
      userNameUpdate: this.state.userNameUpdate,
    };

    for (x in unitListCopy) {
        unitCopy = unitListCopy[x];
        unitCopy["room"] = value;
        unitListCopy[x] = unitCopy;
      }
      this.setState({ units: unitListCopy });
      this.setState({ room: value });
      this.setState({ existingRoom: value });
      console.log("inside room", unitListCopy);
      if (
          this.state.currentWindowStyleSelected !== "" &&
          this.state.floor !== ""
      ){
        this.setState({isNextBtnDisable:false})
        this.selectedWindowStyle(
            this.state.currentWindowStyleSelected,
            value,
            this.state.floor,
            roomUpdated,
            this.state.isNextBtnDisable
        );
      }
    
  };

  onChangeFloor = (value) => {
    console.log("onChangeFloor", this.state.unitList);

      let unitListCopy = this.state.units;
      let x;
      let unitCopy;
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let floorUpdated = {
      room: this.state.room,
      floor: value,
      updatedDate: currDate,
      updatedTime: currTime,
      userNameUpdate: this.state.userNameUpdate,
    };

      for (x in unitListCopy) {
        unitCopy = unitListCopy[x];
        unitCopy["floor"] = value;
        unitListCopy[x] = unitCopy;
      }
      this.setState({ units: unitListCopy });
      this.setState({ floor: value });
      this.setState({ existingFloor: value });
      console.log("inside floor", unitListCopy);
      if (
          this.state.currentWindowStyleSelected !== undefined &&
          this.state.room !== undefined
      ) {
        this.setState({isNextBtnDisable:false})
        this.selectedWindowStyle(
            this.state.currentWindowStyleSelected,
            this.state.room,
            value,
            floorUpdated,
            this.state.isNextBtnDisable
        );
      }
    
  };

  selectedWindowStyle(
      currentWindowStyleSelected,
      room,
      floor,
      updatedValues,
      isNextBtnDisable
  ) {
    console.log("selectedWindowStyle", room);
    console.log("selectedWindowStyle", floor);
    this.setState({isNextBtnDisable})
    // Check below code to see why this was added
    let products = this.state.productList;
    console.log(" Combo ID map ", this.state.products);
    let panelsInCombo = [];
    let unitListCopy = [];
    let matchesProducts = [];

    let productDimensionsCopy = this.state.productDimensionsMap;
    let uiMinMaxCopy = this.state.uiMinMax;

    panelsInCombo = currentWindowStyleSelected.includes("Transom")? currentWindowStyleSelected.split("|").reverse():currentWindowStyleSelected.split("|");
    products = this.state.seriesProductResponse;

    let product;
    let productData = {};
    let panel;
    let orientation = "Horizontal";
    //Stripping the Transom Keyword from Product Name
    for (product in products) {
      for (panel in panelsInCombo) {
        if (panelsInCombo[panel].includes("Transom")) {
          orientation = "Vertical";
          panelsInCombo[panel] = panelsInCombo[panel]
              .replace("Transom", "")
              .trim();
        }

        //panel.replace("Transom", "");

        //panel.trim();
        if (products[product] === panelsInCombo) {
          productData[panel] = products[product];
          console.log("productData[panel]  ", productData[panel]);
        }
      }
    }

    for (let product in productDimensionsCopy) {
      console.log(" productStyle  ", product);
      if (product === currentWindowStyleSelected) {
        console.log("product Matched ", product);
        matchesProducts.push(productDimensionsCopy[product].id);
        uiMinMaxCopy = {
          productStyle: product,
          id: productDimensionsCopy[product].id,
          minWidth: productDimensionsCopy[product].minWidth,
          minHeight: productDimensionsCopy[product].minHeight,
          maxHeight: productDimensionsCopy[product].maxHeight,
          maxWidth: productDimensionsCopy[product].maxWidth,
          maxUI: productDimensionsCopy[product].maxUI,
          styleCommon1: productDimensionsCopy[product].styleCommon1,
          styleCommon2: productDimensionsCopy[product].styleCommon2,
          styleCommon3: productDimensionsCopy[product].styleCommon3,
          productCode: productDimensionsCopy[product].productCode,
          productStyleCode: productDimensionsCopy[product].productStyleCode,
          lmsCode: productDimensionsCopy[product].lmsCode,
        };
      }
    }
    console.log(" Pricing Map in unitList", this.state.pricingMap);
    console.log(" Pricing tax in unitList", this.state.pricingTaxForAllProducts);





    let unitNumber = 1;
    for (panel in panelsInCombo) {
      unitListCopy.push({
        unitNumber: unitNumber++,
        productCategories: this.state.productCategories[panelsInCombo[panel]],
        productCategoriesSettings:
            this.state.productCategoriesSettings[panelsInCombo[panel]],
        compatibilityAttributeMap:
            this.state.categoryAllProductAttributeMap[panelsInCombo[panel]],
        productAttributesMap:
            this.state.productAttributes[panelsInCombo[panel]],
        windowStyle: panelsInCombo[panel],
        pricingMap: this.state.pricingMap[panelsInCombo[panel]],
        pricingTaxImu: this.state.pricingTaxForAllProducts[panelsInCombo[panel]],
        orientation: orientation,
        room: room,
        floor: floor,
        updatedDateTimeValues: updatedValues? updatedValues: "",
        productId: productData[panel],
        bayAngle: 0,
        seatBoard: false,
        detailUuid: "525C950F-EBF1-44B1-A595-CE584583840B",
        tileStates: [],
        flankerLength: 0,
        lmsTieSoffitId: 0,
        widthOfOverhang: -1,
        lmsVendorId: 2,
        selectedSashButtonTag: -1,
        openingUuid: "9A431A07-90FC-46D6-B9E1-5C517F560506",
      });
    }
    /*
    for (x in panelsInCombo)
    {
      unitListCopy.push({ unitNumber: y++ , windowStyle: panelsInCombo[x], orientation: this.state.comboProductStyleMap[value], room: this.state.room, floor: this.state.floor });
    }
    */

    this.setState({
      showUnits: true,
      unitList: unitListCopy,
      uiMinMax: uiMinMaxCopy,
    });
    console.log(" panelsInCombo", panelsInCombo);
    console.log("Min max", uiMinMaxCopy);
    console.log(" unitList after  ", unitListCopy);

    for (let x in unitListCopy) {
      //unitListCopy[x]["totalPrice"] = 0.0;
      
      for (product in productDimensionsCopy) {
        if (product === unitListCopy[x].windowStyle) {
          unitListCopy[x]["minWidth"] =
              productDimensionsCopy[product]["minWidth"];
          unitListCopy[x]["minHeight"] =
            productDimensionsCopy[product]["minHeight"];
          // RXM8QHE: As per new requirement the width/height will be decided in dimensions page, so default values are not required.
          //unitListCopy[x]["width"] = productDimensionsCopy[product]["minWidth"];
          // unitListCopy[x]["height"] =
          //   productDimensionsCopy[product]["minHeight"];
          unitListCopy[x]["maxWidth"] =
              productDimensionsCopy[product]["maxWidth"];
          unitListCopy[x]["maxHeight"] =
              productDimensionsCopy[product]["maxHeight"];
          unitListCopy[x]["styleCommon1"] =
              productDimensionsCopy[product]["styleCommon1"];
          unitListCopy[x]["styleCommon2"] =
              productDimensionsCopy[product]["styleCommon2"];
          unitListCopy[x]["styleCommon3"] =
              productDimensionsCopy[product]["styleCommon3"];
          unitListCopy[x]["productCode"] =
              productDimensionsCopy[product]["productCode"];
          unitListCopy[x]["productStyleCode"] =
              productDimensionsCopy[product]["productStyleCode"];
          unitListCopy[x]["productId"] = productDimensionsCopy[product]["id"];
          unitListCopy[x]["lmsCode"] =
              productDimensionsCopy[product]["lmsCode"];
        }
      }
    }

    console.log("Final Unit List  ", unitListCopy);
    console.log("UI Min/Max  ", uiMinMaxCopy);
    console.log("isNextBtnDisable", this.state.isNextBtnDisable);
    this.props.comboToStepper(
        this.state.numberOfUnit,
        unitListCopy,
        uiMinMaxCopy,
        this.state.currentSeriesSelected,
        this.state.categories,
        { ...this.state.categoryAttributeMap },
        this.state.pricingMap,
        //this.state.itemLaborData,
        //this.state.jobLaborData,
        this.state.categoryAllProductAttributeMap,
        this.state.commonCategoriesAttributeMap,
        this.state.productCategories,
        this.state.isEdit,
        this.state.isNextBtnDisable,
        this.state.currentWindowStyleSelected,
        this.state.currentOpeningWidth,
        this.state.currentOpeningHeight
    );
  }

  listAttributesOfCategory = (
    categoryName,
    productName,
    categoryAllProductAttributeMap
  ) => {
    console.log("listAttributesOfCategory ", categoryAllProductAttributeMap);
    let categories = ["Interior Color", "Exterior Color"];

    const categoryIndex = categories.indexOf(categoryName);
    let attributes = [];
    let catAtt = [];
    if (categoryIndex !== -1) {
      attributes =
        categoryAllProductAttributeMap[productName][categoryIndex][
          "Attributes"
        ];
    }

    attributes.forEach((attribute) => {
      catAtt.push(attribute.AttributeName);
    });
    console.log("Cat Attributes ", catAtt);
    return catAtt;
  };

  listAttributesOfAllCategory = (
    categoryName,
    productName,
    categoryAllProductAttributeMap
  ) => {
    console.log("listAttributesOfCategory ", categoryAllProductAttributeMap);

    let categories = this.state.productCategories[productName];

    const categoryIndex = categories.indexOf(categoryName);
    let attributes = [];
    let catAtt = [];
    if (categoryIndex !== -1) {
      attributes =
        categoryAllProductAttributeMap[productName][categoryIndex][
          "Attributes"
        ];
    }

    attributes.forEach((attribute) => {
      catAtt.push(attribute.AttributeName);
    });
    console.log("Cat Attributes ", catAtt);
    return catAtt;
  };

  async onChangeStyle(value) {
    //TODO 1: Store the product Salesforce ID. Ramya needs to adjust in Backend
    //TODO 2: Set Pricing for the products
    //TODO 3: Check the products value
    this.setState({ spinner: true });
    console.log("onChangeStyle", value);
    console.log("***isEdit, ", this.state.isEdit);
    if(this.props.currentWindowStyleSelected!== value){
      this.setState({ currentOpeningWidth: null,currentOpeningHeight: null });
    }
    

    if (this.state.isEdit) {
      this.setState({ currentWindowStyleSelected: value });
      let currentWindowStyleSelected = value;
      let products = this.state.productList;
      console.log(" Combo ID map ", this.state.products);
      let panelsInCombo = [];
      let unitListCopy = [];
      let matchesProducts = [];

      let productDimensionsCopy = this.state.productDimensionsMap;
      let uiMinMaxCopy = this.state.uiMinMax;

      panelsInCombo = currentWindowStyleSelected.includes("Transom")? currentWindowStyleSelected.split("|").reverse():currentWindowStyleSelected.split("|");
      products = this.state.seriesProductResponse;

      let product;
      let productData = {};
      let panel;
      let orientation = "Horizontal";
      //Stripping the Transom Keyword from Product Name
      for (product in products) {
        for (panel in panelsInCombo) {
          if (panelsInCombo[panel].includes("Transom")) {
            orientation = "Vertical";
            panelsInCombo[panel] = panelsInCombo[panel]
              .replace("Transom", "")
              .trim();
          }

          //panel.replace("Transom", "");

          //panel.trim();
          if (products[product] === panelsInCombo) {
            productData[panel] = products[product];
            console.log("productData[panel]  ", productData[panel]);
          }
        }
      }

      for (let product in productDimensionsCopy) {
        console.log(" productStyle  ", product);
        if (product === currentWindowStyleSelected) {
          console.log("product Matched ", product);
          matchesProducts.push(productDimensionsCopy[product].id);
          uiMinMaxCopy = {
            productStyle: product,
            id: productDimensionsCopy[product].id,
            minWidth: productDimensionsCopy[product].minWidth,
            minHeight: productDimensionsCopy[product].minHeight,
            maxHeight: productDimensionsCopy[product].maxHeight,
            maxWidth: productDimensionsCopy[product].maxWidth,
            maxUI: productDimensionsCopy[product].maxUI,
            styleCommon1: productDimensionsCopy[product].styleCommon1,
            styleCommon2: productDimensionsCopy[product].styleCommon2,
            styleCommon3: productDimensionsCopy[product].styleCommon3,
            productCode: productDimensionsCopy[product].productCode,
            productStyleCode: productDimensionsCopy[product].productStyleCode,
            lmsCode: productDimensionsCopy[product].lmsCode,
          };
        }
      }
      console.log(" Pricing Map in unitList", this.state.pricingMap);
      console.log(
        " Pricing tax in unitList",
        this.state.pricingTaxForAllProducts
      );
      let unitNumber = 1;
      for (panel in panelsInCombo) {
        /**********awd01o */
        
        //let value = { "Oriel Ratio": "50/50" };
        //valueSelected.push(value);
        //value = ["Glass Sash", "Top"];
        //valueSelected.push(value);
        //value = ["key", 0, "Category", "Oriel Ratio"]
        /**********awd01o */
        unitListCopy.push({
          unitNumber: unitNumber++,
          productCategories: this.state.productCategories[panelsInCombo[panel]],
          productCategoriesSettings:
            this.state.productCategoriesSettings[panelsInCombo[panel]],
          compatibilityAttributeMap:
            this.state.categoryAllProductAttributeMap[panelsInCombo[panel]],
          productAttributesMap:
            this.state.productAttributes[panelsInCombo[panel]],
          windowStyle: panelsInCombo[panel],
          pricingMap: this.state.pricingMap[panelsInCombo[panel]],
          pricingTaxImu:
            this.state.pricingTaxForAllProducts[panelsInCombo[panel]],
          orientation: orientation,
          room: this.state.room,
          floor: this.state.floor,
          productId: productData[panel],
          bayAngle: 0,
          seatBoard: false,
          detailUuid: "525C950F-EBF1-44B1-A595-CE584583840B",
          tileStates: [],
          flankerLength: 0,
          lmsTieSoffitId: 0,
          widthOfOverhang: -1,
          lmsVendorId: 2,
          selectedSashButtonTag: -1,
          openingUuid: "9A431A07-90FC-46D6-B9E1-5C517F560506",
          /**********awd01o */
          //width: 111,
          //height: height,
          //valueSelected: value,
          /**********awd01o */
        });
      }
      /*
      for (x in panelsInCombo)
      {
        unitListCopy.push({ unitNumber: y++ , windowStyle: panelsInCombo[x], orientation: this.state.comboProductStyleMap[value], room: this.state.room, floor: this.state.floor });
      }
      */

      this.setState({
        showUnits: true,
        unitList: unitListCopy,
        uiMinMax: uiMinMaxCopy,
      });
      console.log(
        "***unitList valueSelected, ",
        this.state.unitList.valueSelected
      );
      console.log(" panelsInCombo", panelsInCombo);
      console.log("Min max", uiMinMaxCopy);
      console.log(" unitList after  ", unitListCopy);

      for (let x in unitListCopy) {
        //unitListCopy[x]["totalPrice"] = 0.0;
        
        for (product in productDimensionsCopy) {
          if (product === unitListCopy[x].windowStyle) {
            unitListCopy[x]["minWidth"] =
              productDimensionsCopy[product]["minWidth"];
            unitListCopy[x]["minHeight"] =
              productDimensionsCopy[product]["minHeight"];
            // RXM8QHE: As per new requirement the width/height will be decided in dimensions page, so default values are not required.
            // unitListCopy[x]["width"] =
            //   productDimensionsCopy[product]["minWidth"];
            // unitListCopy[x]["height"] =
            //   productDimensionsCopy[product]["minHeight"];
            unitListCopy[x]["maxWidth"] =
              productDimensionsCopy[product]["maxWidth"];
            unitListCopy[x]["maxHeight"] =
              productDimensionsCopy[product]["maxHeight"];
            unitListCopy[x]["styleCommon1"] =
              productDimensionsCopy[product]["styleCommon1"];
            unitListCopy[x]["styleCommon2"] =
              productDimensionsCopy[product]["styleCommon2"];
            unitListCopy[x]["styleCommon3"] =
              productDimensionsCopy[product]["styleCommon3"];
            unitListCopy[x]["productCode"] =
              productDimensionsCopy[product]["productCode"];
            unitListCopy[x]["productStyleCode"] =
              productDimensionsCopy[product]["productStyleCode"];
            unitListCopy[x]["productId"] = productDimensionsCopy[product]["id"];
            unitListCopy[x]["lmsCode"] =
              productDimensionsCopy[product]["lmsCode"];
          }
        }
      }

      console.log("Final Unit List  ", unitListCopy);
      console.log("UI Min/Max  ", uiMinMaxCopy);
      this.props.comboToStepper(
        this.state.numberOfUnit,
        unitListCopy,
        uiMinMaxCopy,
        this.state.currentSeriesSelected,
        this.state.categories,
        { ...this.state.categoryAttributeMap },
        this.state.pricingMap,
        //this.state.itemLaborData,
        //this.state.jobLaborData,
        this.state.categoryAllProductAttributeMap,
        this.state.commonCategoriesAttributeMap,
        this.state.productCategories,
        this.state.isEdit,
        this.state.isNextBtnDisable,
        this.state.currentWindowStyleSelected,
        this.state.currentOpeningWidth,
        this.state.currentOpeningHeight
      );
    } else {
      this.setState({ currentWindowStyleSelected: value });
      this.setState({ isLoading: true });
      //logger.info('Entered onChangeStyle');
      this.setState({isStyle:""})
      //TODO 1: Store the product Salesforce ID. Ramya needs to adjust in Backend
      //TODO 2: Set Pricing for the products
      //TODO 3: Check the products value
      console.log("onChangeStyle", value);
      this.setState({ currentWindowStyleSelected: value });
      console.log("room", this.state.room);
      console.log("floor", this.state.floor);
      this.setState({isNextBtnDisable:true})
      if (this.state.room !== undefined && this.state.floor !== undefined) {
        this.setState({isNextBtnDisable:false})
      }

      // Check below code to see why this was added
      let products = this.state.productList;
      console.log(" Combo ID map ", this.state.products);
      let panelsInCombo = [];
      let unitListCopy = [];
      let matchesProducts = [];

      let productDimensionsCopy = this.state.productDimensionsMap;
      let uiMinMaxCopy = this.state.uiMinMax;

      panelsInCombo = value ? value.includes("Transom")? value.split("|").reverse():value.split("|") : [];
      products = this.state.seriesProductResponse;

      let product;
      let productData = {};
      let panel;
      let orientation = "Horizontal";
      //Stripping the Transom Keyword from Product Name
      for (product in products) {
        for (panel in panelsInCombo) {
          if (panelsInCombo[panel].includes("Transom")) {
            orientation = "Vertical";
            panelsInCombo[panel] = panelsInCombo[panel]
              .replace("Transom", "")
              .trim();
          }

          //panel.replace("Transom", "");

          //panel.trim();
          if (products[product] === panelsInCombo) {
            productData[panel] = products[product];
            console.log("productData[panel]  ", productData[panel]);
          }
        }
      }

      for (let product in productDimensionsCopy) {
        console.log(" productStyle  ", product);
        if (product === value) {
          console.log("product Matched ", product);
          matchesProducts.push(productDimensionsCopy[product].id);
          uiMinMaxCopy = {
            productStyle: product,
            id: productDimensionsCopy[product].id,
            minWidth: productDimensionsCopy[product].minWidth,
            minHeight: productDimensionsCopy[product].minHeight,
            maxHeight: productDimensionsCopy[product].maxHeight,
            maxWidth: productDimensionsCopy[product].maxWidth,
            maxUI: productDimensionsCopy[product].maxUI,
            styleCommon1: productDimensionsCopy[product].styleCommon1,
            styleCommon2: productDimensionsCopy[product].styleCommon2,
            styleCommon3: productDimensionsCopy[product].styleCommon3,
          productCode: productDimensionsCopy[product].productCode,
            productStyleCode: productDimensionsCopy[product].productStyleCode,
            lmsCode: productDimensionsCopy[product].lmsCode,
          };
        }
      }

      let unitNumber = 1;
      for (panel in panelsInCombo) {
        unitListCopy.push({
          unitNumber: unitNumber++,
          productCategories: this.state.productCategories[panelsInCombo[panel]],
          productCategoriesSettings:
            this.state.productCategoriesSettings[panelsInCombo[panel]],
          compatibilityAttributeMap:
            this.state.categoryAllProductAttributeMap[panelsInCombo[panel]],
          productAttributesMap:
            this.state.productAttributes[panelsInCombo[panel]],
          windowStyle: panelsInCombo[panel],
          pricingMap: this.state.pricingMap[panelsInCombo[panel]],
          pricingTaxImu:
            this.state.pricingTaxForAllProducts[panelsInCombo[panel]],
          orientation: orientation,
          room: this.state.room,
          floor: this.state.floor,
          productId: productData[panel],
          bayAngle: 0,
          seatBoard: false,
          detailUuid: "525C950F-EBF1-44B1-A595-CE584583840B",
          tileStates: [],
          flankerLength: 0,
          lmsTieSoffitId: 0,
          widthOfOverhang: -1,
          lmsVendorId: 2,
          selectedSashButtonTag: -1,
          openingUuid: "9A431A07-90FC-46D6-B9E1-5C517F560506",
        });
      }
      /*
      for (x in panelsInCombo)
      {
        unitListCopy.push({ unitNumber: y++ , windowStyle: panelsInCombo[x], orientation: this.state.comboProductStyleMap[value], room: this.state.room, floor: this.state.floor });
      }
      */

      this.setState({
        showUnits: true,
        unitList: unitListCopy,
        uiMinMax: uiMinMaxCopy,
      });
      console.log(" panelsInCombo", panelsInCombo);
      console.log("Min max", uiMinMaxCopy);
      console.log(" unitList after  ", unitListCopy);

    for (let x in unitListCopy) {
      //unitListCopy[x]["totalPrice"] = 0.0;
      
      for (product in productDimensionsCopy) {
        if (product === unitListCopy[x].windowStyle) {
          unitListCopy[x]["minWidth"] =
            productDimensionsCopy[product]["minWidth"];
          unitListCopy[x]["minHeight"] =
            productDimensionsCopy[product]["minHeight"];
          // unitListCopy[x]["width"] = productDimensionsCopy[product]["minWidth"];
          // unitListCopy[x]["height"] =
          //   productDimensionsCopy[product]["minHeight"];
          unitListCopy[x]["maxWidth"] =
            productDimensionsCopy[product]["maxWidth"];
          unitListCopy[x]["maxHeight"] =
            productDimensionsCopy[product]["maxHeight"];
          unitListCopy[x]["styleCommon1"] =
            productDimensionsCopy[product]["styleCommon1"];
          unitListCopy[x]["styleCommon2"] =
            productDimensionsCopy[product]["styleCommon2"];
          unitListCopy[x]["styleCommon3"] =
            productDimensionsCopy[product]["styleCommon3"];
          unitListCopy[x]["productCode"] =
            productDimensionsCopy[product]["productCode"];
            unitListCopy[x]["productStyleCode"] =
              productDimensionsCopy[product]["productStyleCode"];

            unitListCopy[x]["productId"] = productDimensionsCopy[product]["id"];
            unitListCopy[x]["lmsCode"] =
              productDimensionsCopy[product]["lmsCode"];
          }
        }
      }
      console.log("Final Unit List  ", unitListCopy);
      console.log("UI Min/Max  ", uiMinMaxCopy);
      this.props.comboToStepper(
        this.state.numberOfUnit,
        unitListCopy,
        uiMinMaxCopy,
        this.state.currentSeriesSelected,
        this.state.categories,
        { ...this.state.categoryAttributeMap },
        this.state.pricingMap,
        //this.state.itemLaborData,
        //this.state.jobLaborData,
        this.state.categoryAllProductAttributeMap,
        this.state.commonCategoriesAttributeMap,
        this.state.productCategories,
          this.state.isEdit,
          this.state.isNextBtnDisable,
        this.state.currentWindowStyleSelected,
        this.state.currentOpeningWidth,
        this.state.currentOpeningHeight
      );
    }
    this.setState({ spinner: false });
    this.setState({ isLoading: false });
  }
  /*Commenting below as we are not handling position change as of now

  onChangePosition = (unitElement, event) => {
    console.log("Unit Clicked ", unitElement)
    console.log(" Position ", event)

    let unitListCopy = this.state.unitList;
    let x;

    if (!containsObject(unitElement, unitListCopy)) {
      unitListCopy.push({ unitNumber: unitElement, windowStyle: event, position: event });
    }
    else {
      for (x in unitListCopy) {
        if (unitListCopy[x].unitNumber === unitElement) {
          unitListCopy[x].position = event

        }
      }
    }
    this.setState({ unitList: unitListCopy })

  }
*/
  onChangeCoastalReinforcement = (checked, event) => {
    this.setState({ coastalTypeReinforcement: checked });
  };

  onApplyHandler = () => {
    console.log(
      " Inside Save  single Unit dimensions ",
      this.state.singleUnitDimensions
    );

    let unitListCopy = this.state.unitList;
    let individualDimensions = this.state.singleUnitDimensions;
    var x;
    var y;

    for (x in unitListCopy) {
      unitListCopy[x]["totalPrice"] = 0.0;
      for (y in individualDimensions) {
        if (
          unitListCopy[x].windowStyle === individualDimensions[y].productStyle
        ) {
          unitListCopy[x]["minWidth"] = individualDimensions[y]["minWidth"];
          unitListCopy[x]["minHeight"] = individualDimensions[y]["minHeight"];
          unitListCopy[x]["maxWidth"] = individualDimensions[y]["maxWidth"];
          unitListCopy[x]["maxHeight"] = individualDimensions[y]["maxHeight"];
        }
      }
    }

    console.log("Final Unit List  ", unitListCopy);
    console.log("UI Min/Max  ", this.state.uiMinMax);
    this.props.comboToStepper(
      this.state.numberOfUnit,
      unitListCopy,
      this.state.uiMinMax,
      this.state.currentSeriesSelected,
      this.state.categories,
      this.state.categoryAttributeMap,
      this.state.pricingMap,
      //this.state.jobLaborData,
      this.state.categoryAllProductAttributeMap,
      this.state.commonCategoriesAttributeMap,
      this.state.productCategories,
      this.state.isEdit,
      this.state.isNextBtnDisable,

      this.state.currentWindowStyleSelected,
      this.state.currentOpeningWidth,
      this.state.currentOpeningHeight
    );
    this.setState({ spinner: false });
  };
  async getToken () {
  
    const fullUrl = new URL(process.env.REACT_APP_NODE_URL).href;
    //const data = new URLSearchParams();
      const accessTokenResponse = await fetch(fullUrl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
      });
      return accessTokenResponse
  };
  
  async componentDidMount() {
    let accessToken;
    const accessTokenResponse = await this.getToken()
    if(accessTokenResponse.ok){
      accessToken = await accessTokenResponse.json()
    }
    this.setState({ accessToken: accessToken });   
    if (
      this.state.currentSeriesSelected === "" &&
      this.state.currentWindowStyleSelected === undefined
    ) {
      console.log("mount", this.state.currentSeriesSelected);
    }
    const commonCategories = ["Interior Color", "Exterior Color"];
    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // const authorizationCode = params.get("code");
    let products = new Set();

    this.setState({ spinner: true });
    // var accessToken = "";

    // //================================== Below is the code for authorization ===================
    // if (authorizationCode != undefined) {
    //   let responseJson = null;

    //   const response = await fetch(
    //     process.env.REACT_APP_BASE_URL +
    //       "/authorization?code=" +
    //       authorizationCode,
    //     {
    //       method: "GET", // *GET, POST, PUT, DELETE, etc.
    //       mode: "cors", // no-cors, *cors, same-origin
    //       cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //       credentials: "same-origin", // include, *same-origin, omit
    //       headers: {
    //         "Content-Type": "application/json",
    //         // 'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //       redirect: "follow", // manual, *follow, error
    //       referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
    //     }
    //   );

    //   if (response.ok) {
    //     // if HTTP-status is 200-299
    //     // get the response body (the method explained below)
    //     responseJson = await response.json();
    //     //localStorage.setItem('accessToken', responseJson.access_token);
    //     //this.setState({ accessToken: responseJson.access_token})
    //   } else {
    //     //alert("HTTP-Error: " + response.status);
    //     if (response.status == 401) {
    //       window.location.href = ssoLoginUrl;
    //     } else if (response.status == 403) {
    //       this.setState({ unauthorized: true, loadingIsActive: false });
    //     }
    //   }
    // }

    let productSeries = {};

    productSeries = this.state.productSeries;
    let series = this.state.series;
    series.forEach((s, i) => {
      if (
        this.props.currentSeriesSelected &&
        s.includes(this.props.currentSeriesSelected)
      ) {
        this.setState({
          currentSeriesSelected: s,
        });
      }
    });

    products = this.state.products;
    if (this.state.vinylOrWood === 1) {
      this.setState({ selectedMaterial: "Vinyl" });
    }

    console.log("vinylOrWood: ", this.state.vinylOrWood);

    /* awd01o Translate material code to string value and get specific series of that material*/
    if (this.state.vinylOrWood === 1) {
      this.setState({ selectedMaterial: "Vinyl" });

      //let productSeries = this.state.ProductSeries;
       series = new Set();
      for (let record in productSeries) {
        //console.log("Product Series: ", productSeries[record]["Series_Material__c"]);
        if (productSeries[record]["Series_Material__c"] === "Vinyl") {
          series.add(productSeries[record]["Series__c"]);
        }
      }
      this.setState({ series: Array.from(series) });
    }

    for (let record in productSeries) {
      //console.log(" record ", productSeries[record]["Series_Material__c"]);
      if (
        productSeries[record]["Series_Material__c"] ===
        this.state.selectedMaterial
      ) {
        series.add(productSeries[record]["Series__c"]);
      }
    }
    //console.log(" Series ", Array.from(series));
    this.setState({ series: Array.from(series) });

    if (this.state.isEdit) {
      this.onChangeSeries(this.state.currentSeriesSelected);
      // this.onChangeNumberOfUnits(this.props.currentNoOfUnitSelected);
    }
    let productCompatibleAttrResponseJson = [];
    let allProducts = [];

    console.log(
      "process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API",
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API
    );
    let reloadData = true;
    let compatibleAttributesData = localStorage.getItem("compatibleAttributesApiResponse");
    
      if (
        process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" && 
        compatibleAttributesData
      ) {
        compatibleAttributesData = JSON.parse(
          localStorage.getItem("compatibleAttributesApiResponse")
        );
        reloadData = needsDataRefresh(
          compatibleAttributesData["timestamp"],
          this.state.modifiedTimeStampObj["compatibleAttributeModifiedOn"]
        );

        if(!reloadData){
          var compatibleAttributes2 = compatibleAttributesData['data'];
          allProducts = compatibleAttributes2["Products"];
          this.setState({
            compatibleAttributes: compatibleAttributes2["Products"],
          });
          console.log("Fetching compatible attributes from localStorage");
        }
        
      } if(reloadData) {
        console.log("Fetching compatible attributes from backend");
        this.setState({ spinner: true });
        const apiUrl = process.env.REACT_APP_BASE_URL;
        let path =  "/getCompatibleAttributes"
        const url = new URL(path, apiUrl).href;
        const productCompatibleAttrResponse = await fetch(
          url,
          {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              "Content-Type": "application/json",
              "User": this.state.user,
              "Api-Key": process.env.REACT_APP_API_KEY,
              'Authorization': 'Bearer ' + this.state.accessToken
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
          }
        );

        if (productCompatibleAttrResponse.ok) {
          // if HTTP-status is 200-299

          productCompatibleAttrResponseJson =
            await productCompatibleAttrResponse.json();
         

          
          allProducts = productCompatibleAttrResponseJson["Products"];
          this.setState({ compatibleAttributes: productCompatibleAttrResponseJson });
          console.log(
            "compatibleAttributes response ",
            productCompatibleAttrResponseJson
          );
          if(allProducts && allProducts.length>0){
            localStorage.setItem(
              "compatibleAttributesApiResponse",
              JSON.stringify({timestamp:getUTCTimestamp(), data:productCompatibleAttrResponseJson})
            );
          }
          
          
        }
      }
    

    var categoryAllProductAttributeMap = {};

    products.forEach((product) => {
      for (let x in allProducts) {
        if (allProducts[x]["ProductName"] === product) {
          categoryAllProductAttributeMap[product] =
            allProducts[x]["Categories"];
        }
      }
    });
    console.log(
      "Compatible categoryAllProductAttributeMap ",
      categoryAllProductAttributeMap
    );
    // Create commonCategories Attribute map
    //{'Interior Color':['White','Beige'], 'Exterior Color':['White','Black']}

    const commonCategoriesAttributeMap = {};
    //const commonCategories = this.props.commonCategories;

    commonCategories.map(
      (category) => (commonCategoriesAttributeMap[category] = [])
    );
    //TODO: Need to pass the productName instead of hardcoded value
    console.log("commonCategories ", commonCategories);

    commonCategories?.forEach((category) => {
      commonCategoriesAttributeMap[category].push(
        ...this.listAttributesOfCategory(
          category,
          COMMON_VALUE,
          categoryAllProductAttributeMap
        )
      );
    });
    console.log(
      "Common Category Attribute Maps ",
      commonCategoriesAttributeMap
    );

    /*let productCategories = {};
    for (const [key, value] of Object.entries(categoryAllProductAttributeMap)) {
      let categories = [];
      for (let i = 0; i < value.length; i++) {
        categories.push(categoryAllProductAttributeMap[key][i]["CategoryName"]);
      }
      productCategories[key] = categories;
    }
   */
    console.log(
      " categoryAllProductAttributeMap",
      categoryAllProductAttributeMap
    );
    // Moving all set state to one place
    // this.setState({
    //   categoryAllProductAttributeMap,
    //   commonCategoriesAttributeMap,
    // });
    //product attributes

    // let productAttrResponseJson = [];

    var productAttributesMap = {};
    let matchesProductsMap = {};
    // let productAttrResponse = {};
    let matchesProducts = [];
    let productCategories = {};
    let allProductsAttributes = [];

    matchesProductsMap["productIds"] = matchesProducts;

    allProductsAttributes = allProducts;
    /*if (localStorage.getItem("productAttributesApiResponse") && process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true") {
        productAttrResponse = JSON.parse(
          localStorage.getItem("productAttributesApiResponse")
        );
        allProductsAttributes = productAttrResponse["Products"];
        console.log("Fetching product attributes from localStorage");
      } else { 
    console.log("Fetching product attributes from backend");
    this.setState({ isLoading: true });
    productAttrResponse = await fetch(
      process.env.REACT_APP_BASE_URL + "/productAttributes",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
          //'Authorization': 'Bearer ' + this.state.accessToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
        body: JSON.stringify(matchesProductsMap),
      }
    );

    if (productAttrResponse.ok) {
      productAttrResponseJson = await productAttrResponse.json();
      localStorage.setItem(
        "productAttributesApiResponse",
        JSON.stringify(productAttrResponseJson)
      );
      allProductsAttributes = productAttrResponseJson["Products"];
    }
    this.setState({ isLoading: false });
    } */
    products.forEach((product) => {
      for (let x in allProducts) {
        if (allProductsAttributes[x]["ProductName"] === product) {
          productAttributesMap[product] =
            allProductsAttributes[x]["Categories"];
        }
      }
    });

    // this.setState({ productAttributes: productAttributesMap });
    console.log("productAttributes on ChangeStyle ", productAttributesMap);

    let productCategoriesSettings = {};
    for (const [key, value] of Object.entries(productAttributesMap)) {
      let categories = [];
      let categoryName = "";
      let productCategorySettings = [];
      for (let i = 0; i < value.length; i++) {
        categoryName = productAttributesMap[key][i]["CategoryName"];
        categories.push(categoryName);
        if (categoryName === "Performance Option") {
          productCategorySettings.push({ categoryName: "default" });
        }
      }
      productCategories[key] = categories;
      productCategoriesSettings[key] = productCategorySettings;
    }

    console.log("Product Categories ", productCategories);
    // Moving all set state to one place
    // this.setState({
    //   productCategories: productCategories,
    //   productCategoriesSettings: productCategoriesSettings,
    // });

    console.log(
      "ComponentDidMount Pricing starts here",
      this.state.currentWindowStyleSelected
    );
    //Pricing is moved here

    let productsPricingCopy = {};
    //replace the call process productAttributeJson
    products.forEach((product) => {
      let newObject = {};
      console.log("--------productName-----", product);

      let attributeList = categoryAllProductAttributeMap[product];
      for (let attribute in attributeList) {
        let attributes = attributeList[attribute]["Attributes"];
        console.log("attributeList", attributes);
        let categoryName = attributeList[attribute]["CategoryName"];
        newObject[categoryName] = [];
        if (attributeList[attribute]["CategoryName"] === "Dimension") {
          for (let att in attributes) {
            let objectMap = {};

            objectMap["basePrice"] = attributes[att]["Price"];
            objectMap["Id"] = attributes[att]["Id"];
            objectMap["minUiRange"] = attributes[att]["Min UI Range"];
            objectMap["maxUiRange"] = attributes[att]["Max UI Range"];

            if (
              attributes[att]["CompatibleWith"] !== null &&
              attributes[att]["CompatibleWith"]["Interior Color"]
            ) {
              objectMap["interiorColor"] =
                attributes[att]["CompatibleWith"]["Interior Color"][0];
            }
            if (
              attributes[att]["CompatibleWith"] !== null &&
              attributes[att]["CompatibleWith"]["Additional Glass Type"]
            ) {
              objectMap["Additional Glass Type"] =
                attributes[att]["CompatibleWith"]["Additional Glass Type"];
            }
            if (
              attributes[att]["CompatibleWith"] !== null &&
              attributes[att]["CompatibleWith"]["Grid"]
            ) {
              objectMap["Grid"] = attributes[att]["CompatibleWith"]["Grid"];
            }
            newObject[categoryName].push(objectMap);
            console.log("dimension new object", newObject);
          }
        } else {
          for (let att in attributes) {
            let objectMap = {};
            console.log(
              attributeList[attribute]["CategoryName"],
              "attributeList",
              attributes[att]["GridStyle"],
              attributes[att]["Gridcode"]
            );
            objectMap["attribute"] = attributes[att]["AttributeName"];
            objectMap["attributeShort"] = attributes[att]["Attribute"];
            objectMap["accPrice"] = attributes[att]["Price"];
            objectMap["Id"] = attributes[att]["Id"];
            // Added in object to frame InHome Data
            objectMap["accessory"] = attributes[att]["Accessory"];
            objectMap["accessoryUOM"] = attributes[att]["AccessoryUOM"];
            objectMap["accessoryCode"] = attributes[att]["AccessoryCode"];

            if (attributes[att]["Gridcode"]) {
              //alert('updating');
              objectMap["gridCode"] = attributes[att]["Gridcode"];
              objectMap["gridStyle"] = attributes[att]["GridStyle"];
              objectMap["profile"] = attributes[att]["Profile"];
              objectMap["lmsGridId"] = attributes[att]["LMSGridId"];
            }
            newObject[categoryName].push(objectMap);
            // if (attributeList[attribute]["CategoryName"] === "Grid") {
            // console.log("other category new object", newObject);
            // }
          }
        }
        productsPricingCopy[product] = newObject;
      }
    });
    console.log(
      "-------------------productPricingMap------- ",
      productsPricingCopy
    );

    //pricing api removed from here

    this.setState(
      {
        categoryAllProductAttributeMap,
        commonCategoriesAttributeMap,
        productAttributes: productAttributesMap,
        productCategories: productCategories,
        productCategoriesSettings: productCategoriesSettings,
        pricingMap: productsPricingCopy,
      },
      () => {
        if (this.state.isEdit) {
          this.onChangeStyle(this.props.currentWindowStyleSelected);
        }
      }
    );
    this.setState({ spinner: false });
    // if(this.state.isEdit){

    // }
  }

  /*  = productCategories[panelsInCombo[panel]].map((category) => {
      productAttributesMap[category].push(
        ...this.listAttributesOfCategory(
          category,
          panelsInCombo[panel],
          categoryAllProductAttributeMap
        )
        allProductAttributesMap[panelsInCombo[panel]] = {category: this.listAttributesOfCategory(category,
          panelsInCombo[panel],
          categoryAllProductAttributeMap
        )}
      );
    });*/

  render() {
    return (
      <div className="WindowStyle-content">
        <Spin tip="Loading..." spinning={this.state.spinner}>
          <Form.Item>
            <div
              style={{ fontWeight: 700, fontSize: "1.6em", color: "#f96302" }}
            >
              BUILD YOUR
            </div>
            <div
              style={{ fontWeight: 700, fontSize: "2.6em", color: "#f96302" }}
            >
              {" "}
              WINDOW
            </div>
            <Divider />
            <Space>
              <Row justify="space-between">
                <h3 style={{ marginRight: "8px" }}>Install Type</h3>
                <Checkbox checked disabled>
                  Insert
                </Checkbox>
              </Row>
            </Space>
            <Divider />

            <Space>
              <Row justify="space-between">
                <h3 style={{ marginRight: "8px" }}>Window Material</h3>
                <Select
                  disabled={this.state.isEdit}
                  value={this.state.selectedMaterial}
                  selected={this.state.selectedMaterial}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="material"
                  optionFilterProp="children"
                  onChange={this.onChangeMaterial.bind(this)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.material.map((value) => {
                    return (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Space>
            <Divider />

            <Space>
              <Row justify="space-between">
                <h3 style={{ marginRight: "8px" }}>Window Series </h3>
                <Select
                  disabled={this.state.isEdit}
                  value={this.state.currentSeriesSelected}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="series"
                  optionFilterProp="children"
                  onChange={this.onChangeSeries.bind(this)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.series.map((value) => {
                    return (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Space>

            <Divider />
            <Space>
              <Row justify="space-between">
                <h3 style={{ marginRight: "8px" }}> No. of Panels</h3>
                <Select
                  disabled={this.state.isEdit}
                  value={this.state.currentNoOfUnitSelected}
                  showSearch
                  style={{ width: 200 }}
                  status={this.state.isUnits}
                  placeholder="Choose up to 6 ..."
                  optionFilterProp="children"
                  onChange={this.onChangeNumberOfUnits.bind(this)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.unitCount.map((index) => {
                    return (
                      <Option key={index} value={index}>
                        {index}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Space>
            <Divider />

            <Space>
              <Row>
                <h3 style={{ marginRight: "8px" }}> Select Floor </h3>
                <Select
                  value={this.state.existingFloor}
                  showSearch
                  style={{ width: 300 }}
                  placeholder="floor"
                  status={this.state.isFloor}
                  optionFilterProp="children"
                  onChange={this.onChangeFloor.bind(this)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {floors["floors"].map((index) => {
                    return (
                      <Option key={index} value={index}>
                        {index}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Space>
            <Divider />
            <Space>
              <Row>
                <h3 style={{ marginRight: "8px" }}> Select Room </h3>
                <Select
                  value={this.state.existingRoom}
                  showSearch
                  style={{ width: 300 }}
                  status={this.state.isRoom}
                  placeholder="room"
                  optionFilterProp="children"
                  onChange={this.onChangeRoom.bind(this)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {rooms["rooms"].map((index) => {
                    return (
                      <Option key={index} value={index}>
                        {index}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Space>
            <Divider />

            <Space>
              <Row>
                <h3 style={{ marginRight: "8px" }}> Window Style </h3>

                <Select
                  disabled={this.state.isEdit}
                  selected={this.state.currentWindowStyleSelected}
                  value={this.state.currentWindowStyleSelected}
                  status={this.state.isStyle}
                  style={{ width: "Auto", minWidth: 450 }}
                  placeholder="style"
                  optionFilterProp="children"
                  onChange={this.onChangeStyle.bind(this)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.seriesProductResponse.map((keyName) => {
                    return (
                      <Option key={keyName} value={keyName}>
                        {keyName}
                      </Option>
                    );
                  })}
                </Select>
              </Row>
            </Space>
            <Divider />

            <Space>
              <Row justify="space-between">
              
                  {this.state.showUnits ? (
                     <Card>
                     {" "}
                     <h2
                       style={{
                         fontWeight: 700,
                         fontSize: "1.6em",
                         color: "#4240c2",
                         
                       }}
                     >
                       {" "}
                       
                       Window #1 Room - {this.state.room}
                     </h2>
                  { this.state.currentWindowStyleSelected && !this.state.currentWindowStyleSelected.includes('Transom') ? (
                    <Row gutter={24}>
                    {this.state.unitList.length > 0 &&
                      this.state.unitList.map((index) => {
                        return (
                          <Col span={this.state.unitList.length>2?8:(this.state.unitList.length===1?24:12)} >
                            <Card
                              hoverable={true}
                              key={this.state.numberOfUnit[index]}
                              class="card"
                              title={"Panel #" + index.unitNumber}
                            >
                              <h2
                                style={{
                                  fontWeight: 700,
                                  fontSize: "1.6em",
                                  color: "#f96302",
                                  width: 180
                                }}
                              >
                                {index.windowStyle}
                              </h2>

                              {/* {index.windowStyle == "Double Hung" && ( */}
                                <div className="container" style={{height:200}}>
                                  <img
                                    class="img"
                                    alt="Window"
                                    src={imageMap.get(index.windowStyle)}
                                  />{" "}
                                </div>
                              {/*   )}
                              {index.windowStyle == "Picture Window" && (
                                <div className="container">
                                  <img
                                    class="img"
                                    alt="Window"
                                    src={imageMap.get(index.windowStyle)}
                                  />{" "}
                                </div>
                              )}
                              {index.windowStyle == "Single Hung" && (
                                <div className="container">
                                  <img
                                    class="img"
                                    alt="Window"
                                    src={imageMap.get(index.windowStyle)}
                                  />{" "}
                                </div>
                              */}
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>

                  ):(
                   <Col gutter={24}>
                      {this.state.unitList.length > 0 &&
                        this.state.unitList.map((index) => {
                          return (
                            <Row span={this.state.unitList.length>2?8:(this.state.unitList.length===1?24:12)} >
                              <Card
                                hoverable={true}
                                key={this.state.numberOfUnit[index]}
                                class="card"
                                title={"Panel #" + index.unitNumber}
                              >
                                <h2
                                  style={{
                                    fontWeight: 700,
                                    fontSize: "1.6em",
                                    color: "#f96302",
                                    width: 180
                                  }}
                                >
                                  {index.windowStyle}
                                </h2>

                                {/* {index.windowStyle == "Double Hung" && ( */}
                                  <div className="container" style={{height:200}}>
                                    <img
                                      class="img"
                                      alt="Window"
                                      src={imageMap.get(index.windowStyle)}
                                    />{" "}
                                  </div>
                                {/*                                )}
                                {index.windowStyle == "Picture Window" && (
                                  <div className="container">
                                    <img
                                      class="img"
                                      alt="Window"
                                      src={imageMap.get(index.windowStyle)}
                                    />{" "}
                                  </div>
                                )}
                                {index.windowStyle == "Single Hung" && (
                                  <div className="container">
                                    <img
                                      class="img"
                                      alt="Window"
                                      src={imageMap.get(index.windowStyle)}
                                    />{" "}
                                  </div>
                                )} */}
                              </Card>
                            </Row>
                          );
                        })}
                    </Col> )}
                    </Card>
                  ) : (
                    <div></div>
                  )}
                  <Divider />
                </Row>
              
            </Space>

            {this.state.showSuccessIcon ? (
              <div>
                <Alert
                  showIcon
                  message={" Configured Successfully "}
                  type="success"
                  closable
                ></Alert>
                <Divider />
              </div>
            ) : (
              <div></div>
            )}
            {this.state.showErrorIcon ? (
              <div>
                <Alert
                  showIcon
                  message={" Fix the window styles "}
                  type="error"
                  action={
                    <Button size="small" danger>
                      {" "}
                      Detail{" "}
                    </Button>
                  }
                  closable
                >
                  {" "}
                </Alert>
                <Divider />
              </div>
            ) : (
              <div></div>
            )}

            {/* <Space direction='horizontal' align='center'>
            {this.state.showUnits? (
              <Row>
                <div class="right" style={{ float: "right" }}>
                  <Row>
                    <Col span={24}>
                      <Button type="primary" size="large" onClick={this.onApplyHandler.bind(this)} >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>


              </Row>


            ) : (<div> </div>)
            }
          </Space> */}
          </Form.Item>
        </Spin>
      </div>
    );
  }
}
