import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ComboWindowStepper from "./ComboWindowStepper";
import EnterLeadInformation from "./EnterLeadInformation";
import ChangeOrder from "./ChangeOrder";
import ChangeOrderStepper from "./ChangeOrderStepper";
import VosGenerate from "./Components/VosGenerate";
//import VosGenerate from "./Components/VosGenerate";

export default function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
                      renders the first one that matches the current URL. */}
        <Switch>
          <Route
            path={[
              "/leadId/:leadId/branchNumber/:branchNumber/priceList/:priceList/coastalFlag/:coastalFlag/leadSafeFlag/:leadSafeFlag/userName/:userName/userSFID/:userSFID/token/:token/openingUuid/:openingUuid",
            ]}
            component={EnterLeadInformation}
          />
          <Route path="/combo-unit" component={ComboWindowStepper} />
          {/*awd01o*/}
          <Route path="/changeOrder" component={ChangeOrder} />
          <Route path="/changeOrderConfig" component={ChangeOrderStepper} />
          <Route path="/vosGenerate" component={VosGenerate} />
          {/************************************/}
        </Switch>
      </div>
    </Router>
  );
}
