import React, { Component } from "react";
import imageMap from "../images";
import {
  Form,
  List,
  Col,
  Row,
  Card,
  Space,
  Divider,
  Select,
  Button,
  Modal,
  Table,
  TreeSelect,
  Affix,
  Input,
  InputNumber,
} from "antd";

const { SHOW_PARENT } = TreeSelect;
const { TextArea } = Input;

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

const { Option } = Select;
let existingSelectedItemLabor = [];

export default class ChangeOrderOthers extends Component {
  constructor(props) {
    super(props);

    console.log("in props", this.props);
    console.log("labor history", this.props.selectedItemLabor);
    console.log("labor history 2", this.props.existingAddOnLabors);

    existingSelectedItemLabor = Object.assign(
      [],
      this.props.existingSelectedItemLabor
    );
    console.log(
      "existingSelectedItemLabor object assign",
      existingSelectedItemLabor
    );

    this.state = {
      //awd01o************************
      existingInteriorColor: this.props.existingInteriorColor,
      existingExteriorColor: this.props.existingExteriorColor,
      oldBabData: this.props.oldBabData,
      existingAddOnLabors: this.props.isEdit
        ? this.props.existingAddOnLabors
        : [],
      //existingSelectedItemLabor: existingSelectedItemLabor,
      uiMinMax: this.props.uiMinMax,
      itemMiscNotes: this.props.isEdit
        ? this.props.itemLaborNotes
          ? this.props.itemLaborNotes
          : ""
        : "",
      miscItemLabor: this.props.isEdit ? this.props.miscItemLabor : undefined,

      existingVerticalBars: this.props.existingVerticalBars,
      existingHorizontalBars: this.props.existingHorizontalBars,
      //**********************************
      numberOfUnit: this.props.numberOfUnit,
      unitIndexs: this.props.numberOfUnit,
      isEdit: this.props.isEdit,
      selectedItemLabor: this.props.selectedItemLabor,

      lineId: this.props.lineId,
      units: this.props.units,
      categoryAttributeMap: {},
      currentAttributeSeleted: {},
      categoryAllProductAttributeMap: this.props.categoryAllProductAttributeMap,
      productCategories: this.props.productCategories,
      uiRangeofProduct: this.props.uiRangeofProduct,
      valueSelected: {},
      horizontalBarsDefault: 0,
      verticalBarsDefault: 0,
      horizontalBarsMin: 0,
      verticalBarsMin: 0,
      horizontalBarsMax: 0,
      verticalBarsMax: 0,
      verticalBarsValue: 0,
      horizontalBarsValue: 0,
      categories: [],
      isGridModalVisible: false,
      defaultCategories: [
        "Glass Sash",
        "Glass Pack",
        "Additional Glass Type",
        "Add Grids",
        "Performance Option",
        "Handing",
        "Frame",
        "Screens",
        "Hardware",
        "Performance Option",
      ],
      categoryWithDimensions: [
        "Additional Glass Type",
        "Grid",
        "Performance Option",
      ],
      //YXC8HEN TODO:1 Remove below hardcoding
      configuringUnitNumber: 1,
      currentCategoryMode: "",
      // currentInteriorSelected: "",
      // currentExteriorSelected: "",
      //pricingMap: this.props.pricingMap,
      commonCategories: this.props.commonCategories,
      commonCategoriesMap: this.props.commonCategoriesMap,
      commonCategoriesAttributeMap: {},
      configureEnable: this.props.isEdit ? true : false,
      size: {},
      quantity: Array(20)
        .fill()
        .map((_, idx) => 1 + idx),
      configuredUnitDimensions: this.props.singleUnitDimensions,
      modalVisible: false,
      imageModalVisible: false,
      imageDisplay: "none",
      showErrorIcon: false,
      isSubmitEnable: true,
      isInterior: "error",
      isExterior: "",
      isAttribute: "",
      showSuccessIcon: false,
      i: 0,
      container: null,
      currentTotal: 0.0,
      currentImage: null,
      totalComboUnitPrice: 0.0,
      dataSource: [],
      attributeImage: "",
      showLabor: this.props.showItemMiscDiv
        ? this.props.showItemMiscDiv
        : false,
      inHomeData: {},
      columns: [
        {
          title: "Category",
          dataIndex: "Category",
          width: "30%",
          editable: true,
        },
        {
          title: "Attribute",
          dataIndex: "Attribute",
          width: "30%",
          editable: true,
        },

        /*{
                  title: "Action",
                  dataIndex: "",
                  key: "x",
                  render: (row) => (
                    <span
                      className={`${this.props.className}-delete`}
                      onClick={(e) => {
                        this.onDelete(row.key, e);
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: "20px" }} />
                    </span>
                  ),
                }*/
      ],
    };
    this.splitCategoryAndValue = this.splitCategoryAndValue.bind(this);
    this.updateCompatibilityAttributeMap =
      this.updateCompatibilityAttributeMap.bind(this);
    this.updateCompatibilityAttributeMapForCommonCategories =
      this.updateCompatibilityAttributeMapForCommonCategories.bind(this);
    this.listAttributesOfCategory = this.listAttributesOfCategory.bind(this);
    this.categoryWithDimension = this.categoryWithDimension.bind(this);
    this.valueObjectMatchingDimension =
      this.valueObjectMatchingDimension.bind(this);
    this.compatibleWith = this.compatibleWith.bind(this);
    this.intersectedAttributeList = this.intersectedAttributeList.bind(this);
  }

  noneOnTop = (newCategoryAttributeMap) => {
    let noneOnTopMap = newCategoryAttributeMap;
    for (const [key, value] of Object.entries(newCategoryAttributeMap)) {
      for (let index in value) {
        if (value[index].includes("None")) {
          let noneValue = noneOnTopMap[key][index];
          noneOnTopMap[key].splice(index, 1);
          noneOnTopMap[key].unshift(noneValue);
        }
      }
    }
    return noneOnTopMap;
  };

  handleGridOk = () => {
    //this.props.history.push('/combo-unit');
    this.setState({ isGridModalVisible: false });
    let unitsCopy = this.state.units;
    let unitCopy = {};
    for (let x in this.state.units) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        delete unitCopy["valueSelected"]["Grid"];
        this.deleteAttribute(unitCopy, "Grid");
        this.deleteInHomeAttributes(unitCopy, "Grid");
        unitsCopy[x] = unitCopy;
      }
    }

    this.setState({ units: unitsCopy });
  };

  handleCancel = () => {
    this.setState({ isGridModalVisible: false });
    let unitsCopy = this.state.units;
    let unitCopy = {};
    for (let x in this.state.units) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        delete unitCopy["valueSelected"]["Grid"];
        this.deleteAttribute(unitCopy, "Grid");
        this.deleteInHomeAttributes(unitCopy, "Grid");
        unitsCopy[x] = unitCopy;
      }
    }

    this.setState({ units: unitsCopy });
  };
  splitCategoryAndValue = (categoryAndValue) => {
    let categoryAndValueList = [];
    if (categoryAndValue !== undefined && categoryAndValue !== "") {
      const categoryAndValueSplit = categoryAndValue.split("---");
      if (
        categoryAndValueSplit !== undefined &&
        categoryAndValueSplit.length > 0
      ) {
        categoryAndValueList.push(categoryAndValueSplit[0]);
        categoryAndValueList.push(categoryAndValueSplit[1]);
      }
    }
    return categoryAndValueList;
  };

  onDelete = (key, e) => {
    e.preventDefault();
    const data = this.state.dataSource.filter((item) => item.key !== key);
    //update the unit by checking the item.value
    this.setState({ dataSource: data });
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleOk = () => {
    this.setState({ imageModalVisible: false });
    this.setState({ modalVisible: false });
    let unitsCopy = this.state.units;

    console.log("Units Copy", unitsCopy);

    let unitCopy = {};
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["configurationString"] = "Configured";
        unitsCopy[x] = unitCopy;
      }
    }
    this.setState({ units: unitsCopy });
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({ modalVisible: false });
  };

  onChecked = (checkedValues) => {
    console.log("CheckedValues ", checkedValues);

    let unitsCopy = this.state.units;
    let unitCopy = {};
    let x, y, z;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
      }
    }
    for (z in unitsCopy) {
      for (y in checkedValues) {
        if (checkedValues[y].unitNumber !== this.state.configuringUnitNumber) {
          //unitsCopy[z].Labor = unitCopy.Labor
          //unitsCopy[z].Frame = unitsCopy.Frame
          unitsCopy[z].configurationString = unitCopy.configurationString;
          unitsCopy[z].attributes = unitCopy.attributes;
          unitsCopy[z].totalPrice = unitCopy.totalPrice;
        }
      }
    }
    console.log("Copied units ", unitsCopy);
    this.setState({ units: unitsCopy });
  };

  onAddWraps = (checked) => {
    let unitListCopy = this.state.units;
    let x;
    let unitCopy;
    for (x in unitListCopy) {
      unitCopy = unitListCopy[x];
      unitCopy["wraps"] = checked;
      unitListCopy[x] = unitCopy;
    }
    this.setState({ units: unitListCopy });
    this.setState({ wraps: checked });
  };

  onChangeHorizontalBars = (value) => {
    let unitsCopy = this.state.units;
    let horizontalBarsValue = 0;

    console.log(" HorizontalBars ", value);
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        if (
          value > unitsCopy[x].horizontalBarsMin ||
          value < unitsCopy[x].horizontalBarsMax
        ) {
          horizontalBarsValue = value;
          this.setState({ horizontalBarsValue: value });
        } else {
          horizontalBarsValue = unitsCopy[x].horizontalBarsMax;
          this.setState({ horizontalBarsValue });
        }
        unitsCopy[x].horizontalBarsValue = horizontalBarsValue;
      }
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        let elementsIndex = this.state.dataSource.findIndex(
            (element) => element.Category === "Bars"
        );
        let newArray = [...this.state.dataSource];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          Attribute: unitsCopy[x].verticalBarsValue  + ", " + horizontalBarsValue,
        };
        this.setState({
          dataSource: newArray,
        });
      }
      this.setState({ units: unitsCopy });
    }
  }
  onChangeVerticalBars = (value) => {
    let unitsCopy = this.state.units;
    let verticalBarsValue = 0;

    console.log(" VerticalBars ", value);
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        if (
          value > unitsCopy[x].verticalBarsMin ||
          value < unitsCopy[x].verticalBarsMax
        ) {
          //alert("Please enter the width between range " + dimensions.minWidth + " to" + dimensions.maxWidth );
          this.setState({ verticalBarsValue: value });
          verticalBarsValue = value;
        } else {
          this.setState({ verticalBarsValue: unitsCopy[x].verticalBarsMax });
        }
        unitsCopy[x].verticalBarsValue = verticalBarsValue;
      }
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        let elementsIndex = this.state.dataSource.findIndex(
            (element) => element.Category === "Bars"
        );
        let newArray = [...this.state.dataSource];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          Attribute: verticalBarsValue + ", " + unitsCopy[x].horizontalBarsValue,
        };
        this.setState({
          dataSource: newArray,
        });
      }
      this.setState({ units: unitsCopy });
    }
  }

  onChangeHorizontalBarsInChangeOrder = (unit) => {
    let unitsCopy = unit;
    let horizontalBarsValue = unit.horizontalBarsValue;
    let value = horizontalBarsValue;

    console.log(" HorizontalBars ", value);
    // eslint-disable-next-line
    for (let x in unitsCopy) {
      if (unitsCopy.unitNumber === this.state.configuringUnitNumber) {
        if (
          value > unitsCopy.horizontalBarsMin ||
          value < unitsCopy.horizontalBarsMax
        ) {
          horizontalBarsValue = value;
          this.setState({ horizontalBarsValue: value });
        } else {
          horizontalBarsValue = unitsCopy.horizontalBarsMax;
          this.setState({ horizontalBarsValue });
        }
        unitsCopy.horizontalBarsValue = horizontalBarsValue;
      }
      if (unitsCopy.unitNumber === this.state.configuringUnitNumber) {
        let elementsIndex = this.state.dataSource.findIndex(
            (element) => element.Category === "Bars"
        );
        let newArray = [...this.state.dataSource];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          Attribute: unitsCopy.verticalBarsValue + ", " + horizontalBarsValue,
        };
        this.setState({
          dataSource: newArray,
        });
      }
      this.setState({ units: unitsCopy });
    }
  }

  onChangeVerticalBarsInChangeOrder = (unit) => {
    let unitsCopy = unit;
    let verticalBarsValue = unit.verticalBarsValue;
    let value = verticalBarsValue;
    console.log(" VerticalBars ", value);
    // eslint-disable-next-line
    for (let x in unitsCopy) {
      if (unitsCopy.unitNumber === this.state.configuringUnitNumber) {
        if (
          value > unitsCopy.verticalBarsMin ||
          value < unitsCopy.verticalBarsMax
        ) {
          //alert("Please enter the width between range " + dimensions.minWidth + " to" + dimensions.maxWidth );
          this.setState({ verticalBarsValue: value });
          verticalBarsValue = value;
        } else {
          this.setState({ verticalBarsValue: unitsCopy.verticalBarsMax });
        }
        unitsCopy.verticalBarsValue = verticalBarsValue;
      }
      if (unitsCopy.unitNumber === this.state.configuringUnitNumber) {
        let elementsIndex = this.state.dataSource.findIndex(
            (element) => element.Category === "Bars"
        );
        let newArray = [...this.state.dataSource];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          Attribute: verticalBarsValue + ", " + unitsCopy.horizontalBarsValue,
        };
        this.setState({
          dataSource: newArray,
        });
      }
      this.setState({ units: unitsCopy });
    }
  }

  onSaveHandler = () => {
    let unitsCopy = this.state.units;
    let unitCopy = {};
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["configurationString"] = "Configured";
        unitsCopy[x] = unitCopy;
      }
    }
    this.setState({ units: unitsCopy });

    console.log(" Inside saveApplyHandler  ");
    if (this.props.numberOfUnit.length > 1) {
      this.showModal();
    }

    this.props.othersToStepper(unitsCopy);
  };

  editUnit = (value) => {
    let unitsCopy = this.state.units;
    this.setState({isAttribute:"error"})
    console.log("Inside editUnit ", value);
    console.log("unitsCopy ", unitsCopy);
    let attributeImage = "";

    let categoryAttributeMap = {};

    /*   for (let unit in unitsCopy) {
      if(unitsCopy[unit].unitNumber === value){
           unitCopy =  unitsCopy[unit]
      }

    }*/
    let unit = unitsCopy.find((unit) => {
      return unit.unitNumber === value;
    });
    console.log(
      "Unit Selected categoryAttributeMap",
      unit.categoryAttributeMap
    );

    categoryAttributeMap = { ...unit.categoryAttributeMap };
    this.setState({
      showConfigurationSection: true,
      horizontalBarsValue: unit.horizontalBarsValue,
      verticalBarsValue: unit.verticalBarsValue,
      horizontalBarsDefault: unit.horizontalBarsDefault,
      verticalBarsDefault: unit.verticalBarsDefault,
      horizontalBarsMin: unit.horizontalBarsMin,
      horizontalBarsMax: unit.horizontalBarsMax,
      verticalBarsMax: unit.verticalBarsMax,
      verticalBarsMin: unit.verticalBarsMin,
      configuringUnitNumber: value,
      valueSelected: { ...unit.valueSelected },
      categoryAttributeMap: categoryAttributeMap,
      /*********awd01o */
      dataSource: [...unit["dataSource"]],
      categories: Object.keys(unit.categoryAttributeMap),
      /*********awd01o */
      // dataSource: rows,
      attributeImage: attributeImage,
    });
  };

  onChangeOption = (unitObject, value) => {
    let unitsCopy = this.state.units;
    let unitCopy = {};
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["gridPattern"] = value;
        unitsCopy[x] = unitCopy;
      }
    }
    console.log("Inside setHeightWithRatio ", unitsCopy);
    this.setState({ units: unitsCopy });
  };

  onQuantityChange = (labeledValue, Option) => {
    console.log("const existingSelectedItemLabor", existingSelectedItemLabor);
    console.log(
      "before qty change",
      this.props.existingSelectedItemLabor,
      this.state.miscItemLabor,
      this.state.selectedItemLabor
    );
    console.log("labeledValue ", labeledValue, "Option ", Option);
    if (labeledValue.split("---").length > 1) {
      let selectLabor = labeledValue.split("---")[0];
      let qty = labeledValue.split("---")[1];
      let addOnlaborCost = 0;
      let addOnItemLabor = [];
      let updatedItemLabor = this.state.selectedItemLabor
        ? this.state.selectedItemLabor
        : existingSelectedItemLabor;

      for (let i = 0; i < updatedItemLabor.length; i++) {
        let labor = Object.assign({}, updatedItemLabor[i]);
        console.log("iterated labor", labor);
        if (labor.desc === selectLabor) {
          labor.quantity = parseInt(qty);
          labor.priceTotal = labor.priceBase * parseInt(qty);
        }
        addOnlaborCost += labor.priceTotal;

        addOnItemLabor.push(labor);
      }
      console.log("updatedItemLabor", addOnItemLabor);
      this.setState({
        selectedItemLabor: addOnItemLabor,
        addOnlaborCost: addOnlaborCost,
      });
      let miscItemLabor = [];
      for (let k = 0; k < this.state.miscItemLabor.length; k++) {
        if (this.state.miscItemLabor[k].desc === selectLabor) {
          let labor = Object.assign({}, this.state.miscItemLabor[k]);
          console.log("iterated labor", labor);
          if (labor.desc === selectLabor) {
            labor.quantity = parseInt(qty);
            labor.priceTotal = labor.priceBase * parseInt(qty);
          }

          miscItemLabor.push(labor);
          break;
        }
      }
      console.log("on qty change", this.props.existingSelectedItemLabor);
      this.props.othersToStepper(
        this.state.units,
        addOnItemLabor,
        this.state.inHomeData,
        this.state.addOnlaborCost,
        this.state.isSubmitEnable,
        this.state.itemMiscNotes,
        this.state.existingAddOnLabors,
        miscItemLabor
      );
    }
  };

  onMiscNotesUpdate = (notes) => {
    console.log("notes", notes.target.value);

    this.setState({
      itemMiscNotes: notes.target.value,
    });

    this.props.othersToStepper(
      this.state.units,
      this.state.selectedItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      this.state.isSubmitEnable,
      notes.target.value,
      this.state.existingAddOnLabors,
      this.state.miscItemLabor
    );
  };
  onQuantityDeselect(labeledValue) {
    console.log("Qty Deselected", labeledValue);
  }
  onLaborDeselect(currentNode, selectedNodes) {
    console.log("on labor deselect", this.props.existingSelectedItemLabor);
    console.log("on labor deselect ::", currentNode, selectedNodes);
    let addOnlaborCost = this.state.addOnlaborCost;
    let updatedItemLabor = this.state.selectedItemLabor
      ? this.state.selectedItemLabor
      : existingSelectedItemLabor;
    console.log("before removing it", updatedItemLabor);
    let addonItemLabor = [];
    for (let i = 0; i < updatedItemLabor.length; i++) {
      let labor = updatedItemLabor[i];
      console.log("iterated labor", labor);
      if (labor.desc === selectedNodes.title) {
        addOnlaborCost = addOnlaborCost - labor.priceTotal;
        // updatedItemLabor.splice(i, 1);
      } else {
        addonItemLabor.push(labor);
      }
    }

    for (let k = 0; k < this.state.existingAddOnLabors.length; k++) {
      if (this.state.existingAddOnLabors[k] === currentNode) {
        this.state.existingAddOnLabors.splice(k, 1);
        break;
      }
    }
    for (let k = 0; k < this.state.miscItemLabor.length; k++) {
      if (this.state.miscItemLabor[k].desc === selectedNodes.title) {
        this.state.miscItemLabor.splice(k, 1);
        break;
      }
    }

    console.log("updatedItemLabor on deselect", updatedItemLabor);
    this.setState({
      selectedItemLabor: addonItemLabor,
      addOnlaborCost: addOnlaborCost,
    });
    this.props.othersToStepper(
      this.state.units,
      addonItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      this.state.isSubmitEnable,
      this.state.itemMiscNotes,
      this.state.existingAddOnLabors,
      this.state.miscItemLabor
    );
    console.log(
      "on labor deselect later",
      this.props.existingSelectedItemLabor
    );
  }

  onLaborChange = (currentNode, selectedNodes) => {
    console.log("on labor change", this.props.existingSelectedItemLabor);
    console.log("onChange in others::", currentNode, selectedNodes);
    this.setState({
      existingAddOnLabors: currentNode,
    });
    let childrenData = {};
    let labor = {};
    let itemLaborsData = this.props.itemLaborsData;
    let addonItemLabor = [];
    let miscItemLabor = [];
    let addOnlaborCost = 0;

    for (let i = 0; i < itemLaborsData.length; i++) {
      childrenData = itemLaborsData[i].children;
      // console.log("onChange::childrenData", childrenData);
      for (let j = 0; j < currentNode.length; j++) {
        // console.log('currentNode', currentNode[j]);
        for (let k = 0; k < childrenData.length; k++) {
          // console.log(childrenData[k].value);
          if (currentNode[j] === childrenData[k].value ) {
            let quantity = 1;
            if(this.state.selectedItemLabor){
              let existingLabor = this.state.selectedItemLabor.find(
                // eslint-disable-next-line
                (lab) => lab.desc === childrenData[k].title
              );
              quantity = existingLabor?existingLabor.quantity: 1;
            }
            labor = {
              quantity: quantity,
              laborId: 0,

              desc: childrenData[k].title,
              priceBase: childrenData[k].price,
              detailId: 0,
              specCode: childrenData[k].specCode,
              unitOfMeasurement: childrenData[k].uom,
              quoteId: 0,
              lmsLaborId: childrenData[k].lmsItemNumber,
              priceTotal: childrenData[k].price * quantity,
            };
            console.log(childrenData[k].title, childrenData[k].price);
            addOnlaborCost += childrenData[k].price;
            addonItemLabor.push(labor);
            if (itemLaborsData[i].title === "MISCELLANEOUS LABOR OPTIONS") {
              miscItemLabor.push(labor);
            }
            break;
          }
        }
      }
    }
    console.log("Inhome labor", addonItemLabor);
    console.log("addOnlaborCost", addOnlaborCost);
    this.setState({
      selectedItemLabor: addonItemLabor,
      addOnlaborCost: addOnlaborCost,
      showLabor: miscItemLabor.length > 0 ? true : false,
      itemMiscNotes: miscItemLabor.length > 0 ? this.state.itemMiscNotes : '',
      miscItemLabor: miscItemLabor,
    });
    this.props.othersToStepper(
      this.state.units,
      addonItemLabor,
      this.state.inHomeData,
      addOnlaborCost,
      this.state.isSubmitEnable,
      miscItemLabor.length > 0 ? this.state.itemMiscNotes : '',
      currentNode,
      miscItemLabor
    );
  };

  configureUnit(value) {
    let unitsCopy = this.state.units;
    this.setState({isAttribute:"error"})
    console.log("Inside configureUnit ", value);
    console.log("unitsCopy ", unitsCopy);
    let attributeImage = "";
    let categoryAttributeMap = {};
    let unitCopy;
    for (let unit in unitsCopy) {
      if (unitsCopy[unit].unitNumber === value) {
        unitCopy = unitsCopy[unit];
      }
    }
    this.setState({
      configuringUnitNumber: value,
    });

    if (this.props.isEdit) {
      for (let unit in unitsCopy) {
        if (unitsCopy[unit].unitNumber === value) {
          unitsCopy[unit]["configurationString"] = "Configured";
          console.log("configureUnit unit number", unitsCopy[unit].unitNumber);
          for (const [key, value] of Object.entries(
            unitsCopy[unit].valueSelected
          )) {
            if (Array.isArray(value)) {
              let arrayOption = [];
              let ele = {};
              value.forEach((element) => {
                arrayOption.push(key + "---" + element);
                ele = { key: element };
                this.onAttributeChangedInChangeOrder(
                  arrayOption,
                  [ele],
                  unitsCopy[unit].unitNumber
                );
              });
              this.onAttributeChangedInChangeOrder(
                arrayOption,
                [ele],
                unitsCopy[unit].unitNumber
              );
            } else {
              console.log("Calling onAttributeChanged", key, value);
              this.onAttributeChangedInChangeOrder(
                key + "---" + value,
                [{ key: value }],
                unitsCopy[unit].unitNumber
              );
            }
          }
        }
      }
    }

    categoryAttributeMap = { ...unitCopy.categoryAttributeMap };
    this.setState({
      showConfigurationSection: true,
      configuringUnitNumber: value,
      valueSelected: { ...unitCopy.valueSelected },
      categoryAttributeMap: categoryAttributeMap,
      /*********awd01o */
      dataSource: [...unitCopy.dataSource],
      categories: Object.keys(unitCopy.categoryAttributeMap),
      /*********awd01o */
      // dataSource: rows,
      units: unitsCopy,
      attributeImage: attributeImage,
    });

    /*let z ;
    
                  let sizeRange = this.state.categoryAttributeMap['Size Range']
                  let totalUI = unitsCopy[x].width + unitsCopy[x].height;
                  for (z in sizeRange){
                    let sizeRangeSplit = sizeRange[z].split("-");
                     if(sizeRangeSplit != undefined && sizeRangeSplit.length > 0) {
                      let startRange = sizeRangeSplit[0];
                      let endRange = sizeRangeSplit[1];
                      if (startRange < totalUI < endRange){
                          unitsCopy[x]["sizeRange"] = sizeRange[z]
                          break;
                      }
                   // }
                }
                rows.push({key: x+1, Category: unitsCopy[x].windowStyle,
                Attribute: unitsCopy[x]["sizeRange"],
                Price: 80.00})*/
    this.props.othersToStepper(
      unitsCopy,
      this.state.selectedItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      this.state.isSubmitEnable,
      this.state.itemMiscNotes
    );
  }

  listAttributesOfCategory = (categoryName, unit) => {
    //let categories = ["Interior Color", "Grid", "Bar info"];

    let categories = unit.productCategories;

    const categoryIndex = categories.indexOf(categoryName);
    let attributes = [];
    let catAtt = [];
    if (categoryIndex !== -1) {
      attributes = unit.compatibilityAttributeMap[categoryIndex]["Attributes"];
    }

    attributes.forEach((attribute) => {
      catAtt.push(attribute.AttributeName);
    });
    console.log("listAttributesOfCategory for ", categoryName, "--->", catAtt);
    return catAtt;
  };

  /*updateCompatibilityAttributeMapForCommonCategories = (
      categoryName,
      attributeName,
      windowStyle,
      windowMinMax,
      uiMinMax
    ) => {
      let parentCategory = "";
      let parentCategoryIndex = undefined;
      let categoryAllProductAttributeMap =
        this.state.categoryAllProductAttributeMap;
  
      //if labelled category found in this list then get the position/index
      console.log("Updating Category", categoryName);
      console.log("With Value", attributeName);
      let unitList = this.state.units;
      const unit = unitList.find((unit) => {
        return unit.windowStyle === windowStyle;
      });
      //TODO: uncomment below when category order is corrected in Salesforce for product Attributes
      //let categories = unit.productCategories
      let categories = ["Interior Color", "Exterior Color"];
      console.log(" Possible categories for windowStyle in UI ", categories);
      for (let i = 0; i < categories.length; i++) {
        if (categoryName === categories[i]) {
          parentCategory = categories[i];
          parentCategoryIndex = i;
          break;
        }
      }
      let catAttrMapCopy = []; //this.state.catAttrMap;
      if (parentCategoryIndex === 0 || parentCategoryIndex === 1)
        for (const [key, value] of Object.entries(
          categoryAllProductAttributeMap
        )) {
          let compatibleWith = {};
          console.log("Keys ", key);
          if (key === windowStyle) {
            console.log("value ", value);
            for (let i = parentCategoryIndex; i < parentCategoryIndex + 1; i++) {
              const lengthOfAttributes =
                categoryAllProductAttributeMap[key][i]["Attributes"].length;
              for (let j = 0; j < lengthOfAttributes; j++) {
                if (
                  attributeName ===
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "AttributeName"
                  ]
                ) {
                  console.log(
                    categoryAllProductAttributeMap[key][i]["Attributes"][j][
                      "CompatibleWith"
                    ]
                  );
                  if (
                    categoryAllProductAttributeMap[key][i]["Attributes"][j][
                      "CompatibleWith"
                    ] !== null
                  ) {
                    compatibleWith =
                      categoryAllProductAttributeMap[key][i]["Attributes"][j][
                        "CompatibleWith"
                      ];
                    break;
                  }
                }
              }
              catAttrMapCopy = compatibleWith;
              console.log("Compatible with ", compatibleWith);
  
          }
        }
  
      console.log("catAttrMap ", catAttrMapCopy);
      unit["categoryAttributeMap"] = catAttrMapCopy;
      this.setState({ categoryAttributeMap: catAttrMapCopy, units: unitList });
      return catAttrMapCopy;
    };
    */

  updateCompatibilityAttributeMapForCommonCategories = (
    categoryName,
    valueName,
    unit,
    windowMinMax,
    uiMinMax
  ) => {
    let parentCategoryIndex = undefined;
    let categoryAllProductAttributeMap =
      this.state.categoryAllProductAttributeMap;
    let categories = unit.productCategories;
    let productAttributes = unit.productAttributesMap;
    //if labelled category found in this list then get the position/index
    console.log("Updating Category", categoryName);
    console.log("With Value", valueName);
    console.log("Unit Passed ", unit);

    let unitsCopy = this.state.units;
    let newCategoryAttributeMap = {};

    //TODO: uncomment below when category order is corrected in Salesforce for product Attributes

    //Check if the attribute is already added in unitList

    const lastCategoryIndex = categories.length - 2;

    //Handle if its a last category
    console.log(" Possible categories for windowStyle in UI ", categories);
    for (let i = 0; i < categories.length - 2; i++) {
      if (categoryName === categories[i]) {
        //parentCategory = categories[i];
        parentCategoryIndex = i;
        break;
      }
    }
    let nextCategoryIndex = parentCategoryIndex + 1;
    if (parentCategoryIndex === lastCategoryIndex) {
      nextCategoryIndex = lastCategoryIndex;
    }

    let catAttrMapCopy = []; //this.state.catAttrMap;
    // if (parentCategoryIndex === 0 || parentCategoryIndex === 1)
    let compatibleWith = {};
    let inHomeData = this.state.inHomeData;
    let compatibleFlag = false;
    for (const [key, value] of Object.entries(categoryAllProductAttributeMap)) {
      //console.log("Keys ", key);
      if (key === unit.windowStyle) {
        console.log("value ", value);
        for (let i = parentCategoryIndex; i < nextCategoryIndex; i++) {
          const lengthOfAttributes =
            categoryAllProductAttributeMap[key][i]["Attributes"].length;
          for (let j = 0; j < lengthOfAttributes; j++) {
            let condition;
            if (categoryName === "Interior Color") {
              condition =
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "Attribute"
                ];
            } else {
              condition =
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "AttributeName"
                ];
            }
            if (valueName === condition) {
              if (valueName.includes("Interior")) {
                this.setState({ currentInteriorSelected: valueName });
                inHomeData["accCost"] = 0;
                inHomeData["InteriorColor"] = valueName.split(" ")[0];
                inHomeData["InteriorColorActual"] = valueName;
                inHomeData["InteriorColorCode"] =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "ColorCode"
                  ];
                inHomeData["InteriorColorSpecCode"] =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "ColorSpecCode"
                  ];
              }
              if (valueName.includes("Exterior")) {
                this.setState({ currentExteriorSelected: valueName });

                inHomeData["ExteriorColor"] = valueName.split(" ")[0];
                inHomeData["ExteriorColorActual"] = valueName;
                inHomeData["ExteriorColorCode"] =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "ColorCode"
                  ];
                inHomeData["ExteriorColorSpecCode"] =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "ColorSpecCode"
                  ];
              }
              //console.log('inHomeData', inHomeData);
              this.setState({ inHomeData: inHomeData });

              console.log("Inside iteration", valueName);
              console.log(
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "CompatibleWith"
                ]
              );
              if (
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "CompatibleWith"
                ] !== null
              ) {
                compatibleWith =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "CompatibleWith"
                  ];
                compatibleFlag = true;
                break;
              }
            }
          }
          catAttrMapCopy = compatibleWith;
          if (compatibleFlag) {
            break;
          }
          console.log("Compatible with ", compatibleWith);
          /* compatibleWith.map((element)=> {return (
                                catAttrMap[categoryAllProductAttributeMap[key][i]["category"]] = compatibleWith[element]
                              )
                              })*/
        }
      }
    }
    //let unit = unitsCopy.find((unit) => unit.windowStyle === windowStyle);
    //const units = unitsCopy.filter((unit) => unit.windowStyle === windowStyle);
    const isEmpty = Object.keys(catAttrMapCopy).length === 0;
    this.setState({ inHomeData: inHomeData });

    if (!isEmpty && categoryName === "Interior Color") {
      newCategoryAttributeMap = { ...catAttrMapCopy };
      unit["baseCategoryAttributeMap"] = { ...newCategoryAttributeMap };
    } else {
      newCategoryAttributeMap[
        productAttributes[nextCategoryIndex].CategoryName
      ] = [
        ...this.listAttributesOfCategory(
          productAttributes[nextCategoryIndex].CategoryName,
          unit
        ),
      ];
    }

    let attributeList = [];

    const baseCategoryAttributeMap = { ...catAttrMapCopy };
    for (const [key, value] of Object.entries(catAttrMapCopy)) {
      if (
        key !== "Max UI Range" &&
        key !== "Min UI Range" &&
        key !== "Price" &&
        key !== "Id" &&
        key !== "AttributeName" &&
        key !== "Attribute"
      ) {
        if (this.state.categoryWithDimensions.includes(key)) {
          attributeList = this.attributesWithDimension(key, unit);
          console.log("AttributeListWithDimension in update", attributeList);
          unit["baseCategoryAttributeMap"][key] = attributeList;
          let intersectedArray = baseCategoryAttributeMap[key].filter(
            // eslint-disable-next-line
            (element) => attributeList.includes(element)
          );
          unit["baseCategoryAttributeMap"][key] = intersectedArray;
          console.log(
            " unitsCopy[x][baseCategoryAttributeMap][key]",
            unit["baseCategoryAttributeMap"]
          );
          console.log(
            " unitsCopy[x][baseCategoryAttributeMap][key] attributeList",
            unit["baseCategoryAttributeMap"]
          );
        } else {
          let intersectedArray = baseCategoryAttributeMap[key].filter(
            (element) => value.includes(element)
          );
          unit["baseCategoryAttributeMap"][key] = intersectedArray;
          console.log(
            " unitsCopy[x][baseCategoryAttributeMap][key]",
            unit["baseCategoryAttributeMap"]
          );
        }
      }
    }

    for (let x in unitsCopy) {
      if (unitsCopy[x].unitNumber === unit.unitNumber) {
        unitsCopy[x] = unit;
      }
    }

    /*if (
            isEmpty &&
            unitsCopy[x]["productAttributesMap"][nextCategoryIndex].CategoryName ===
              Object.keys(catAttrMapCopy)[0]
          ) {
            unitsCopy[x]["categoryAttributeMap"] = { ...catAttrMapCopy };
            newCategoryAttributeMap = { ...catAttrMapCopy };
            //this.setState({categoryAttributeMap:newCategoryAttributeMap});
          }
          if (
            unitsCopy[x].productAttributesMap[nextCategoryIndex].CategoryName !==
            Object.keys(catAttrMapCopy)[0]
          ) {
            unitsCopy[x]["categoryAttributeMap"] = { ...newCategoryAttributeMap };
            // this.setState({categoryAttributeMap:newCategoryAttributeMap});
          }
    */
    this.setState({ units: unitsCopy });
    console.log("catAttrMap ", newCategoryAttributeMap);
    return { ...this.noneOnTop(newCategoryAttributeMap) };
  };

  deleteAttribute = (unit, category) => {
    let attributes = unit["attributes"];
    if (attributes !== undefined && attributes.length > 0) {
      //We need to loop through the existing attributes to see if this one exists and we are updating it
      const attribute = attributes.find((attribute) => {
        return attribute.category === category;
      });
      if (attribute !== undefined) {
        attribute.value = [];
        attribute.price = [];
        attribute.retailPrice = [];
      }
    }
  };

  deleteInHomeAttributes = (unit, category) => {
    // debugger;
    let attributes = unit["inHomeAttributes"];
    if (attributes !== undefined && attributes.length > 0) {
      //We need to loop through the existing attributes to see if this one exists and we are updating it
      // const attribute = attributes.find((attribute) => {
      //   return attribute.category === category;
      // });
      // if (attribute !== undefined) {
      //   attribute.value = [];
      //   attribute.price = [];
      // }
      for (let k = 0; k < attributes.length; k++) {
        if (attributes[k].category === category) {
          attributes.splice(k, 1);
        }
      }
    }
    unit["inHomeAttributes"] = attributes;
  };

  updateCompatibilityAttributeMap = (
    categoryName,
    valueName,
    unit,
    windowMinMax,
    uiMinMax
  ) => {
    let parentCategoryIndex = undefined;
    let categoryAllProductAttributeMap =
      this.state.categoryAllProductAttributeMap;
    let newCategoryAttributeMap = unit.categoryAttributeMap;
    //if labelled category found in this list then get the position/index
    console.log("Updating Category", categoryName);
    console.log("With Value", valueName);

    let unitsCopy = this.state.units;
    for (let x in this.state.units) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitsCopy[x] = unit;
      }
    }

    //TODO: uncomment below when category order is corrected in Salesforce for product Attributes

    //Check if the attribute is already added in unitList

    let categories = unit.productCategories;
    let windowStyle = unit.windowStyle;

    //Below is maintained to see what is current selected list of attributes
    //Attribute exists the list
    /*  if (attributes != undefined && attributes.length > 0) {
          //We need to loop through the existing attributes to see if this one exists and we are updating it
    
          const attribute = attributes.find((attribute) => {
            return attribute.category === categoryName;
          });
    
          if (attribute !== undefined) {
            if (mode === "multiple") {
              var attr = Option.find((option, index, options) => {
                return option.key === valueName;
              });
              if (attr !== undefined) {
                if (!attribute.value.includes(valueName)) {
                  attribute.value.push(valueName);
                }
              }
            } else {
              attribute.value = valueName;
            }
          }
          console.log("Attributes after update", attributes);
          /*            for(let attribute in attributes){
                  if (Array.isArray(attributes[attribute].value)){
                  categoryAttributeMap[attributes[attribute].category] = [attributes[attribute].value]
                  }
                  if(typeof (attributes[attribute].value) === 'string'){
                  categoryAttributeMap[attributes[attribute].category] = [...attributes[attribute].value]
                  }
              }
        }
        //There is nothing selected yet we will create attr for first time
        else {
          return this.listAttributesOfCategory(
            unit.compatibilityAttributeMap[0],
            unit
          );
        }
    */
    const lastCategoryIndex = categories.length - 2;

    //Handle if its a last category
    console.log(" Possible categories for windowStyle in UI ", categories);
    let nextCategoryIndex;
    let defaultCategory;
    if (parentCategoryIndex === lastCategoryIndex) {
      nextCategoryIndex = lastCategoryIndex;
    }
    console.log(" Default category ", defaultCategory);

    for (let i = 0; i < categories.length - 1; i++) {
      if (categoryName === categories[i]) {
        parentCategoryIndex = i;
        nextCategoryIndex = i + 1;
        if (categories[nextCategoryIndex] === "Dimension") {
          nextCategoryIndex = i;
        }
        break;
      }
    }

    let catAttrMapCopy = []; //this.state.catAttrMap;
    for (const [key, value] of Object.entries(categoryAllProductAttributeMap)) {
      let compatibleWith = {};
      //console.log("Keys ", key);
      if (key === windowStyle) {
        console.log("value ", value);
        for (let i = parentCategoryIndex; i < nextCategoryIndex; i++) {
          const lengthOfAttributes =
            categoryAllProductAttributeMap[key][i]["Attributes"].length;
          for (let j = 0; j < lengthOfAttributes; j++) {
            if (
              valueName ===
              categoryAllProductAttributeMap[key][i]["Attributes"][j][
                "AttributeName"
              ]
            ) {
              console.log(
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "CompatibleWith"
                ]
              );

              if (
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "CompatibleWith"
                ] !== null
              ) {
                compatibleWith =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "CompatibleWith"
                  ];
                break;
              }
            }
          }
          catAttrMapCopy = compatibleWith;
          console.log("Compatible with ", compatibleWith);
          /* compatibleWith.map((element)=> {return (
                            catAttrMap[categoryAllProductAttributeMap[key][i]["category"]] = compatibleWith[element]
                          )
                          })*/
        }
      }
    }

    if (categoryName === "Add Grids" && valueName === "No") {
      if (unit["productCategories"].includes("Grid Sash")) {
        nextCategoryIndex = nextCategoryIndex + 2;
      } else {
        nextCategoryIndex = nextCategoryIndex + 1;
      }
      delete newCategoryAttributeMap["Grid"];
      delete unit["valueSelected"]["Grid"];
      delete newCategoryAttributeMap["Grid Sash"];
      delete unit["valueSelected"]["Grid Sash"];
      delete newCategoryAttributeMap["Bars"];
      delete unit["valueSelected"]["Bars"];
      delete unit["horizontalBarsValue"];
      delete unit["verticalBarsValue"];
      this.deleteAttribute(unit, "Grid");
      this.deleteInHomeAttributes(unit, "Grid Sash");
      this.deleteAttribute(unit, "Grid Sash");
      this.deleteInHomeAttributes(unit, "Grid");
      this.deleteAttribute(unit, "Bars");
      this.deleteInHomeAttributes(unit, "Bars");
    }
    let nextCategoryName =
      unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName;
    let barSelected = "";
    const isEmpty = Object.keys(catAttrMapCopy).length === 0;
    if (nextCategoryName === "Dimension") {
      newCategoryAttributeMap = {};
    }

    if (!isEmpty) {
      for (const [key, value] of Object.entries(catAttrMapCopy)) {
        if (
          unit["baseCategoryAttributeMap"].hasOwnProperty(key) &&
          !unit["categoryAttributeMap"].hasOwnProperty(key)
        ) {
          unit["baseCategoryAttributeMap"][key] = this.intersectedAttributeList(
            this.uniq(value),
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        } else if (
          unit["baseCategoryAttributeMap"].hasOwnProperty(key) &&
          unit["categoryAttributeMap"].hasOwnProperty(key)
        ) {
          unit["baseCategoryAttributeMap"][key] = this.intersectedAttributeList(
            unit["categoryAttributeMap"][key],
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        } else {
          unit["baseCategoryAttributeMap"][key] = catAttrMapCopy[key];
        }
      }
      this.setState({ units: unitsCopy });
      console.log("baseCategoryAttributeMap ", catAttrMapCopy);
    }

    if (isEmpty && this.state.defaultCategories.includes(nextCategoryName)) {
      //check if its in the baseCategories then copy that else
      let attributeList = [];
      let categoryWithDimensionsFlag =
        this.state.categoryWithDimensions.includes(nextCategoryName);
      if (categoryWithDimensionsFlag) {
        attributeList = this.intersectedAttributeList(
          this.attributesWithDimension(nextCategoryName, unit),
          unit["baseCategoryAttributeMap"][nextCategoryName]
        );
        if (unit["baseCategoryAttributeMap"].hasOwnProperty(nextCategoryName)) {
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        } else {
          // This gets executed when Grid is next category
          attributeList = this.intersectedAttributeList(
            this.listAttributesOfCategory(
              unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
              unit
            ),
            this.attributesWithDimension(nextCategoryName, unit)
          );
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        }
      } else {
        if (unit["baseCategoryAttributeMap"].hasOwnProperty(nextCategoryName)) {
          newCategoryAttributeMap[nextCategoryName] = [
            ...this.uniq(unit["baseCategoryAttributeMap"][nextCategoryName]),
          ];
        } else {
          // This gets executed when Grid is next category

          newCategoryAttributeMap[nextCategoryName] = [
            ...this.listAttributesOfCategory(
              unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
              unit
            ),
          ];
        }
      }
    }
    if (isEmpty && !this.state.defaultCategories.includes(nextCategoryName)) {
      let attributeList = [];
      if (nextCategoryName === "Grid") {
        console.log("")
      } else {
        //Added this else as Grid is moved to non default Category now.
        nextCategoryIndex = nextCategoryIndex + 1;
      }
      nextCategoryName =
        unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName;
      let categoryWithDimensionsFlag =
        this.state.categoryWithDimensions.includes(nextCategoryName);
      if (categoryWithDimensionsFlag && nextCategoryName !== "Dimension") {
        attributeList = this.intersectedAttributeList(
          this.attributesWithDimension(nextCategoryName, unit),
          unit["baseCategoryAttributeMap"][nextCategoryName]
        );
        if (unit["baseCategoryAttributeMap"].hasOwnProperty(nextCategoryName)) {
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        } else {
          // This gets executed when Grid is next category
          attributeList = this.intersectedAttributeList(
            this.listAttributesOfCategory(
              unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
              unit
            ),
            this.attributesWithDimension(nextCategoryName, unit)
          );
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        }
      } else {
        attributeList = this.intersectedAttributeList(
          this.listAttributesOfCategory(
            unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
            unit
          ),
          this.uniq(unit["baseCategoryAttributeMap"][nextCategoryName])
        );
        newCategoryAttributeMap[nextCategoryName] = attributeList;
      }
    }
    // This is when compatibleWith has data
    console.log(
      "Before Compatibility ",
      catAttrMapCopy.hasOwnProperty(nextCategoryName)
    );
    // This gets executed for Glass Pack
    if (
      !isEmpty &&
      !this.state.defaultCategories.includes(nextCategoryName) &&
      catAttrMapCopy.hasOwnProperty(nextCategoryName)
    ) {
      for (const [key, value] of Object.entries(catAttrMapCopy)) {
        if (key === nextCategoryName) {
          newCategoryAttributeMap[nextCategoryName] = this.uniq(value);
        }
        if (newCategoryAttributeMap.hasOwnProperty(key)) {
          newCategoryAttributeMap[key] = this.intersectedAttributeList(
            this.uniq(value),
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        }
      }
    }
    //This gets executed for spacer
    if (
      !isEmpty &&
      !this.state.defaultCategories.includes(nextCategoryName) &&
      !catAttrMapCopy.hasOwnProperty(nextCategoryName)
    ) {
      if (nextCategoryName !== "Bars") {
        nextCategoryIndex = nextCategoryIndex + 1;
      }
      nextCategoryName =
        unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName;
      if (nextCategoryName !== "Dimension") {
        for (const [key, value] of Object.entries(catAttrMapCopy)) {
          if (key === nextCategoryName) {
            newCategoryAttributeMap[nextCategoryName] =
              this.intersectedAttributeList(
                this.uniq(value),
                this.uniq(unit["baseCategoryAttributeMap"][key])
              );
          }
          if (key === "barInfo" && nextCategoryName === "Bars") {
            if (this.state.edit) {
              newCategoryAttributeMap[nextCategoryName] = [
                this.props.barSelected,
              ];

              unit[key] = value;
              this.onBarChanged(
                "Bars---" + this.props.barSelected,
                [{ Bars: this.props.barSelected }],
                unit
              );
            } else {
              let barsWithDimensionMap = this.barsWithDimension(
                value,
                unit.width,
                unit.height
              );
              if (barsWithDimensionMap !== undefined) {
                barSelected = Object.keys(barsWithDimensionMap)[0];
                newCategoryAttributeMap[nextCategoryName] = [barSelected];
                for (const [key, value] of Object.entries(
                  barsWithDimensionMap[barSelected]
                )) {
                  unit[key] = value;
                  if (
                    key === "horizontalBarsDefault" &&
                    !unit["horizontalBarsValue"]
                  ) {
                    unit["horizontalBarsValue"] = value;
                  }
                  if (
                    key === "verticalBarsDefault" &&
                    !unit["verticalBarsValue"]
                  ) {
                    unit["verticalBarsValue"] = value;
                  }
                  this.onBarChanged(
                    "Bars---" + barSelected,
                    [{ Bars: barSelected }],
                    unit
                  );
                }
              } else {
                this.setState({ isGridModalVisible: true });
                delete unit["valueSelected"]["Grid"];
                this.deleteAttribute(unit, "Grid");
              }
            }

            //set bars with dimension
          }
        }
      }
    }

    if (!isEmpty && this.state.defaultCategories.includes(nextCategoryName)) {
      for (const [key, value] of Object.entries(catAttrMapCopy)) {
        let attributeList = [];
        let categoryWithDimensionsFlag =
          this.state.categoryWithDimensions.includes(nextCategoryName);
        if (categoryWithDimensionsFlag) {
          attributeList = this.attributesWithDimension(nextCategoryName, unit);
        } else if (categoryName === "Add Grids" && valueName === "No") {
          attributeList = this.intersectedAttributeList(
            this.uniq(catAttrMapCopy[key]),
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        }
        if (key === nextCategoryName) {
          newCategoryAttributeMap[nextCategoryName] = attributeList; //this.uniq(value);
        }
        if (key === "barInfo" && nextCategoryName === "Bars") {
          let barsWithDimensionMap = this.barsWithDimension(
            value,
            unit.width,
            unit.height
          );
          barSelected = Object.keys(barsWithDimensionMap)[0];
          newCategoryAttributeMap[nextCategoryName] = [barSelected];
          for (const [key, value] of Object.entries(
            barsWithDimensionMap[barSelected]
          )) {
            unit[key] = value;
            if (
              key === "horizontalBarsDefault" &&
              !unit["horizontalBarsValue"]
            ) {
              unit["horizontalBarsValue"] = value;
            }
            if (key === "verticalBarsDefault" && !unit["verticalBarsValue"]) {
              unit["verticalBarsValue"] = value;
            }
            this.onBarChanged(
              "Bars---" + barSelected,
              [{ Bars: barSelected }],
              unit
            );
          }

          //set bars with dimension
        }
      }
    }

    console.log("In UpdateCompatibilityAttributeMap ", newCategoryAttributeMap);

    //Code Tweak for Spacer as this is the only category not going with the logic
    if (categoryName === "Glass Pack") {
      delete unit["valueSelected"]["Additional Glass Type"];
      this.deleteAttribute(unit, "Additional Glass Type");
    }
    if (categoryName === "Glass Pack" && nextCategoryName !== "Spacer") {
      delete unit["categoryAttributeMap"]["Spacer"];
      delete newCategoryAttributeMap["Spacer"];
      delete unit["valueSelected"]["Spacer"];
      this.deleteAttribute(unit, "Spacer");
      this.deleteInHomeAttributes(unit, "Spacer");
    }
    if (categoryName === "Glass Pack" && nextCategoryName === "Spacer") {
      delete unit["categoryAttributeMap"]["Additional Glass Type"];
      delete newCategoryAttributeMap["Additional Glass Type"];
      delete unit["valueSelected"]["Additional Glass Type"];
      this.deleteAttribute(unit, "Additional Glass Type");
      this.deleteInHomeAttributes(unit, "Additional Glass Type");
    }

    if (categoryName === "Grid" && nextCategoryName !== "Bars") {
      delete unit["categoryAttributeMap"]["Bars"];
      unit["horizontalBarsDefault"] = 0;
      unit["verticalBarsDefault"] = 0;
      unit["horizontalBarsMin"] = 0;
      unit["verticalBarsMin"] = 0;
      unit["horizontalBarsMax"] = 0;
      unit["verticalBarsMax"] = 0;
      unit["horizontalBarsValue"] = 0;
      unit["verticalBarsValue"] = 0;
      delete newCategoryAttributeMap["Bars"];
      delete unit["valueSelected"]["Bars"];
      this.deleteAttribute(unit, "Bars");
      this.deleteInHomeAttributes(unit, "Bars");
    }

    for (let x in this.state.units) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitsCopy[x] = unit;
      }
    }

    this.setState({
      horizontalBarsDefault: unit["horizontalBarsDefault"],
      verticalBarsDefault: unit["verticalBarsDefault"],
      horizontalBarsValue: unit["horizontalBarsValue"],
      verticalBarsValue: unit["verticalBarsValue"],
      horizontalBarsMin: unit["horizontalBarsMin"],
      verticalBarsMin: unit["verticalBarsMin"],
      horizontalBarsMax: unit["horizontalBarsMax"],
      verticalBarsMax: unit["verticalBarsMax"],
      units: unitsCopy,
      categoryAttributeMap: unit["categoryAttributeMap"],
      valueSelected: unit["valueSelected"],
      barSelected,
    });
    return { ...this.noneOnTop(newCategoryAttributeMap) };
  };

  updateCompatibilityAttributeMapInChangeOrder = (
    categoryName,
    valueName,
    unit,
    configuringUnit,
    uiMinMax
  ) => {
    let parentCategoryIndex = undefined;
    let categoryAllProductAttributeMap =
      this.state.categoryAllProductAttributeMap;
    let newCategoryAttributeMap = unit.categoryAttributeMap;

    //if labelled category found in this list then get the position/index
    console.log("Updating Category", categoryName);
    console.log("With Value", valueName);

    let unitsCopy = this.state.units;
    for (let x in this.state.units) {
      if (unitsCopy[x].unitNumber === configuringUnit) {
        unitsCopy[x] = unit;
      }
    }

    //TODO: uncomment below when category order is corrected in Salesforce for product Attributes

    //Check if the attribute is already added in unitList

    let categories = unit.productCategories;
    let windowStyle = unit.windowStyle;

    //Below is maintained to see what is current selected list of attributes
    //Attribute exists the list
    /*  if (attributes != undefined && attributes.length > 0) {
          //We need to loop through the existing attributes to see if this one exists and we are updating it
    
          const attribute = attributes.find((attribute) => {
            return attribute.category === categoryName;
          });
    
          if (attribute !== undefined) {
            if (mode === "multiple") {
              var attr = Option.find((option, index, options) => {
                return option.key === valueName;
              });
              if (attr !== undefined) {
                if (!attribute.value.includes(valueName)) {
                  attribute.value.push(valueName);
                }
              }
            } else {
              attribute.value = valueName;
            }
          }
          console.log("Attributes after update", attributes);
          /*            for(let attribute in attributes){
                  if (Array.isArray(attributes[attribute].value)){
                  categoryAttributeMap[attributes[attribute].category] = [attributes[attribute].value]
                  }
                  if(typeof (attributes[attribute].value) === 'string'){
                  categoryAttributeMap[attributes[attribute].category] = [...attributes[attribute].value]
                  }
              }
        }
        //There is nothing selected yet we will create attr for first time
        else {
          return this.listAttributesOfCategory(
            unit.compatibilityAttributeMap[0],
            unit
          );
        }
    */
    const lastCategoryIndex = categories.length - 2;

    //Handle if its a last category
    console.log(" Possible categories for windowStyle in UI ", categories);
    let nextCategoryIndex;
    let defaultCategory;
    if (parentCategoryIndex === lastCategoryIndex) {
      nextCategoryIndex = lastCategoryIndex;
    }
    console.log(" Default category ", defaultCategory);

    for (let i = 0; i < categories.length - 1; i++) {
      if (categoryName === categories[i]) {
        parentCategoryIndex = i;
        nextCategoryIndex = i + 1;
        if (categories[nextCategoryIndex] === "Dimension") {
          nextCategoryIndex = i;
        }
        break;
      }
    }

    let catAttrMapCopy = []; //this.state.catAttrMap;
    for (const [key, value] of Object.entries(categoryAllProductAttributeMap)) {
      let compatibleWith = {};
      //console.log("Keys ", key);
      if (key === windowStyle) {
        console.log("value ", value);
        for (let i = parentCategoryIndex; i < nextCategoryIndex; i++) {
          const lengthOfAttributes =
            categoryAllProductAttributeMap[key][i]["Attributes"].length;
          for (let j = 0; j < lengthOfAttributes; j++) {
            if (
              valueName ===
              categoryAllProductAttributeMap[key][i]["Attributes"][j][
                "AttributeName"
              ]
            ) {
              console.log(
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "CompatibleWith"
                ]
              );

              if (
                categoryAllProductAttributeMap[key][i]["Attributes"][j][
                  "CompatibleWith"
                ] !== null
              ) {
                compatibleWith =
                  categoryAllProductAttributeMap[key][i]["Attributes"][j][
                    "CompatibleWith"
                  ];
                break;
              }
            }
          }
          catAttrMapCopy = compatibleWith;
          console.log("Compatible with ", compatibleWith);
          /* compatibleWith.map((element)=> {return (
                            catAttrMap[categoryAllProductAttributeMap[key][i]["category"]] = compatibleWith[element]
                          )
                          })*/
        }
      }
    }

    if (categoryName === "Add Grids" && valueName === "No") {
      if (unit["productCategories"].includes("Grid Sash")) {
        nextCategoryIndex = nextCategoryIndex + 2;
      } else {
        nextCategoryIndex = nextCategoryIndex + 1;
      }
      delete newCategoryAttributeMap["Grid"];
      delete unit["valueSelected"]["Grid"];
      delete newCategoryAttributeMap["Grid Sash"];
      delete unit["valueSelected"]["Grid Sash"];
      delete newCategoryAttributeMap["Bars"];
      delete unit["valueSelected"]["Bars"];
      delete unit["horizontalBarsValue"];
      delete unit["verticalBarsValue"];
      this.deleteAttribute(unit, "Grid");
      this.deleteInHomeAttributes(unit, "Grid Sash");
      this.deleteAttribute(unit, "Grid Sash");
      this.deleteInHomeAttributes(unit, "Grid");
      this.deleteAttribute(unit, "Bars");
      this.deleteInHomeAttributes(unit, "Bars");
    }
    let nextCategoryName =
      unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName;
    let barSelected = "";
    const isEmpty = Object.keys(catAttrMapCopy).length === 0;
    if (nextCategoryName === "Dimension") {
      newCategoryAttributeMap = {};
    }

    if (!isEmpty) {
      for (const [key, value] of Object.entries(catAttrMapCopy)) {
        if (
          unit["baseCategoryAttributeMap"].hasOwnProperty(key) &&
          !unit["categoryAttributeMap"].hasOwnProperty(key)
        ) {
          unit["baseCategoryAttributeMap"][key] = this.intersectedAttributeList(
            this.uniq(value),
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        } else if (
          unit["baseCategoryAttributeMap"].hasOwnProperty(key) &&
          unit["categoryAttributeMap"].hasOwnProperty(key)
        ) {
          unit["baseCategoryAttributeMap"][key] = this.intersectedAttributeList(
            unit["categoryAttributeMap"][key],
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        } else {
          unit["baseCategoryAttributeMap"][key] = catAttrMapCopy[key];
        }
      }
      this.setState({ units: unitsCopy });
      console.log("baseCategoryAttributeMap ", catAttrMapCopy);
    }

    if (isEmpty && this.state.defaultCategories.includes(nextCategoryName)) {
      //check if its in the baseCategories then copy that else
      let attributeList = [];
      let categoryWithDimensionsFlag =
        this.state.categoryWithDimensions.includes(nextCategoryName);
      if (categoryWithDimensionsFlag) {
        attributeList = this.intersectedAttributeList(
          this.attributesWithDimension(nextCategoryName, unit),
          unit["baseCategoryAttributeMap"][nextCategoryName]
        );
        if (unit["baseCategoryAttributeMap"].hasOwnProperty(nextCategoryName)) {
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        } else {
          // This gets executed when Grid is next category
          attributeList = this.intersectedAttributeList(
            this.listAttributesOfCategory(
              unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
              unit
            ),
            this.attributesWithDimension(nextCategoryName, unit)
          );
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        }
      } else {
        if (unit["baseCategoryAttributeMap"].hasOwnProperty(nextCategoryName)) {
          newCategoryAttributeMap[nextCategoryName] = [
            ...this.uniq(unit["baseCategoryAttributeMap"][nextCategoryName]),
          ];
        } else {
          // This gets executed when Grid is next category

          newCategoryAttributeMap[nextCategoryName] = [
            ...this.listAttributesOfCategory(
              unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
              unit
            ),
          ];
        }
      }
    }
    if (isEmpty && !this.state.defaultCategories.includes(nextCategoryName)) {
      let attributeList = [];
      if (nextCategoryName === "Grid") {
        console.log("")
      } else {
        //Added this else as Grid is moved to non default Category now.
        nextCategoryIndex = nextCategoryIndex + 1;
      }
      nextCategoryName =
        unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName;
      let categoryWithDimensionsFlag =
        this.state.categoryWithDimensions.includes(nextCategoryName);
      if (categoryWithDimensionsFlag && nextCategoryName !== "Dimension") {
        attributeList = this.intersectedAttributeList(
          this.attributesWithDimension(nextCategoryName, unit),
          unit["baseCategoryAttributeMap"][nextCategoryName]
        );
        if (unit["baseCategoryAttributeMap"].hasOwnProperty(nextCategoryName)) {
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        } else {
          // This gets executed when Grid is next category
          attributeList = this.intersectedAttributeList(
            this.listAttributesOfCategory(
              unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
              unit
            ),
            this.attributesWithDimension(nextCategoryName, unit)
          );
          newCategoryAttributeMap[nextCategoryName] = attributeList;
        }
      } else {
        attributeList = this.intersectedAttributeList(
          this.listAttributesOfCategory(
            unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName,
            unit
          ),
          this.uniq(unit["baseCategoryAttributeMap"][nextCategoryName])
        );
        newCategoryAttributeMap[nextCategoryName] = attributeList;
      }
    }
    // This is when compatibleWith has data
    console.log(
      "Before Compatibility ",
      catAttrMapCopy.hasOwnProperty(nextCategoryName)
    );
    // This gets executed for Glass Pack
    if (
      !isEmpty &&
      !this.state.defaultCategories.includes(nextCategoryName) &&
      catAttrMapCopy.hasOwnProperty(nextCategoryName)
    ) {
      for (const [key, value] of Object.entries(catAttrMapCopy)) {
        if (key === nextCategoryName) {
          newCategoryAttributeMap[nextCategoryName] = this.uniq(value);
        }
        if (newCategoryAttributeMap.hasOwnProperty(key)) {
          newCategoryAttributeMap[key] = this.intersectedAttributeList(
            this.uniq(value),
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        }
      }
    }
    //This gets executed for spacer
    if (
      !isEmpty &&
      !this.state.defaultCategories.includes(nextCategoryName) &&
      !catAttrMapCopy.hasOwnProperty(nextCategoryName)
    ) {
      if (nextCategoryName !== "Bars") {
        nextCategoryIndex = nextCategoryIndex + 1;
      }
      nextCategoryName =
        unit["compatibilityAttributeMap"][nextCategoryIndex].CategoryName;
      if (nextCategoryName !== "Dimension") {
        for (const [key, value] of Object.entries(catAttrMapCopy)) {
          if (key === nextCategoryName) {
            newCategoryAttributeMap[nextCategoryName] =
              this.intersectedAttributeList(
                this.uniq(value),
                this.uniq(unit["baseCategoryAttributeMap"][key])
              );
          }
          if (key === "barInfo" && nextCategoryName === "Bars") {
            if (this.state.edit) {
              newCategoryAttributeMap[nextCategoryName] = [
                this.props.barSelected,
              ];

              unit[key] = value;
              this.onBarChanged(
                "Bars---" + this.props.barSelected,
                [{ Bars: this.props.barSelected }],
                unit
              );
              this.onChangeHorizontalBarsInChangeOrder(unit);
              this.onChangeVerticalBarsInChangeOrder(unit);
            } else {
              let barsWithDimensionMap = this.barsWithDimension(
                value,
                unit.width,
                unit.height
              );
              if (barsWithDimensionMap !== undefined) {
                barSelected = Object.keys(barsWithDimensionMap)[0];
                newCategoryAttributeMap[nextCategoryName] = [barSelected];
                for (const [key, value] of Object.entries(
                  barsWithDimensionMap[barSelected]
                )) {
                  unit[key] = value;
                  if (
                    key === "horizontalBarsDefault" &&
                    !unit["horizontalBarsValue"]
                  ) {
                    unit["horizontalBarsValue"] = value;
                  }
                  if (
                    key === "verticalBarsDefault" &&
                    !unit["verticalBarsValue"]
                  ) {
                    unit["verticalBarsValue"] = value;
                  }
                  this.onBarChanged(
                    "Bars---" + barSelected,
                    [{ Bars: barSelected }],
                    unit
                  );
                }
                this.onChangeHorizontalBarsInChangeOrder(unit);
                this.onChangeVerticalBarsInChangeOrder(unit);
              } else {
                this.setState({ isGridModalVisible: true });
                delete unit["valueSelected"]["Grid"];
                this.deleteAttribute(unit, "Grid");
                this.deleteInHomeAttributes(unit, "Grid");
              }
            }

            //set bars with dimension
          }
        }
      }
    }

    if (!isEmpty && this.state.defaultCategories.includes(nextCategoryName)) {
      for (const [key, value] of Object.entries(catAttrMapCopy)) {
        let attributeList = [];
        let categoryWithDimensionsFlag =
          this.state.categoryWithDimensions.includes(nextCategoryName);
        if (categoryWithDimensionsFlag) {
          attributeList = this.attributesWithDimension(nextCategoryName, unit);
        } else if (categoryName === "Add Grids" && valueName === "No") {
          attributeList = this.intersectedAttributeList(
            this.uniq(catAttrMapCopy[key]),
            this.uniq(unit["baseCategoryAttributeMap"][key])
          );
        }
        if (key === nextCategoryName) {
          newCategoryAttributeMap[nextCategoryName] = attributeList; //this.uniq(value);
        }
        if (key === "barInfo" && nextCategoryName === "Bars") {
          let barsWithDimensionMap = this.barsWithDimension(
            value,
            unit.width,
            unit.height
          );
          barSelected = Object.keys(barsWithDimensionMap)[0];
          newCategoryAttributeMap[nextCategoryName] = [barSelected];
          for (const [key, value] of Object.entries(
            barsWithDimensionMap[barSelected]
          )) {
            unit[key] = value;
            if (
              key === "horizontalBarsDefault" &&
              !unit["horizontalBarsValue"]
            ) {
              unit["horizontalBarsValue"] = value;
            }
            if (key === "verticalBarsDefault" && !unit["verticalBarsValue"]) {
              unit["verticalBarsValue"] = value;
            }
            this.onBarChanged(
              "Bars---" + barSelected,
              [{ Bars: barSelected }],
              unit
            );
          }

          //set bars with dimension
        }
      }
    }

    console.log("In UpdateCompatibilityAttributeMap ", newCategoryAttributeMap);

    //Code Tweak for Spacer as this is the only category not going with the logic
    if (categoryName === "Glass Pack") {
      delete unit["valueSelected"]["Additional Glass Type"];
      this.deleteAttribute(unit, "Additional Glass Type");
      this.deleteInHomeAttributes(unit, "Additional Glass Type");
    }
    if (categoryName === "Glass Pack" && nextCategoryName !== "Spacer") {
      delete unit["categoryAttributeMap"]["Spacer"];
      delete newCategoryAttributeMap["Spacer"];
      delete unit["valueSelected"]["Spacer"];
      this.deleteAttribute(unit, "Spacer");
      this.deleteInHomeAttributes(unit, "Spacer");
    }
    if (categoryName === "Glass Pack" && nextCategoryName === "Spacer") {
      delete unit["categoryAttributeMap"]["Additional Glass Type"];
      delete newCategoryAttributeMap["Additional Glass Type"];
      delete unit["valueSelected"]["Additional Glass Type"];
      this.deleteAttribute(unit, "Additional Glass Type");
      this.deleteInHomeAttributes(unit, "Additional Glass Type");
    }

    if (categoryName === "Grid" && nextCategoryName !== "Bars") {
      delete unit["categoryAttributeMap"]["Bars"];
      unit["horizontalBarsDefault"] = 0;
      unit["verticalBarsDefault"] = 0;
      unit["horizontalBarsMin"] = 0;
      unit["verticalBarsMin"] = 0;
      unit["horizontalBarsMax"] = 0;
      unit["verticalBarsMax"] = 0;
      unit["horizontalBarsValue"] = 0;
      unit["verticalBarsValue"] = 0;
      delete newCategoryAttributeMap["Bars"];
      delete unit["valueSelected"]["Bars"];
      this.deleteAttribute(unit, "Bars");
      this.deleteInHomeAttributes(unit, "Bars");
    }

    for (let x in this.state.units) {
      if (unitsCopy[x].unitNumber === configuringUnit) {
        unitsCopy[x] = unit;
      }
    }

    this.setState({
      horizontalBarsDefault: unit["horizontalBarsDefault"],
      verticalBarsDefault: unit["verticalBarsDefault"],
      horizontalBarsValue: unit["horizontalBarsValue"],
      verticalBarsValue: unit["verticalBarsValue"],
      horizontalBarsMin: unit["horizontalBarsMin"],
      verticalBarsMin: unit["verticalBarsMin"],
      horizontalBarsMax: unit["horizontalBarsMax"],
      verticalBarsMax: unit["verticalBarsMax"],
      units: unitsCopy,
      categoryAttributeMap: unit["categoryAttributeMap"],
      valueSelected: unit["valueSelected"],
      barSelected,
    });

    return { ...this.noneOnTop(newCategoryAttributeMap) };
  };

  onDeselect(categoryName, labeledValue) {
    console.log("Attribute Deselected", labeledValue);
    let unitsCopy = this.state.units;
    let unitCopy = {};
    let mode = this.state.currentCategoryMode;

    // let categoryName;
    let valueName = labeledValue;

    console.log("Category and Value Deselected", labeledValue);
    console.log(`Category:${categoryName}, Attribute:${valueName}`);
    let updatedValueSelected;

    for (let x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        let attributes = unitCopy["attributes"];
        let valueSelected = unitCopy["valueSelected"];

        let inHomeAttributes = unitCopy["inHomeAttributes"];
        console.log("Units before deletion", attributes);

        let price = 0.0;
        let height = 0.0;

        console.log(
          "categoryName :",
          categoryName,
          " value :",
          valueName,
          " price : ",
          price,
          " height :",
          height
        );

        //this.setState({ categoryAttributeMap: { slicedCategoryAttributeMap } });
        //Below is maintained to see what is current selectedse list of attributes
        //Attribute exists the list
        if (attributes !== undefined && attributes.length > 0) {
          //We need to loop through the existing attributes to see if this one exists and we are updating it

          const attribute = attributes.find((attribute) => {
            return attribute.category === categoryName;
          });

          if (attribute !== undefined) {
            if (Array.isArray(attribute.value)) {
              let index = attribute.value.indexOf(valueName);
              let valueIndex = valueSelected[categoryName].indexOf(valueName);
              console.log("Value Index ", valueIndex);

              if (index !== -1) {
                attribute.value.splice(index, 1);
                attribute.price.splice(index, 1);
                attribute.retailPrice.splice(index, 1);
                //valueSelected[categoryName].splice(valueIndex, 1)
              }
            } else {
              attribute.value = "";
              attribute.price = 0.0;
              attribute.retailPrice = 0.0;
              attribute.height = 0.0;
              attribute.width = 0.0;
            }

            unitCopy["valueSelected"] = valueSelected;
            updatedValueSelected = valueSelected;
          }
          console.log("Attributes after update", attribute);
        }

        for (let h = 0; h < inHomeAttributes.length; h++) {
          if (
            inHomeAttributes[h].category === categoryName &&
            inHomeAttributes[h].value === valueName
          ) {
            inHomeAttributes.splice(h, 1);
            break;
          }else if (inHomeAttributes[h].category === categoryName && mode === "single"){
            inHomeAttributes.splice(h, 1);
            break;
          }
        }

        //unitCopy["Grid"] =  value;
        unitCopy["inHomeAttributes"] = inHomeAttributes;
        console.log("unitCopy after delete", unitCopy);
        unitsCopy[x] = unitCopy;
      
      let unitAttributes = unitCopy["attributes"];
      unitCopy["dataSource"] = [];
      for (let attribute in unitAttributes) {
        let i = 0;
        const newData = {
          key: i++,
          Category: unitAttributes[attribute].category,
          Attribute: unitAttributes[attribute].value,
          Price: unitAttributes[attribute].price,
        };
        unitCopy["dataSource"].push(newData);
      }

      unitsCopy[x] = unitCopy;
      /*this.setState({
dataSource: [...unitsCopy[x]["dataSource"]],
});*/
      const attributeUpdated = unitAttributes.find((attribute) => {
        return attribute.category === categoryName;
      });
      console.log(attributeUpdated.value);
      let attributeValue = attributeUpdated.value;
      let elementsIndex = this.state.dataSource.findIndex(
        (element) => element.Category === categoryName
      );
      let newArray = [...this.state.dataSource];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        Attribute: attributeValue.join(","),
      };
      this.setState({
        dataSource: newArray,
      });
    }
  }
  let isSubmitEnable = this.cartButtonToggle(unitsCopy)
    this.setState({
      units: unitsCopy,
      isSubmitEnable,
      valueSelected: updatedValueSelected,
    });
    console.log("Units Copy after deselecting Value ", unitsCopy);
    this.props.othersToStepper(
      unitsCopy,
      this.state.selectedItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      isSubmitEnable,
      this.state.itemMiscNotes
    );
  }

  onBarChanged(labeledValue, Option, configuringUnit) {
    //TODO:Image handling
    //let attributeImage = Option.key + ".png";
    //   this.state.isAttribute = "";
    console.log("Attribute Changed", labeledValue);
    console.log("Option", Option);
    //console.log(" pricing ", this.state.pricingMap);
    console.log("Category ");

    let unitsCopy = this.state.units;
    console.log("inside on attribute change", unitsCopy);

    let unitCopy = {};

    //Here I will set the current selected unit compatibiltyMapAttributeCopy
    //this.setState({categoryAttributeMap:compatibilityAttributeMapCopy})
    //if CategoryName is in the list of dependency map Cat4 -> Cat 5, Cat 6
    //then create a list of new the attributes

    //if CategoryName is in the list of dependency map Cat4 -> Cat 5, Cat 6
    //then create a list of new the attributes
    let categoryAndValue;
    let categoryName;
    let valueName;

    console.log("isSubmitEnable", this.state.isSubmitEnable);
    console.log("unitsCopy", unitsCopy);
    for (let x in unitsCopy) {
      if (unitsCopy[x].unitNumber === configuringUnit.unitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["configureAttributes"] = "Configured";
        let attributes = unitCopy["attributes"];
        let inHomeAttributes = [];
        if (unitCopy["inHomeAttributes"]) {
          inHomeAttributes = unitCopy["inHomeAttributes"];
        }
        let valueSelected = unitCopy["valueSelected"];
        let pricingMap = unitCopy["pricingMap"];
        console.log("Selected Unit's attributes", attributes);

        let mode = "single";

        if (Array.isArray(labeledValue)) {
          mode = "multiple";
          categoryAndValue = labeledValue.pop();

          if (categoryAndValue === undefined) {
            console.log("Category Undefined in onAttribute ", categoryAndValue);
          }
        } else {
          categoryAndValue = labeledValue;
        }

        this.setState({ currentCategoryMode: mode });
        console.log("Category And Value", categoryAndValue);

        [categoryName, valueName] =
          this.splitCategoryAndValue(categoryAndValue);
        console.log(`Category:${categoryName}, Attribute:${valueName}`);
        if (!unitsCopy[x].productCategories.includes(categoryName)) {
          return;
        }

        // updated in home attributes

        for (let h = 0; h < inHomeAttributes.length; h++) {
          if (
            inHomeAttributes[h].category === categoryName &&
            inHomeAttributes[h].value === valueName
          ) {
            inHomeAttributes.splice(h, 1);
            break;
          }else if (inHomeAttributes[h].category === categoryName && mode === "single"){
            inHomeAttributes.splice(h, 1);
            break;
          }
        }

        if (categoryAndValue !== undefined) {
          let price = 0.0;
          let height = 0.0;
          let width = 0.0;
          let retailPrice = 0;
          let discount = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["discount"]
          : 0;
          let imu = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["imu"]
            : 0;
          let tax = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["tax"]
            : 0;
          console.log(" ");
          /* Product Pricing Code ----- Need to enhance below code*/
          console.log("Product map loop", pricingMap[categoryName]);

          console.log("Product style matched");
          let catAttrPrices = pricingMap[categoryName];
          let row = {};
          for (row in catAttrPrices) {
            console.log("catAttrPrices");
            if (catAttrPrices[row]["attribute"] === valueName) {
              console.log("cat and att matched");
              price = Math.round(catAttrPrices[row]["accPrice"] * 100) / 100;
              height = catAttrPrices[row]["height"];
              width = catAttrPrices[row]["width"];

              retailPrice =  Math.round ((catAttrPrices[row]["accPrice"] *
              (1 - parseFloat(discount / 100)) *
              (1 + parseFloat(tax / 100))) /
              (1 - parseFloat(imu / 100)) * 1000)/ 1000;
             

              let categoryAndValueObject = {};
              categoryAndValueObject["price"] = price;
              categoryAndValueObject["retailPrice"] = retailPrice;
              categoryAndValueObject["value"] = valueName;
              categoryAndValueObject["category"] = categoryName;
              categoryAndValueObject["accessory"] =
                catAttrPrices[row]["accessory"];
              categoryAndValueObject["accessoryUOM"] =
                catAttrPrices[row]["accessoryUOM"];
              categoryAndValueObject["accessoryCode"] =
                catAttrPrices[row]["accessoryCode"];
              categoryAndValueObject["attributeShort"] =
                catAttrPrices[row]["attributeShort"];
              //alert(catAttrPrices[row]["gridCode"]);
              console.log(catAttrPrices[row]["gridCode"]);
              if (catAttrPrices[row]["gridCode"]) {
                categoryAndValueObject["gridCode"] =
                  catAttrPrices[row]["gridCode"];
                categoryAndValueObject["gridStyle"] =
                  catAttrPrices[row]["gridStyle"];
                categoryAndValueObject["profile"] =
                  catAttrPrices[row]["profile"];
                categoryAndValueObject["lmsGridId"] =
                  catAttrPrices[row]["lmsGridId"];
              }
              inHomeAttributes.push(categoryAndValueObject);
            }
          }

          console.log(
            "categoryName :",
            categoryName,
            " value :",
            valueName,
            " price : ",
            price,
            " height :",
            height
          );
          console.log("inHomeAttributes", inHomeAttributes);

          //Below is maintained to see what is current selected list of attributes
          //Attribute exists the list
          if (attributes !== undefined && attributes.length > 0) {
            //We need to loop through the existing attributes to see if this one exists and we are updating it
            // eslint-disable-next-line
            const attribute = attributes.find((attribute) => {
              // eslint-disable-next-line
              return attribute.category === categoryName;
            });

            if (attribute !== undefined) {
              if (mode === "multiple") {
                // eslint-disable-next-line
                var attr = Option.find((option, index, options) => {
                  // eslint-disable-next-line
                  return option.key === valueName;
                });
                if (attr !== undefined) {
                  if (!attribute.value.includes(valueName)) {
                    attribute.value.push(valueName);
                    attribute.price.push(price);
                    attribute.retailPrice.push(retailPrice);
                    valueSelected[categoryName] = attribute.value;
                  }
                } else {
                  // attribute.price = [];
                }
              } else {
                attribute.value = valueName;
                attribute.price = price;
                attribute.retailPrice = retailPrice;
                valueSelected[categoryName] = attribute.value;
              }

              //attribute.price = price;
              attribute.height = height;
              attribute.width = width;
            } else {
              let categoryAndValueObject = {};
              categoryAndValueObject["category"] = categoryName;
              if (mode === "multiple") {
                valueSelected[categoryName] = [];
                categoryAndValueObject["value"] = [];
                categoryAndValueObject["value"].push(valueName);
                categoryAndValueObject["price"] = [];
                categoryAndValueObject["price"].push(price);
                categoryAndValueObject["retailPrice"] = [];
                categoryAndValueObject["retailPrice"].push(retailPrice);
              } else {
                valueSelected[categoryName] = "";
                categoryAndValueObject["value"] = "";
                categoryAndValueObject["value"] = valueName;
                categoryAndValueObject["price"] = "";
                categoryAndValueObject["price"] = price;
                categoryAndValueObject["retailPrice"] = "";
              categoryAndValueObject["retailPrice"] = retailPrice;
              }
              categoryAndValueObject["height"] = height;
              categoryAndValueObject["width"] = width;
              attributes.push(categoryAndValueObject);
              valueSelected[categoryName] = categoryAndValueObject["value"];
            }
            console.log("Attributes after update", attributes);
          } //874
          //There is nothing selected yet we will create attr for first time
          else {
            attributes = [];
            valueSelected = {};
            let categoryAndValueObject = {};
            categoryAndValueObject["category"] = categoryName;
            if (mode === "multiple") {
              valueSelected[categoryName] = [];
              categoryAndValueObject["value"] = [];
              categoryAndValueObject["price"] = [];
              categoryAndValueObject["price"].push(price);
              categoryAndValueObject["retailPrice"] = [];
              categoryAndValueObject["retailPrice"].push(retailPrice);
              categoryAndValueObject["value"].push(valueName);
            } else {
              valueSelected[categoryName] = "";
              categoryAndValueObject["value"] = "";
              categoryAndValueObject["value"] = valueName;
              categoryAndValueObject["price"] = "";
              categoryAndValueObject["price"] = price;
              categoryAndValueObject["retailPrice"] = "";
              categoryAndValueObject["retailPrice"] = retailPrice;
            }

            categoryAndValueObject["height"] = height;
            categoryAndValueObject["width"] = width;
            attributes.push(categoryAndValueObject);
            valueSelected[categoryName] = categoryAndValueObject["value"];
          }

          //unitCopy["Grid"] =  value;
          unitCopy["attributes"] = attributes;
          unitCopy["inHomeAttributes"] = inHomeAttributes;
          unitCopy["valueSelected"] = valueSelected;
          //var categoryAttributeMapCopy =
          //var categoryAttributeMap = unitsCopy[x].productAttributesMap

          if (
            categoryName !== "Interior Color" ||
            categoryName !== "Exterior Color"
          ) {
            //This is getting the next compatible category
            let catAttr = {
              ...this.updateCompatibilityAttributeMapInChangeOrder(
                categoryName,
                valueName,
                unitCopy,
                unitCopy["unitNumber"],
                ""
              ),
            };
            let currentAttributeSeleted = {};
            currentAttributeSeleted[categoryName] = valueName;
            const isEmpty = Object.keys(catAttr).length === 0;
            if (isEmpty) {
              // unitCopy["categoryAttributeMap"] = categoryAttributeMap
              //this.setState({categoryAttributeMap: {...this.state.categoryAttributeMap},currentAttributeSeleted})
            } else {
              //Check if catAttr already exists then get rid of all others except the next category.
              /*const baseCategoryAttributeMapCopy =  unitCopy["baseCategoryAttributeMap"]
              Object.keys(catAttr).forEach((key) => {
                if (
                  baseCategoryAttributeMapCopy[key] === "Additional Glass Type" &&
                  unitCopy["pricingMap"]["Additional Glass Type"] !==
                    undefined
                ) {
                  baseCategoryAttributeMapCopy[key] =
                    unitCopy["pricingMap"]["Additional Glass Type"];
                }
              });
              unitCopy["baseCategoryAttributeMap"] = {
                ...baseCategoryAttributeMapCopy
              }; */
              unitCopy["categoryAttributeMap"] = { ...catAttr };
              console.log(
                "Attributes Pricing for Window : Attributes Pricing for Window : ",
                unitCopy["windowStyle"],
                " panel #",
                unitCopy["unitNumber"],
                " is ",
                unitCopy["attributes"]
              );
              this.setState({
                categoryAttributeMap: { ...catAttr },
                categories: Object.keys(catAttr),
              });
            }
          }
          unitCopy["totalPrice"] = attributes.reduce(function (a, b) {
            return a + b.price;
          }, 0.0);
          console.log("Unit Copy after adding attributes", unitCopy);
        }

        let unitAttributes = unitCopy["attributes"];
        let attribute = {};

        /* for (attribute in unitAttributes) {
          if (unitAttributes[attribute].category === categoryName) {
            newPrice = unitAttributes[attribute].price;
            configuration = unitAttributes[attribute].value;
            currentCategorySelected = unitAttributes[attribute].category;
          }
        }*/

        unitCopy["dataSource"] = [];

        for (attribute in unitAttributes) {
          let attributeValue = " ";
          if (Array.isArray(unitAttributes[attribute].value)) {
            unitAttributes[attribute].value.forEach((val) => {
              attributeValue = attributeValue + "," + val;
            });
          } else {
            attributeValue = unitAttributes[attribute].value;
          }
          let j = 0;
          j++;
          const newData = {
            key: j,
            Category: unitAttributes[attribute].category,
            Attribute: unitAttributes[attribute].value,
            Price: unitAttributes[attribute].price,
          };
          unitCopy["dataSource"].push(newData);
        }

        unitsCopy[x] = unitCopy;
        this.setState({
          valueSelected: { ...unitCopy["valueSelected"] },
          dataSource: [...unitsCopy[x]["dataSource"]],
          units: unitsCopy,
        });
      }
    }
    if (unitsCopy.length > 1) {
      console.log(
        "if frame",
        unitsCopy.every(this.isAllConfigured),
        Object.keys(unitCopy["categoryAttributeMap"]).length
      );
      if (
        Object.keys(unitCopy["categoryAttributeMap"]).length ===
          unitCopy["attributes"].length &&
        unitsCopy.every(this.isAllConfigured)
      ) {
        this.setState({isSubmitEnable:false})
      }
    } else {
      console.log("else frame");
      if (
        Object.keys(unitCopy["categoryAttributeMap"]).length ===
        unitCopy["attributes"].length
      ) {
        this.setState({isSubmitEnable:false})
      }
    }

    //Updation to the summary table, Would move it to separate function

    console.log("Units Copy ", unitsCopy);
    console.log("is submitenable", this.state.isSubmitEnable);
    this.props.othersToStepper(
      this.state.units,
      this.state.selectedItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      this.state.isSubmitEnable,
      this.state.itemMiscNotes
    );
  }
  reOrderCategories = (unitCopy) => {
    let newCategoryAttributeMap = unitCopy["categoryAttributeMap"];
    let unitCategoryAttributeMap = {};
    let categoryOrder = {};
    let unitCategoryOrder = {};
    for (let x in unitCopy.productCategories) {
      categoryOrder[unitCopy.productCategories[x]] = x;
    }

    for (const [key] of Object.entries(newCategoryAttributeMap)) {
      if (categoryOrder.hasOwnProperty(key))
        unitCategoryOrder[key] = categoryOrder[key];
    }

    const sortable = Object.entries(unitCategoryOrder)
      .sort(([, a], [, b]) => a - b)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    let unitCategorySequence = Object.keys(sortable);
    for (let x in unitCategorySequence) {
      unitCategoryAttributeMap[unitCategorySequence[x]] =
        newCategoryAttributeMap[unitCategorySequence[x]];
    }
    return unitCategoryAttributeMap;
  };

  onAttributeChanged(labeledValue, Option) {
    //TODO:Image handling
    //let attributeImage = Option.key + ".png";
    this.setState({isAttribute:""})
    console.log("Attribute Changed", labeledValue);
    console.log("Option", Option);
    //console.log(" pricing ", this.state.pricingMap);
    console.log("Category ");

    let unitsCopy = this.state.units;

    let unitCopy = {};


    //Here I will set the current selected unit compatibiltyMapAttributeCopy
    //this.setState({categoryAttributeMap:compatibilityAttributeMapCopy})
    //if CategoryName is in the list of dependency map Cat4 -> Cat 5, Cat 6
    //then create a list of new the attributes

    //if CategoryName is in the list of dependency map Cat4 -> Cat 5, Cat 6
    //then create a list of new the attributes
    let categoryAndValue;
    let categoryName;
    let valueName;

    for (let x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["configureAttributes"] = "Configured";
        let attributes = unitCopy["attributes"];
        let inHomeAttributes = [];
        if (unitCopy["inHomeAttributes"]) {
          inHomeAttributes = unitCopy["inHomeAttributes"];
        }
        let valueSelected = unitCopy["valueSelected"];
        let pricingMap = unitCopy["pricingMap"];
        console.log("Selected Unit's attributes", valueSelected);
        let mode = "single";

        if (Array.isArray(labeledValue)) {
          mode = "multiple";
          categoryAndValue = labeledValue.pop();

          if (categoryAndValue === undefined) {
            console.log("Category Undefined in onAttribute ", categoryAndValue);
          }
        } else {
          categoryAndValue = labeledValue;
        }

        this.setState({ currentCategoryMode: mode });
        console.log("Category And Value", categoryAndValue);

        [categoryName, valueName] =
          this.splitCategoryAndValue(categoryAndValue);
        console.log(`Category:${categoryName}, Attribute:${valueName}`);
        if (!unitsCopy[x].productCategories.includes(categoryName)) {
          return;
        }
        // updated in home attributes

        for (let h = 0; h < inHomeAttributes.length; h++) {
          if (
            inHomeAttributes[h].category === categoryName &&
            inHomeAttributes[h].value === valueName
          ) {
            inHomeAttributes.splice(h, 1);
            break;
          }else if (inHomeAttributes[h].category === categoryName && mode === "single"){
            inHomeAttributes.splice(h, 1);
            break;
          }
        }

        if (categoryAndValue !== undefined) {
          let price = 0.0;
          let height = 0.0;
          let width = 0.0;
          let retailPrice = 0;
          let discount = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["discount"]
          : 0;
          let imu = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["imu"]
            : 0;
          let tax = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["tax"]
            : 0;
          console.log(" ");
          /* Product Pricing Code ----- Need to enhance below code*/
          console.log("Product map loop", pricingMap[categoryName]);

          console.log("Product style matched");
          let catAttrPrices = pricingMap[categoryName];
          let row = {};
          for (row in catAttrPrices) {
            console.log("catAttrPrices");
            if (catAttrPrices[row]["attribute"] === valueName) {
              console.log("cat and att matched");
              price = Math.round(catAttrPrices[row]["accPrice"] * 100) / 100;
              retailPrice =  Math.round ((catAttrPrices[row]["accPrice"] *
                (1 - parseFloat(discount / 100)) *
                (1 + parseFloat(tax / 100))) /
              (1 - parseFloat(imu / 100)) * 1000)/ 1000;
              height = catAttrPrices[row]["height"];
              width = catAttrPrices[row]["width"];

              let categoryAndValueObject = {};
              categoryAndValueObject["price"] = price;
              categoryAndValueObject["retailPrice"] = retailPrice;
              categoryAndValueObject["value"] = valueName;
              categoryAndValueObject["category"] = categoryName;
              categoryAndValueObject["accessory"] =
                catAttrPrices[row]["accessory"];
              categoryAndValueObject["accessoryUOM"] =
                catAttrPrices[row]["accessoryUOM"];
              categoryAndValueObject["accessoryCode"] =
                catAttrPrices[row]["accessoryCode"];
              categoryAndValueObject["attributeShort"] =
                catAttrPrices[row]["attributeShort"];
              //alert(catAttrPrices[row]["gridCode"]);
              console.log(catAttrPrices[row]["gridCode"]);
              if (catAttrPrices[row]["gridCode"]) {
                categoryAndValueObject["gridCode"] =
                  catAttrPrices[row]["gridCode"];
                categoryAndValueObject["gridStyle"] =
                  catAttrPrices[row]["gridStyle"];
                categoryAndValueObject["profile"] =
                  catAttrPrices[row]["profile"];
                categoryAndValueObject["lmsGridId"] =
                  catAttrPrices[row]["lmsGridId"];
              }
              inHomeAttributes.push(categoryAndValueObject);
            }
          }

          console.log(
            "categoryName :",
            categoryName,
            " value :",
            valueName,
            " price : ",
            price,
            " height :",
            height
          );
          console.log("inHomeAttributes", inHomeAttributes);

          //Below is maintained to see what is current selected list of attributes
          //Attribute exists the list
          if (attributes !== undefined && attributes.length > 0) {
            //We need to loop through the existing attributes to see if this one exists and we are updating it
            // eslint-disable-next-line
            const attribute = attributes.find((attribute) => {
              // eslint-disable-next-line
              return attribute.category === categoryName;
            });

            if (attribute !== undefined) {
              if (mode === "multiple") {
                // eslint-disable-next-line
                var attr = Option.find((option, index, options) => {
                  // eslint-disable-next-line
                  return option.key === valueName;
                });
                if (attr !== undefined) {
                  if (!attribute.value.includes(valueName)) {
                    attribute.value.push(valueName);
                    attribute.price.push(price);
                    attribute.retailPrice.push(retailPrice);
                    valueSelected[categoryName] = attribute.value;
                  }
                } else {
                  attribute.price = [];
                  attribute.retailPrice = [];
                }
              } else {
                attribute.value = valueName;
                attribute.price = price;
                attribute.retailPrice = retailPrice;
                valueSelected[categoryName] = attribute.value;
              }

              //attribute.price = price;
              attribute.height = height;
              attribute.width = width;
            } else {
              let categoryAndValueObject = {};
              categoryAndValueObject["category"] = categoryName;
              if (mode === "multiple") {
                valueSelected[categoryName] = [];
                categoryAndValueObject["value"] = [];
                categoryAndValueObject["value"].push(valueName);
                categoryAndValueObject["price"] = [];
                categoryAndValueObject["price"].push(price);
                categoryAndValueObject["retailPrice"] = [];
                categoryAndValueObject["retailPrice"].push(retailPrice);
              } else {
                valueSelected[categoryName] = "";
                categoryAndValueObject["value"] = "";
                categoryAndValueObject["value"] = valueName;
                categoryAndValueObject["price"] = "";
                categoryAndValueObject["price"] = price;
                categoryAndValueObject["retailPrice"] = "";
              categoryAndValueObject["retailPrice"] = retailPrice;
              }
              categoryAndValueObject["height"] = height;
              categoryAndValueObject["width"] = width;
              attributes.push(categoryAndValueObject);
              valueSelected[categoryName] = categoryAndValueObject["value"];
            }
            console.log("Attributes after update", attributes);
          } //874
          //There is nothing selected yet we will create attr for first time
          else {
            attributes = [];
            valueSelected = {};
            let categoryAndValueObject = {};
            categoryAndValueObject["category"] = categoryName;
            if (mode === "multiple") {
              valueSelected[categoryName] = [];
              categoryAndValueObject["value"] = [];
              categoryAndValueObject["price"] = [];
              categoryAndValueObject["price"].push(price);
              categoryAndValueObject["retailPrice"] = [];
              categoryAndValueObject["retailPrice"].push(retailPrice);
              categoryAndValueObject["value"].push(valueName);
            } else {
              valueSelected[categoryName] = "";
              categoryAndValueObject["value"] = "";
              categoryAndValueObject["value"] = valueName;
              categoryAndValueObject["price"] = "";
              categoryAndValueObject["price"] = price;
              categoryAndValueObject["retailPrice"] = "";
              categoryAndValueObject["retailPrice"] = retailPrice;
            }

            categoryAndValueObject["height"] = height;
            categoryAndValueObject["width"] = width;
            attributes.push(categoryAndValueObject);
            valueSelected[categoryName] = categoryAndValueObject["value"];
          }

          //unitCopy["Grid"] =  value;
          unitCopy["attributes"] = attributes;
          unitCopy["inHomeAttributes"] = inHomeAttributes;
          unitCopy["valueSelected"] = valueSelected;
          //var categoryAttributeMapCopy =
          //var categoryAttributeMap = unitsCopy[x].productAttributesMap

          if (
            categoryName !== "Interior Color" ||
            categoryName !== "Exterior Color"
          ) {
            //This is getting the next compatible category
            let catAttr = {
              ...this.updateCompatibilityAttributeMap(
                categoryName,
                valueName,
                unitCopy,
                "",
                ""
              ),
            };
            let currentAttributeSeleted = {};
            currentAttributeSeleted[categoryName] = valueName;
            const isEmpty = Object.keys(catAttr).length === 0;
            if (isEmpty) {
              // unitCopy["categoryAttributeMap"] = categoryAttributeMap
              //this.setState({categoryAttributeMap: {...this.state.categoryAttributeMap},currentAttributeSeleted})
            } else {
              //Check if catAttr already exists then get rid of all others except the next category.
              /*const baseCategoryAttributeMapCopy =  unitCopy["baseCategoryAttributeMap"]
                            Object.keys(baseCategoryAttributeMapCopy).forEach((key) => {
                              if (
                                baseCategoryAttributeMapCopy[key] === "Additional Glass Type" &&
                                unitCopy["pricingMap"]["Additional Glass Type"] !==
                                  undefined
                              ) {
                                baseCategoryAttributeMapCopy[key] =
                                  unitCopy["pricingMap"]["Additional Glass Type"];
                              }
                            });
                            unitCopy["baseCategoryAttributeMap"] = {
                              ...baseCategoryAttributeMapCopy
                            }; */
              unitCopy["categoryAttributeMap"] = { ...catAttr };
              this.setState({
                categoryAttributeMap: { ...catAttr },
                categories: Object.keys(catAttr),
              });
            }
          }
          unitCopy["totalPrice"] = attributes.reduce(function (a, b) {
            return a + b.price;
          }, 0.0);
          console.log("Unit Copy after adding attributes", unitCopy);
        }

        let unitAttributes = unitCopy["attributes"];
        let attribute = {};

        /* for (attribute in unitAttributes) {
                  if (unitAttributes[attribute].category === categoryName) {
                    newPrice = unitAttributes[attribute].price;
                    configuration = unitAttributes[attribute].value;
                    currentCategorySelected = unitAttributes[attribute].category;
                  }
                }*/

        unitCopy["dataSource"] = [];

        for (attribute in unitAttributes) {
          let attributeValue = " ";
          if (Array.isArray(unitAttributes[attribute].value)) {
            unitAttributes[attribute].value.forEach((val) => {
              attributeValue = attributeValue + "," + val;
            });
          } else {
            attributeValue = unitAttributes[attribute].value;
          }
          if (unitAttributes[attribute].category === "Bars") {
            if (
              unitCopy.horizontalBarsValue !== undefined &&
              unitCopy.verticalBarsValue !== undefined
            ) {
              attributeValue =
                unitCopy.verticalBarsValue +
                ", " +
                unitCopy.horizontalBarsValue;
            } else {
              attributeValue = "";
            }
          }

          let j = 0;
          j++;
          const newData = {
            key: j,
            Category: unitAttributes[attribute].category,
            Attribute: attributeValue,
            Price: unitAttributes[attribute].price,
          };
          unitCopy["dataSource"].push(newData);
        }
        unitCopy["categoryAttributeMap"] = this.reOrderCategories(unitCopy);
        unitsCopy[x] = unitCopy;
        console.log(
          "Re Ordered CategoryAttribute Map ",
          unitCopy["categoryAttributeMap"]
        );

        this.configureUnit(this.state.configuringUnitNumber);
        this.setState({
          valueSelected: { ...unitCopy["valueSelected"] },
          dataSource: [...unitsCopy[x]["dataSource"]],
          units: unitsCopy,
          categoryAttributeMap: { ...unitCopy["categoryAttributeMap"] },
        });
      }
    }
    let isSubmitEnable =  this.cartButtonToggle(unitsCopy)
    this.setState({ isSubmitEnable});
    /* if (unitsCopy.length > 1) {
      console.log(
        "if frame",
        unitsCopy.every(this.isAllConfigured),
        Object.keys(unitCopy["categoryAttributeMap"]).length
      );
      if (
        Object.keys(unitCopy["categoryAttributeMap"]).length ===
          unitCopy["attributes"].length &&
        unitsCopy.every(this.isAllConfigured)
      ) {
        this.state.isSubmitEnable = false;
      }
    } else {
      console.log("else frame");
      if (
        Object.keys(unitCopy["categoryAttributeMap"]).length ===
        unitCopy["attributes"].length
      ) {
        this.state.isSubmitEnable = false;
      }
    }*/

    //Updation to the summary table, Would move it to separate function
  

    console.log("Units Copy ", unitsCopy);
    console.log(
      "is submitenable",
      this.state.isSubmitEnable,
      this.state.selectedItemLabor
    );
    this.props.othersToStepper(
      this.state.units,
      this.state.selectedItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      isSubmitEnable,
      this.state.itemMiscNotes
    );
  }
  cartButtonToggle = (unitsCopy) => {
    let isSubmitEnable = false;
    let unitsLoopsBreakFlag = false;
    for (let x in unitsCopy) {
      let categorykeys = Object.keys(unitsCopy[x].categoryAttributeMap);
      let valueSelected = unitsCopy[x].valueSelected;
      let productCategories = unitsCopy[x].productCategories;
      let valueKeys = Object.keys(valueSelected);
      if (
        !categorykeys.includes(productCategories[productCategories.length - 2])
      ) {
        //if frame is not avaialable yet
        isSubmitEnable = false;
        console.log("Frame not found in categoryAttributeMap");
        unitsLoopsBreakFlag = true;
        break;
      } else {
        for (let cat in categorykeys) {
          if (valueKeys.includes(categorykeys[cat])) {
            if (
              (Array.isArray(valueSelected[categorykeys[cat]]) &&
                valueSelected[categorykeys[cat]].length === 0) ||
              (!Array.isArray(valueSelected[categorykeys[cat]]) &&
                valueSelected[categorykeys[cat]] === "")
            ) {
              isSubmitEnable = false;
              unitsLoopsBreakFlag = true;
              break;
            }
          } else {
            isSubmitEnable = false;
            unitsLoopsBreakFlag = true;
            break;
          }
          isSubmitEnable = true;
        }
      }
      if( unitsLoopsBreakFlag){
        break;
      }
    }
    return !isSubmitEnable;
  };

  onAttributeChangedInChangeOrder(labeledValue, Option, configuringUnit) {
    //TODO:Image handling
    //let attributeImage = Option.key + ".png";
   
    this.setState({isAttribute:""})
    console.log("Attribute Changed", labeledValue);
    console.log("Option", Option);
    //console.log(" pricing ", this.state.pricingMap);
    console.log("Category ");

    let unitsCopy = this.state.units;

    let unitCopy = {};


    //Here I will set the current selected unit compatibiltyMapAttributeCopy
    //this.setState({categoryAttributeMap:compatibilityAttributeMapCopy})
    //if CategoryName is in the list of dependency map Cat4 -> Cat 5, Cat 6
    //then create a list of new the attributes

    //if CategoryName is in the list of dependency map Cat4 -> Cat 5, Cat 6
    //then create a list of new the attributes
    let categoryAndValue;
    let categoryName;
    let valueName;
    for (let x in unitsCopy) {
      if (unitsCopy[x].unitNumber === configuringUnit) {
        unitCopy = unitsCopy[x];
        unitCopy["configureAttributes"] = "Configured";
        let attributes = unitCopy["attributes"];
        let inHomeAttributes = [];
        if (unitCopy["inHomeAttributes"]) {
          inHomeAttributes = unitCopy["inHomeAttributes"];
        }
        let valueSelected = unitCopy["valueSelected"];
        let pricingMap = unitCopy["pricingMap"];
        console.log("Selected Unit's attributes", valueSelected);
        let mode = "single";

        if (Array.isArray(labeledValue)) {
          mode = "multiple";
          categoryAndValue = labeledValue.pop();

          if (categoryAndValue === undefined) {
            console.log("Category Undefined in onAttribute ", categoryAndValue);
          }
        } else {
          categoryAndValue = labeledValue;
        }

        this.setState({ currentCategoryMode: mode });
        console.log("Category And Value", categoryAndValue);

        [categoryName, valueName] =
          this.splitCategoryAndValue(categoryAndValue);
        console.log(`Category:${categoryName}, Attribute:${valueName}`);

        for (let h = 0; h < inHomeAttributes.length; h++) {
          if (
            inHomeAttributes[h].category === categoryName &&
            inHomeAttributes[h].value === valueName
          ) {
            inHomeAttributes.splice(h, 1);
            break;
          }else if (inHomeAttributes[h].category === categoryName && mode === "single"){
            inHomeAttributes.splice(h, 1);
            break;
          }
        }

        if (categoryAndValue !== undefined) {
          let price = 0.0;
          let height = 0.0;
          let width = 0.0;
          let retailPrice = 0;
          let discount = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["discount"]
          : 0;
          let imu = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["imu"]
            : 0;
          let tax = unitCopy.pricingTaxImu?unitCopy.pricingTaxImu["tax"]
            : 0;
          console.log(" ");
          /* Product Pricing Code ----- Need to enhance below code*/
          console.log("Product map loop", pricingMap[categoryName]);

          console.log("Product style matched");
          let catAttrPrices = pricingMap[categoryName];
          let row = {};
          for (row in catAttrPrices) {
            console.log("catAttrPrices");
            if (catAttrPrices[row]["attribute"] === valueName) {
              console.log("cat and att matched");
              price = Math.round(catAttrPrices[row]["accPrice"] * 100) / 100;
              height = catAttrPrices[row]["height"];
              width = catAttrPrices[row]["width"];

              retailPrice =  Math.round ((catAttrPrices[row]["accPrice"] *
              (1 - parseFloat(discount / 100)) *
              (1 + parseFloat(tax / 100))) /
              (1 - parseFloat(imu / 100)) * 1000)/ 1000;
              

              let categoryAndValueObject = {};
              categoryAndValueObject["price"] = price;
              categoryAndValueObject["retailPrice"] = retailPrice;
              categoryAndValueObject["value"] = valueName;
              categoryAndValueObject["category"] = categoryName;
              categoryAndValueObject["accessory"] =
                catAttrPrices[row]["accessory"];
              categoryAndValueObject["accessoryUOM"] =
                catAttrPrices[row]["accessoryUOM"];
              categoryAndValueObject["accessoryCode"] =
                catAttrPrices[row]["accessoryCode"];
              categoryAndValueObject["attributeShort"] =
                catAttrPrices[row]["attributeShort"];
              //alert(catAttrPrices[row]["gridCode"]);
              console.log(catAttrPrices[row]["gridCode"]);
              if (catAttrPrices[row]["gridCode"]) {
                categoryAndValueObject["gridCode"] =
                  catAttrPrices[row]["gridCode"];
                categoryAndValueObject["gridStyle"] =
                  catAttrPrices[row]["gridStyle"];
                categoryAndValueObject["profile"] =
                  catAttrPrices[row]["profile"];
                categoryAndValueObject["lmsGridId"] =
                  catAttrPrices[row]["lmsGridId"];
              }
              inHomeAttributes.push(categoryAndValueObject);
            }
          }

          console.log(
            "categoryName :",
            categoryName,
            " value :",
            valueName,
            " price : ",
            price,
            " height :",
            height
          );
          console.log("inHomeAttributes", inHomeAttributes);

          //Below is maintained to see what is current selected list of attributes
          //Attribute exists the list
          if (attributes !== undefined && attributes.length > 0) {
            //We need to loop through the existing attributes to see if this one exists and we are updating it
              // eslint-disable-next-line
            const attribute = attributes.find((attribute) => {
              // eslint-disable-next-line
              return attribute.category === categoryName;
            });

            if (attribute !== undefined) {
              if (mode === "multiple") {
                // eslint-disable-next-line
                var attr = Option.find((option) => {
                  // eslint-disable-next-line
                  return (option.key === valueName);
                });
                if (attr !== undefined) {
                  if (!attribute.value.includes(valueName)) {
                    attribute.value.push(valueName);
                    attribute.price.push(price);
                    attribute.retailPrice.push(retailPrice);
                    valueSelected[categoryName] = attribute.value;
                  }
                } else {
                }
              } else {
                attribute.value = valueName;
                attribute.price = price;
                attribute.retailPrice = retailPrice;
                valueSelected[categoryName] = attribute.value;
              }
              // Yogita: please keep it commented for pricing
              //attribute.price = price;
              attribute.height = height;
              attribute.width = width;
            } else {
              let categoryAndValueObject = {};
              categoryAndValueObject["category"] = categoryName;
              if (mode === "multiple") {
                valueSelected[categoryName] = [];
                categoryAndValueObject["value"] = [];
                categoryAndValueObject["value"].push(valueName);
                categoryAndValueObject["price"] = [];
                categoryAndValueObject["retailPrice"] = [];
                categoryAndValueObject["price"].push(price);
                categoryAndValueObject["retailPrice"].push(retailPrice);
              } else {
                valueSelected[categoryName] = "";
                categoryAndValueObject["value"] = "";
                categoryAndValueObject["value"] = valueName;
                categoryAndValueObject["price"] = "";
                categoryAndValueObject["price"] = price;
                categoryAndValueObject["retailPrice"] = "";
                categoryAndValueObject["retailPrice"] = retailPrice;
              }
              categoryAndValueObject["height"] = height;
              categoryAndValueObject["width"] = width;
              attributes.push(categoryAndValueObject);
              valueSelected[categoryName] = categoryAndValueObject["value"];
            }
            console.log("Attributes after update", attributes);
          } //874
          //There is nothing selected yet we will create attr for first time
          else {
            attributes = [];
            valueSelected = {};
            let categoryAndValueObject = {};
            categoryAndValueObject["category"] = categoryName;
            if (mode === "multiple") {
              valueSelected[categoryName] = [];
              categoryAndValueObject["value"] = [];
              categoryAndValueObject["price"] = [];
              categoryAndValueObject["price"].push(price);
              categoryAndValueObject["retailPrice"] = [];
              categoryAndValueObject["retailPrice"].push(retailPrice);
              categoryAndValueObject["value"].push(valueName);
            } else {
              valueSelected[categoryName] = "";
              categoryAndValueObject["value"] = "";
              categoryAndValueObject["value"] = valueName;
              categoryAndValueObject["price"] = "";
              categoryAndValueObject["price"] = price;
              categoryAndValueObject["retailPrice"] = "";
              categoryAndValueObject["retailPrice"] = retailPrice;
            }

            categoryAndValueObject["height"] = height;
            categoryAndValueObject["width"] = width;
            attributes.push(categoryAndValueObject);
            valueSelected[categoryName] = categoryAndValueObject["value"];
          }

          //unitCopy["Grid"] =  value;
          unitCopy["attributes"] = attributes;
          unitCopy["inHomeAttributes"] = inHomeAttributes;
          unitCopy["valueSelected"] = valueSelected;
          //var categoryAttributeMapCopy =
          //var categoryAttributeMap = unitsCopy[x].productAttributesMap

          if (
            categoryName !== "Interior Color" ||
            categoryName !== "Exterior Color"
          ) {
            //This is getting the next compatible category
            let catAttr = {
              ...this.updateCompatibilityAttributeMapInChangeOrder(
                categoryName,
                valueName,
                unitCopy,
                configuringUnit,
                "2"
              ),
            };
            let currentAttributeSeleted = {};
            currentAttributeSeleted[categoryName] = valueName;
            const isEmpty = Object.keys(catAttr).length === 0;
            if (isEmpty) {
              // unitCopy["categoryAttributeMap"] = categoryAttributeMap
              //this.setState({categoryAttributeMap: {...this.state.categoryAttributeMap},currentAttributeSeleted})
            } else {
              //Check if catAttr already exists then get rid of all others except the next category.
              /*const baseCategoryAttributeMapCopy =  unitCopy["baseCategoryAttributeMap"]
                            Object.keys(baseCategoryAttributeMapCopy).forEach((key) => {
                              if (
                                baseCategoryAttributeMapCopy[key] === "Additional Glass Type" &&
                                unitCopy["pricingMap"]["Additional Glass Type"] !==
                                  undefined
                              ) {
                                baseCategoryAttributeMapCopy[key] =
                                  unitCopy["pricingMap"]["Additional Glass Type"];
                              }
                            });
                            unitCopy["baseCategoryAttributeMap"] = {
                              ...baseCategoryAttributeMapCopy
                            }; */
              unitCopy["categoryAttributeMap"] = { ...catAttr };
              this.setState({
                categoryAttributeMap: { ...catAttr },
                categories: Object.keys(catAttr),
              });
            }
          }
          unitCopy["totalPrice"] = attributes.reduce(function (a, b) {
            return a + b.price;
          }, 0.0);
          console.log("Unit Copy after adding attributes", unitCopy);
        }

        let unitAttributes = unitCopy["attributes"];
        let attribute = {};

        /* for (attribute in unitAttributes) {
                  if (unitAttributes[attribute].category === categoryName) {
                    newPrice = unitAttributes[attribute].price;
                    configuration = unitAttributes[attribute].value;
                    currentCategorySelected = unitAttributes[attribute].category;
                  }
                }*/

        unitCopy["dataSource"] = [];

        for (attribute in unitAttributes) {
          let attributeValue = " ";
          if (Array.isArray(unitAttributes[attribute].value)) {
            unitAttributes[attribute].value.forEach((val) => {
              attributeValue = attributeValue + "," + val;
            });
          } else {
            attributeValue = unitAttributes[attribute].value;
          }
          if (unitAttributes[attribute].category === "Bars") {
            if (
              unitCopy.horizontalBarsValue !== undefined &&
              unitCopy.verticalBarsValue !== undefined
            ) {
              attributeValue =
                unitCopy.verticalBarsValue +
                ", " +
                unitCopy.horizontalBarsValue;
            } else {
              attributeValue = "";
            }
          }

          let j = 0;
          j++;
          const newData = {
            key: j,
            Category: unitAttributes[attribute].category,
            Attribute: attributeValue,
            Price: unitAttributes[attribute].price,
          };
          unitCopy["dataSource"].push(newData);
        }
        unitCopy["categoryAttributeMap"] = this.reOrderCategories(unitCopy);
        unitsCopy[x] = unitCopy;
        this.setState({
          valueSelected: { ...unitCopy["valueSelected"] },
          dataSource: [...unitsCopy[x]["dataSource"]],
          units: unitsCopy,
        });
      }
    }
    let isSubmitEnable =  this.cartButtonToggle(unitsCopy)
    this.setState({ isSubmitEnable});
    /*    if (unitsCopy.length > 1) {
      console.log("if frame", unitsCopy.every(this.isAllConfigured));
      if (categoryName === "Frame" && unitsCopy.every(this.isAllConfigured)) {
        this.state.isSubmitEnable = false;
      }
    } else {
      console.log("else frame");
      if (categoryName === "Frame") {
        this.state.isSubmitEnable = false;
      }
    }*/

    //Updation to the summary table, Would move it to separate function
   
    console.log("Units Copy ", unitsCopy);
    this.props.othersToStepper(
      unitsCopy,
      this.state.selectedItemLabor,
      this.state.inHomeData,
      this.state.addOnlaborCost,
      isSubmitEnable,
      this.state.itemMiscNotes
    );
  }

  compatibleWith = (categoryName, valueName, windowStyle) => {
    let catAttrMapCopy = {};
    let compatibleWith = {};
    let attributeData = {};
    let compatibleFlag = false;
    let categoryAllProductAttributeMap =
      this.state.categoryAllProductAttributeMap[windowStyle];
    for (const element of Object.values(categoryAllProductAttributeMap)) {
      if (element.CategoryName === categoryName) {
        const lengthOfAttributes = element.Attributes.length;
        for (let j = 0; j < lengthOfAttributes; j++) {
          if (valueName === element.Attributes[j]["AttributeName"]) {
            console.log(
              "Compatible with function for ",
              windowStyle,
              element.Attributes[j]["CompatibleWith"]
            );
            if (element.Attributes[j]["CompatibleWith"] !== null) {
              compatibleWith = element.Attributes[j]["CompatibleWith"];
              compatibleWith["Id"] = attributeData["price"] =
                element.Attributes[j]["Id"];
              compatibleWith["Price"] = element.Attributes[j]["Price"];
              compatibleWith["Attribute"] = element.Attributes[j]["Attribute"];
              compatibleWith["AttributeName"] =
                element.Attributes[j]["AttributeName"];
              compatibleFlag = true;
              console.log("Inside compatibleWith function ", compatibleWith);
              break;
            }
          }
        }
        catAttrMapCopy = compatibleWith;
        if (compatibleFlag) {
          break;
        }

        /* compatibleWith.map((element)=> {return (
                    catAttrMap[categoryAllProductAttributeMap[key][i]["category"]] = compatibleWith[element]
                  )
                  })*/
      }
    }
    return catAttrMapCopy;
  };
  attributesCompatibleWith = (categoryName, valueName, unit) => {
    let uiRange = unit.width + unit.height;
    let categoryAllProductAttributeMap = unit["compatibilityAttributeMap"];
    for (const element of Object.values(categoryAllProductAttributeMap)) {
      if (element.CategoryName === categoryName) {
        const lengthOfAttributes = element.Attributes.length;
        for (let j = 0; j < lengthOfAttributes; j++) {
          if (valueName === element.Attributes[j]["AttributeName"]) {
            console.log(
              "attributesCompatibleWith ",
              element.Attributes[j]["CompatibleWith"]
            );
            if (!element.Attributes[j].hasOwnProperty("Max UI Range")) {
              return element.Attributes[j]["AttributeName"];
            }
            if (
              element.Attributes[j].hasOwnProperty("Max UI Range") &&
              element.Attributes[j].hasOwnProperty("Min UI Range") &&
              uiRange < element.Attributes[j]["Max UI Range"] &&
              uiRange > element.Attributes[j]["Min UI Range"]
            ) {
              return element.Attributes[j]["AttributeName"];
            }
          }
        }
      }
    }
  };

  unionAttributeList = (categoryAttributeMapForAllWindows) => {
    let intersectedAttributeListForAll = [];
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(
      categoryAttributeMapForAllWindows
    )) {
      value.forEach((element) => {
        if (!intersectedAttributeListForAll.includes(element)) {
          intersectedAttributeListForAll.push(element);
        }
      });
    }
    return intersectedAttributeListForAll;
  };

  intersectedAttributeList = (array1, array2) => {
    let intersectedAttributeListForAll = [];
    if (array1 !== undefined && array2 !== undefined) {
      intersectedAttributeListForAll = array1.filter(function (n) {
        return array2.indexOf(n) !== -1;
      });
      return intersectedAttributeListForAll;
    } else if (array1 === undefined && array2 !== undefined) {
      return array2;
    } else if (array2 === undefined && array1 !== undefined) {
      return array1;
    }
  };

  uniq(array1) {
    return Array.from(new Set(array1));
  }

  // This function would return all the valid Attributes filtered with Dimension
  // TODO: I need to change the structure of Category attribute map coz now we have dimensions and Price.
  // Need to set unitNumber instead of style.

  attributesWithDimension = (categoryName, unit) => {
    //TODO: Need to check if below needs to be be uiRange of unit/Combo
    let uiRangeofProduct = unit.width + unit.height;

    let maxUiRange;
    let minUiRange;
    let price;
    let attribute;
    let id;
    let attributeList = [];
    let compatibleWithMapOfCategory = this.listAttributesOfCategory(
      categoryName,
      unit
    );
    //Set all the keys before starting, Merge below code
    for (let key in compatibleWithMapOfCategory) {
      let value = this.attributesCompatibleWith(
        categoryName,
        compatibleWithMapOfCategory[key],
        unit
      );
      if (!attributeList.includes(value)) {
        attributeList.push(value);
      }
    }

    for (let key in compatibleWithMapOfCategory) {
      if (compatibleWithMapOfCategory[key] === "Max UI Range") {
        maxUiRange = compatibleWithMapOfCategory[key];
      }
      if (key === "Min UI Range") {
        minUiRange = compatibleWithMapOfCategory[key];
      }
      if (key === "Price") {
        price = compatibleWithMapOfCategory[key];
      }
      if (key === "Id") {
        id = compatibleWithMapOfCategory[key];
      }
      if (key === "Attribute") {
        attribute = compatibleWithMapOfCategory[key];
      }
      if (key === "AttributeName") {
        console.log("")
      }
    }

    for (let key in compatibleWithMapOfCategory) {
      //for compatible with of Each Attribute in the list:
      if (
        key !== "Min UI Range" &&
        key !== "Max UI Range" &&
        key !== "Price" &&
        key !== "Id" &&
        key !== "Attribute" &&
        minUiRange !== undefined &&
        maxUiRange !== undefined &&
        id !== undefined &&
        attribute !== undefined &&
        price !== undefined &&
        maxUiRange >= uiRangeofProduct &&
        uiRangeofProduct >= minUiRange
      ) {
        if (attributeList.indexOf(key) === -1) {
          attributeList.push(key);
        }
      }
    }
    console.log("attributesWithDimension ", attributeList);
    //TODO debug why undefined is coming
    attributeList = attributeList.filter(function (element) {
      return element !== undefined;
    });
    return attributeList;
  };

  barsWithDimension = (barsInfo, width, height) => {
    for (let index in barsInfo) {
      let minWidth;
      let maxWidth;
      let maxHeight;
      let minHeight;
      // eslint-disable-next-line
      for (const [i, element] of Object.entries(barsInfo[index])) {
        for (const [key, value] of Object.entries(element)) {
          if (key === "maxWidth") {
            maxWidth = value;
          }
          if (key === "minWidth") {
            minWidth = value;
          }
          if (key === "maxHeight") {
            maxHeight = value;
          }
          if (key === "minHeight") {
            minHeight = value;
          }
        }
        if (
          height <= maxHeight &&
          height >= minHeight &&
          width <= maxWidth &&
          width >= minWidth
        ) {
          console.log("returing ", barsInfo[index]);
          return barsInfo[index];
        }
      }
    }
  };

  categoryWithDimension = (categoryName, categoryAttributeMap, unit) => {
    let uiRangeofProduct = unit["width"] + unit["height"];
    //this.props.uiRangeofProduct;

    let newCategoryAttributeMap = {};
    //const newCategoryAttributeMapWithPricesAndDimensions = new Map();
    let newCategoryAttributeMapWithPricesAndDimensions = {};
    let newCategoryAttributeMapWithPricesAndDimensionsList = [];
    let mainNewCategoryAttributeMapWithPricesAndDimensions = {};
    //TODO: We not only need the compatibleWith but also the price, Id etc

    newCategoryAttributeMap[categoryName] = [];

    for (let index in categoryAttributeMap) {
      let compatibleWithMapOfCategory = this.compatibleWith(
        categoryName,
        categoryAttributeMap[index],
        unit["windowStyle"]
      );
      let maxUiRange;
      let minUiRange;
      let price;
      let attribute;
      let id;
      let attributeName;
      //Set all the keys before starting, Merge below code
      for (let key in compatibleWithMapOfCategory) {
        if (key === "Max UI Range") {
          maxUiRange = compatibleWithMapOfCategory[key];
        }
        if (key === "Min UI Range") {
          minUiRange = compatibleWithMapOfCategory[key];
        }
        if (key === "Price") {
          price = compatibleWithMapOfCategory[key];
        }
        if (key === "Id") {
          id = compatibleWithMapOfCategory[key];
        }
        if (key === "Attribute") {
          attribute = compatibleWithMapOfCategory[key];
        }
        if (key === "AttributeName") {
          attributeName = compatibleWithMapOfCategory[key];
        }
      }

      for (let key in compatibleWithMapOfCategory) {
        //for compatible with of Each Attribute in the list:
        if (
          key !== "Min UI Range" &&
          key !== "Max UI Range" &&
          key !== "Price" &&
          key !== "Id" &&
          key !== "Attribute" &&
          minUiRange !== undefined &&
          maxUiRange !== undefined &&
          id !== undefined &&
          attribute !== undefined &&
          price !== undefined &&
          maxUiRange >= uiRangeofProduct &&
          uiRangeofProduct >= minUiRange
        ) {
          if (
            newCategoryAttributeMap[categoryName].indexOf(
              categoryAttributeMap[index]
            ) === -1
          ) {
            newCategoryAttributeMap[categoryName].push(
              categoryAttributeMap[index]
            );
            newCategoryAttributeMapWithPricesAndDimensions = {
              "Min UI Range": minUiRange,
              "Max UI Range": maxUiRange,
              Id: id,
              Price: price,
              Attribute: attribute,
              AttributeName: attributeName,
              WindowStyle: unit["windowStyle"],
              unitNumber: unit["unitNumber"],
            };

            newCategoryAttributeMap[key] = compatibleWithMapOfCategory[key];
            console.log("newCategoryAttributeMap key ", key);
            console.log("newCategoryAttributeMap ", newCategoryAttributeMap);
            newCategoryAttributeMapWithPricesAndDimensionsList.push(
              newCategoryAttributeMapWithPricesAndDimensions
            );
          }
        }
      }
      mainNewCategoryAttributeMapWithPricesAndDimensions[categoryName] =
        newCategoryAttributeMapWithPricesAndDimensionsList;
    }
    console.log(
      "Exiting CategoryWithDimension ",
      mainNewCategoryAttributeMapWithPricesAndDimensions
    );
    return [
      newCategoryAttributeMap,
      mainNewCategoryAttributeMapWithPricesAndDimensions,
    ];
  };

  valueObjectMatchingDimension = (colorMap, uiRangeofProduct, value) => {
    let maxUiRange;
    let minUiRange;
    let price;
    let attribute;
    let id;

    for (let data in colorMap) {
      for (const [index, element] of Object.entries(colorMap[data])) {
        if (index === "Max UI Range") {
          maxUiRange = element;
        }
        if (index === "Min UI Range") {
          minUiRange = element;
        }
        if (index === "Price") {
          price = element;
        }
        if (index === "Id") {
          id = element;
        }
        if (index === "Attribute") {
          attribute = element;
        }
        if (index === "AttributeName") {
          console.log("")
        }
      }

      if (
        minUiRange !== undefined &&
        maxUiRange !== undefined &&
        id !== undefined &&
        attribute !== undefined &&
        attribute === value &&
        price !== undefined &&
        maxUiRange >= uiRangeofProduct &&
        uiRangeofProduct >= minUiRange
      ) {
        return colorMap[data];
      }
    }
  };
  removeColonial = (unit) => {};

  isAllConfigured(el, index, arr) {
    // Return true for the first element
    if (index === 0) {
      return true;
    } else {
      // Compare the value of the previous element
      return el.configureAttributes === arr[index - 1].configureAttributes;
    }
  }

  onCommonCategoryChanged = (labeledValue, options) => {
    this.setState({isInterior:""})
    const [categoryName, valueName] = this.splitCategoryAndValue(
      labeledValue.value
    );
    console.log("Entered onCommonCategoryChanged", categoryName, valueName);

    //let categoryAttributeMap = this.state.categoryAttributeMap;
    let categoryAttributeMap = {};
    let units = this.state.units;
    console.log("In onCommonCategoryChanged Units ", units);
    let commonCategoriesAttributeMapCopy = this.state.commonCategoriesMap;
    console.log("categoryName", categoryName);
    if (categoryName === "Interior Color") {
      this.setState({
        existingInteriorColor: valueName.split("Color")[0].trim(),
      });
      if (
        this.state.existingInteriorColor &&
        this.state.currentInteriorSelected &&
        this.state.currentInteriorSelected !== this.state.existingInteriorColor
      ) {
        units.forEach((unit) => {
          unit.baseCategoryAttributeMap = {};
          if (unit.hasOwnProperty("attributes")) {
            delete unit.attributes;
          }
          if (unit.hasOwnProperty("valueSelected")) {
            unit.valueSelected = {};
          }
          /*if (unit.hasOwnProperty("baseCategoryAttributeMap")) {
            unit.baseCategoryAttributeMap = {};
          }*/
          unit.configurationString = undefined;
        });
        //this.setState({ existingInteriorColor: valueName});

      }
      this.setState({ units, currentExteriorSelected: "" });
      const oldCommonCategoriesAttr = commonCategoriesAttributeMapCopy;
      let categoryAttributeMapForAllWindows = {};
      let intersectedAttributeMapWithAllricesAndDimensions = [];
      let intersectedExteriorColors = [];
      let newCategoryAttributeMap = {};
      for (let unit in units) {
        let interiorColorPricingMap =
          units[unit]["pricingMap"]["Interior Color"];
        console.log("interiorColorPricingMap ", interiorColorPricingMap);
        interiorColorPricingMap.forEach((element) => {
          if (
            element.attribute === valueName ||
            element.attributeShort === valueName
          ) {
            units[unit]["attributes"] = [
              {
                category: categoryName,
                value: valueName,
                price: element.Price,
                id: element.Id,
              },
            ];
          }
        });
        categoryAttributeMap = {
          ...this.updateCompatibilityAttributeMapForCommonCategories(
            categoryName,
            valueName.split("Color")[0].trim(),
            units[unit],
            "windowMinMax",
            "uiMinMax"
          ),
        };
        units[unit]["categoryAttributeMap"] = categoryAttributeMap;
        let catWithDimension = this.categoryWithDimension(
          "Exterior Color",
          categoryAttributeMap["Exterior Color"],
          units[unit]
        );

        newCategoryAttributeMap = catWithDimension[0];
        // RXM8QHE:: Need to check
        if (!this.state.isEdit) {
        }
        console.log("units in common", units);
        for (const [key] of Object.entries(categoryAttributeMap)) {
          let attributeList = [];
          if (this.state.categoryWithDimensions.includes(key)) {
            attributeList = this.attributesWithDimension(key, units[unit]);
            newCategoryAttributeMap[key] = attributeList;
            console.log("AttributeListWithDimension ", attributeList);
          }
          units[unit]["baseCategoryAttributeMap"][key] =
            this.intersectedAttributeList(
              newCategoryAttributeMap[key],
              categoryAttributeMap[key]
            );
        }

        //intersectedAttributeMapWithPricesAndDimensions = catWithDimension[1];

        //TODO: Write Pricing map to all of the units instead of just one
        categoryAttributeMapForAllWindows[units[unit].unitNumber] =
          newCategoryAttributeMap["Exterior Color"];
        units[unit]["pricingMap"]["Exterior Color"] =
          catWithDimension[1]["Exterior Color"];
        console.log("Existing Pricing Map ", units[unit]["pricingMap"]);
        console.log("New Pricing Map ", catWithDimension[1]);
        intersectedAttributeMapWithAllricesAndDimensions.push(
          catWithDimension[1]
        );
        units[unit]["intersectedExteriorColorObjects"] = catWithDimension[1];
      }
      console.log(
        "categoryAttributeMapForAllWindows ",
        categoryAttributeMapForAllWindows
      );
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(
        intersectedAttributeMapWithAllricesAndDimensions
      )) {
        // eslint-disable-next-line
        for (const [index, element] of Object.entries(value)) {
          console.log("index and element ", element);
          // eslint-disable-next-line
          element.filter((ele) => {
            console.log("ele.Attribute ", ele.Attribute);
            if (!intersectedExteriorColors.includes(ele.Attribute)) {
              intersectedExteriorColors.push(ele.Attribute);
            }
          });
        }
      }
      // let intersectedExteriorColors = this.intersectedAttributeList(categoryAttributeMapForAllWindows)

      console.log(
        "Intersected Category list ",
        intersectedAttributeMapWithAllricesAndDimensions
      );
      oldCommonCategoriesAttr["Exterior Color"] =
        newCategoryAttributeMap["Exterior Color"];
      this.setState({
        currentExteriorSelected: "",
        currentInteriorSelected: valueName.split("Color")[0].trim(),
        showConfigurationSection: false,
        configureEnable: false,
        units: units,
        categoryAttributeMap: newCategoryAttributeMap,
        intersectedExteriorColorObjects:
          intersectedAttributeMapWithAllricesAndDimensions,
        commonCategoriesAttributeMap: oldCommonCategoriesAttr,
      });
    }

    if (categoryName === "Exterior Color") {
      this.setState({
        currentExteriorSelected: valueName,  configureEnable: false,
      });
        //retain baseCategoryAttributeMap if Interior Color didnt change
      if (
        this.state.isEdit &&
        this.state.currentInteriorSelected &&
        this.state.existingInteriorColor &&
        this.state.currentInteriorSelected !== this.state.existingInteriorColor
      ) {
        units.forEach((unit) => {
          unit.baseCategoryAttributeMap = {};
        });
      }
      if (
        this.state.existingExteriorColor &&
        valueName &&
        valueName !== this.state.existingExteriorColor
      ) {
      
        // We need to reset

        units.forEach((unit) => {
          //unit.baseCategoryAttributeMap = {};
          if (unit.hasOwnProperty("attributes")) {
            unit.attributes = [];
          }
          if (unit.hasOwnProperty("valueSelected")) {
            unit.valueSelected = {};
          }
          /*if (unit.hasOwnProperty("baseCategoryAttributeMap")) {
            unit.baseCategoryAttributeMap = {};
          }*/
          unit.configurationString = undefined;
        });
        this.setState({ units});

      }

      

      console.log("Exterior ", commonCategoriesAttributeMapCopy);
      for (let unit in units) {
        let uiRangeofProduct =
          parseFloat(units[unit].width) + parseFloat(units[unit].height);
        // Remove the Colonial option from drop down if the dimension is not valid

        let colorMap =
          units[unit]["intersectedExteriorColorObjects"][categoryName];
        console.log(
          "colormap ",
          units[unit]["intersectedExteriorColorObjects"]
        );
        /*const valueObj = colorMap.find(
          (value) => value.AttributeName === valueName
        );
        */

        const valueObj = this.valueObjectMatchingDimension(
          colorMap,
          uiRangeofProduct,
          valueName.split("Color")[0].trim()
        );

        categoryAttributeMap = {
          ...this.updateCompatibilityAttributeMapForCommonCategories(
            categoryName,
            valueObj.AttributeName,
            units[unit],
            "windowMinMax",
            "uiMinMax"
          ),
        };
        if (!this.state.isEdit) {
          if (units[unit].hasOwnProperty("valueSelected")) {
            units[unit].valueSelected = {};
          }
          units[unit]["categoryAttributeMap"] = { ...categoryAttributeMap };
          this.setState({ existingExteriorColor: valueName });
        }
        units[unit]["dataSource"] = [];
        if (this.props.isEdit) {
          units[unit]["categoryAttributeMap"] = { ...categoryAttributeMap };
        }
        console.log(
          "CategoryAttributeMap in onCommonColor ",
          units[unit]["categoryAttributeMap"]
        );
        let exteriorColorPricingMap =
          units[unit]["pricingMap"]["Exterior Color"];
        console.log("exteriorColorPricingMap ", exteriorColorPricingMap);
        exteriorColorPricingMap.forEach((element) => {
          if (element.Attribute === valueObj.Attribute) {
              units[unit]["attributes"].push({
                category: categoryName,
                value: valueObj.AttributeName,
                price: element.Price,
                retailPrice: Math.round ((element.Price *
                (1 - parseFloat(units[unit]["pricingTaxImu"]["discount"] / 100)) *
                (1 + parseFloat(units[unit]["pricingTaxImu"]["tax"] / 100))) /
              (1 - parseFloat(units[unit]["pricingTaxImu"]["imu"] / 100)) * 1000)/ 1000,
                id: element.Id,
              });

            if(this.state.existingExteriorColor &&
                valueName &&
                valueName !== this.state.existingExteriorColor){
              let interiorColorPricingMap =
                  units[unit]["pricingMap"]["Interior Color"];
              interiorColorPricingMap.forEach((element) => {
                if (element.attributeShort === this.state.currentInteriorSelected) {
                  units[unit]["attributes"].push({
                    category: "Interior Color",
                    value: this.state.currentInteriorSelected,
                    price: element.Price,
                    id: element.Id,
                  });
                }
              });
            }
            if (!this.state.isEdit || this.props.resetOptions) {
              units[unit]["valueSelected"] = {};
              units[unit]["valueSelected"][categoryName] =
                valueObj.AttributeName;
            }
          }
        });

        /*         for (const [key, value] of Object.entries(categoryAttributeMap)) {
          units[unit]["baseCategoryAttributeMap"][key] =
            this.intersectedAttributeList(
              units[unit]["baseCategoryAttributeMap"][key],
              categoryAttributeMap[key]
            );
        } */

        let pricingMap = units[unit]["pricingMap"]["Dimension"];

        console.log("Pricing Map in the Color ", pricingMap);
        console.log("UI range of the product ", uiRangeofProduct);
        let catAttrPrices = pricingMap;
        let row = {};
        for (row in catAttrPrices) {
          console.log(
            "Base Price of the product",
            this.state.currentInteriorSelected
              ? this.state.currentInteriorSelected
              : this.state.existingInteriorColor
          );
          if (
            catAttrPrices[row]["interiorColor"] ===
            (this.state.currentInteriorSelected
              ? this.state.currentInteriorSelected
              : this.state.existingInteriorColor) +
              " Color"
          ) {
            console.log(
              "basePrice of the product",
              catAttrPrices[row]["basePrice"]
            );
            if (
              parseFloat(catAttrPrices[row]["maxUiRange"]) >=
                uiRangeofProduct &&
              uiRangeofProduct >= parseFloat(catAttrPrices[row]["minUiRange"])
            ) {

              console.log(
                "Range Matched of the product",
                catAttrPrices[row]["basePrice"],
                "Additional Glass Type",
                catAttrPrices[row]["Additional Glass Type"],
                catAttrPrices[row]["Grid"]
              );
              /*Old Price calculation
                           units[unit]["basePriceAfterTax"] =
                Math.round(
                  catAttrPrices[row]["basePrice"] *
                    (1 -
                      parseFloat(
                        units[unit]["pricingTaxImu"]["discount"] / 100
                      )) *
                    (1 +
                      parseFloat(units[unit]["pricingTaxImu"]["imu"] / 100)) *
                    (1 +
                      parseFloat(units[unit]["pricingTaxImu"]["tax"] / 100)) *
                    100
                ) / 100; */
              //New formula
              units[unit]["basePrice"] =
                Math.round(catAttrPrices[row]["basePrice"] * 100) / 100;

              units[unit]["basePriceAfterTax"] =
                Math.round(
                  ((catAttrPrices[row]["basePrice"] *
                    (1 -
                      parseFloat(
                        units[unit]["pricingTaxImu"]["discount"] / 100
                      )) *
                    (1 +
                      parseFloat(units[unit]["pricingTaxImu"]["tax"] / 100))) /
                    (1 -
                      parseFloat(units[unit]["pricingTaxImu"]["imu"] / 100)) ) *
                    100
                ) / 100;
              console.log(
                "base price after tax ",
                units[unit]["basePriceAfterTax"]
              );
              break;
            }
          }
        }
      }
      this.setState({
        showConfigurationSection: false,
        currentExteriorSelected: valueName,
        configureEnable: true,
        units,
        //categories: Object.keys(newCategoryAttributeMap),
        //categoryAttributeMap,
      });
    }
    console.log("Interior/Exterior Color updated the units ", units);
  };

  componentDidMount() {
    //YXC8HEN calling Color
    console.log("Inside others componentDidMount");
    if (this.props.existingExteriorColor !== undefined) {
      console.log("Inside if");
      if (this.state.isEdit) {
        let oldBabData = this.state.oldBabData;
        let oldInteriorColorValue = oldBabData[0].attributes.find(
          (o) => o.attribute === this.state.existingInteriorColor
        );
        let oldExteriorColorValue = oldBabData[0].attributes.find(
          (o) => o.attribute === this.state.existingExteriorColor
        );
        console.log(
          oldInteriorColorValue,
          oldInteriorColorValue.category,
          oldExteriorColorValue,
          oldExteriorColorValue.category
        );
        let labeledValue = {};
        labeledValue["value"] =
          oldInteriorColorValue.category +
          "---" +
          this.state.existingInteriorColor;
        this.onCommonCategoryChanged(labeledValue);
        labeledValue["value"] =
          oldExteriorColorValue.category +
          "---" +
          this.state.existingExteriorColor;
        this.onCommonCategoryChanged(labeledValue);

        /*if (this.props.isEdit) {
          for (let unit in unitsCopy) {
            this.configureUnit(unitsCopy[unit].unitNumber);
            for (const [key, value] of Object.entries(
              unitsCopy[unit].valueSelected
            )) {
              if (Array.isArray(value)) {
                let arrayOption = [];
                value.forEach((element) => {
                  arrayOption.push(key + "---" + element);
                  //this.onAttributeChanged(key + "---" + element);
                });
                this.onAttributeChangedInChangeOrder(
                  arrayOption, "",
                  unitsCopy[unit].unitNumber
                );
              } else {
                console.log("Calling onAttributeChanged", key, value);
                this.onAttributeChangedInChangeOrder(
                  key + "---" + value, "",
                  unitsCopy[unit].unitNumber
                );
              }
            }
          }
       }*/
      }
    } else {
      console.log("Do nothing");
    }
  }
  componentDidUpdate() {
    console.log("Entering componentDidUpdate");
    /*
        this.setState({existingInteriorColor:"White Interior Color"})
        this.setState({existingExteriorColor:"Bronze Exterior Color 0-140 UI"})
        let labeledValue ={}
        labeledValue["value"] = "Interior Color---White Interior Color";
        this.onCommonCategoryChanged(labeledValue)
        labeledValue["value"] = "Exterior Color---Bronze Exterior Color 0-140 UI";
        this.onCommonCategoryChanged(labeledValue)
        // YXC8HEN Need to remove below hardcoding 
       */
  }

  render() {
    const units = this.state.units;
    // eslint-disable-next-line
    const { dataSource, columns } = this.state;
    console.log(
      "In render commonCategoriesMap",
      this.state.commonCategoriesMap
    );
    //const commonCategories = ['Interior Color', 'Exterior Color'];
    return (
      <div>
        <Form.Item label="">
          <div style={{ fontWeight: 700, fontSize: "1.6em", color: "#f96302" }}>
            ADD OTHER
          </div>
          <div style={{ fontWeight: 700, fontSize: "2.6em", color: "#f96302" }}>
            Options
          </div>
          <Divider />

          {this.state.commonCategories?.map(
            function (category) {
              if (
                category === "Interior Color" ||
                category === "Exterior Color"
              ) {
                return (
                  <Row justify="center">
                    <h3 style={{ marginRight: "8px" }}>{category}</h3>
                    <Space>
                      <Select
                        name={category}
                        value={
                          category === "Interior Color"
                            ? this.state.currentInteriorSelected
                            : this.state.currentExteriorSelected
                        }
                        labelInValue
                        style={{ width: "Auto", minWidth: 400 }}
                        status={this.state.isInterior}
                        placeholder=""
                        optionFilterProp="children"
                        onSelect={this.onCommonCategoryChanged}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.commonCategoriesMap[category]?.map(
                          (element) => {
                            return (
                              <Option
                                key={element + `${new Date().getTime()}`}
                                value={category + "---" + element}
                              >
                                {element}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Space>
                    <Divider />
                  </Row>
                );
              }
            }.bind(this)
          )}

          <Space direction="Vertical">
            {this.props.uiMinMax &&
            this.props.uiMinMax.productStyle &&
            this.props.uiMinMax.productStyle.includes("Transom") ? (
              <Col gutter={24}>
                {this.state.units.length > 0 &&
                  this.state.units.map((index) => {
                    return (
                      <Row
                        span={
                          this.state.units.length > 2
                            ? 8
                            : this.state.units.length === 1
                            ? 24
                            : 12
                        }
                      >
                        <Card
                          hoverable={true}
                          key={this.state.numberOfUnit[index]}
                          class="card"
                          title={"Panel #" + index.unitNumber}
                        >
                          <h2
                            style={{
                              fontWeight: 700,
                              fontSize: "1.6em",
                              color: "#f96302",
                              width: 180,
                            }}
                          >
                            {index.windowStyle}
                          </h2>
                          {/* {index.windowStyle == "Double Hung" && ( */}
                          <div className="container" style={{ height: 200 }}>
                            <img
                              class="img"
                              alt="Window"
                              src={imageMap.get(index.windowStyle)}
                            />{" "}
                          </div>
                          {/* )}
                        {index.windowStyle == "Picture Window" && (
                          <div className="container">
                            <img class="img" alt="Window" src={imageMap.get(index.windowStyle)} />{" "}
                          </div>
                        )}
                        {index.windowStyle == "Single Hung" && (
                          <div className="container">
                            <img class="img" alt="Window" src={imageMap.get(index.windowStyle)} />{" "}
                          </div>
                        )}*/}

                          <h2
                            style={{
                              fontWeight: 700,
                              fontSize: "1em",
                              color: "Gray",
                            }}
                          >
                            <div>
                              {index.width} in. X {index.height} in.
                            </div>{" "}
                          </h2>
                          <br></br>
                          {index.configurationString === undefined ? (
                            <Button
                              key={this.state.units[index]}
                              type="primary"
                              size="small"
                              disabled={!this.state.configureEnable}
                              onClick={(e) =>
                                this.configureUnit(index.unitNumber)
                              }
                            >
                              Configure
                            </Button>
                          ) : (
                            <Button
                              key={this.state.units[index]}
                              type="primary"
                              size="small"
                              disabled={!this.state.configureEnable}
                              onClick={(e) => this.editUnit(index.unitNumber)}
                            >
                              Configure
                            </Button>
                          )}
                        </Card>{" "}
                      </Row>
                    );
                  })}
              </Col>
            ) : (
              <Row gutter={24}>
                {this.state.units.length > 0 &&
                  this.state.units.map((index) => {
                    return (
                      <Col
                        span={
                          this.state.units.length > 2
                            ? 8
                            : this.state.units.length === 1
                            ? 24
                            : 12
                        }
                      >
                        <Card
                          hoverable={true}
                          key={this.state.numberOfUnit[index]}
                          class="card"
                          title={"Panel #" + index.unitNumber}
                        >
                          <h2
                            style={{
                              fontWeight: 700,
                              fontSize: "1.6em",
                              color: "#f96302",
                              width: 180,
                            }}
                          >
                            {index.windowStyle}
                          </h2>
                          {/* {index.windowStyle == "Double Hung" && ( */}
                          <div className="container" style={{ height: 200 }}>
                            <img
                              class="img"
                              alt="Window"
                              src={imageMap.get(index.windowStyle)}
                            />{" "}
                          </div>
                          {/*    )}
                        {index.windowStyle == "Picture Window" && (
                          <div className="container">
                            <img class="img" alt="Window" src={imageMap.get(index.windowStyle)} />{" "}
                          </div>
                        )}  */}

                          <h2
                            style={{
                              fontWeight: 700,
                              fontSize: "1em",
                              color: "Gray",
                            }}
                          >
                            <div>
                              {index.width} in. X {index.height} in.
                            </div>{" "}
                          </h2>
                          <br></br>
                          {index.configurationString === undefined ? (
                            <Button
                              key={this.state.units[index]}
                              type="primary"
                              size="small"
                              disabled={!this.state.configureEnable}
                              onClick={(e) =>
                                this.configureUnit(index.unitNumber)
                              }
                            >
                              Configure
                            </Button>
                          ) : (
                            <Button
                              key={this.state.units[index]}
                              type="primary"
                              size="small"
                              disabled={!this.state.configureEnable}
                              onClick={(e) => this.editUnit(index.unitNumber)}
                            >
                              Configure
                            </Button>
                          )}
                        </Card>{" "}
                      </Col>
                    );
                  })}
              </Row>
            )}
          </Space>
          <Divider />
          <Space>
            {this.state.showConfigurationSection ? (
              <div class="cardWidth">
                <div class="config-left">
                  <h2
                    style={{
                      fontWeight: 700,
                      fontSize: "1.6em",
                      color: "#f96302",
                    }}
                  >
                    {" "}
                    Panel #{this.state.configuringUnitNumber} Config{" "}
                  </h2>
                  <Divider />
                  <div class="cardWidth">
                    <Card>
                      {this.state.categories?.map(
                        function (category) {
                          if (category === "Bars") {
                            return (
                              <div style={{ marginBottom: 20 }}>
                                {/*                                 <h2
                                  style={{ fontWeight: 600, fontSize: "1.2em" }}
                                >
                                  {category}
                                </h2>
                                <Divider />
                                <Space>
                                  <Select
                                    value={this.state.valueSelected[category]}
                                    //name={category}
                                    mode="single"
                                    style={{ width: 200 }}
                                    //placeholder=""
                                    optionFilterProp="children"
                                    onDeselect={this.onDeselect.bind(this)}
                                    onChange={this.onAttributeChanged.bind(
                                      this
                                    )}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.categoryAttributeMap[
                                      category
                                    ]?.map((index) => {
                                      return (
                                        <Option
                                          key={index}
                                          value={category + "---" + index}
                                        >
                                          {index}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Space> */}
                                <h2
                                  style={{ fontWeight: 600, fontSize: "1.2em" }}
                                >
                                  {category}
                                </h2>

                                <Space>
                                  <Row justify="space-between">
                                 
                                    <h4
                                      style={{
                                        marginLeft: "48px",
                                        marginRight: "8px",
                                      }}
                                    >
                                      Vertical Bars
                                    </h4>
                                    <InputNumber
                                      min={this.state.verticalBarsMin}
                                      max={this.state.verticalBarsMax}
                                      defaultValue={
                                        this.state.verticalBarsDefault
                                      }
                                      value={this.state.verticalBarsValue}
                                      onChange={this.onChangeVerticalBars}
                                    />
                                       <h4
                                      style={{
                                        marginLeft: "32px",
                                        marginRight: "8px",
                                      }}
                                    >
                                      Horizontal bars
                                    </h4>
                                    <InputNumber
                                      min={this.state.horizontalBarsMin}
                                      max={this.state.horizontalBarsMax}
                                      defaultValue={
                                        this.state.horizontalBarsDefault
                                      }
                                      value={this.state.horizontalBarsValue}
                                      onChange={this.onChangeHorizontalBars}
                                    />

                                  </Row>
                                </Space>
                                <Divider />
                              </div>
                            );
                          }
                          if (
                            category !== "Size Range" &&
                            category !== "Job Labor" &&
                            category !== "Labor"
                          ) {
                            return (
                              <div style={{ marginBottom: 20 }}>
                                <h2
                                  style={{ fontWeight: 600, fontSize: "1.2em" }}
                                >
                                  {category}
                                </h2>
                                <Space>
                                  <Select
                                    value={this.state.valueSelected[category]}
                                    //name={category}
                                    mode={
                                      category === "Performance Option" ||
                                      category === "Frame" ||
                                      category === "Additional Glass Type"
                                        ? "multiple"
                                        : "single"
                                    }
                                    style={{ width: "Auto", minWidth: 550 }}
                                    //placeholder=""
                                    optionFilterProp="children"
                                    onDeselect={this.onDeselect.bind(
                                      this,
                                      category
                                    )}
                                    onChange={this.onAttributeChanged.bind(
                                      this
                                    )}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.categoryAttributeMap[
                                      category
                                    ]?.map((index) => {
                                      return (
                                        <Option
                                          key={index}
                                          value={category + "---" + index}
                                        >
                                          {index}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Space>
                                <Divider />
                              </div>
                            );
                          }
                        }.bind(this)
                      )}

                      <Card>
                        <h3 style={{ fontWeight: 500 }}>
                          Panel #{this.state.configuringUnitNumber}-{" "}
                          {
                            units[this.state.configuringUnitNumber - 1][
                              "windowStyle"
                            ]
                          }{" "}
                        </h3>

                        <Table
                          dataSource={this.state.dataSource}
                          columns={columns}
                          pagination={false}
                        />
                      </Card>
                    </Card>
                    <Divider />
                    <Space>
                      <Row justify="space-between">
                        <h3 style={{ marginRight: "8px" }}>Add-on Labor </h3>
                        <TreeSelect
                          treeDefaultExpandAll
                          style={{ width: "Auto", minWidth: 450 }}
                          treeData={this.props.itemLaborsData}
                          value={this.state.existingAddOnLabors}
                          onChange={this.onLaborChange}
                          onDeselect={this.onLaborDeselect.bind(this)}
                          treeCheckable={true}
                          showCheckedStrategy={SHOW_PARENT}
                          placeholder="Select Labor"
                        />
                        {this.state.showLabor && this.state.miscItemLabor && this.state.miscItemLabor.length>0 ? (
                          <List
                            dataSource={this.state.miscItemLabor}
                            renderItem={(labor) => (
                              <Row justify="end">
                                <h3
                                  style={{
                                    fontSize: "14.5px",
                                    fontWeight: 20,
                                    marginRight: "8px",
                                    marginLeft: "4px",
                                  }}
                                >
                                  {labor.desc}
                                </h3>
                                <Select
                                  style={{ width: 50 }}
                                  optionFilterProp="children"
                                  onChange={this.onQuantityChange.bind(this)}
                                  onDeselect={this.onQuantityDeselect.bind(
                                    this
                                  )}
                                  defaultValue={labor.quantity}
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.quantity.map((index) => {
                                    return (
                                      <Option
                                        key={index}
                                        value={labor.desc + "---" + index}
                                      >
                                        {index}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Row>
                            )}
                          />
                        ) : (
                          <br />
                        )}
                      </Row>
                    </Space>
                    {this.state.showLabor && this.state.miscItemLabor && this.state.miscItemLabor.length>0 ? (
                      <Row>
                        <h4 style={{ marginRight: "8px", marginLeft: "4px" }}>
                          Misc. Labor Notes{" "}
                        </h4>

                        <TextArea
                          id="miscText"
                          onChange={this.onMiscNotesUpdate.bind(this)}
                          value={this.state.itemMiscNotes}
                          allowClear={true}
                          rows={4}
                        >
                          {" "}
                        </TextArea>
                      </Row>
                    ) : (
                      <br />
                    )}

                    <Divider />
                  </div>
                  {this.props.numberOfUnit.length > 1 && (
                    <Button
                      type="primary"
                      size="large"
                      onClick={this.onSaveHandler.bind(this)}
                    >
                      Copy
                    </Button>
                  )}
                  <Modal
                    title="Grid Selection"
                    visible={this.state.isGridModalVisible}
                    onOk={this.handleGridOk}
                    onCancel={this.handleGridCancel}
                  >
                    <p>
                      Please change the window's dimension or select
                      Praire/Perimeter Grid
                    </p>
                    <br />
                  </Modal>
                </div>
                <Button visible="false" />
                <Modal
                  title="Image"
                  visible={this.state.imageModalVisible}
                  onOk={this.handleOk}
                  style={{ top: 100 }}
                  //confirmLoading={confirmLoading}
                  onCancel={this.handleCancel}
                >
                  <div className="container" style={{ height: 200 }}>
                    <img
                      class="img"
                      alt="Window"
                      src={imageMap.get("Double Hung")}
                    />{" "}
                  </div>
                </Modal>
                <div
                  class="config-right"
                  style={{ display: this.state.imageDisplay }}
                >
                  <Affix>
                    <h2
                      style={{
                        fontWeight: 700,
                        fontSize: "1.6em",
                        color: "#f96302",
                      }}
                    >
                      Panel #{this.state.configuringUnitNumber}{" "}
                    </h2>
                    <Divider />
                    <Card
                      hoverable={true}
                      key={this.state.configuringUnitNumber}
                      class="card"
                    >
                      <h2
                        style={{
                          fontWeight: 700,
                          fontSize: "1.6em",
                          color: "#f96302",
                          width: 180,
                        }}
                      >""</h2>

                      <div className="container">
                        <img
                          class="img"
                          alt="Window"
                          src={this.state.currentImage}
                        />{" "}
                      </div>
                    </Card>
                  </Affix>
                  <Divider />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Space>
          <Row>
            <Col span={24}></Col>
          </Row>
        </Form.Item>
      </div>
    );
  }
}
