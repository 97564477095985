import React, {Component} from "react";


export default class VosTemplate extends Component{
    constructor(props) {
        super(props);
        console.log("VosTemplate ", this.props.vosDetail);
        this.state = {
            vosDetail: this.props.vosDetail,
        }
    }


    render()
    {

        const currDate = new Date().toLocaleDateString();
        const currTime = new Date().toLocaleTimeString();
        return(
        <>

            <div style={{width: 1080}}>
               <div  style={{marginTop: 20,width: "100%"}}>
                    <table style={{width: "100%"}}>
                        <tr>
                            <td style={{fontSize:13,textAlign:"left",width:"23%"}}>

                            </td>

                            <td style={{fontSize:12,textAlign:"right",width:"23%"}}>
                                {currDate}{' '}{currTime}
                            </td>
                        </tr>
                    </table>
                </div>

                <div style={{marginTop:2,width:"100%",fontSize:11}}>

                    <table style={{width:"100%"}}>
                        <tr>
                            <td colSpan="4" style={{fontWeight: "bold",fontSize:12}}>The Home Depot At Home Services
                            </td>
                        </tr>
                        <tr>
                            <td style={{width:"25"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Job No:</span> &nbsp;&nbsp;&nbsp;
                                {this.state.vosDetail.job_No}</td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Homeowner:</span> &nbsp;&nbsp;{this.state.vosDetail.home_owner}</td>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Sale Date:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {this.state.vosDetail.saleDate}</td>
                            <td style={{width:"23%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Product: </span>&nbsp;&nbsp;&nbsp;
                                {this.state.vosDetail.product}</td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Vendor: </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {this.state.vosDetail.vendor}</td>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Consultant 1:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {this.state.vosDetail.consultant1}</td>
                            <td style={{width:"23%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span></td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Branch:</span> &nbsp;&nbsp;&nbsp;{this.state.vosDetail.branch}</td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Ship To:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {this.state.vosDetail.shipTo}</td>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span></td>
                            <td style={{width:"23%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> </td>
                        </tr>
                    </table>

                    <div style={{marginTop:5,marginBottom:5,width:"101%",height:1,backgroundColor:"black",marginLeft:-3}}></div>
                    <div style={{marginTop:5,marginBottom:5,width:"101%",height:1,backgroundColor:"black",marginLeft:-3}}></div>

                </div>

                <div style={{marginTop:2,width:"100%",fontSize:11}}>

                    <table style={{width:"100%"}}>
                        <tr>
                            <td style={{width:"15%"}}><span style={{fontWeight: "bold",fontSize:12}}>Line/Rm/Flr</span></td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Qty</span></td>

                        </tr>
                    </table>

                    <div style={{marginTop:5,marginBottom:5,width:"101%",height:1,backgroundColor:"black",marginLeft:-3}}></div>
                    <div style={{marginTop:5,marginBottom:5,width:"101%",height:1,backgroundColor:"black",marginLeft:-3}}></div>
                    {this.state.vosDetail.lineItems.map((line) => (
                    <div style={{marginLeft:8,width:"100%"}}>
                    <table style={{width:"100%"}}>

                        <tr>
                            <td style={{width:"15%"}}><span style={{fontWeight: "bold",fontSize:12}}>{line.line}{" "}{line.room}{"  "}{line.floor}</span>
                            </td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>{line.quantity}</span></td>
                        </tr>

                    </table>

                    <table style={{width:"100%"}}>

                        <tr>
                        <td style={{width:"15%"}}>
                        </td>
                        <td style={{width:"25%"}}></td>
                        <td style={{width:"7%"}}></td>
                        <td style={{width:"15%"}}>
                        </td>
                        <td style={{width:"15%"}}></td>

                        <td style={{width:"10%",textAlign: "center"}}></td>
                        <td style={{width:"10%",textAlign: "center"}}></td>
                        <td style={{width:"10%",textAlign: "center"}}></td>
                        </tr>
                        <tr>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                        </tr>
                        <tr>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                        </tr>
                        <tr>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                        </tr>
                        <tr>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                        </tr>
                        <tr>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"25%"}}></td>
                            <td style={{width:"7%"}}></td>
                            <td style={{width:"15%"}}>
                            </td>
                            <td style={{width:"15%"}}></td>

                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                            <td style={{width:"10%",textAlign: "center"}}></td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Style:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.style}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Series:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.series}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Mull Reinforcement:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.mullReinforcment}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Mulling:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.mulling}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Interior Color:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.interiorColor}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Exterior Color:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.exteriorColor}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Rough Opening Width :</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.width}</td>
                        </tr>
                        <tr>
                            <td style={{width:"25%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}>Rough Opening Height:</span></td>
                            <td style={{width:"27%"}}><span
                                style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{line.height}</td>
                        </tr>
                    </table>
                        {line.units.map((units) => (
                     <table style={{width:"100%"}}>
                                    <tr>
                                        <td style={{width: "15%"}}><span
                                            style={{fontWeight: "bold", fontSize: 12}}>Window {""} {units.unitNumber} {" "}{units.style}</span></td>
                                      </tr>
                                    <tr>
                                        <td style={{width: "15%"}}></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "15%"}}></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "15%"}}></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "15%"}}></td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"15%",paddingLeft:15}}><span
                                            style={{fontWeight: "bold",fontSize:12}}>Width:</span></td>
                                        <td style={{width:"45%"}}><span
                                            style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{units.dimensions.width}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"15%",paddingLeft:15}}><span
                                            style={{fontWeight: "bold",fontSize:12}}>Height:</span></td>
                                        <td style={{width:"45%"}}><span
                                            style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{units.dimensions.height}</td>
                                    </tr>
                                    {units.attributes.map((attributes) => (
                                        attributes.category === "Bars" ? [
                                                <tr>
                                                    <td style={{width:"15%",paddingLeft:15}}><span
                                                        style={{fontWeight: "bold",fontSize:12}}>Horizontal Bars:</span></td>
                                                    <td style={{width:"45%"}}><span
                                                        style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{attributes.numberOfBarsHorizontal}</td>
                                                </tr>,
                                                <tr>
                                                    <td style={{width:"15%",paddingLeft:15}}><span
                                                        style={{fontWeight: "bold",fontSize:12}}>Vertical Bars:</span></td>
                                                    <td style={{width:"45%"}}><span
                                                        style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{attributes.numberOfBarsVertical}</td>
                                                </tr>
                                            ]: (
                                            <tr>
                                                <td style={{width:"15%",paddingLeft:15}}><span
                                                    style={{fontWeight: "bold",fontSize:12}}>{attributes.category}:</span></td>
                                                <td style={{width:"45%"}}><span
                                                    style={{fontWeight: "bold",fontSize:12}}></span> &nbsp;&nbsp;{attributes.attribute}</td>
                                            </tr>
                                        )))}

                                     <tr>
                                         <td style={{width: "15%"}}></td>
                                     </tr>
                                     <tr>
                                         <td style={{width: "15%"}}></td>
                                     </tr>
                                     <tr>
                                         <td style={{width: "15%"}}></td>
                                     </tr>
                                     <tr>
                                         <td style={{width: "15%"}}></td>
                                     </tr>
                    </table>
                                ))}
                        {this.state.vosDetail.lineItems.length > 1 ? (
                     <table style={{width:"100%"}}>
                         <tr>
                             <td colSpan="8">
                                 <hr/>
                             </td>
                         </tr>
                     </table>
                        ) : (
                            <table>
                                <tr>
                                    <td colSpan="8">

                                    </td>
                                </tr>
                            </table>
                        )}
                    </div>
                    ))}
                </div>
            </div>

        <p style={{pageBreakAfter: "always"}}>&nbsp;</p>
        <p style={{pageBreakBefore: "always"}}>&nbsp;</p>
       </>
        );

    }

}