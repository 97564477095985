import React, { Component } from "react";
import "./style.css";
import {
  Form,
  Col,
  Row,
  Card,
  Space,
  InputNumber,
  Divider,
  Radio,
  Select,
  Modal,
  Button,
  Switch,
  Alert
} from "antd";
import DimensionImg from "../images/DimensionSquare.PNG";
import imageMap from "../images";
import { CheckCircleTwoTone } from "@ant-design/icons";

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

const { Option } = Select;
let errorMessage = '';
export default class ChangeOrderDimensions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfUnit: this.props.numberOfUnit,
      unitIndexs: this.props.numberOfUnit,
      units: this.props.units,
      isEdit: this.props.isEdit,
      
      lineId: this.props.lineId,
      uiMinMax: this.props.uiMinMax,
      size: {},
      currentOpeningWidth: this.props.isEdit
        ? this.props.existingOpeningWidth
        : this.props.uiMinMax["minWidth"],
      currentOpeningHeight: this.props.isEdit
        ? this.props.existingOpeningHeight
        : this.props.uiMinMax["minHeight"],
      oldBabData: this.props.oldBabData,
      mullReinforce: this.props.mullReinforce,
      isMulling: this.props.isMulling,
      userNameUpdate: this.props.userNameUpdate,
      /******awd01o*************************************/
      existingOpeningWidth: this.props.existingOpeningWidth,
      existingOpeningHeight: this.props.existingOpeningHeight,
      /******awd01o*************************************/
      currentSelectedUnit: 0,
      refreshUnits: false,
      ratio: this.props.numberOfUnit.length,
      ratioChosen: this.props.ratioChosen,
      modalVisible: false,
      showErrorIcon: false,
      showMull: false,
      showSuccessIcon: false,
      showConfigurationSection: false,
      singleUnitDimensions: this.props.singleUnitDimensions,
      currentWindowStyleSelected: this.props.currentWindowStyleSelected,
      isConfigureEnable: this.props.isEdit  ? true: false,
      roughOpeningWidth: this.props.isEdit 
      ? this.props.existingOpeningWidth : (this.props.currentOpeningWidth? this.props.currentOpeningWidth: null),
      roughOpeningHeight :this.props.isEdit
      ? this.props.existingOpeningHeight
      : (this.props.currentOpeningHeight? this.props.currentOpeningHeight: null),
      showError: false,
      disableWidth:true,
      disableHeight:true
    };
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleOk = () => {
    this.setState({ modalVisible: false });
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({ modalVisible: false });
  };

  onRadioHandler = (e) => {
    console.log("radio checked", e.target.value);
    let unitListCopy = this.state.units;
    let x;
    let unitCopy;
    for (x in unitListCopy) {
      unitCopy = unitListCopy[x];
      if (
          unitCopy["changeOrderConfig"] === undefined
      ) {
        unitCopy["changeOrderConfig"] = [];
      }
      unitCopy["mulling"] = e.target.value;
      unitCopy["mullingChecked"] = true;
      unitListCopy[x] = unitCopy;
    }
    this.setState({ units: unitListCopy });
    console.log('dimension 1',this.state.showError);
    this.props.dimensionToStepper(unitListCopy, this.state.currentOpeningWidth, this.state.currentOpeningHeight, !(!this.state.showError && this.hasAllDimEntered()));
  };

  handleSizeChanged = (size) => {
    console.log(" Size ", size);
    this.setState({ size });
  };

  editUnit = (value) => {
    this.setState({
      showConfigurationSection: true,
      configuringUnitNumber: value,
    });
  };

  configureUnit = (value) => {
    console.log("Inside configureUnit ", value);
    console.log(
      "this.state.currentOpeningWidth ",
      this.state.currentOpeningWidth
    );
    console.log(
      "this.state.currentOpeningHeight ",
      this.state.currentOpeningHeight
    );
    let unitsCopy = this.state.units;
   
    if(unitsCopy.length>1){
      if (this.props.uiMinMax.productStyle.includes("Transom")) {
        this.setState({ disableHeight: false, disableWidth: true });
        
      } else {
        this.setState({ disableHeight: true, disableWidth: false });
        
      }
    }
    
    this.setState({ units: unitsCopy });
    this.setState({
      showConfigurationSection: true,
      configuringUnitNumber: value,
    });
  };
  onAddMullReinforcement = (checked) => {
    console.log("checked", checked);
    let unit = this.state.units;
    let x;
    let unitCopy;
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    if (checked) {
      let mullCharges = {
        mullReinforcment: "Y",
        category: "Dimension",
        noOfMulls: unit.length - 1,
        priceBase: (unit.length - 1) * 10,
        priceTotal: (unit.length - 1) * 10,
        updatedDate: currDate,
        updatedTime: currTime,
        userNameUpdate: this.state.userNameUpdate,
      };
      for (x in unit) {
        unitCopy = unit[x];
        if (
            unitCopy["changeOrderConfig"] === undefined
        ) {
          unitCopy["changeOrderConfig"] = [];
        }
        unitCopy["mulling"] = "Factory";
        unitCopy["mullCharges"] = mullCharges;
        unit[x] = unitCopy;
      }
      this.setState({mullReinforce : true});
      this.setState({ units: unit });
      this.props.dimensionToStepper(unit);
    } else {
      console.log("unchecked", unit);
      for (x in unit) {
        unitCopy = unit[x];
        if (
            unitCopy["changeOrderConfig"] === undefined
        ) {
          unitCopy["changeOrderConfig"] = [];
        }
        unitCopy["mulling"] = "Factory";
        unitCopy["mullCharges"] = {};
        unitCopy["mullReinforcment"] = "N";
        unitCopy["updatedDate"] = currDate;
        unitCopy["updatedTime"] = currTime;
        unitCopy["userNameUpdate"] = this.state.userNameUpdate;
        unit[x] = unitCopy;
        this.setState({ units: unit });
        this.setState({mullReinforce : false});
        this.props.dimensionToStepper(unit);
      }
    }
  };
  onChangeRatio = (value) => {
    if (value === "1") {
      this.setState({ ratio: this.state.numberOfUnit.length });
      this.setState({ ratioChosen: true });
    }

    if (value === "3") {
      this.setState({ ratioChosen: false });
      this.setState({ ratio: 1 });
      let unitsCopy = this.state.units;
      console.log(
        " singleUnitDimensionsCopy ",
        this.state.singleUnitDimensions
      );
      console.log("Updated units width here", unitsCopy);
      this.setState({ showConfigurationSection: false });
    }
  };

  onApplyHandler = () => {
    console.log(" Inside Dimension ApplyHandler  ", this.state.units);
    console.log('dimension 2',this.state.showError);
    this.props.dimensionToStepper(this.state.units, this.state.currentOpeningWidth, this.state.currentOpeningHeight,!(!this.state.showError && this.hasAllDimEntered()));
  };
  onHandlerUnitCard = (element) => {
    this.setState({
      cardClicked: true,
      unitStyleTypeSelected: element,
      hidden: true,
    });
    //alert("StyleTypeCardClicked "+  this.state.styleTypeCardClicked);
  };

  onChange = (value) => {
    let valueList = [];
    for (let i = 1; i <= value; i++) {
      valueList.push(i);
    }
    this.setState({ numberOfUnit: valueList });
  };

  getHeight = () => {
   
    
   // debugger;
   console.log("check height value", this.state.units, this.props.uiMinMax);
   if (this.state.units.length === 1 || !this.props.uiMinMax.productStyle.includes("Transom")) {
      if (this.state.units[this.state.configuringUnitNumber - 1].height) {
        return this.state.units[this.state.configuringUnitNumber - 1].height;
      } 
     // else {
     //   return this.state.units[this.state.configuringUnitNumber - 1].minWidth;
     // }
   }else{
     let enteredHeight = 0;
     // validate width 
     let otherWindowHeight = 0;
     let unitsCopy = this.state.units;
       for (let u = 0; u < this.state.units.length; u++) {
         if (this.state.configuringUnitNumber - 1 !== u && this.state.units[u].height) {
          otherWindowHeight += this.state.units[u].height;
         }else if(this.state.units[u].height){
          enteredHeight = this.state.units[u].height;
         }
       }

      

       if(enteredHeight === 0){
         
         // do nothing
       }
       else if (enteredHeight < this.state.units[this.state.configuringUnitNumber - 1].minHeight){
        errorMessage = 'Invalid Panel '+this.state.configuringUnitNumber+' Height: Height inputted is lesser than minimum Height manufacturable ('+(this.state.units[this.state.configuringUnitNumber - 1].minHeight)+' in)';
        if(!this.state.showError){
          this.setState({ showError: true });
        }
        if(this.state.errorMessage!== errorMessage){
          this.setState({ errorMessage: errorMessage });
        }
        for (let x in unitsCopy) {
          if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
           
              
            if(unitsCopy[x]["height"] && unitsCopy[x]["height"] !== 0){
              delete unitsCopy[x]["height"] ;
              this.setState({ units: unitsCopy });
            }
              
            
          }
        }
        return null;
      }
      else if (enteredHeight > this.state.units[this.state.configuringUnitNumber - 1].maxHeight){
        errorMessage = 'Invalid Panel '+this.state.configuringUnitNumber+' Height: Height inputted is greater than maximum Height manufacturable ('+(this.state.units[this.state.configuringUnitNumber - 1].maxHeight)+' in)';
        if(!this.state.showError){
          this.setState({ showError: true });
        }
        if(this.state.errorMessage!== errorMessage){
          this.setState({ errorMessage: errorMessage });
        }
        for (let x in unitsCopy) {
          if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
           
              
            if(unitsCopy[x]["height"] && unitsCopy[x]["height"] !== 0){
              delete unitsCopy[x]["height"] ;
              this.setState({ units: unitsCopy });
            }
              
            
          }
        }
        return null;
      }
       else if((enteredHeight+ otherWindowHeight) > this.state.roughOpeningHeight){
         //errorMessage = 'Invalid Unit '+this.state.configuringUnitNumber+' Height: Height inputted is greater than maximum Height allowed ('+(this.state.roughOpeningHeight-otherWindowHeight)+' in)';
         errorMessage = 'The sum height of all individual panels should equal to Rough Opening Height';
         if(!this.state.showError){
           this.setState({ showError: true });
         }
         if(this.state.errorMessage!== errorMessage){
          this.setState({ errorMessage: errorMessage });
        }
        for (let x in unitsCopy) {
          if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
           
              
            if(unitsCopy[x]["height"] && unitsCopy[x]["height"] !== 0){
              delete unitsCopy[x]["height"] ;
              this.setState({ units: unitsCopy });
            }
              
            
          }
        }
         
         return null;
       }else if(otherWindowHeight!==0 && (enteredHeight+ otherWindowHeight) !== this.state.roughOpeningHeight){
         errorMessage = 'Invalid Panel '+this.state.configuringUnitNumber+' Height: Height inputted when added with '+otherWindowHeight+' in should be equal to ('+(this.state.roughOpeningHeight)+' in)';
         if(!this.state.showError){
           this.setState({ showError: true });
         }
         if(this.state.errorMessage!== errorMessage){
          this.setState({ errorMessage: errorMessage });
        }
        // Need to confirm with client if we need to remove the input as they jump to other configure panel that will also get removed. 
         return enteredHeight;
         //return null;
       }else{
         if(this.state.showError && this.state.roughOpeningWidth && this.state.roughOpeningHeight){
         this.setState({ showError: false });
        
         }
         errorMessage = '';
         return enteredHeight;
       }
       // console.log(
       //   "width for combo",
       //   this.state.currentOpeningWidth - otherWindowWidth
       // );
       // width =  this.state.currentOpeningWidth - otherWindowWidth;
       // if(this.state.units[this.state.configuringUnitNumber - 1].maxWidth > width){
       //   return width;
       // }else{
       //   return this.state.units[this.state.configuringUnitNumber - 1].maxWidth;
       // }

     // return null;
   }


  };

  getWidth = (i) => {
    
    
   // debugger;
    console.log("check width value", this.state.units, this.props.uiMinMax);
    if (this.state.units.length === 1 || this.props.uiMinMax.productStyle.includes("Transom")) {
      if (this.state.units[this.state.configuringUnitNumber - 1].width) {
        return this.state.units[this.state.configuringUnitNumber - 1].width;
      } 
      // else {
      //   return this.state.units[this.state.configuringUnitNumber - 1].minWidth;
      // }
    }else{
      let enteredWidth = 0;
      // validate width 
      let otherWindowWidth = 0;
        for (let u = 0; u < this.state.units.length; u++) {
          if (this.state.configuringUnitNumber - 1 !== u && this.state.units[u].width) {
            otherWindowWidth += this.state.units[u].width;
          }else if(this.state.units[u].width){
            enteredWidth = this.state.units[u].width;
          }
        }

        if(this.state.configuringUnitNumber - 1!== i){
          console.log('unmatched');
          return enteredWidth;
        }

        if(enteredWidth === 0){
          
          // do nothing
        }else if (enteredWidth < this.state.units[this.state.configuringUnitNumber - 1].minWidth){
          errorMessage = 'Invalid Panel '+this.state.configuringUnitNumber+' Width: Width inputted is lesser than minimum width manufacturable ('+(this.state.units[this.state.configuringUnitNumber - 1].minWidth)+' in)';
          if(!this.state.showError){
            this.setState({ showError: true });
          }
          if(this.state.errorMessage!== errorMessage){
            this.setState({ errorMessage: errorMessage });
          }

          let unitsCopy = this.state.units;
          
          
          for (let x in unitsCopy) {
            if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
             
                
                if(unitsCopy[x]["width"] && unitsCopy[x]["width"] !== 0){
                  delete unitsCopy[x]["width"] ;
                  this.setState({ units: unitsCopy });
                }
                
              
            }
          }
          

          return null;
        }
        else if (enteredWidth > this.state.units[this.state.configuringUnitNumber - 1].maxWidth){
          errorMessage = 'Invalid Panel '+this.state.configuringUnitNumber+' Width: Width inputted is greater than maximum width manufacturable ('+(this.state.units[this.state.configuringUnitNumber - 1].maxWidth)+' in)';
          if(!this.state.showError){
            this.setState({ showError: true });
          }
          if(this.state.errorMessage!== errorMessage){
            this.setState({ errorMessage: errorMessage });
          }
          let unitsCopy = this.state.units;
          for (let x in unitsCopy) {
            if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
             
                
              if(unitsCopy[x]["width"] && unitsCopy[x]["width"] !== 0){
                delete unitsCopy[x]["width"] ;
                this.setState({ units: unitsCopy });
              }
                
              
            }
          }
          return null;
        }
        else if((enteredWidth+ otherWindowWidth) > this.state.roughOpeningWidth){
         // errorMessage = 'Invalid Unit '+this.state.configuringUnitNumber+' Width: Width inputted is greater than maximum width allowed ('+(this.state.roughOpeningWidth-otherWindowWidth)+' in)';
         errorMessage = 'The sum width of all individual panels should equal to Rough Opening Width';
          if(!this.state.showError){
            this.setState({ showError: true });
          }
          if(this.state.errorMessage!== errorMessage){
            this.setState({ errorMessage: errorMessage });
          }

          let unitsCopy = this.state.units;
          for (let x in unitsCopy) {
            if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
             
                
              if(unitsCopy[x]["width"] && unitsCopy[x]["width"] !== 0){
                delete unitsCopy[x]["width"] ;
                this.setState({ units: unitsCopy });
              }
                
              
            }
          }
          
          return null;
        }else if(otherWindowWidth!==0 && (enteredWidth+ otherWindowWidth) !== this.state.roughOpeningWidth){
          errorMessage = 'Invalid Panel '+this.state.configuringUnitNumber+' Width: Width inputted when added with '+otherWindowWidth+' in should be equal to ('+(this.state.roughOpeningWidth)+' in)';
          if(!this.state.showError){
            this.setState({ showError: true });
          }
          if(this.state.errorMessage!== errorMessage){
            this.setState({ errorMessage: errorMessage });
          }
         // Need to confirm with client if we need to remove the input as they jump to other configure panel that will also get removed. 
          return enteredWidth;
          //return null;
        }else{
          if(this.state.showError && this.state.roughOpeningWidth && this.state.roughOpeningHeight){
          this.setState({ showError: false });
         
          }
          errorMessage = '';
          return enteredWidth;
        }
        // console.log(
        //   "width for combo",
        //   this.state.currentOpeningWidth - otherWindowWidth
        // );
        // width =  this.state.currentOpeningWidth - otherWindowWidth;
        // if(this.state.units[this.state.configuringUnitNumber - 1].maxWidth > width){
        //   return width;
        // }else{
        //   return this.state.units[this.state.configuringUnitNumber - 1].maxWidth;
        // }

      // return null;
    }
  };

  getWidthCombo = () => {
    
    // (this.state.units.length>1)?  (this.props.uiMinMax.productStyle.includes('Transom')? this.state.currentOpeningWidth : this.getWidthCombo())  : this.state.currentOpeningWidth
    if (this.state.units.length > 1) {
      //this.state.units[this.state.configuringUnitNumber - 1].minWidth
      let width = 0;
      if (this.props.uiMinMax.productStyle.includes("Transom")) {
        width = this.state.currentOpeningWidth;
      } else {
        let otherWindowWidth = 0;
        for (let u = 0; u < this.state.units.length; u++) {
          if (this.state.configuringUnitNumber - 1 !== u) {
            otherWindowWidth += this.state.units[u].width;
          }
        }
        console.log(
          "width for combo",
          this.state.currentOpeningWidth - otherWindowWidth
        );
        width =  this.state.currentOpeningWidth - otherWindowWidth;
        if(this.state.units[this.state.configuringUnitNumber - 1].maxWidth > width){
          return width;
        }else{
          return this.state.units[this.state.configuringUnitNumber - 1].maxWidth;
        }
      }
    } else {
      return this.state.currentOpeningWidth;
    }
  };

  getHeightCombo = () => {
    
    // (this.state.units.length>1)?  (this.props.uiMinMax.productStyle.includes('Transom')? this.state.currentOpeningWidth : this.getWidthCombo())  : this.state.currentOpeningWidth
    if (this.state.units.length > 1) {
      if (this.props.uiMinMax.productStyle.includes("Transom")) {
        let otherWindowHeight = 0;
        for (let u = 0; u < this.state.units.length; u++) {
          if (this.state.configuringUnitNumber - 1 !== u) {
            otherWindowHeight += this.state.units[u].height;
          }
        }
        console.log(
          "width for combo",
          this.state.currentOpeningHeight - otherWindowHeight
        );
        if (
          this.state.currentOpeningHeight - otherWindowHeight >
          this.state.units[this.state.configuringUnitNumber - 1].maxHeight
        ) {
          return this.state.units[this.state.configuringUnitNumber - 1]
            .maxHeight;
        } else {
          return this.state.currentOpeningHeight - otherWindowHeight;
        }
      } else {
        return this.state.currentOpeningHeight;
      }
    } else {
      return this.state.currentOpeningHeight;
    }
  };

  getWidthWithRatio = () => {
    return this.state.currentOpeningWidth / this.state.ratio;
  };

  setWidthWithRatio = (unitObject) => {
    let unitsCopy = this.state.units;
    let unitCopy = {};
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === unitObject.unitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["height"] = this.state.currentOpeningHeight;
        unitsCopy[x] = unitCopy;
      }
    }
    console.log("Inside setWidthWithRatio ", unitsCopy);
    this.setState({ units: unitsCopy });
  };

  getHeightWithRatio = () => {
    return this.state.currentOpeningHeight;
  };

  setHeightWithRatio = (unitObject) => {
    let unitsCopy = this.state.units;
    let unitCopy = {};
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === unitObject.unitNumber) {
        unitCopy = unitsCopy[x];
        unitCopy["width"] = this.state.currentOpeningWidth / this.state.ratio;
        unitsCopy[x] = unitCopy;
      }
    }
    console.log("Inside setHeightWithRatio ", unitsCopy);
    this.setState({ units: unitsCopy });
  };

  onChangeHeight = (e) => {
   // debugger;
    let value = parseFloat((e.target.value.trim())?e.target.value:0);
    let totalUI =0;
    // RXM8QHE - To enable configure button once Rough opening width and height is set
    if(value>0 && this.state.roughOpeningWidth && this.state.roughOpeningWidth>0){
      totalUI = value + this.state.roughOpeningWidth;
      this.setState({isConfigureEnable: true});
      
    }else{
      this.setState({isConfigureEnable: false});
    }


    let unitsCopy = this.state.units;
    let dimensions = this.props.uiMinMax;
    console.log(" Max Height from backend", dimensions.maxHeight);
    console.log(" Min Height from backend", dimensions.minHeight);
    console.log("Height entered from UI ", value);
    
    if(value === 0){
      // do nothing
    }
    else if (value < dimensions.minHeight) {
      errorMessage = 'Invalid Height: Height inputted is smaller than minimum height manufacturable ('+dimensions.minHeight+' in)';
      if(this.state.errorMessage!== errorMessage){
        this.setState({ errorMessage: errorMessage });
      }
      this.setState({ showError: true });
      
      this.setState({roughOpeningHeight: null});

    } else if(value > dimensions.maxHeight ){
      errorMessage = 'Invalid Height: Height inputted is larger than maximum height manufacturable ('+dimensions.maxHeight+' in)';
      if(this.state.errorMessage!== errorMessage){
        this.setState({ errorMessage: errorMessage });
      }
      this.setState({ showError: true });
      this.setState({roughOpeningHeight: null});
    }else if(totalUI> dimensions.maxUI){
      errorMessage = 'The dimensions UI Range is more than the manufacturable UI range ('+dimensions.maxUI+' in)';
      if(this.state.errorMessage!== errorMessage){
        this.setState({ errorMessage: errorMessage });
      }
      this.setState({ showError: true });
      this.setState({roughOpeningHeight: null});
    }
    else {
      errorMessage = '';
      this.setState({ showError: false });
      this.setState({roughOpeningHeight: value});
      var x;
   
    if (unitsCopy.length === 1) {
      // RXM8QHE: minWidth should not update, this has to be constant
      // unitsCopy[x].minWidth = value;
      unitsCopy[0].height = value;
    }

    if (this.state.units.length > 1) {
      if (this.props.uiMinMax.productStyle.includes("Transom")) {
        
// Need to decide.
       
      } else {
        for (x in unitsCopy) {
          unitsCopy[x].height = value;
        }
        
      }
    } else {
   
      for (x in unitsCopy) {
        unitsCopy[x].height = value;
      }
    }
      
    }
    
    this.setState({ units: unitsCopy });
    console.log('dimension 3',this.state.showError, errorMessage , !errorMessage);
    //this.props.dimensionToStepper(unitsCopy, this.state.currentOpeningWidth, currentOpeningHeight, this.hasAllDimEntered());
  };

  hasAllDimEntered(){
    console.log('dimension fn',this.state.showError, errorMessage , !errorMessage);
    // if(this.state.showError){
    //   return this.state.showError;
    // }else{
      let hasDimensions = true;
      let unitsCopy = this.state.units;
      for (let x in unitsCopy) {
        if( !(unitsCopy[x].width  && unitsCopy[x].height)){
          hasDimensions = false;
        }
      }
  
      if(!(this.state.roughOpeningWidth && this.state.roughOpeningHeight)){
        hasDimensions = false;
      }
      console.log('hasDimensions',hasDimensions);
      return hasDimensions;
    // }
    
    
  }

  onChangeWidth = (e) => {
    let value = parseFloat((e.target.value.trim())?e.target.value:0);
    
    // RXM8QHE - To enable configure button once Rough opening width and height is set
    let totalUI=0;
    if(value>0 && this.state.roughOpeningHeight && this.state.roughOpeningHeight>0){
      this.setState({isConfigureEnable: true});
      totalUI = value + this.state.roughOpeningHeight;
    }else{
      this.setState({isConfigureEnable: false});
    }

    let unitsCopy = this.state.units;
    let dimensions = this.state.uiMinMax;
    console.log("Width from backend", dimensions);
    console.log("Width entered from UI ", value);
     console.log("Units  ", this.state.units);
    
     if(value === 0){
      // do nothing
    }
    else if (value < dimensions.minWidth) {
      errorMessage = 'Invalid Width: Width inputted is smaller than minimum width manufacturable ('+dimensions.minWidth+' in)';
      if(this.state.errorMessage!== errorMessage){
        this.setState({ errorMessage: errorMessage });
      }
      this.setState({ showError: true });
      this.setState({roughOpeningWidth: null});
     // setTimeout(() => this.toCloseAlert(), 2000);
      //currentOpeningWidth=dimensions.maxWidth;

    } else if(value > dimensions.maxWidth ){
      errorMessage = 'Invalid Width: Width inputted is larger than maximum width manufacturable ('+dimensions.maxWidth+' in)';
      if(this.state.errorMessage!== errorMessage){
        this.setState({ errorMessage: errorMessage });
      }
      this.setState({ showError: true });
      this.setState({roughOpeningWidth: null});
    }else if(totalUI> dimensions.maxUI){
      errorMessage = 'The dimensions UI Range is more than the manufacturable UI range ('+dimensions.maxUI+' in)';
      if(this.state.errorMessage!== errorMessage){
        this.setState({ errorMessage: errorMessage });
      }
      this.setState({ showError: true });
      this.setState({roughOpeningWidth: null});
    }
    else {
      errorMessage = '';
      this.setState({ showError: false });
      this.setState({roughOpeningWidth: value});

      if (unitsCopy.length === 1) {
        // RXM8QHE: minWidth should not update, this has to be constant
        // unitsCopy[x].minWidth = value;
        unitsCopy[0].width = value;
        this.setState({ disableHeight: true, disableWidth: true });
        
      }
  
      if (this.state.units.length > 1) {
        if (this.props.uiMinMax.productStyle.includes("Transom")) {
          this.setState({ disableHeight: false, disableWidth: true });
          var x;
          
          for (x in unitsCopy) {
            unitsCopy[x].width = value;
          }
        } else {
          this.setState({ disableHeight: true, disableWidth: false });
          
        }
      } 
  
      
  
      this.setState({ units: unitsCopy });
      console.log('dimension 4',this.state.showError, errorMessage , !errorMessage);
      
    }
    this.setState({ refreshUnits: true });
    console.log(" OnChangeInput Value ", value);

    
    //this.props.dimensionToStepper(unitsCopy, currentOpeningWidth, this.state.currentOpeningHeight, this.hasAllDimEntered() );

    // this.props.comboToStepper(this.state.numberOfUnit, this.state.units, this.state.dimensions  );
  };

  onChangeUnitWidth = (e) => {
    let value = parseFloat((e.target.value.trim())?e.target.value:0);
    let unitsCopy = this.state.units;
    let unitCopy = {};
    console.log(" Width entered ", value);
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
       
          unitCopy = unitsCopy[x];
          unitCopy["width"] = value;
          unitsCopy[x] = unitCopy;
        
      }
    }
    

    this.setState({ units: unitsCopy });
    this.setState({ minWidthOfUnit: value });
    console.log("Updated units width ", unitsCopy);
    console.log('dimension 5',this.state.showError, errorMessage , !errorMessage);
    //this.props.dimensionToStepper(unitsCopy, this.state.currentOpeningWidth, this.state.currentOpeningHeight,this.hasAllDimEntered());
  };

  onChangeUnitHeight = (e) => {
    
    let value = parseFloat((e.target.value.trim())?e.target.value:0);
    let unitsCopy = this.state.units;
    let unitCopy = {};
    

    console.log(" Height ", value);
    let x;
    for (x in unitsCopy) {
      if (unitsCopy[x].unitNumber === this.state.configuringUnitNumber) {
       
          unitCopy = unitsCopy[x];
          unitCopy["height"] = value;
          unitsCopy[x] = unitCopy;
        
      }
    }
    this.setState({ units: unitsCopy });
    console.log("Updated units height ", unitsCopy);
    console.log('dimension 6',this.state.showError, errorMessage , !errorMessage);
    //this.props.dimensionToStepper(unitsCopy, this.state.currentOpeningWidth, this.state.currentOpeningHeight, this.hasAllDimEntered());
  };

  setCurrentWidth = () => {
    console.log(" Im  in current width");
  };

  componentDidMount() {
    

    let unitsCopy = this.state.units;
   
    if(unitsCopy.length>1){
      if (this.props.uiMinMax.productStyle.includes("Transom")) {
        this.setState({ disableHeight: false, disableWidth: true });
        
      } else {
        this.setState({ disableHeight: true, disableWidth: false });
        
      }
    }

    if(this.state.roughOpeningHeight && this.state.roughOpeningHeight>0 && this.state.roughOpeningWidth && this.state.roughOpeningWidth>0){
     
      this.setState({isConfigureEnable: true});
      
    }
    
  }
  render() {
    console.log("In Dimension render ", this.state.units);
    console.log('dimension render',this.state.showError, errorMessage , !errorMessage);
    if(this.state.units.length>0){
      this.props.dimensionToStepper(this.state.units, this.state.roughOpeningWidth, this.state.roughOpeningHeight, !(!this.state.showError && this.hasAllDimEntered()));
    }
    
    return (
      <div>
        <Form.Item label="">
          <div style={{ fontWeight: 700, fontSize: "1.6em", color: "#f96302" }}>
            ADD WINDOW
          </div>
          <div style={{ fontWeight: 700, fontSize: "2.6em", color: "#f96302" }}>
            DIMENSIONS
          </div>

          <Divider />

          {(this.state.showError) ?  <Alert
              message="Error"
              description={this.state.errorMessage}
              type="error"
              showIcon
            /> : <div></div>}

          <Space>
            {this.state.units.length > 1 ? (
                <Row justify="space-between">
                  <h3 style={{ marginRight: "8px" }}> Mull Reinforcement </h3>
                  <Switch size="large" onChange={this.onAddMullReinforcement.bind(this)} checked={this.state.mullReinforce}/>
                </Row>
            ) : (
                <br />
            )}

          </Space>
          {this.state.units.length > 1 ? <Divider /> : <br />}

          <Space>
            {this.state.units.length > 1 ? (

                <Row justify="space-between">
                  <Radio.Group
                      style={{ marginLeft: "8px" }}
                      onChange={this.onRadioHandler.bind(this)}
                      defaultValue={this.state.isMulling? this.state.isMulling : 'Factory'}
                  >
                    <Radio value={'Field'}>
                      <h3>Field Mulling</h3>
                    </Radio>
                    <Radio value={'Factory'}>
                      <h3>Factory Mulling</h3>
                    </Radio>
                  </Radio.Group>
                </Row>
            ) : (
                <br />
            )}

          </Space>
          {this.state.units.length > 1 ? <Divider /> : <br />}
          <Space>
            <Row justify="space-between">
              <h3 style={{ marginLeft: "32px", marginRight: "8px" }}>
                Rough Opening Width
              </h3>
              <InputNumber
               // min={uiMinMax["minWidth"]}
               // max={uiMinMax["maxWidth"]}
                defaultValue={
                  this.state.isEdit && !this.props.resetOptions
                    ? this.state.existingOpeningWidth
                    : null
                    //uiMinMax["minWidth"]
                }
                value={this.state.roughOpeningWidth}
                onBlur={this.onChangeWidth.bind(this)}
                step="01.0000"
              />

              <h3 style={{ marginLeft: "8px", marginRight: "8px" }}>
                Rough Opening Height
              </h3>
              <InputNumber
               // min={uiMinMax["minHeight"]}
               // max={uiMinMax["maxHeight"]}
                defaultValue={
                  this.state.isEdit 
                    ? this.state.existingOpeningHeight
                    : null
                    //uiMinMax["minHeight"]
                }
                value={this.state.roughOpeningHeight}
                onBlur={this.onChangeHeight.bind(this)}
                step="01.0000"
              />
            </Row>
          </Space>

          <Divider />

          <h3 style={{ marginRight: "8px" }}> Choose Ratio</h3>
          <Select
            style={{ width: 200 }}
            placeholder="ratio ..."
            optionFilterProp="children"
            defaultValue="3"
            onChange={this.onChangeRatio.bind(this)}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="3">Manual</Option>
            <Option value="2" disabled>
              Ratio TBD
            </Option>
          </Select>

          <Divider />
          <Space direction="Vertical">
            {this.props.uiMinMax.productStyle &&
            this.props.uiMinMax.productStyle.includes("Transom") ? (
              <Col gutter={24}>
                {this.state.ratioChosen
                  ? this.state.units.map((index) => {
                      return (
                        <Col span={4 + { index }}>
                          <Card
                            hoverable={true}
                            class="card"
                            title={"Panel #" + index.unitNumber}
                          >
                            <h2
                              style={{
                                fontWeight: 700,
                                fontSize: "1.6em",
                                color: "#f96302",
                                width: 180
                              }}
                            >
                              {index.windowStyle}
                            </h2>
                                    
                            <div className="container">
                              <img
                                class="img"
                                alt="Window"
                                src={DimensionImg}
                              />
                                      {" "}
                            </div>
                            <h4
                              style={{ marginLeft: "2px", marginRight: "4px" }}
                            >
                              Width
                            </h4>
                            <InputNumber
                              min={32 / this.state.ratio}
                              max={112}
                              value={this.getWidthWithRatio()}
                              readOnly={true}
                              defaultValue={32 / this.state.ratio}
                              onChange={this.setWidthWithRatio.bind(
                                this,
                                index
                              )}
                              step="01.0000"
                            />
                            <Divider />
                            <h4
                              style={{ marginLeft: "2px", marginRight: "4px" }}
                            >
                              Height
                            </h4>
                            <InputNumber
                              min={23.375}
                              max={84}
                              value={this.getHeightWithRatio()}
                              readOnly={true}
                              defaultValue={23.375}
                              onChange={this.setHeightWithRatio.bind(
                                this,
                                index
                              )}
                              step="01.0000"
                            />
                          </Card>{" "}
                        </Col>
                      );
                    })
                  : this.state.units.length > 0 &&
                    this.state.units.map((index) => {
                      return (
                        <Row
                          span={
                            this.state.units.length > 2
                              ? 8
                              : this.state.units.length === 1
                              ? 24
                              : 12
                          }
                        >
                          <Card
                            hoverable={true}
                            key={this.state.numberOfUnit[index]}
                            class="card"
                            title={"Panel #" + index.unitNumber}
                          >
                            <h2
                              style={{
                                fontWeight: 700,
                                fontSize: "1.6em",
                                color: "#f96302",
                                width: 180
                              }}
                            >
                              {index.windowStyle}
                            </h2>

                            
                              <div className="container" style={{height:200}}>
                                <img
                                  class="img"
                                  alt="Window"
                                  src={imageMap.get(index.windowStyle)}
                                />
                              </div>
                           

                            <br></br>
                            <br />
                            {index.configurationString === undefined ? (
                              <Button
                                key={this.state.units[index]}
                                type="primary"
                                size="small"
                                onClick={(e) =>
                                  this.configureUnit(index.unitNumber)
                                }
                                disabled={!this.state.isConfigureEnable}
                              >
                                Configure
                              </Button>
                            ) : (
                              <div className="icons-list">
                                <CheckCircleTwoTone
                                  twoToneColor="#52c41a"
                                  style={{ fontSize: "30px" }}
                                />
                                <br></br>
                                <br></br>
                                <br></br>
                                <Button
                                  key={this.state.units[index]}
                                  type="primary"
                                  size="small"
                                  onClick={(e) =>
                                    this.editUnit(index.unitNumber)
                                  }
                                >
                                  Edit
                                </Button>
                              </div>
                            )}
                          </Card>{" "}
                        </Row>
                      );
                    })}
              </Col>
            ) : (
              <Row gutter={24}>
                {this.state.ratioChosen
                  ? this.state.units.map((index) => {
                      return (
                        <Col span={4 + { index }}>
                          <Card
                            hoverable={true}
                            class="card"
                            title={"Panel #" + index.unitNumber}
                          >
                            <h2
                              style={{
                                fontWeight: 700,
                                fontSize: "1.6em",
                                color: "#f96302",
                                width: 180
                              }}
                            >
                              {index.windowStyle}
                            </h2>
                                    
                            <div className="container">
                              <img
                                class="img"
                                alt="Window"
                                src={DimensionImg}
                              />
                                      {" "}
                            </div>
                            <h4
                              style={{ marginLeft: "2px", marginRight: "4px" }}
                            >
                              Width
                            </h4>
                            <InputNumber
                              min={32 / this.state.ratio}
                              max={112}
                              value={this.getWidthWithRatio()}
                              readOnly={true}
                              defaultValue={32 / this.state.ratio}
                              onChange={this.setWidthWithRatio.bind(
                                this,
                                index
                              )}
                              step="01.0000"
                            />
                            <Divider />
                            <h4
                              style={{ marginLeft: "2px", marginRight: "4px" }}
                            >
                              Height
                            </h4>
                            <InputNumber
                              min={23.375}
                              max={84}
                              value={this.getHeightWithRatio()}
                              readOnly={true}
                              defaultValue={23.375}
                              onChange={this.setHeightWithRatio.bind(
                                this,
                                index
                              )}
                              step="01.0000"
                            />
                          </Card>{" "}
                        </Col>
                      );
                    })
                  : this.state.units.length > 0 &&
                    this.state.units.map((index) => {
                      return (
                        <Col
                          span={
                            this.state.units.length > 2
                              ? 8
                              : this.state.units.length === 1
                              ? 24
                              : 12
                          }
                        >
                          <Card
                            hoverable={true}
                            key={this.state.numberOfUnit[index]}
                            class="card"
                            title={"Panel #" + index.unitNumber}
                          >
                            <h2
                              style={{
                                fontWeight: 700,
                                fontSize: "1.6em",
                                color: "#f96302",
                                width: 180
                              }}
                            >
                              {index.windowStyle}
                            </h2>

                           
                              <div className="container" style={{height:200}}>
                                <img
                                  class="img"
                                  alt="Window"
                                  src={imageMap.get(index.windowStyle)}
                                />
                              </div>
                         
                            <br></br>
                            <br />
                            {index.configurationString === undefined ? (
                              <Button
                                key={this.state.units[index]}
                                type="primary"
                                size="small"
                                onClick={(e) =>
                                  this.configureUnit(index.unitNumber)
                                }
                                disabled={!this.state.isConfigureEnable}
                              >
                                Configure
                              </Button>
                            ) : (
                              <div className="icons-list">
                                <CheckCircleTwoTone
                                  twoToneColor="#52c41a"
                                  style={{ fontSize: "30px" }}
                                />
                                <br></br>
                                <br></br>
                                <br></br>
                                <Button
                                  key={this.state.units[index]}
                                  type="primary"
                                  size="small"
                                  onClick={(e) =>
                                    this.editUnit(index.unitNumber)
                                  }
                                >
                                  Edit
                                </Button>
                              </div>
                            )}
                          </Card>{" "}
                        </Col>
                      );
                    })}
              </Row>
            )}
            
          </Space>
          <Divider />
          <Space>
           
            {this.state.showConfigurationSection ? (
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    fontSize: "1.6em",
                    color: "#f96302",
                  }}
                >
                  {" "}
                  Panel #{this.state.configuringUnitNumber}{" "}
                </h2>
                <Divider />
                <h4 style={{ marginLeft: "2px", marginRight: "4px" }}>Width</h4>
                <InputNumber
                  
                  value={this.state.isConfigureEnable?this.getWidth(this.state.configuringUnitNumber - 1):0}
                  
                  disabled = {this.state.disableWidth}
                  onBlur={this.onChangeUnitWidth.bind(this)}
                  step="01.0000"
                />
                <Divider />
                <h4 style={{ marginLeft: "2px", marginRight: "4px" }}>
                  Height
                </h4>
                <InputNumber
                 
                  value={this.state.isConfigureEnable?this.getHeight():0}
                  
                  disabled = {this.state.disableHeight}
                  onBlur={this.onChangeUnitHeight.bind(this)}
                  step="01.0000"
                />
                <Divider />
              </div>
            ) : (
              <div></div>
            )}
          </Space>
          <Row>
            <Col span={24}>
              <Modal
                title="Invalid Dimensions !"
                visible={this.state.modalVisible}
                onOk={this.handleOk}
                style={{ top: 100 }}
                //confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
              >
                <p>Please enter the dimensions within range</p>
                <ol>
                  
                </ol>
              </Modal>
            </Col>
          </Row>
        </Form.Item>
      </div>
    );
  }
}
