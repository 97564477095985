import React, { Component } from "react";
import VosTemplate from "./VosTemplate";
import jsPDF from "jspdf";
import { Redirect } from "react-router-dom";
import "../index.css";
import {
  Button,
  Spin
} from "antd";
export default class VosGenerate extends Component {
  constructor(props) {
    super(props);
    console.log("VOS Generate ", this.props);
    this.state = {
      landingPage: false,
      vosDetail: null,
      isLoading: false,
    };
  }
  async getToken () {
  
    const fullUrl = new URL(process.env.REACT_APP_NODE_URL).href;
    //const data = new URLSearchParams();
      const accessTokenResponse = await fetch(fullUrl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
      });
      return accessTokenResponse
  };
  async componentDidMount() {
    let accessToken;
    const accessTokenResponse = await this.getToken()
    if(accessTokenResponse.ok){
      accessToken = await accessTokenResponse.json()
    }
  this.setState({ accessToken: accessToken['access_token'] });   
    this.setState({ isLoading: true });
    let vosJson = "";
    const url = process.env.REACT_APP_BASE_URL + "/getVOSDetails?leadId="+this.props.location.leadNumber
    const response = await fetch( url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Api-Key": process.env.REACT_APP_API_KEY,
        'Authorization': 'Bearer ' + this.state.accessToken
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
    });

    //console.log("responseString ", response.text())  ;
    if (response.ok) {


      vosJson = await response.json();
      console.log(vosJson);
      this.setState({vosDetail: vosJson});
      this.setState({ isLoading: false });
    } else {
      if (response.status === 401) {
        window.location.href = "ssoLoginUrl";
      } else if (response.status === 403) {
        this.setState({unauthorized: true, loadingIsActive: false});
      }
    }
  }

  handleGeneratePdf() {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    //get table htmlsdfjflksed
    const pdfTable = document.getElementById("divToPrint");

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text("Vendor Order Summary", doc.internal.pageSize.width / 2, 15, {
      baseline: "top",
      align: "center",
    });

    doc.html(pdfTable.innerHTML, { html2canvas: { scale: 0.4 }, margin: 8, autoPaging: true }).then(() => {
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
          "Page " + String(i) + " of " + String(pageCount),
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height,
          {
            baseline: "bottom",
            align: "center",
          }
        );
      }
      doc.save(this.state.vosDetail.job_No+"_VOS.pdf");
    });
  }

  onBack = () => {

    if(this.props.location.state?.changeOrderFlowVos){
      this.setState({redirect: true});
    }else{
      this.setState({ landingPage: true });
    }
  };

  render() {
    if (!this.state.vosDetail) {
      return <div />
    }
    return (
        <Spin tip="Loading..." spinning={this.state.isLoading}>
      <div>
        <Button

            type="primary"
            size="medium"
            onClick={this.onBack.bind(this)}
        >
          {" "}
          Back
        </Button>
        <Button
            type="primary"
            size="medium"
            style={{ margin: "0 30px" }}

            onClick={this.handleGeneratePdf.bind(this)}
        >
          {" "}
          Download PDF
        </Button>
        <div id="divToPrint">
          <VosTemplate vosDetail={this.state.vosDetail}/>
        </div>
         {this.state.landingPage ? (
             <Redirect
                 push
                 to={{
                   pathname:
                       "leadId/" +
                       `${this.props.location.leadNumber}` +
                       "/branchNumber/" +
                       `${this.props.location.branchNumber}` +
                       "/priceList/" +
                       `${this.props.location.priceList}` +
                       "/coastalFlag/" +
                       `${this.props.location.coastalFlag}` +
                       "/leadSafeFlag/" +
                       `${this.props.location.leadSafeFlag}` +
                       "/userName/" +
                       `${this.props.location.userName}` +
                       "/userSFID/" +
                       `${this.props.location.userSFID}` +
                       "/token/" +
                       `${this.props.location.accessToken}` +
                       "/openingUuid/" +
                       `${this.props.location.openingUuid}`,
                   state: {
                     windows: this.props.location.windows,
                     orderSubmitted: this.props.location.orderSubmitted,
                   },
                 }}
             />
        ) : null}
        {this.state.redirect ? (
            <Redirect
                push
                to={{
                  pathname: "changeOrder",
                  state: {
                    windows: this.props.location.state.windows,
                    isEdit: this.props.location.state.isEdit,
                    leadNumber: this.props.location.state.leadNumber,
                    lineId: this.props.location.state.lineId,
                    branchNumber: this.props.location.state.branch,
                    storeNumber: this.props.location.state.storeNumber,
                    priceList: this.props.location.state.priceList,
                    leadSafeFlag: this.props.location.state.leadSafeFlag,
                    openingUuid: this.props.location.state.openingUuid,
                    token: this.props.location.state.token,
                    authenticated:this.props.location.state.authenticated,
                    addedWindowInExistingOrder: !this.props.location.state.isEdit,
                    selectedWrapColor: this.props.location.state.selectedWrapColor,
                    isBackClicked: this.state.redirect,
                    miscNotes: this.props.location.state.miscNotes,
                  },
                }}
            />
        ) : null}
      </div>
        </Spin>
    );
  }
}
