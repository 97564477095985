import { Steps, Button, Row, Col, PageHeader, InputNumber } from "antd";
import React, { createElement } from "react";
import { HomeOutlined } from '@ant-design/icons'
import { Redirect } from "react-router-dom";
import { v4 as uuid } from "uuid";
import LogoName from "./images/home-depot.svg";
import "./index.css";
import ChangeOrderStyles from "./Components/ChangeOrderStyles";
import ChangeOrderDimensions from "./Components/ChangeOrderDimensions";
import ChangeOrderOthers from "./Components/ChangeOrderOthers";
import { ShoppingCartOutlined } from "@ant-design/icons";

const { Step } = Steps;

const numberOfUnit = [];
const units = [];
const uiMinMax = { maxHeight: 0, maxWidth: 0, minHeight: 0, minWidth: 0 };
const categories = [];
//const existingOrderJSON = {};
let miscItemLabor = [];
let itemLaborNotes = "";
let showItemMiscDiv = false;

const pages = [ChangeOrderStyles, ChangeOrderDimensions, ChangeOrderOthers];

export default class ChangeOrderStepper extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    console.log("stepper state", this.props);
    this.state = {
      existingOrderJSON: this.props.location.existingOrderJSON,
      existingItemToEdit: this.props.location.existingItemToEdit,
      finalInHomeJSON: this.props.location.finalInHomeJSON,
      isEdit: this.props.location.isEdit,
      lineId: this.props.location.lineId,
      laborImu: 0.0,
      laborRate: 0.0,
      selectedWrapColor: this.props.location.selectedWrapColor,
      oldBabData: this.props.location.oldBabData,
      authenticated: this.props.location.authenticated,
      current: 0,
      key: 0,
      isMullChecked: false,
      isMulling: "",
      numberOfUnit: [],
      unitList: [], //unitList and singleUnitDimension needs to be merged
      selectedItemLabor: this.props.location.existingItemToEdit?.labors,
      // reading series name from babdata
      currentSeriesSelected: this.props.location.existingItemToEdit
        ? this.props.location.existingItemToEdit.babSeries
          ? this.props.location.existingItemToEdit.babSeries
          : this.props.location.existingItemToEdit.sizeAbbr
        : "",
      singleUnitDimensions: [],
      leadSafeJSON : undefined,
      wrapLaborJSON: undefined,
      steps: [
        {
          title: "Window Style",
          content: (
            <ChangeOrderStyles
              /************************************/
              existingFloor={this.props.location.existingItemToEdit?.floor}
              existingRoom={this.props.location.existingItemToEdit?.room}
              currentWindowStyleSelected={
                this.props.location.existingItemToEdit?.productStyle
              }
              vinylOrWood={this.props.location.existingItemToEdit?.vinylOrWood}
              currentSeriesSelected={
                this.props.location.existingItemToEdit
                  ? this.props.location.existingItemToEdit.babSeries
                    ? this.props.location.existingItemToEdit.babSeries
                    : this.props.location.existingItemToEdit.sizeAbbr
                  : ""
              }
              currentNoOfUnitSelected={this.getExistingUnitCount(
                  this.props.location.existingItemToEdit?.productStyle
              )}

              // existingUnitDimensionsArray={this.getExistingDimensions(
              //   this.props.location.existingItemToEdit.babData
              // )} //{this.props.location.existingItemToEdit.babData[0].dimensions} //how to get line number?
              isEdit={this.props.location.isEdit}
              lineId={this.props.location.lineId}
              priceListNumber={this.props.location.priceList}
              branchNumber={this.props.location.branchNumber}
              userNameUpdate={this.props.location.userNameUpdate}
              series={this.props.location.series}
              material={this.props.location.material}
              products={this.props.location.products}
              productSeries={this.props.location.productSeries}
              user={this.props.location.user}
              pricingTaxForAllProducts={this.props.location.pricingTaxForAllProducts}
              modifiedTimeStampObj={this.props.location.modifiedTimeStampObj}

              /* awd01o the above pass exisiting values to the Style page */
              units={units}
              numberOfUnit={numberOfUnit}
              showUnits={true}
              ratioChosen={false}
              commonCategories={["Interior Color", "Exterior Color"]}
            />
          ),
        },
        {
          title: "Dimensions",
          content: (
            <ChangeOrderDimensions
              key={"Dimensions"}
              units={units}
              numberOfUnit={numberOfUnit}
              uiMinMax={uiMinMax}
              showConfigurationSection={true}
              isEdit={this.props.location.isEdit}
              dimensionToStepper={this.dimensionToStepper}
            />
          ),
        },
        {
          title: "Others",
          content: (
            <ChangeOrderOthers
              key={"Others"}
              units={units}
              numberOfUnit={numberOfUnit}
              categories={categories}
              //YXC8HEN TODO 3# Remove below hardcoding
              commonCategoriesMap={{
                "Interior Color": ["White", "Beige"],
                "Exterior Color": ["White", "Black"],
              }}
              isEdit={this.props.location.isEdit}
              commonCategories={["Interior Color", "Exterior Color"]}
              ref={this.child}
              othersToStepper={this.othersToStepper}
            />
          ),
        },
      ],
      isStepUpdated: false,
      uiMinMax: {},
      categoryAttributeMap: {},
      branchNumber: this.props.location.branchNumber,
      leadSafeFlag: this.props.location.leadSafeFlag
        ? this.props.location.leadSafeFlag
        : true,
      userName: this.props.location.userName,
      userSFID: this.props.location.userSFID,
      priceListNumber: this.props.location.priceList,
      coastalFlag: this.props.location.coastalFlag,
      redirectPath: "",
      categories: [],
      basePrice: 0.0,
      modalVisible: false,
      leadNumber: this.props.location.leadNumber,
      openingUuid: this.props.location.openingUuid,
      token: this.props.location.token,
      order: {},
      windows: this.props.location.windows,
      labors: this.props.location.labors,
      jobMiscNotes: this.props.location.jobMiscNotes,
      pricingMapList: [],
      redirect: false,
      itemLaborsData: this.props.location.itemLaborData,
      jobLaborsData: [],
      isNextBtnDisable: false,
      isSubmitEnable: true,
      defaultLaborsData: this.props.location.defaultLaborsData,
      accessToken: this.props.location.accessToken,
      jobLaborUpdates: this.props.location.jobLaborUpdates,
      deleteLaborUpdates: this.props.location.deleteLaborUpdates,
      laborQuantityUpdate: this.props.location.laborQuantityUpdate,
      wrapColorUpdate: this.props.location.wrapColorUpdate,
      deleteWindowUpdate: this.props.location.deleteWindowUpdate,
      miscNotesUpdate: this.props.location.miscNotesUpdate,
      userNameUpdate: this.props.location.userNameUpdate,
      user: this.props.location.user,
      categoryAllProductAttributeMap: {},
      productCategories: {},
      commonCategoriesAttributeMap: {},
      commonCategories: ["Interior Color", "Exterior Color"],
    };

    this.comboToStepper = this.comboToStepper.bind(this);
    this.dimensionToStepper = this.dimensionToStepper.bind(this);
    this.othersToStepper = this.othersToStepper.bind(this);
  }

  getExistingUnitCount(value) {
    if (value !== undefined) {
      console.log("*** | split length ", value.split("|").length);
      return value.split("|").length;
    } else {
      return 1;
    }
  }

  getExistingDimensions(babData) {
    console.log("***getExistingDimensions ", babData);
    let existingDimensions = [];
    if (babData) {
      babData.forEach((unit, i) => {
        existingDimensions.push(unit.dimensions);
      });
    }

    return existingDimensions;
  }

  getExistingLabors(value, notes, itemUpdatedLaborData) {
    let labors = [];
    let defaultLaborValue = [];
    miscItemLabor = [];
    //let itemLaborNotes = '';
    if (notes && notes.includes("Item Labor Notes:")) {
      itemLaborNotes = notes.split("Item Labor Notes:")[1].split("\n")[0];
    }
    if (value && value.length > 0) {
      value.forEach((value, i) => {
        labors.push(value.desc);
      });
    } else {
      return [];
    }

    for (var i = 0; i < itemUpdatedLaborData.length; i++) {
      let childrenData = itemUpdatedLaborData[i].children;
      console.log("onChange::childrenData", childrenData);

      for (var j = 0; j < childrenData.length; j++) {
        for (var k = 0; k < value.length; k++) {
          if (value[k].desc === childrenData[j].title) {
            defaultLaborValue.push(childrenData[j].key);
            if (
              itemUpdatedLaborData[i].title === "MISCELLANEOUS LABOR OPTIONS"
            ) {
              showItemMiscDiv = true;
              miscItemLabor.push(value[k]);
            }
            break;
          }
        }
      }
    }

    console.log("stepper miscItemLabor", miscItemLabor, itemLaborNotes);
    this.setState({
      miscItemLabor: miscItemLabor,
      itemLaborNotes: itemLaborNotes,
    });
    return defaultLaborValue;
  }

  getLaborsForViewSummary(value, itemUpdatedLaborData) {
    let labors = [];
    let defaultLaborValue = [];
    if (value && value.length > 0) {
      value.forEach((value, i) => {
        labors.push(value.desc);
      });
    } else {
      return [];
    }

    for (var i = 0; i < itemUpdatedLaborData.length; i++) {
      let childrenData = itemUpdatedLaborData[i].children;
      console.log("onChange::childrenData", childrenData);

      for (var j = 0; j < childrenData.length; j++) {
        for (var k = 0; k < labors.length; k++) {
          if (labors[k] === childrenData[j].title) {
            defaultLaborValue.push(childrenData[j].title);
            break;
          }
        }
      }
    }
    return defaultLaborValue;
  }

  comboToStepper = (
    numberOfUnitFromUnitStyles,
    unitListStyles,
    uiMinMaxStyles,
    currentSeriesSelected,
    categoriesStyles,
    categoryAttributeMapstyles,
    pricingMapStyles,
    categoryAllProductAttributeMap,
    commonCategoriesAttributeMap,
    productCategories,
    isEdit,
    isNextBtnDisable,
    currentWindowStyleSelected,
    currentOpeningWidth,
    currentOpeningHeight
  ) => {
    console.log("Inside combotoStepper ", unitListStyles);
    console.log("Inside combotoStepper ", isEdit);
    console.log(
      "Inside numberOfUnitFromUnitStyles ",
      numberOfUnitFromUnitStyles
    );
    console.log("Category ", categoriesStyles);
    console.log("categoryAttributeMapstyles ", categoryAttributeMapstyles);

    console.log("Category ", categoriesStyles);
    console.log(
      "categoryAllProductsAttributeMap ",
      categoryAllProductAttributeMap
    );
    this.setState({
      numberOfUnit: numberOfUnitFromUnitStyles,
      unitList: unitListStyles,
      uiMinMax: uiMinMaxStyles,
      currentSeriesSelected: currentSeriesSelected,
      categories: categoriesStyles,
      categoryAttributeMap: categoryAttributeMapstyles,
      pricingMapList: pricingMapStyles,
      categoryAllProductAttributeMap: categoryAllProductAttributeMap,
      commonCategoriesAttributeMap: commonCategoriesAttributeMap,
      productCategories: productCategories,
      isEdit: isEdit,

      isNextBtnDisable,
      currentWindowStyleSelected: currentWindowStyleSelected,
      currentOpeningWidth: currentOpeningWidth,
      currentOpeningHeight: currentOpeningHeight,
    });
  };

  dimensionToStepper = (
    unitListDimension,
    currentOpeningWidth,
    currentOpeningHeight,
    isNextBtnDisable
  ) => {
    // alert('called');
    console.log(
      "Inside dimensionToStepper ",
      isNextBtnDisable,
      unitListDimension
    );
    console.log(
      "currentOpeningWidth in stepper",
      currentOpeningWidth,
      currentOpeningHeight
    );
    let uiMinMax = this.state.uiMinMax;
    if (currentOpeningWidth) {
      uiMinMax["currentOpeningWidth"] = currentOpeningWidth;
      uiMinMax["currentOpeningHeight"] = currentOpeningHeight;
    }

    var uiRangeofProduct = 0;
    var index = 0;
    for (let x in unitListDimension) {
      var orientation = unitListDimension[x]["orientation"];
      console.log("width in cal", unitListDimension[x]["width"]);
      console.log("height in cal", unitListDimension[x]["height"]);
      let height = unitListDimension[x]["height"]
        ? unitListDimension[x]["height"]
        : 0;
      // unitListDimension[x]["height"] = height;
      let width = unitListDimension[x]["width"]
        ? unitListDimension[x]["width"]
        : 0;
      // unitListDimension[x]["width"] = width;
      if (orientation === "Vertical") {
        if (index === 0) {
          uiRangeofProduct += width;
        }
        uiRangeofProduct += height;
      } else {
        if (index === 0) {
          uiRangeofProduct += height;
        }
        uiRangeofProduct += width;
      }
      if (
        unitListDimension[x]["mulling"] === undefined ||
        unitListDimension[x]["mulling"] === null
      ) {
        unitListDimension[x]["mulling"] = "Factory";
      }
      if (unitListDimension[x]["configurationString"] !== undefined) {
        unitListDimension[x]["configurationString"] = undefined;
      }

      index++;
    }
    console.log("uiRangeofProduct" + uiRangeofProduct);
    this.setState({
      unitList: unitListDimension,
      uiRangeofProduct: uiRangeofProduct,
      currentOpeningWidth: currentOpeningWidth,
      currentOpeningHeight: currentOpeningHeight,
      uiMinMax: uiMinMax,
      isNextBtnDisable: isNextBtnDisable,
    });
  };

  othersToStepper = (
    unitListGrid,
    selectedItemLabor,
    inHomeData,
    addOnlaborCost,
    isSubmitEnable,
    miscNotes,
    existingAddOnLabors,
    miscItemLabor
  ) => {
    // inhome final
    console.log("Inside othersTostepper ", unitListGrid);
    console.log("item labor", existingAddOnLabors);
    console.log(selectedItemLabor);
    this.setState({
      unitList: unitListGrid,
      selectedItemLabor: selectedItemLabor,
      inHomeData: inHomeData,
      addOnlaborCost: addOnlaborCost,
      isSubmitEnable: isSubmitEnable,
      miscNotes: miscNotes,
      existingAddOnLabors: existingAddOnLabors,
      miscItemLabor: miscItemLabor,
    });
  };

  laborToStepper = (unitListColor) => {
    this.setState({ unitList: unitListColor });
  };

  next() {
    let i;
    let stepsCopy = this.state.steps;
    let parameters = {};
    const current = this.state.current + 1;

    if (this.state.steps[current].title === "Dimensions") {
      //YXC8HEN generate valueSelected from inHome json babData section and remove below hardcoding also we will push this from state

      // let valueSelected =
      let unitsCopy = this.state.unitList;
      console.log("on next", unitsCopy);
      // debugger;
      let existingDim = this.getExistingDimensions(
        this.props.location.existingItemToEdit?.babData
      );
      if (existingDim && existingDim.length > 0) {
        for (let unit in unitsCopy) {
          unitsCopy[unit].width = existingDim[unit]["width"];
          unitsCopy[unit].height = existingDim[unit]["height"];
        }
      }

      if (this.state.isEdit) {
        for (let unit in unitsCopy) {
          //console.log("Inside for ", valueSelected)

          /*awd01o***********************/
          let glassSash = "";
          let glassPack = "";
          let additionalGlassType = [];
          let spacer = "";
          let gridSash = "";
          let grid = "";
          let performanceOption = [];
          let screen = "";
          let frame = [];
          let hardware = "";
          let handing = "";
          let bars = "";
          let oriel = "";
          let addGrids = "";
          let valueSelectedMap = {};
          // let horizontalBarsDefault = 0;
          // let verticalBarsDefault = 0;
          // let horizontalBarsMin = 0;
          // let verticalBarsMin = 0;
          // let horizontalBarsMax = 0;
          // let verticalBarsMax = 0;
          let verticalBarsValue = 0;
          let horizontalBarsValue = 0;
          this.props.location.existingItemToEdit.babData[
            unit
          ].attributes.forEach((attribute, i) => {
            if (attribute.category === "Glass Sash") {
              glassSash = attribute.attribute;
              if (glassSash !== undefined) {
                Object.assign(valueSelectedMap, { "Glass Sash": glassSash });
              }
            } else if (attribute.category === "Glass Pack") {
              glassPack = attribute.attribute;
              if (glassPack !== undefined) {
                Object.assign(valueSelectedMap, { "Glass Pack": glassPack });
              }
            } else if (attribute.category === "Spacer") {
              console.log("*** inside SPACER accessory");
              spacer = attribute.attribute;
              if (spacer !== undefined) {
                Object.assign(valueSelectedMap, { Spacer: spacer });
              }
            } else if (attribute.category === "Additional Glass Type") {
              attribute.attribute.forEach((value, i) => {
                additionalGlassType.push(value);
              });
              if (additionalGlassType !== undefined) {
                Object.assign(valueSelectedMap, {
                  "Additional Glass Type": additionalGlassType,
                });
              }
            } else if (attribute.category === "Grid Sash") {
              gridSash = attribute.attribute;
              if (gridSash !== undefined) {
                Object.assign(valueSelectedMap, { "Grid Sash": gridSash });
              }
            } else if (attribute.category === "Grid") {
              grid = attribute.attribute;
              if (grid !== undefined) {
                Object.assign(valueSelectedMap, { Grid: grid });
              }
            } else if (attribute.category === "Performance Option") {
              attribute.attribute.forEach((value, i) => {
                performanceOption.push(value);
              });
              if (performanceOption !== undefined) {
                Object.assign(valueSelectedMap, {
                  "Performance Option": performanceOption,
                });
              }
            } else if (attribute.category === "Screens") {
              screen = attribute.attribute;
              if (screen !== undefined) {
                Object.assign(valueSelectedMap, { Screens: screen });
              }
            } else if (attribute.category === "Frame") {
              attribute.attribute.forEach((value, i) => {
                frame.push(value);
              });
              if (frame !== undefined) {
                Object.assign(valueSelectedMap, { Frame: frame });
              }
            } else if (attribute.category === "Hardware") {
              hardware = attribute.attribute;
              if (hardware !== undefined) {
                Object.assign(valueSelectedMap, { Hardware: hardware });
              }
            } else if (attribute.category === "Handing") {
              handing = attribute.attribute;
              if (handing !== undefined) {
                Object.assign(valueSelectedMap, { Handing: handing });
              }
            } else if (attribute.category === "Bars") {
              bars = attribute.attribute;
              if (bars !== undefined) {
                Object.assign(valueSelectedMap, { Bars: [bars] });
                verticalBarsValue = attribute.numberOfBarsVertical;
                horizontalBarsValue = attribute.numberOfBarsHorizontal;
              }
            } else if (attribute.category === "Oriel Ratio") {
              oriel = attribute.attribute;
              if (oriel !== undefined) {
                Object.assign(valueSelectedMap, { "Oriel Ratio": oriel });
              }
            } else if (attribute.category === "Add Grids") {
              addGrids = attribute.attribute;
              if (addGrids !== undefined) {
                Object.assign(valueSelectedMap, { "Add Grids": addGrids });
              }
            }
          });
          if (
            this.props.location.existingItemToEdit.babData[unit].mullCharges !==
              undefined &&
            this.props.location.existingItemToEdit.babData[unit].mullCharges !==
              null
          ) {
            const isEmpty =
              Object.keys(
                this.props.location.existingItemToEdit.babData[unit].mullCharges
              ).length !== 0;
            this.setState({isMullChecked:isEmpty})
            // eslint-disable-next-line
            this.state.isMulling =
              this.props.location.existingItemToEdit.babData[unit].mulling;
            console.log(
              Object.keys(
                this.props.location.existingItemToEdit.babData[unit].mullCharges
              ).length !== 0
            );
          }
          if (
            this.props.location.existingItemToEdit.babData[unit].mullCharges ===
              undefined &&
            this.props.location.existingItemToEdit.babData[unit].mulling !==
              undefined
          ) {

            this.setState({isMulling:
              this.props.location.existingItemToEdit.babData[unit].mulling});
          }

          unitsCopy[unit]["valueSelected"] = valueSelectedMap;
          unitsCopy[unit]["verticalBarsValue"] = verticalBarsValue;
          unitsCopy[unit]["horizontalBarsValue"] = horizontalBarsValue;
          unitsCopy[unit]["configurationString"] = undefined;
        }
        console.log("Units valueSelected ", unitsCopy);
      }
      //YXC8HEN ends here
     
      parameters = {
         // eslint-disable-next-line
        key: this.state.key++,
        dimensionToStepper: this.dimensionToStepper,
        numberOfUnit: this.state.numberOfUnit,
        units: unitsCopy,
        uiMinMax: this.state.uiMinMax,
        singleUnitDimensions: this.state.singleUnitDimensions,
        isEdit: this.state.isEdit,
        existingOpeningWidth: this.props.location.existingItemToEdit?.width,
        existingOpeningHeight: this.props.location.existingItemToEdit?.height,
        lineId: this.state.lineId,
        oldBabData: this.state.oldBabData,
        mullReinforce: this.state.isMullChecked,
        isMulling: this.state.isMulling,
        userNameUpdate: this.props.location.userNameUpdate,
        currentOpeningWidth: this.state.currentOpeningWidth,
        currentOpeningHeight: this.state.currentOpeningHeight,
      };
      this.dimensionToStepper(unitsCopy, 0, 0, true);
    }

    if (this.state.steps[current].title === "Others") {
      console.log("this.state.oldBabData", this.state.oldBabData);
      this.dimensionToStepper(this.state.unitList);
      console.log("uiRangeofProduct in others" + this.state.uiRangeofProduct);
      //console.log("Child ", this.child.current)
      //console.log("Child ", this.child.current.onCommonCategoryChanged("Exterior Color---Bronze Exterior Color 0-140 UI"))
      let unitsCopy = this.state.unitList;
      let oldBab = this.state.oldBabData;
      let oldInteriorColorJson;
      let oldExteriorColorJson;
      let oldInteriorColor;
      let oldExteriorColor;
      // if(!this.state.isEdit){
      this.setState({ isSubmitEnable: true });
      //}
      if (this.state.isEdit) {
        this.setState({ unitList: unitsCopy });
        console.log("oldBab data", oldBab);
        oldInteriorColorJson = oldBab[0].attributes.find(
          (o) => o.category === "Interior Color"
        );
        oldExteriorColorJson = oldBab[0].attributes.find(
          (o) => o.category === "Exterior Color"
        );
        oldInteriorColor = oldInteriorColorJson.attribute;
        oldExteriorColor = oldExteriorColorJson.attribute;
      }
      var defaultLabor = 0;
      let laborRate = 0;
      let laborImu = 0.0;
      // defaulr labor calculation
      for (let x in this.state.defaultLaborsData) {
        //console.log('labor',this.state.defaultLaborsData[x]);
        if (x === this.state.uiMinMax["productStyle"]) {
          for (let subLabor in this.state.defaultLaborsData[x]) {
            for (let details in this.state.defaultLaborsData[x][subLabor]) {
              console.log(
                "from dim",
                this.state.defaultLaborsData[x][subLabor][details][
                  "fromDimension"
                ],
                this.state.uiRangeofProduct >=
                  this.state.defaultLaborsData[x][subLabor][details][
                    "fromDimension"
                  ]
              );
              console.log(
                "to  dim",
                this.state.defaultLaborsData[x][subLabor][details][
                  "fromDimension"
                ],
                this.state.uiRangeofProduct <=
                  this.state.defaultLaborsData[x][subLabor][details][
                    "fromDimension"
                  ]
              );
              if (
                this.state.uiRangeofProduct >=
                  this.state.defaultLaborsData[x][subLabor][details][
                    "fromDimension"
                  ] &&
                this.state.uiRangeofProduct <=
                  this.state.defaultLaborsData[x][subLabor][details][
                    "toDimension"
                  ]
              ) {
                defaultLabor +=
                  this.state.defaultLaborsData[x][subLabor][details][
                    "jobLabor"
                  ];
                laborRate =
                  this.state.defaultLaborsData[x][subLabor][details][
                    "laborRate"
                  ];
                laborImu =
                  this.state.defaultLaborsData[x][subLabor][details]["imu"];
                console.log("defaultLabor", defaultLabor);
                this.setState({
                  defaultLabor: defaultLabor,
                  laborImu,
                  laborRate,
                });
              }
            }
          }
        }
      }
      var itemUpdatedLaborData = [];
      var itemLabor;
      var objectMap = {};

      var k = 0;
      var children = [];
      for (itemLabor in this.state.itemLaborsData) {
        children = [];
        var itemSubLabors = this.state.itemLaborsData[itemLabor];
        //alert(subLabors);

        var j = 0;

        //subLabors = jobLabors[itemLabors];
        for (let subLabor in itemSubLabors) {
          // console.log("subLabor ", itemSubLabors[subLabor]);
          console.log("sublabor leadsafe flag", this.state.leadSafeFlag);
          if (this.state.leadSafeFlag) {
            console.log("sublabor leadsafe", defaultLabor);
            if (
              itemSubLabors[subLabor].jobDesc ===
              "VinylLB Lead Safe Renovation F&I"
            ) {
              console.log("sublabor leadsafe desc", itemSubLabors[subLabor]);
              defaultLabor += itemSubLabors[subLabor].jobLabor;
              let leadSafe =  {
                "quantity": 0,
                "desc": itemSubLabors[subLabor].jobDesc,
                "priceBase": itemSubLabors[subLabor].jobLabor,
                "detailId": 0,
                "specCode": itemSubLabors[subLabor].specCode,
                "unitOfMeasurement": "Per Opening",
                "quoteId": 0,
                "lmsLaborId": 0,
                "priceTotal": itemSubLabors[subLabor].jobLabor,
                "laborId": itemSubLabors[subLabor].lmsItemNumber
            }

              this.setState({ defaultLabor: defaultLabor, leadSafeJSON: leadSafe });
              console.log("subLabor leadsafe", defaultLabor);
            }
          }
          if(itemSubLabors[subLabor].specCode === 'WRAP'){
            if (
              this.state.uiRangeofProduct >=
                itemSubLabors[subLabor].fromDimension &&
                this.state.uiRangeofProduct <=
                  itemSubLabors[subLabor].toDimension)
            {
              
              let wrapLabor = {
                quantity: 1,
                laborId: 0,
  
                desc: itemSubLabors[subLabor].jobDesc,
                priceBase: itemSubLabors[subLabor].jobLabor,
                detailId: 0,
                specCode: itemSubLabors[subLabor].specCode,
                unitOfMeasurement: "Per Opening",
                quoteId: 0,
                lmsLaborId: itemSubLabors[subLabor].lmsItemNumber,
                priceTotal: itemSubLabors[subLabor].jobLabor,
              };

              this.setState({ wrapLaborJSON: wrapLabor });
            }
          }
          console.log();
          // labors that needs to skip in displaying in UI
          if(itemSubLabors[subLabor].specCode === 'LSR' || itemSubLabors[subLabor].specCode === 'WRAP'){
            continue;
          }
          if (
            itemSubLabors[subLabor].toDimension === 0 ||
            !itemSubLabors[subLabor].toDimension ||
            (this.state.uiRangeofProduct >=
              itemSubLabors[subLabor].fromDimension &&
              this.state.uiRangeofProduct <=
                itemSubLabors[subLabor].toDimension)
          ) {
            children.push({
              title: itemSubLabors[subLabor].jobDesc,
              value: 0 + "-" + k + "-" + j,
              key: 0 + "-" + k + "-" + j,
              price: itemSubLabors[subLabor].jobLabor,
              lmsItemNumber: itemSubLabors[subLabor].lmsItemNumber,
              specCode: itemSubLabors[subLabor].specCode,
              uom: itemSubLabors[subLabor].uom,
              qntCard: <InputNumber></InputNumber>,
            });
            j++;
          }
        }
        objectMap = {
          title: itemLabor,
          value: 0 + "-" + k,
          key: 0 + "-" + k,
          children: children,
          checkable: false,
        };
        itemUpdatedLaborData.push(objectMap);
        k++;
      }

      console.log("itemUpdatedLaborData", itemUpdatedLaborData);
      this.setState({
        itemUpdatedLaborData: itemUpdatedLaborData,
        laborImu,
        laborRate,
      });
      console.log(this.state.unitList);
      console.log(
        "Inside next commonCategoriesAttributeMap",
        this.state.commonCategoriesAttributeMap
      );

      let existingAddOnLabors = this.getExistingLabors(
        this.props.location.existingItemToEdit?.labors,
        this.props.location.existingItemToEdit?.notes,
        itemUpdatedLaborData
      );
     
      parameters = {
         // eslint-disable-next-line
        key: this.state.key++,
        othersToStepper: this.othersToStepper,
        numberOfUnit: this.state.numberOfUnit,
        units: this.state.unitList,
        isEdit: this.state.isEdit,
        lineId: this.state.lineId,
        categoryAttributeMap: this.state.categoryAttributeMap,
        categories: this.state.categories,
        pricingMap: this.state.pricingMapList,
        itemLaborsData: itemUpdatedLaborData,
        jobLaborsData: this.state.jobLaborsData,
        categoryAllProductAttributeMap:
          this.state.categoryAllProductAttributeMap,
        productCategories: this.state.productCategories,
        commonCategoriesMap: this.state.commonCategoriesAttributeMap,
        commonCategories: this.state.commonCategories,
        uiRangeofProduct: this.state.uiRangeofProduct,
        existingInteriorColor: this.state.isEdit ? oldInteriorColor : "",
        existingExteriorColor: this.state.isEdit ? oldExteriorColor : "",
        oldBabData: this.state.isEdit ? this.state.oldBabData : "",
        /*awd01o************************/
        existingAddOnLabors: existingAddOnLabors,
        miscItemLabor: miscItemLabor,
        itemLaborNotes: itemLaborNotes,
        showItemMiscDiv: showItemMiscDiv,
        selectedItemLabor: this.state.selectedItemLabor,
        existingVerticalBars: this.props.location.existingItemToEdit?.sashes
          ? this.props.location.existingItemToEdit?.sashes[0]
            ? this.props.location.existingItemToEdit?.sashes[0]
                .numberOfBarsVertical
            : 6
          : 0,
        existingHorizontalBars: this.props.location.existingItemToEdit?.sashes
          ? this.props.location.existingItemToEdit?.sashes[0]
            ? this.props.location.existingItemToEdit?.sashes[0]
                .numberOfBarsHorizontal
            : 6
          : 0,
        /*awd01o************************/
        uiMinMax: this.state.uiMinMax,
        laborImu: laborImu,
        laborRate: laborRate,
      };
    }

    console.log(" steps ", this.state.steps[current].title);
    for (i in stepsCopy) {
      if (stepsCopy[i].title === this.state.steps[current].title) {
        stepsCopy[i].content = createElement(pages[i], parameters);
      }
    }

    this.setState({ steps: stepsCopy });
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    let unitsCopy = this.state.unitList;
    if (this.state.steps[current].title === "Dimensions") {
      for (let unit in unitsCopy) {
        unitsCopy[unit]["configurationString"] = undefined;
        console.log("In the previous ", unitsCopy);
      }
    }
    this.setState({ isSubmitEnable: true });
    this.setState({ current, unitList: unitsCopy });
    this.setState({ isNextBtnDisable: false });
  }

  onSubmit() {
    this.showModal();
  }

  onEdit() {
    this.setState({ modalVisible: false });
    this.setState({ current: 0 });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleOk = () => {
    this.setState({ modalVisible: false });
  };

  submitComboUnit = () => {
    console.log("SUBMIT COMBO UNIT", this.state.uiMinMax);
    console.log("currentSeriesSelected", this.state.currentSeriesSelected);
    console.log("existingSelectedItemLabor", this.state.existingAddOnLabors);

    console.log(this.state.unitList);
    console.log(this.state.order);
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let configuredWindow = this.state.windows ? this.state.windows : [];
    let unitList = this.state.unitList;
    let detailUuid = uuid();
    console.log("unitList in combo submit", unitList);

    let priceBase = 0;
    let priceTotal = 0;

    let totalAccessoryCost = 0;
    let frameHeight = 0;
    let frameWidth = 0;
    let mullCharges = {};
    let glassArray = [];

    let inHomeData = this.state.inHomeData;
    let accessories = [];
    let hardwares = [];
    let sashes = [];
    let glassPosition = "Full";
    let gridPosition = "All";
    let windowGridExist = false;

    let gridCode = "";
    let gridStyle = "";
    let profile = "none";
    let lmsGridId = 0;
    let lmsWrapId = 0;
    let gridOption = "";
    let barSectionCode = "";

    let gridPatternCode = "";
    let barSec = "";

    let unitListCopy = this.state.unitList;
    let l;
    let unitCopy;
    let babData = [];
    let finalAttributeArray = [];

    let notes =
      "Item Labor Notes: " +
      (this.state.miscNotes ? this.state.miscNotes : "-") +
      "\nWindow Style: " +
      this.state.uiMinMax.productStyle +
      "\n";

    if (unitList.length === 1) {
      for (l in unitListCopy) {
        unitCopy = unitListCopy[l];
        unitCopy["mulling"] = "";
        unitCopy["mullCharges"] = {};
        unitCopy["mullReinforcment"] = "N";
        unitListCopy[l] = unitCopy;
      }
      this.setState({ units: unitListCopy });
    }

    // this.submitComboUnitToBackend(this.state.order, this.state.unitList);
    //if (unitList.length > 0 && !this.state.isEdit) {
    // RXM8QHE: Commenting isEdit condition because JSON has to created for change order also.
    if (unitList.length > 0) {
      for (var i = 0; i < unitList.length; i++) {
        let accessoryCost = 0;
        let unitObject = {};

        let babAttributes = [];
        if (unitList[i].basePriceAfterTax) {
          priceBase += unitList[i].basePriceAfterTax;
        }
        if (unitList[i].orientation === "Horizontal") {
          frameHeight = unitList[i].height;
          frameWidth += unitList[i].width;
        } else {
          frameHeight += unitList[i].height;
          frameWidth = unitList[i].width;
        }
        let unitDim = {};

        unitDim["height"] = unitList[i].height;
        unitDim["width"] = unitList[i].width;
        unitDim["updateDate"] = currDate;
        unitDim["updateTime"] = currTime;
        unitDim["userNameUpdate"] = this.state.userNameUpdate;

        notes +=
          "Unit " +
          (i + 1) +
          ": " +
          unitList[i].width +
          '" Width, ' +
          unitList[i].height +
          '" Height' +
          "\n";

        if (unitList[i].mullCharges) {
          mullCharges = unitList[i].mullCharges;
        }
        let attributeObject = [];
        if (
          Array.isArray(unitList[i].attributes) &&
          unitList[i].attributes.length
        ) {
          for (let j = 0; j < unitList[i].attributes.length; j++) {
            let obj = {};
            if (
              !Array.isArray(unitList[i].attributes[j]["value"]) ||
              (Array.isArray(unitList[i].attributes[j]["value"]) &&
                unitList[i].attributes[j]["value"].length > 0)
            ) {
              if (
                unitList[i].attributes[j]["category"].includes("Interior Color")
              ) {
                continue;
              }else if(unitList[i].attributes[j]["category"].includes("Exterior Color")){
                priceBase +=unitList[i].attributes[j]["retailPrice"];
                
                continue;
              }
             
              else if (
                !Array.isArray(unitList[i].attributes[j]["value"])
              ) {
              //   let aCost = Math.round ((unitList[i].attributes[j]["price"] *
              //   (1 - parseFloat(discount / 100)) *
              //   (1 + parseFloat(tax / 100))) /
              // (1 - parseFloat(imu / 100)) * 1000)/ 1000;
                accessoryCost +=unitList[i].attributes[j]["retailPrice"];
                  
                // console.log(unitList[i].attributes[j]["value"]);
                // console.log(aCost);
                // obj["retailPrice"] = aCost;
              } else if (Array.isArray(unitList[i].attributes[j]["retailPrice"])) {
                let priceList = unitList[i].attributes[j]["retailPrice"];
                // let aCostArr = [];
                for (let aPrice in priceList) {
                  // let aCost = 
                  // Math.round ((priceList[aPrice] *
                  //   (1 - parseFloat(discount / 100)) *
                  //   (1 + parseFloat(tax / 100))) /
                  // (1 - parseFloat(imu / 100)) * 1000)/ 1000;
                  accessoryCost +=priceList[aPrice];
                  // aCostArr.push(aCost);
                  // obj["retailPrice"] = aCostArr;
                  // console.log(unitList[i].attributes[j]["value"]);
                  // console.log(aCost);
                }
              }
              obj["attribute"] = unitList[i].attributes[j]["value"];

              obj["price"] = unitList[i].attributes[j]["price"];
              obj["retailPrice"] = unitList[i].attributes[j]["retailPrice"];
              obj["date"] = currDate;
              obj["time"] = currTime;
              obj["userNameUpdate"] = this.state.userNameUpdate;
              obj["category"] = unitList[i].attributes[j]["category"];
              if (unitList[i].attributes[j]["category"] === "Bars") {
                obj["numberOfBarsVertical"] = unitList[i].verticalBarsValue
                  ? unitList[i].verticalBarsValue
                  : 0;
                obj["numberOfBarsHorizontal"] = unitList[i].horizontalBarsValue
                  ? unitList[i].horizontalBarsValue
                  : 0;
              }

              attributeObject.push(obj);
            }
          }
        }
        let color = {};
        color["category"] = "Interior Color";
        color["attribute"] = inHomeData["InteriorColorActual"];
        attributeObject.push(color);
        color = {};
        color["attribute"] = inHomeData["ExteriorColorActual"];
        color["category"] = "Exterior Color";
        attributeObject.push(color);

        unitObject["unitNumber"] = i + 1;
        unitObject["dimensions"] = unitDim;
        unitObject["attributes"] = attributeObject;

        unitObject["productStyle"] = unitList[i].windowStyle;
        unitObject["priceListNumber"] = this.state.priceListNumber;
        unitObject["branchNumber"] = this.state.branchNumber;
        console.log("updated Time values", unitList[i].updatedDateTimeValues);
        if (unitList[i].mulling) {
          unitObject["mulling"] = unitList[i].mulling;
          unitObject["mullCharges"] = unitList[i].mullCharges;
        }

        if(unitList[i].updatedDateTimeValues){
          unitObject["roomFloorUpdates"] = unitList[i].updatedDateTimeValues;
        }

        if(unitList[i].height && unitList[i].width){
          unitObject["roughOpeningUpdateDate"] = currDate;
          unitObject["roughOpeningUpdateTime"] = currTime;
          unitObject["userNameUpdate"] = this.state.userNameUpdate;
        }

        if(this.state.selectedItemLabor){
          unitObject["selectItemLaborUpdateDt"] = currDate;
          unitObject["selectItemLaborUpdateTm"] = currTime;
          unitObject["userNameUpdate"] = this.state.userNameUpdate;
        }

        if(this.state.miscNotes){
          unitObject["miscNotesUpdateDt"] = currDate;
          unitObject["miscNotesUpdateTm"] = currTime;
          unitObject["userNameUpdate"] = this.state.userNameUpdate;
        }

        let attributeMap = {};

        if (unitList[i].inHomeAttributes) {
          let gridExist = false;
          let attributes = unitList[i].inHomeAttributes;
          let glass = {};
          let glassPack = {};
          for (let j = 0; j < attributes.length; j++) {
            if (attributeMap.hasOwnProperty(attributes[j]["category"])) {
              attributeMap[attributes[j]["category"]] =
                attributeMap[attributes[j]["category"]] +
                "," +
                attributes[j]["attributeShort"];
            } else {
              attributeMap[attributes[j]["category"]] =
                attributes[j]["attributeShort"];
            }
            if (attributes[j]["category"] === "Glass Pack") {
              glassPack["lmsAccessoryId"] = attributes[j]["accessory"];
              glassPack["category"] = attributes[j]["accessory"];
              glassPack["unitOfMeasurement"] = attributes[j]["accessoryUOM"];
              glassPack["accessCode"] = attributes[j]["accessoryCode"];
              glassPack["accessoryId"] = 0;
              glassPack["desc"] = attributes[j]["attributeShort"];
              glassPack["priceTotal"] = attributes[j]["retailPrice"];
              glassPack["priceBase"] = attributes[j]["retailPrice"];
              glass["glassPack"] = glassPack;
              glass["clearGlass"] = "N";
              glass["unit"] = unitList[i].unitNumber;
            } else if (attributes[j]["category"] === "Glass Sash") {
              glassPosition = attributes[j]["value"].split(" ")[0];
              if (glassPosition === "Both") {
                glassPosition = "Full";
              }
            } else if (attributes[j]["category"] === "Additional Glass Type") {
              let attValue = attributes[j]["attributeShort"];
              if (
                attValue.includes("Obscure") ||
                attValue.includes("EZ Clean")
              ) {
                let obscure = {};

                glass["obscurePosition"] = glassPosition;
                obscure["lmsAccessoryId"] = attributes[j]["accessory"];
                obscure["category"] = "Obscure";
                obscure["unitOfMeasurement"] = attributes[j]["accessoryUOM"];
                obscure["accessCode"] = attributes[j]["accessoryCode"];
                obscure["priceTotal"] = attributes[j]["retailPrice"];
                obscure["priceBase"] = attributes[j]["retailPrice"];
                obscure["accessoryId"] = 0;
                obscure["desc"] = attValue;
                glass["obscure"] = obscure;
                if (!glass["tempered"]) {
                  glass["tempered"] = false;
                }
              } else if (attValue.includes("Tempered")) {
                let tempered = {};

                glass["temperedPosition"] = glassPosition;
                tempered["lmsAccessoryId"] = attributes[j]["accessory"];
                tempered["category"] = "Tempering";
                tempered["unitOfMeasurement"] = attributes[j]["accessoryUOM"];
                tempered["accessCode"] = attributes[j]["accessoryCode"];
                tempered["priceTotal"] = attributes[j]["retailPrice"];
                tempered["priceBase"] = attributes[j]["retailPrice"];
                tempered["accessoryId"] = 0;
                tempered["desc"] = attValue;
                glass["tempering"] = tempered;
                glass["tempered"] = true;
              } else if (
                attValue.includes("Tint") ||
                attValue.includes("Laminated")
              ) {
                let tint = {};

                tint["lmsAccessoryId"] = attributes[j]["accessory"];
                tint["category"] = "Tint";
                tint["unitOfMeasurement"] = attributes[j]["accessoryUOM"];
                tint["accessCode"] = attributes[j]["accessoryCode"];
                tint["priceTotal"] = attributes[j]["retailPrice"];
                tint["priceBase"] = attributes[j]["retailPrice"];
                tint["accessoryId"] = 0;
                tint["desc"] = attValue;
                glass["tint"] = tint;
                if (!glass["tempered"]) {
                  glass["tempered"] = false;
                }
              } else if (
                attValue.includes("Sun Glass") ||
                attValue.includes("Double Strength Glass") ||
                attValue.includes("Sound Control Package")
              ) {
                let energyEfficient = {};

                energyEfficient["lmsAccessoryId"] = attributes[j]["accessory"];
                energyEfficient["category"] = "Energy Efficient";
                energyEfficient["unitOfMeasurement"] =
                  attributes[j]["accessoryUOM"];
                energyEfficient["accessCode"] = attributes[j]["accessoryCode"];
                energyEfficient["priceTotal"] = attributes[j]["retailPrice"];
                energyEfficient["priceBase"] = attributes[j]["retailPrice"];
                energyEfficient["accessoryId"] = 0;
                energyEfficient["desc"] = attValue;
                glass["energyEfficient"] = energyEfficient;
                if (!glass["tempered"]) {
                  glass["tempered"] = false;
                }
              }
            } else if (attributes[j]["category"] === "Grid Sash") {
              gridPosition = attributes[j]["value"].split(" ")[0];
              if (gridPosition === "Both") {
                gridPosition = "All";
              }
            } else if (
              attributes[j]["category"] === "Screens" ||
              attributes[j]["category"] === "Frame" ||
              attributes[j]["category"] === "Spacer" ||
              attributes[j]["category"] === "Performance Option"
            ) {
              let obj = {};

              obj["lmsAccessoryId"] = attributes[j]["accessory"];
              obj["category"] = attributes[j]["category"].replace(/s$/, "");
              obj["unitOfMeasurement"] = attributes[j]["accessoryUOM"];
              obj["accessCode"] = attributes[j]["accessoryCode"];
              obj["quantity"] = 1;
              obj["accessoryId"] = 0;
              obj["desc"] = attributes[j]["attributeShort"];
              obj["priceTotal"] = attributes[j]["retailPrice"];
              obj["priceBase"] = attributes[j]["retailPrice"];
              obj["unit"] = unitList[i].unitNumber;
              accessories.push(obj);
            } else if (attributes[j]["category"] === "Oriel Ratio") {
              inHomeData["orielSplit"] = attributes[j]["value"];
            } else if (attributes[j]["category"] === "Hardware") {
              let hardware = {};
              hardware["lmsHardwareId"] = attributes[j]["accessory"];
              hardware["category"] = "Other";
              hardware["unitOfMeasurement"] = attributes[j]["accessoryUOM"];
              hardware["accessCode"] = attributes[j]["accessoryCode"];
              hardware["hardwareId"] = 0;
              hardware["quantity"] = 1;
              hardware["colorCode"] = inHomeData["InteriorColorCode"];
              hardware["color"] = inHomeData["InteriorColor"];

              hardware["colorSpecCode"] = inHomeData["InteriorColorSpecCode"];
              hardware["desc"] = attributes[j]["attributeShort"];
              hardware["priceTotal"] = attributes[j]["retailPrice"];
              hardware["priceBase"] = attributes[j]["retailPrice"];
              hardware["detailId"] = 0;
              hardware["unit"] = unitList[i].unitNumber;
              hardwares.push(hardware);
            } else if (
              attributes[j]["category"] === "Grid" &&
              attributes[j]["value"].toLowerCase() !== "no grid"
            ) {
              gridExist = true;
              windowGridExist = true;
              lmsGridId =
                attributes[j]["lmsGridId"] &&
                attributes[j]["lmsGridId"] !== null
                  ? attributes[j]["lmsGridId"]
                  : 0;
              let attValue = attributes[j]["attributeShort"];
              let verticalBars = unitList[i].verticalBarsValue
                ? unitList[i].verticalBarsValue
                : 0;
              let horizontalBars = unitList[i].horizontalBarsValue
                ? unitList[i].horizontalBarsValue
                : 0;
              let sashPriceTotal = attributes[j]["retailPrice"];
              gridCode = attributes[j]["gridCode"];
              gridStyle = attributes[j]["gridStyle"];
              profile = attributes[j]["profile"];

              if (gridStyle === "GBG") {
                gridOption = "Grids Between Glass";
              } else if (gridStyle === "SDL") {
                gridOption = "Simulated Divided Light";
              }

              if (attValue.includes("Colonial")) {
                gridPatternCode = "C";
                profile = "Colonial";
                if (verticalBars + horizontalBars > 0) {
                  // RXM8QHE: Client asked to remove this formula
                  // sashPriceTotal =
                  //   attributes[j]["price"] * (verticalBars + horizontalBars);
                }
              } else if (attValue.includes("Perimeter")) {
                verticalBars = 2;
                horizontalBars = 2;
                gridPatternCode = "P";
                profile = "Perimeter";
              } else if (attValue.includes("Prarie")) {
                verticalBars = 2;
                horizontalBars = 2;
                gridPatternCode = "PR";
                profile = "Prarie";
              }
              barSec = "";
              barSectionCode = "";
              if (attValue.includes("SDL")) {
                barSec = "SDL";
                barSectionCode = "S";
                console.log("inside 1");
              } else if (attValue.includes("Sculptured")) {
                barSectionCode = "S";
                barSec = "Sculptured";
                console.log("inside 2");
              } else if (attValue.includes("Flat")) {
                barSec = "Flat";
                barSectionCode = "F";
                console.log("inside 3");
              }
              // alert(barSec);
              console.log(attValue);

              let sash = {
                uom: "Per Sash",
                sashId: 0,
                numberOfBarsVertical: verticalBars,
                lmsGridId: lmsGridId,
                barSection: barSec,
                priceBase: attributes[j]["retailPrice"],
                pattern: profile,
                openingId: 0,
                detailId: 0,
                gridOption: gridOption,
                numberOfBarsHorizontal: horizontalBars,
                priceTotal: sashPriceTotal,
                unitCount: 1,
                unit: unitList[i].unitNumber,
              };
              console.log("sash", sash);
              sashes.push(sash);
              if (unitList[i]["windowStyle"].includes("Double Hung")) {
                // numberOfSashes = 2;
                sash = {
                  
                  sashId: 0,
                  numberOfBarsVertical: verticalBars,
                  lmsGridId: lmsGridId,
                  barSection: barSec,
                  priceBase: 0,
                  pattern: profile,
                  openingId: 0,
                  detailId: 0,
                  gridOption: gridOption,
                  numberOfBarsHorizontal: horizontalBars,
                  priceTotal: 0,
                  unitCount: 1,
                  unit: unitList[i].unitNumber,
                };
                
                sashes.push(sash);
              }

              // for (let k = 0; k < numberOfSashes; k++) {
              //   sashes.push(sash);
              // }
            } else if (attributes[j]["category"] === "Handing") {
              if (sashes.length === 0) {
                let sash = {
                  sashId: 0,
                  numberOfBarsVertical: 0,
                  lmsGridId: 0,
                  priceBase: 0,
                  handing: attributes[j]["value"],
                  openingId: 0,
                  detailId: 0,
                  numberOfBarsHorizontal: 0,
                  priceTotal: 0,
                  unitCount: 0,
                  unit: unitList[i].unitNumber,
                };
                console.log("sash", sash);
                let numberOfSashes = 1;
                if (unitList[i]["windowStyle"].includes("Double Hung")) {
                  numberOfSashes = 2;
                }

                for (let k = 0; k < numberOfSashes; k++) {
                  sashes.push(sash);
                }
              } else {
                for (let k = 0; k < sashes.length; k++) {
                  let sash = sashes[k];
                  if (sash.unit === unitList[i].unitNumber) {
                    sash["handing"] = attributes[j]["value"];

                    console.log("current sash array", sashes);
                    sashes[k] = sash;
                  }
                }
              }
            }

            if (
              !gridExist &&
              sashes &&
              sashes.length === 0 &&
              j + 1 === attributes.length
            ) {
              let sash = {
                sashId: 0,
                numberOfBarsVertical: 0,
                lmsGridId: 0,
                priceBase: 0,
                openingId: 0,
                detailId: 0,
                numberOfBarsHorizontal: 0,
                priceTotal: 0,
                unitCount: 0,
                unit: unitList[i].unitNumber,
              };
              console.log("sash", sash);
              let numberOfSashes = 1;
              if (unitList[i]["windowStyle"].includes("Double Hung")) {
                numberOfSashes = 2;
              }

              for (let k = 0; k < numberOfSashes; k++) {
                sashes.push(sash);
              }
            }
          }
          finalAttributeArray.push(attributeMap);
          console.log("glass after loop", glass);
          if (glass["glassPack"]) {
            glassArray.push(glass);
          }
          let aObject = {};
          aObject[unitList[i].windowStyle] = attributeObject;
          babAttributes.push(aObject);
          console.log("attributes for bab", babAttributes);
          unitObject["accessoryCost"] = accessoryCost;
          babData.push(unitObject);
          totalAccessoryCost += accessoryCost;
        }

        //unitDescription = unitDescription + unitList[i].windowStyle + " | ";
      }
    }

    console.log("item labor in submit", this.state.selectedItemLabor);

    // this.submitComboUnitToBackend(this.state.order, this.state.unitList);
    // Adding labor imu to base price instead of directly adding to the total
    priceBase = priceBase + (this.state.laborRate / (1 - parseFloat(this.state.laborImu/100)));
    priceBase = Math.round (priceBase * 100)/ 100;
    priceTotal = priceBase + totalAccessoryCost;

    if (this.state.defaultLabor) {
      // lead safe in default labor
      priceTotal += this.state.defaultLabor;
    }

    
    let addOnlaborCost = 0;
    if(this.state.selectedItemLabor){
      for(let l =0; l<this.state.selectedItemLabor.length; l++){
        // Checking if already wrap labor exist, don't consider her
        if(this.state.selectedItemLabor[l].specCode === 'WRAP'){
          continue;
        }
        addOnlaborCost+= this.state.selectedItemLabor[l].priceTotal;
      }
    }

    if (addOnlaborCost) {
      priceTotal += addOnlaborCost;
    }

    if (Object.keys(mullCharges).length > 0) {
      console.log(" Mull charges ", mullCharges);
      accessories.push(mullCharges);
      priceTotal = priceTotal + mullCharges.priceTotal;
    }
    //debugger;
    // if wrap color exist for the order add wrap labor:
    if(this.props.location?.selectedWrapColor && this.state.wrapLaborJSON){
      let wrapExist = false;
      if(this.state.selectedItemLabor){
        
        for(let l =0; l<this.state.selectedItemLabor.length; l++){
          // Checking if already wrap labor exist
          if(this.state.selectedItemLabor[l].specCode === 'WRAP'){
            wrapExist = true;
            break;
          }
        }
        if(!wrapExist){
          this.state.selectedItemLabor.push(this.state.wrapLaborJSON);
          
          
        }
        lmsWrapId = this.state.wrapLaborJSON.lmsLaborId;
        
      }else{
        let laborArr = [];
        laborArr.push(this.state.wrapLaborJSON);
        lmsWrapId = this.state.wrapLaborJSON.lmsLaborId;
        this.setState({ selectedItemLabor: laborArr });
        
      }
      
      priceTotal = priceTotal + this.state.wrapLaborJSON.priceTotal;
    }

    // adding default labor and labor imu

    console.log(
      " getLaborRateAndImuCalculation  ",
      this.state.laborRate,
      " , ",
      this.state.laborImu
    );

    //priceTotal = priceTotal + (this.state.laborRate / (1 - parseFloat(this.state.laborImu/100)));

    

    console.log("glass framed ", glassArray);
    // If no glass pack was selected
    if (glassArray.length === 0) {
      let glass = {
        glassPack: {
          lmsAccessoryId: "STD",
          category: "STD",
          priceTotal: 0,
          accessCode: "STD",
          accessoryId: 0,
          desc: "Standard",
          priceBase: 0,
        },
        priceBase: 0,
        clearGlass: "N",
        detailId: 0,
        glassId: 0,
        tempered: false,
        priceTotal: 0,
        unit: 1,
      };
      glassArray.push(glass);
    }
    let differenceArray = [];
    let differenceMap = {};
    console.log("finalAttributeArray", finalAttributeArray);

    // identifying the difference in attributes
    if (finalAttributeArray.length === 2) {
      let attributeMap1 = finalAttributeArray[0];
      let attributeMap2 = finalAttributeArray[1];
      Object.keys(attributeMap1).forEach(function (key) {
        if (attributeMap2.hasOwnProperty(key)) {
          attributeMap1[key].split(",").forEach((a) => {
            if (!attributeMap2[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap1[key];
        }
      });
      console.log("differenceMap1", differenceMap);
      differenceArray.push(differenceMap);
      differenceMap = {};
      Object.keys(attributeMap2).forEach(function (key) {
        if (attributeMap1.hasOwnProperty(key)) {
          attributeMap2[key].split(",").forEach((a) => {
            if (!attributeMap1[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap1[key];
        }
      });
      console.log("differenceMap2", differenceMap);
      differenceArray.push(differenceMap);
    }

    if (finalAttributeArray.length === 3) {
      let attributeMap1 = finalAttributeArray[0];
      let attributeMap2 = finalAttributeArray[1];
      let attributeMap3 = finalAttributeArray[3];
      Object.keys(attributeMap1).forEach(function (key) {
        if (attributeMap2.hasOwnProperty(key)) {
          attributeMap1[key].split(",").forEach((a) => {
            if (!attributeMap2[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap1[key];
        }

        if (attributeMap3.hasOwnProperty(key)) {
          attributeMap1[key].split(",").forEach((a) => {
            if (!attributeMap3[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap1[key];
        }
      });
      console.log("differenceMap1", differenceMap);
      differenceArray.push(differenceMap);
      differenceMap = {};
      Object.keys(attributeMap2).forEach(function (key) {
        if (attributeMap1.hasOwnProperty(key)) {
          attributeMap2[key].split(",").forEach((a) => {
            if (!attributeMap1[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap2[key];
        }

        if (attributeMap3.hasOwnProperty(key)) {
          attributeMap2[key].split(",").forEach((a) => {
            if (!attributeMap3[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap2[key];
        }
      });
      console.log("differenceMap2", differenceMap);
      differenceArray.push(differenceMap);
      differenceMap = {};
      Object.keys(attributeMap3).forEach(function (key) {
        if (attributeMap1.hasOwnProperty(key)) {
          attributeMap3[key].split(",").forEach((a) => {
            if (!attributeMap1[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap3[key];
        }

        if (attributeMap2.hasOwnProperty(key)) {
          attributeMap3[key].split(",").forEach((a) => {
            if (!attributeMap2[key].includes(a)) {
              differenceMap[key] = differenceMap[key]
                ? differenceMap[key] + ", " + a
                : a;
            }
          });
        } else {
          differenceMap[key] = attributeMap3[key];
        }
      });
      console.log("differenceMap3", differenceMap);
      differenceArray.push(differenceMap);
    }
    var diffString = "";
    var u = 0;
    // RXM8QHE: Sending the entire array of accessories instead of difference as per new requirement
    finalAttributeArray.forEach((d) => {
      if (d !== {}) {
        diffString +=
          "\n Unit " +
          ++u +
          ": " +
          JSON.stringify(d)
            .replaceAll(",", ", ")
            .replaceAll('"', "")
            .replace("{", "")
            .replace("}", "");
      }
    });
    if (diffString) {
      notes += "Accessories: " + diffString;
    }

    console.log("differenceArray", differenceArray);
    let window;

    if (unitList.length > 0) {
      this.setState({ newWindowTotal: priceTotal });
      window = {
        exteriorWindowColorActual: inHomeData["ExteriorColor"],
        priceBase: priceBase,
        lmsWrapId: lmsWrapId,
        floor: unitList[0].floor,
        lmsProductId: 2,
        gradeCode: "PG1",
        styleCommon2: this.state.uiMinMax.styleCommon2,
        gridCode: gridCode,
        typesAvailable: this.state.uiMinMax.lmsCode,
        priceKey: "1",
        exteriorWindowColorDesc: inHomeData["ExteriorColor"],
        babData: babData,
        specialPricingRequest: {
          insertWidth: frameWidth,
          wrap: 0,
          specialPricingRequestId: 0,
          metalRip: 0,
          insertHeight: frameHeight,
          detailId: 0,
          enabled: false,
          retailPrice: 0,
          fullFrameHeight: frameHeight + 3,
          fullFrameWidth: frameWidth + 2,
        },
        exteriorGridColorDesc: windowGridExist
          ? inHomeData["ExteriorColor"]
          : "", // For PlyGem Grid color would be window color
        interiorWindowColorDesc: inHomeData["InteriorColor"],
        interiorWindowColor: inHomeData["InteriorColorCode"],
        vinylOrWood: 1,
        exteriorGridColorSpecSheet: windowGridExist
          ? inHomeData["ExteriorColorSpecCode"]
          : "",
        exteriorCommonGridColor: windowGridExist
          ? inHomeData["ExteriorColor"]
          : "",
        seatBoard: false,
        gridOption: gridOption,
        interiorCommonGridColor: windowGridExist
          ? inHomeData["InteriorColor"]
          : "",
        gridTypeCode: gridStyle,
        interiorCommonWindowColor: windowGridExist
          ? inHomeData["InteriorColor"]
          : "",
        labors: this.state.selectedItemLabor
          ? this.state.selectedItemLabor
          : [],
        productStyle: this.state.uiMinMax.productStyle,
        standardWindowPromotion: false,
        detailUuid: detailUuid,
        room: unitList[0].room,
        interiorGridColorSpecSheet: windowGridExist
          ? inHomeData["InteriorColorSpecCode"]
          : "",
        bayAngle: 0,
        gridPattern: profile,
        tileStates: [
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isSelected: true,
            isConfigured: true,
            isApplyAllDenied: false,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            applyAllDenied: false,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isSelected: true,
            isConfigured: true,
            isApplyAllDenied: false,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            applyAllDenied: false,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
          {
            isApplyAllDenied: false,
            isConfigured: true,
            selected: true,
            warning: false,
            configured: true,
            hasWarning: false,
            isSelected: true,
          },
        ],
        flankerLength: 0,
        lmsTieSoffitId: 0,
        widthOfOverhang: -1,
        lmsVendorId: 28,
        selectedSashButtonTag: -1,
        interiorGridColor: windowGridExist
          ? inHomeData["InteriorColorCode"]
          : "",
        accessories: accessories,
        width: frameWidth,
        eligibleBranchPromotion: false,
        exteriorGridColorActual: windowGridExist
          ? inHomeData["ExteriorColor"]
          : "",
        lmsItemId: this.state.uiMinMax.productCode,
        openingUuid: this.state.openingUuid,
        gridPosition: gridPosition,
        styleCommon3: this.state.uiMinMax.styleCommon3,
        interiorGridColorActual: windowGridExist
          ? inHomeData["InteriorColor"]
          : "",
        line: this.state.isEdit
          ? this.state.lineId
          : configuredWindow.length + 1,
        quoteId: 0,
        numberOfPockets: 0,
        barSectionCode: barSectionCode,
        eligibleNationalPromotion: false,
        size: 2000,
        lmsMetalRipId: 0,
        commonVendor: "Ply Gem Windows",
        exteriorCommonWindowColor: inHomeData["ExteriorColor"],
        gradeDesc: "PlyGem 2000",
        interiorWindowColorActual: inHomeData["InteriorColor"],
        exteriorWindowColorSpecSheet: inHomeData["ExteriorColorSpecCode"],
        specialPricingRequestItems: [],
        styleCommon1: this.state.uiMinMax.styleCommon1,
        oldInstallType: "Install Type: Insert",
        exteriorWindowColor: inHomeData["ExteriorColorCode"],
        isSelected: true,
        gridPatternCode: gridPatternCode,
        interiorGridColorDesc: windowGridExist
          ? inHomeData["InteriorColor"]
          : "",
        detailId: 0,
        notes: notes,
        lmsBuildToRoofId: 0,
        sashes: sashes,
        interiorWindowColorSpecSheet: inHomeData["InteriorColorSpecCode"],
        babSeries: this.state.currentSeriesSelected,
        sizeAbbr: "2000",
        barSection: barSec,
        type: this.state.uiMinMax.lmsCode,
        priceTotal: priceTotal,
        customWrapColor: this.props.location?.selectedWrapColor
          ? this.props.location.selectedWrapColor
          : "",
        installType: "Install Type: Insert",
        eligibleNationalPriceTotal: priceTotal,
        glasses: glassArray,
        seriesAvailable: "2000",
        topOfWindowToSoffit: -1,
        dimensionType: "C",
        commonSeries: "PlyGem 2000",
        specCode: this.state.uiMinMax.productStyleCode,
        priceDriver: "WDZ",
        lmsVendorIds: "28,985",
        exteriorGridColor: windowGridExist
          ? inHomeData["ExteriorColorCode"]
          : "",
        hardwares: hardwares,
        height: frameHeight,
        allSashesSelected: true,
        numberOfTracks: 0,
        eligibleBranchPriceTotal: priceTotal,
        mulled: this.state.uiMinMax.productStyle.includes("|") ? true : false,
        egress: "",
      };
      if (inHomeData["orielSplit"]) {
        window["orielSplit"] = inHomeData["orielSplit"];
      }
      if (this.state.leadSafeFlag && this.state.leadSafeJSON) {
        window['leadSafe'] = this.state.leadSafeJSON;
      }

      window["babDefaultLabor"] = this.state.laborRate;
      window["babLaborImu"] = this.state.laborImu;
      window["babWrapLabor"] =  this.state.wrapLaborJSON;
      if (!this.state.isEdit) {
        window["babWindowId"] =
          "CHG-" + Math.floor(Math.random() * (999 - 100 + 1) + 100);
        configuredWindow.push(window);
        window["babUpdateWindowDate"] = currDate;
        window["babUpdateWindowTime"] = currTime;
        window["babUserNameUpdate"] = this.state.userNameUpdate;

      } else {
        window["babWindowId"] = this.props.location.babWindowId;
        configuredWindow[this.state.lineId - 1] = window;

        this.setState({
          existingItemToEdit: window,
        });
      }
    }

    console.log("after all logic", unitList);
    this.setState({
      modalVisible: false,
      redirect: true,
      windows: configuredWindow,
      changeOrderConfig: unitList,
    });
  };
  onCancel = () => {
    this.setState({ redirect: false, modalVisible: false });
  };

  extractValue(arr, prop) {
    let extractedValue = arr.map((item) => item[prop]);
    return extractedValue;
  }

  diffArray(a, b) {
    let u = a.slice();
    b?.forEach((e) => {
      if (u.indexOf(e) > -1) {
        console.log("e", e);
        delete u[u.indexOf(e)];
      } else {
        u.push(e);
      }
    });
    console.log("u value", u);

    return u.filter((x) => {
      return x != null;
    });
  }

  async submitComboUnitToBackend(orderId, unitList) {
    let productId = "";
    let unit = {};

    let unitDescription = "";

    if (unitList.length > 0) {
      for (var i = 0; i < unitList.length; i++) {
        unit.Room = unitList[i].room;
        unit.Floor = unitList[i].floor;
        unitDescription = unitDescription + unitList[i].windowStyle + " | ";
      }
    }

    unit.UnitDescription = unitDescription;
    unit.productId = productId;
    unit.Components = unitList;
  }

  async componentDidMount() {
    let stepsCopy = this.state.steps;
    //console.log('Existing Order JSON Inside Stepper: ' , this.state.existingOrderJSON);
    console.log(
      "Existing Item To Edit Inside Stepper: ",
      this.state.existingItemToEdit
    );
    if (!this.state.isEdit) {
      this.setState({ isNextBtnDisable: true });
    }
    stepsCopy[0].content = (
      <ChangeOrderStyles
        //currentWindowStyleSelected={"DH|dH"}
        comboToStepper={this.comboToStepper}
        numberOfUnit={2}
        existingFloor={this.props.location.existingItemToEdit?.floor}
        existingRoom={this.props.location.existingItemToEdit?.room}
        currentWindowStyleSelected={
          this.props.location.existingItemToEdit?.productStyle
        }
        vinylOrWood={this.props.location.existingItemToEdit?.vinylOrWood}
        currentSeriesSelected={this.state.currentSeriesSelected}
        currentNoOfUnitSelected={this.getExistingUnitCount(
          this.props.location.existingItemToEdit?.productStyle
        )}
        userNameUpdate={this.props.location.userNameUpdate}
        user={this.props.location.user}
        /* awd01o the above pass exisiting values to the Style page */
        units={units}
        showUnits={true}
        accessToken={this.state.accessToken}
        currentOpeningWidth={this.state.currentOpeningWidth}
        currentOpeningHeight={this.state.currentOpeningHeight}
        series={this.props.location.series}
        material={this.props.location.material}
        products={this.props.location.products}
        productSeries={this.props.location.productSeries}
        pricingTaxForAllProducts={this.props.location.pricingTaxForAllProducts}
        modifiedTimeStampObj={this.props.location.modifiedTimeStampObj}
      />
    );
    this.setState({ steps: stepsCopy });
    //console.log("aaaaaaaaaa", this.state.accessToken);

    if (localStorage) {
      localStorage.setItem("accessToken", this.state.accessToken);
    }
  }

  render() {
    const { current } = this.state;
    console.log(current);
    console.log(
      "this.state.selectedItemLabor in render",
      this.state.selectedItemLabor
    );

    //let steps = this.state.steps;
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "changeOrder",
            state: {
              windows: this.state.windows,
              labors: this.state.labors,
              isEdit: this.state.isEdit,
              leadNumber: this.state.leadNumber,
              lineId: this.state.lineId,
              addedWindowInExistingOrder: !this.state.isEdit,
              newWindowTotalPrice: this.state.newWindowTotal,
              selectedWrapColor: this.state.selectedWrapColor,
              existingAddOnLabors: this.state.existingAddOnLabors,
              miscItemLabor: this.state.miscItemLabor,
              miscNotes: this.state.miscNotes,
              existingItemToEdit: this.state.existingItemToEdit,
              jobMiscNotes: this.state.jobMiscNotes,
              authenticated: this.state.authenticated,
              itemLaborData: this.state.itemLaborsData,
              defaultLaborsData: this.state.defaultLaborsData,
              laborImu: this.state.laborImu,
              laborRate: this.state.laborRate,
              jobLaborUpdates: this.state.jobLaborUpdates,
              deleteLaborUpdates: this.state.deleteLaborUpdates,
              laborQuantityUpdate: this.state.laborQuantityUpdate,
              wrapColorUpdate: this.state.wrapColorUpdate,
              deleteWindowUpdate: this.state.deleteWindowUpdate,
              miscNotesUpdate: this.state.miscNotesUpdate,
              userName: this.state.userNameUpdate,
              user: this.state.user,

            },
          }}
        />
      );
    }
    return (
      <>
        <PageHeader>
          <img class="homedepot-logo" alt="homedepot-logo" src={LogoName}></img> HDE Configurator{" "}
          {this.state.leadNumber ? "(" + this.state.leadNumber + ")" : ""}
        </PageHeader>
        

        {/* <Button
          type="primary"
          size="large"
          style={{ float: "center", margin: "0 350px 25px" }}
          onClick={() => this.setState({ redirect: true })}
        >
          {" "}
          Home
        </Button> */}
        <div class="main">
        <HomeOutlined  size="large" style={{ float: "center", margin: "0 350px 25px", color: "#f96302", fontSize: '30px' }} 
        
        onClick={() => this.setState({ redirect: true })} />
          <Row>
            {/*<Col><PlyMenu></PlyMenu></Col>*/}

            <Col>
              <Steps size="default" current={this.state.current}>
                {this.state.steps.map((item) => (
                  <Step
                    key={item.title + `${new Date().getTime()}`}
                    title={item.title}
                  />
                ))}
              </Steps>
              <div className="steps-content">
                {this.state.current === this.state.steps.length - 1 && (
                  <Button
                    type="link"
                    size="large"
                    onClick={() => this.onSubmit()}
                  >
                    <ShoppingCartOutlined
                      style={{ fontSize: "32px", color: "#f96302" }}
                    />
                  </Button>
                )}

                {this.state.steps.map(({ title, content }, i) => (
                  <div
                    key={title}
                    className={
                      i === this.state.current ? "page fade-in" : "page"
                    }
                  >
                    {content}
                  </div>
                ))}
                {/*<div className="steps-content">{steps[current].content} </div>*/}
              </div>
              <div className="steps-action">
                {this.state.current > 0 && (
                  <Button
                    type="primary"
                    style={{ margin: "0 8px" }}
                    size="large"
                    onClick={() => this.prev()}
                  >
                    Previous
                  </Button>
                )}
                {this.state.current < this.state.steps.length - 1 && (
                  <Button
                    type="primary"
                    size="large"
                    disabled={this.state.isNextBtnDisable}
                    onClick={() => this.next()}
                  >
                    Next
                  </Button>
                )}
                {this.state.current === this.state.steps.length - 1 && (
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    size="large"
                    disabled={this.state.isSubmitEnable}
                    onClick={() => this.submitComboUnit()}
                  >
                    Update to Cart
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
