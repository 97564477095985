import {
  PageHeader,
  Input,
  List,
  Card,
  Image,
  Modal,
  InputNumber,
  Button,
  Space,
  TreeSelect,
  Table,
  Row,
  Col,
  Spin,
  Select,
  Divider,
  Switch,
  Alert,
} from "antd";
import { v4 as uuid } from "uuid";
import jwt_decode from "jwt-decode";
import { DeleteFilled, FolderOpenOutlined } from "@ant-design/icons";
import imageMap from "./images";
import LogoName from "./images/home-depot.svg";
import "./index.css";
import { getUTCTimestamp, needsDataRefresh } from "./Utilities";
import { errorHandler } from "./gcpLogger";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { JSOpenid } from "@homedepot/js-openid";

/*awd01o*/


const { Search } = Input;
const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;
const { TextArea } = Input;
var today = new Date();
var date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
var time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//"2021-12-14T14:26:57+0530",
const dateTime = date + "T" + time + "-0500";
const packageUuid = uuid();
const quoteUuid = uuid();
// /*awd01o*/
// const OPENID_CONNECT_URL = "https://identity-qa.homedepot.com";
// const OPENID_CLIENT_ID = "spiffe://homedepot.dev/hde-configurator"; //our spiffe
// const OPENID_CLIENT_SECRET = "13db03ab-c40a-4025-b373-64ae45a52194"; //our secret
// const OPENID_SCOPE = "openid";
//const REDIRECT_URI = "http://localhost:3000/changeOrder";
const LOGOUT_REDIRECT_URI = `${window.location.origin}/changeOrder`; //?????? what should this be
/************************************/

let errorMessage = "";
export default class ChangeOrder extends Component {
  constructor(props) {
    super(props);
    console.log("change order info", this.props);

    this.state = {
      authenticated:
        process.env.REACT_APP_ENABLE_SSO === "true"
          ? this.props.location.state?.authenticated
          : true,
      isSearch: false,
      isNoData: false,
      isSaving: false,
      /*awd01o existingOrder is the entire JSON, existingItem is the specific window to pass to configuration screens */
      existingOrderJSON: {},
      generateVOS: false,
      /************************************/
      configuredWindows: this.props.location.state?.windows,
      configuredLabors: this.props.location.state?.labors,
      itemMiscNotes: this.props.location.state?.jobMiscNotes
        ? this.props.location.state.jobMiscNotes
        : "",
      selectedWrapColor: this.props.location.state?.selectedWrapColor,
      isEdit: this.props.location.state?.isEdit,
      leadNumber: this.props.location.state?.leadNumber,
      existingItemToEdit: this.props.location.state?.existingItemToEdit,
      lineId: this.props.location.state?.lineId,
      newWindowTotalPrice: this.props.location.state?.newWindowTotalPrice,
      newWindowInExistingOrderStyle: {
        backgroundColor: "rgba(211,211,211, 0.4)",
        border: 0,
        width: 280,
      },
      existingWindowStyle: {
        width: 280,
      },
      addedWindowInExistingOrder:
        this.props.location.state?.addedWindowInExistingOrder,
      columns: [
        {
          title: "Line Id",
          dataIndex: "LineId",
          width: "5%",
          editable: true,
        },
        {
          title: "Category",
          dataIndex: "Category",
          width: "24%",
          editable: true,
        },
        {
          title: "Existing value",
          dataIndex: "ExistingValue",
          width: "24%",
          editable: true,
        },
        {
          title: "New value",
          dataIndex: "NewValue",
          width: "24%",
          editable: true,
        },
        {
          title: "Date Updated",
          dataIndex: "DateUpdated",
          width: "10%",
          editable: true,
        },
        {
          title: "Time Updated",
          dataIndex: "TimeUpdated",
          width: "20%",
          editable: true,
        },
        {
          title: "User Name",
          dataIndex: "UserName",
          width: "24%",
          editable: true,
        },
      ],
      isMounted: false,
      showLabor: false,
      isAddWindow: true,
      outerJson: {
        favorited: true,
        creditOfferLegalText:
          "With a credit approval for qualifying purchases made on The Home Depot or EXPO Design Center Consume Credit Card." +
          "APR: 17.99%-26.99%.  Minimum interest charge: $2. See card agreement for details including APR applicable to you. Offer valid for consumer" +
          "accounts in good standing; 6 months everyday credit offer is subject to change without notice; see store for details.",
        isUpSell: false,
        basePricePerMonth: 0,
        monthlyPaymentCalculationLegalText: "Equal to Project Amount X 0.02*",
        created: dateTime,
        projectLoanLegalText:
          "*Loan product has four tiers with Term and APR subject to qualifying credit approval: 66 monthly payments at 7.42% APR; 78 monthly payments at 12.86% APR; 90 monthly payments at 16.24% APR; or 114 monthly payments at 19.96% APR. Monthly payments will not exceed $20 per $1000 spent (e.g., $160 per month for an $8000 purchase). APR fixed for the life of the loan. No down payment. See loan agreement for further details.",
        updated: dateTime,
        version: "4",
        packageUuid: packageUuid,
        salesTax: 0,
        name: "Package A",
        status: "valid",
        price: 0,
        letter: "A",
        readOnly: false,
        pricePerMonth: 0.0, //TODO we need to calculate this
        quotes: [
          {
            favorited: false,
            basePricePerMonth: 0,
            commonVendor: "Ply Gem Windows",
            lmsProductId: 2,
            created: dateTime,
            promotionId: 0,
            updated: dateTime,
            groups: [],
            windows: this.props.location.state?.windows,
            babJobLaborUpdates: this.props.location.state?.jobLaborUpdates,
            babDeleteLaborUpdates:
              this.props.location.state?.deleteLaborUpdates,
            babLaborQuantityUpdate:
              this.props.location.state?.laborQuantityUpdate,
            babWrapColorUpdate: this.props.location.state?.wrapColorUpdate,
            babDeleteWindowUpdate:
              this.props.location.state?.deleteWindowUpdate,
            babMiscNotesUpdate: this.props.location.state?.miscNotesUpdate,
            roofs: [],
            quoteId: 0,
            salesTax: 0,
            source: "APP",
            name: "Windows Quote",
            leadNumber: this.props.match.params.leadId,
            gutters: [],
            quoteUuid: quoteUuid,
            insulation: [],
            price: 0,
            readOnly: false,
            productName: "Windows",
            projectId: 0,
            pricePerMonth: 0,
            labors: this.props.location.state?.labors
              ? this.props.location.state.labors
              : [],
            lmsVendorIds: "28,985",
            notes: this.props.location.state?.jobMiscNotes
              ? this.props.location.state.jobMiscNotes
              : "",
          },
        ],
      },
      //Below are regular State variables, we may not need leadNumber here
      labors: [], //TODO: we may need to remove this
      current: 0,
      isModalVisible: false,

      branch: "", // this.props.match.params.branchNumber,
      leadSafeFlag: this.props.match.params.leadSafeFlag,
      priceList: "", //this.props.match.params.priceList,
      coastalFlag: this.props.match.params.coastalFlag,
      userName: this.props.location.state?.userName,
      user: this.props.location.state?.user,
      userSFID: this.props.match.params.userSFID,
      openingUuid: this.props.match.params.openingUuid,
      token: this.props.match.params.token,
      items: [],
      miscLabor: [],
      quantity: Array(20)
        .fill()
        .map((_, idx) => 1 + idx),
      orderId: 0,
      redirect: false,
      isLoading: false,
      value: [],
      //Need to get rid of below
      categoryAttributeMap: JSON.parse(
        localStorage.getItem("categoryAttributeMap")
      ),

      isWrapsSelected: this.props.location.state?.selectedWrapColor
        ? true
        : false,
      itemLaborData: this.props.location.state?.itemLaborData,
      defaultLaborsData: this.props.location.state?.defaultLaborsData,
      modifiedTimeStampObj: this.props.location.state?.modifiedTimeStampObj,
    };
    this.getOrderPrice();

    this.checkValidToken = this.checkValidToken.bind(this);
    console.log("Final outer JSON", this.state.outerJson);
  }

// Pass the URL in GET/POST
/*
  callBackendAPI = async () => {
    const response = await fetch('https://plygem-service-das-ci.techshed-dev.com/getLaborRates');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    return body;
  };
*/
sameOrigin(a, b) {
  const urlA = new URL(a);
  const urlB = new URL(b);
  return urlA.origin === urlB.origin;
}


async getToken () {
  
  const fullUrl = new URL(process.env.REACT_APP_NODE_URL).href;
  //const data = new URLSearchParams();
    const accessTokenResponse = await fetch(fullUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    });
    return accessTokenResponse
};
// async getToken () {
  
//   try {      
//     //const fullUrl = new URL(tokenUrl).href;
//     const data = new URLSearchParams();
//  /*   data.append('grant_type', process.env.REACT_APP_GRANT_TYPE);
//     data.append('client_id', process.env.REACT_APP_CLIENT_ID );
//     data.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
//     data.append('scope', process.env.REACT_APP_SCOPE); */
//     data.append('grant_type', 'client_credentials');
//     data.append('client_id', 'spiffe://homedepot.dev/plygem-ui' );
//     data.append('client_secret', 'eyP8Q~pXkKz5wjuWIz_l3w6h3dul8ITKMSE4tds-');
//     data.append('scope', 'spiffe://homedepot.dev/plygem-service/.default'); 
 
//     const accessTokenResponse = await fetch("https://identity.service.homedepot.dev/oauth2/v1/token", {
//       method: "POST", // *GET, POST, PUT, DELETE, etc.
          
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",            
//       },
//       body:data
//     });
//     //const response = await accessTokenResponse.json();
//     return accessTokenResponse

//   } catch (error) {
//     console.log("Error in plygem-service /getToken", error);
//   }
// }

  async componentDidMount() {
    let accessToken = "none";
    const accessTokenResponse = await this.getToken()

    if(accessTokenResponse.ok){
      accessToken = await accessTokenResponse.json()
      this.setState({ accessToken: accessToken['access_token'] });    
    }
    
    
    this.setState({ isMounted: true });
    this.setState({ isLoading: true });

    /* awd01o redirect to PingFed login and check for required entitlement */
    if (
      process.env.REACT_APP_ENABLE_SSO === "true" &&
      (this.state.authenticated === false ||
        this.state.authenticated === undefined)
    ) {
      
      console.log("OPENID ", process.env.REACT_APP_OPENID_CLIENT_ID);
      const appAuth = new JSOpenid();      
      const res = await appAuth.login({
        connect_url: process.env.REACT_APP_OPENID_CONNECT_URL,
        client_id: process.env.REACT_APP_OPENID_CLIENT_ID,
        client_secret: process.env.REACT_APP_OPENID_CLIENT_SECRET,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        logout_redirect_uri: LOGOUT_REDIRECT_URI,
        scope: process.env.REACT_APP_OPENID_SCOPE,
      });
      this.setState({ token: res });
     
  
      const userInfo = await appAuth.getUserInfo();
/*         console.log("res:", res);
        console.log("token: ", res.access_token.split(".")[1]);
        console.log("userinfo: ", userInfo);
        console.log("userinfo: ", userInfo.roles[0]);
        console.log("role: ", process.env.REACT_APP_ROLE);  */
      if (userInfo.roles.includes(process.env.REACT_APP_ROLE)) {
        //console.log("userinfo: ", userInfo);
        //console.log("approved");
        this.setState({ authenticated: true });
        this.setState({ userName: userInfo.first_name+" "+userInfo.last_name});
        this.setState({ user: userInfo.email});
        
      } else {
        //console.log("userinfo: ", userInfo);
        console.log("denied");
      }
    
    } else {
      this.setState({ authenticated: true });
    }
    this.setState({ isMounted: true });
    /************************************/

    //console.log('LeadID: ' , this.props.match.params.leadId);
    /*this.getExistingOrder(this.props.match.params.leadId);
        this.getOrderItems(this.state.outerJson.quotes);*/ //moved to search
    // await this.getJobLaborData("46", "460");

    if (this.state.isEdit || this.state.addedWindowInExistingOrder) {
      this.setState({ isAddWindow: false });
      await this.getInHomeJSON(this.state.leadNumber, false);
      await this.getExistingOrder(this.state.leadNumber, false);
      this.getChangeOrderSummary();
    } else {
      this.setState({ isLoading: false });
    }

    if (this.state.addedWindowInExistingOrder) {
      let itemsCopy = this.state.items;
      console.log("itemsCopy", itemsCopy);

      let configuredWindowCopy = this.state.configuredWindows;
      console.log("configuredWindowCopy in first load", configuredWindowCopy);
      itemsCopy[0].windows = configuredWindowCopy;

      this.setState({ items: itemsCopy });
    }
    this.getChangeOrderSummary();
  }
  getOrderPrice() {
    let quotePrice = 0;
    let quoteBasePrice = 0;
    if (this.state.outerJson.quotes[0].windows) {
      for (let k = 0; k < this.state.outerJson.quotes[0].windows.length; k++) {
        quoteBasePrice +=
          this.state.outerJson.quotes[0].windows[k]["priceBase"];
        quotePrice += this.state.outerJson.quotes[0].windows[k]["priceTotal"];
        // eslint-disable-next-line
        this.state.selectedWrapColor =
          this.state.outerJson.quotes[0].windows[0]["customWrapColor"];
        // eslint-disable-next-line
        this.state.isWrapsSelected = this.state.outerJson.quotes[0].windows[0][
          "customWrapColor"
        ]
          ? true
          : false;
        if (this.state.isWrapsSelected) {
          this.setState({ showWrapColor: true });
        }
      }
      // eslint-disable-next-line
      this.state.outerJson.basePricePerMonth = quoteBasePrice * 0.02;
      let configuredWinLaborTotal = 0;
      if (this.state.outerJson.quotes[0].labors) {
        for (var j = 0; j < this.state.outerJson.quotes[0].labors.length; j++) {
          configuredWinLaborTotal +=
            this.state.outerJson.quotes[0].labors[j].priceTotal;
        }
      }
      // eslint-disable-next-line
      this.state.outerJson.price =
        Math.round((quotePrice + configuredWinLaborTotal) * 100) / 100;
      // assumption only 1 quote per lead
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].price =
        Math.round((quotePrice + configuredWinLaborTotal) * 100) / 100;
    }
  }
  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  deleteWindow = (item, lineId) => {
    console.log("Delete Window:", item, lineId);
    let itemsCopy = this.state.items;
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let deleteWindowUpdate = [];
    let deleteWindowCopy = this.state.outerJson.quotes[0].babDeleteWindowUpdate;
    let babWindowId;
    let deleteWindowJson = {};
    let configuredWindowCopy = this.state.configuredWindows;
    console.log("configuredWindowCopy", configuredWindowCopy);
    for (let i = 0; i < configuredWindowCopy.length; i++) {
      if (configuredWindowCopy[i].line === lineId) {
        babWindowId = configuredWindowCopy[i].babWindowId;
        configuredWindowCopy.splice(i, 1);
      }
    }
    // RXM8QHE: Updating line number as for remaining windows
    for (let i = 0; i < configuredWindowCopy.length; i++) {
      configuredWindowCopy[i].line = i + 1;
    }
    // all windows are deleted
    if (configuredWindowCopy.length === 0) {
    }
    deleteWindowJson = {
      babWindowId: babWindowId,
      lineId: lineId,
      deleteWindowDate: currDate,
      deleteWindowTime: currTime,
      userNameUpdate: this.state.userName ? this.state.userName : "-",
    };
    if (deleteWindowCopy) {
      deleteWindowCopy.push(deleteWindowJson);
    } else {
      deleteWindowUpdate.push(deleteWindowJson);
    }
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].babDeleteWindowUpdate = deleteWindowCopy
      ? deleteWindowCopy
      : deleteWindowUpdate;
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].windows = configuredWindowCopy;
    itemsCopy[0].windows = configuredWindowCopy;

    this.setState({ items: itemsCopy });
    // deleted window price is removed from total price
    this.getOrderPrice();
    this.getChangeOrderSummary();
  };

  checkValidToken = () => {
    var token = this.props.match.params.token;
    var tokenIsNotExpired = false;
    if (token) {
      var body = jwt_decode(token);
      var decodedHeader = jwt_decode(token, { header: true });
      if (decodedHeader.typ === "JWT" && body.scope === "scope name") {
        var currentTimestamp = new Date().getTime() / 1000;
        tokenIsNotExpired = body.exp > currentTimestamp;
        return tokenIsNotExpired;
        // return true
      }
    }
    return true;
  };

  onChangeWrapColor = (selectedColor) => {
    console.log("onChange selected options::", selectedColor);
    let wrapColorUpdate = [];
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    this.setState({ selectedWrapColor: selectedColor });

    for (let k = 0; k < this.state.configuredWindows.length; k++) {
      let wrapExist = false;
      // eslint-disable-next-line
      this.state.configuredWindows[k].customWrapColor = selectedColor;
      if (
        this.state.configuredWindows[k].labors &&
        this.state.configuredWindows[k].labors.length > 0
      ) {
        for (
          let l = 0;
          l < this.state.configuredWindows[k].labors.length;
          l++
        ) {
          // Checking if already wrap labor exist
          if (this.state.configuredWindows[k].labors[l].specCode === "WRAP") {
            wrapExist = true;
            break;
          }
        }
        if (!wrapExist && this.state.configuredWindows[k].babWrapLabor) {
          // eslint-disable-next-line
          this.state.configuredWindows[k].labors.push(
            this.state.configuredWindows[k].babWrapLabor
          );
          // eslint-disable-next-line
          this.state.configuredWindows[k].lmsWrapId =
            this.state.configuredWindows[k].babWrapLabor.lmsLaborId;
          // eslint-disable-next-line
          this.state.configuredWindows[k].priceTotal =
            this.state.configuredWindows[k].priceTotal +
            this.state.configuredWindows[k].babWrapLabor.priceTotal;
        }
      } else {
        if (this.state.configuredWindows[k].babWrapLabor) {
          let laborArr = [];
          laborArr.push(this.state.configuredWindows[k].babWrapLabor);
          // eslint-disable-next-line
          this.state.configuredWindows[k].labors = laborArr;
          // eslint-disable-next-line
          this.state.configuredWindows[k].lmsWrapId =
            this.state.configuredWindows[k].babWrapLabor.lmsLaborId;
          // eslint-disable-next-line
          this.state.configuredWindows[k].priceTotal =
            this.state.configuredWindows[k].priceTotal +
            this.state.configuredWindows[k].babWrapLabor.priceTotal;
        }
      }
    }
    wrapColorUpdate.push({
      wrapUpdateDate: currDate,
      wrapUpdateTime: currTime,
      userNameUpdate: this.state.userName ? this.state.userName : "-",
    });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].windows = this.state.configuredWindows;
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].babWrapColorUpdate = wrapColorUpdate;
    this.setState({ isEdit: true });
    this.getOrderPrice();
    this.getChangeOrderSummary();
    console.log("add color", this.state.outerJson.quotes[0].windows);
  };

  onLaborChange = (newValue) => {
    console.log("onChange selected options::", newValue);
    let childrenData = {};
    let labors = {};
    let laborCopy = this.state.items;
    let configuredLaborsTotal = 0;
    let joblaborRatesData = this.state.jobLaborData;
    let miscLabor = [];
    let showLabor = false;
    let value = [];
    let laborChangeUpdates = [];
    let defaultLaborValue = [];
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    //if(laborCopy[0]["labors"] === undefined){
    laborCopy[0]["labors"] = [];
    // }else{
    //laborCopy[0]["labors"].splice(0,newValue.length-1);
    //laborCopy[0]["labors"] = [];
    // }
    let initialLabor = [];
    console.log("onChange laborCopy1::", laborCopy);
    for (var i = 0; i < joblaborRatesData.length; i++) {
      childrenData = joblaborRatesData[i].children;
      console.log("onChange::childrenData", childrenData);

      for (var j = 0; j < childrenData.length; j++) {
        for (var k = 0; k < newValue.length; k++) {
          console.log("selected value", newValue[k], childrenData[j].title);
          if (newValue[k] === childrenData[j].key) {
            value.push(newValue[k]);
            defaultLaborValue.push(newValue[k]);
            console.log("onChange::price", childrenData[j].price);
            labors = {
              quantity: 1,
              laborId: 0,
              modifiedProperty: "1",
              desc: childrenData[j].title,
              priceBase: childrenData[j].price,
              detailId: 0,
              specCode: childrenData[j].specCode,
              laborRule: "",
              unitOfMeasurement: childrenData[j].uom,
              quoteId: 0,
              lmsLaborId: childrenData[j].lmsItemNumber,
              priceTotal: childrenData[j].price,
            };
            initialLabor.push(childrenData[j].title);
            if (
              joblaborRatesData[i].title === "MISCELLANEOUS LABOR OPTIONS" &&
              childrenData[j].title ===
                "VinylLB Miscellaneous Labor - Job Level"
            ) {
              miscLabor.push(childrenData[j].title);

              showLabor = true;
            }

            if (laborCopy[0]["labors"].length >= 0) {
              console.log("onChange labor copy ", laborCopy[0]["labors"]);
              configuredLaborsTotal = configuredLaborsTotal + labors.priceTotal;
              laborCopy[0]["labors"].push(labors);
            }
            break;
          }
        }
      }
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].labors = laborCopy[0]["labors"];
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].notes = this.state.itemMiscNotes;
    }
    laborChangeUpdates.push({
      updateLaborDate: currDate,
      updateLaborTime: currTime,
      userNameUpdate: this.state.userName ? this.state.userName : "-",
    });

    // eslint-disable-next-line
    this.state.outerJson.quotes[0].babJobLaborUpdates = laborChangeUpdates;
    this.setState({ initialLabor: initialLabor });
    this.setState({ isEdit: true });
    this.setState({ miscLabor: miscLabor });
    this.setState({
      showLabor: showLabor,
      value: value,
      defaultLaborValue: defaultLaborValue,
    });
    this.getOrderPrice();
    this.getChangeOrderSummary();
  };

  extractValue(arr, prop) {
    let extractedValue = arr.map((item) => item[prop]);
    return extractedValue;
  }

  diffArray(a, b) {
    if (a !== undefined && b !== undefined) {
      let u = a.slice();
      b?.forEach((e) => {
        if (u.indexOf(e) > -1) delete u[u.indexOf(e)];
        else u.push(e);
      });
      return u.filter((x) => {
        return x != null;
      });
    }
  }

  onLaborDeselect(currentNode, selectedNodes) {
    console.log("on labor deselect ::", currentNode, selectedNodes);
    // let addOnlaborCost = this.state.addOnlaborCost;
    // let updatedItemLabor = this.state.selectedItemLabor;
    // console.log("before removing it", updatedItemLabor);
    // for (let i = 0; i < this.state.selectedItemLabor.length; i++) {
    //   let labor = this.state.selectedItemLabor[i];
    //   console.log("iterated labor", labor);
    //   if (labor.desc === selectedNodes.title) {
    //     addOnlaborCost = addOnlaborCost - labor.priceTotal;
    //     updatedItemLabor.splice(i, 1);
    //   }
    // }
    // console.log("updatedItemLabor", updatedItemLabor);
    // this.setState({
    //   selectedItemLabor: updatedItemLabor,
    //   addOnlaborCost: addOnlaborCost,
    // });
    // this.props.othersToStepper(
    //   this.state.units,
    //   this.state.selectedItemLabor,
    //   this.state.inHomeData,
    //   this.state.addOnlaborCost,
    //   this.state.isSubmitEnable,
    //   this.state.itemMiscNotes
    // );
  }

  deleteLabor() {
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let deleteLaborUpdates = [];
    let itemsCopy = this.state.items;
    itemsCopy[0].labors = [];

    this.setState({ isEdit: true });
    this.setState({ items: itemsCopy });
    this.setState({
      itemMiscNotes: "",
      showLabor: false,
      defaultLaborValue: [],
      value: [],
    });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].labors = [];
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].notes = "";
    deleteLaborUpdates.push({
      deleteIndividualDate: currDate,
      deleteIndividualTime: currTime,
      userNameUpdate: this.state.userName ? this.state.userName : "-",
    });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].babDeleteLaborUpdates = deleteLaborUpdates;
    this.getOrderPrice();
    this.getChangeOrderSummary();
  }

  deleteIndividualLabor(labor) {
    // console.log('this.state.outerJson', this.state.outerJson);
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let itemsCopy = this.state.items;
    let laborCopy = this.state.items[0].labors;
    console.log("this.state.value", this.state.value);
    let valueCopy = this.state.value;
    //let defaultLaborValue = this.state.defaultLaborValue;
    let initialLabor = this.state.initialLabor;
    let deleteLaborUpdates = [];
    let laborKey = "";
    let joblaborRatesData = this.state.jobLaborData;
    // to fetch key of the labor thats deleted, comparing with desc, assumption desc is unique
    for (var i = 0; i < joblaborRatesData.length; i++) {
      let childrenData = joblaborRatesData[i].children;

      for (var j = 0; j < childrenData.length; j++) {
        if (labor.desc === childrenData[j].title) {
          laborKey = childrenData[j].key;
          break;
        }
      }
    }
    console.log("process", this.state.value, laborKey);
    for (var k = 0; k < valueCopy.length; k++) {
      if (valueCopy[k] === laborKey) {
        valueCopy.splice(k, 1);
        // defaultLaborValue.splice(k,1);
        break;
      }
    }
    console.log("process delete", valueCopy);
    for (let i = 0; i < laborCopy.length; i++) {
      if (laborCopy[i].desc === labor.desc) {
        console.log("price after diff", this.state.outerJson.quotes[0].price);
        if (laborCopy[i].desc === "VinylLB Miscellaneous Labor - Job Level") {
          this.setState({
            itemMiscNotes: "",
            showLabor: false,
          });
          // eslint-disable-next-line
          this.state.outerJson.quotes[0].notes = "";
        }
        laborCopy.splice(i, 1);
        break;
      }
    }

    for (let i = 0; i < initialLabor.length; i++) {
      if (initialLabor[i] === labor.desc) {
        initialLabor.splice(i, 1);
        break;
      }
    }

    this.setState({ initialLabor: initialLabor });
    console.log("deleted", labor.desc);
    console.log("itemsCopy", itemsCopy);

    itemsCopy[0].labors = laborCopy;
    deleteLaborUpdates.push({
      deleteIndividualDate: currDate,
      deleteIndividualTime: currTime,
      userNameUpdate: this.state.userName ? this.state.userName : "-",
    });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].babDeleteLaborUpdates = deleteLaborUpdates;

    this.setState({ isEdit: true });
    this.setState({ items: itemsCopy, value: valueCopy });
    this.getOrderPrice();
    this.getChangeOrderSummary();
    console.log("after update", valueCopy);
  }

  submitOrder = async () => {
    //console.log("submitOrder updatedOuterJson", this.state.outerJson);
    this.setState({ isLoading: true });
    await this.createOrderInBackend(
      this.state.leadNumber,
      this.state.outerJson
    );
  };

  generateVOS = () => {
    this.setState({
      generateVOSFlag: true,
    });
  };

  editOrder = (item, lineId, accCst, totalPrice) => {
    //debugger;
    console.log(this.state.dataSource);
    this.setState({ existingItemToEdit: item });
    if (item.line === lineId) {
      this.setState({ oldBabData: item.babData });
    }

    this.setState({ babWindowId: item.babWindowId });

    this.setState({ isEdit: true });
    this.setState({ lineId: lineId });

    this.setState({ redirect: true });
  };

  showModal = () => {
    this.setState({ isModalVisible: true, isEdit: false });
  };

  showLaborModal = () => {
    this.setState({ isLaborModalVisible: true });
  };

  handleOk = () => {
    //this.props.history.push('/combo-unit');
    this.setState({ redirect: true, isModalVisible: false });
  };

  handleLaborOk = () => {
    this.setState({ isLaborModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleLaborCancel = () => {
    this.setState({ isLaborModalVisible: false });
  };

  handleComboVsSingleUnitToggle = () => {};

  searchChanged = (value) => {
    this.setState({ leadNumber: value });
  };

  toCloseAlert() {
    console.log("called timeout 2 sec");

    this.setState({ isSearch: false, isNoData: false });
  }

  onSearch = (value) => {
    console.log("FNumber to Search: ", this.props.location.state);
    /* awd01o Search for existing order in salesforce ex. F24404992 outerJson.quotes */

    if (value) {
      this.setState({ isLoading: true, isSearch: true });
      this.getInHomeJSON(value, true);
      this.getExistingOrder(value, true);
    } else {
      this.setState({ isNoData: true, isSearch: true });
      errorMessage = "Please enter a lead to search.";
      setTimeout(() => this.toCloseAlert(), 2000);
    }

    // Ramya calling this function inside getexistingorder
    //this.getOrderItems(this.state.outerJson.quotes);
    /************************************/
    this.setState({ leadNumber: value });

    /*console.log(value);
        this.setState({ leadNumber: value });
        localStorage.setItem("leadNumber", JSON.stringify(value));
        console.log(this.state.leadNumber);
        this.getOrderedItemsForLead(value);
        console.log(this.state.orderId);*/
  };

  addToCart = () => {
    this.setState({ redirect: true });
    console.log("Inside Edit Order");
  };

  onQuantityChange = (labeledValue, Option) => {
    console.log("labeledValue ", labeledValue, "Option ", Option);
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    if (labeledValue.split("---").length > 1) {
      let selectLabor = labeledValue.split("---")[0];
      let qty = labeledValue.split("---")[1];
      let laborObjCopy = this.state.items;
      let laborQuantityUpdate = [];

      for (let i = 0; i < laborObjCopy[0]["labors"].length; i++) {
        console.log("iterated labor", laborObjCopy[0]["labors"]);
        if (laborObjCopy[0]["labors"][i].desc === selectLabor) {
          laborObjCopy[0]["labors"][i].quantity = parseInt(qty);
          laborObjCopy[0]["labors"][i].priceTotal =
            laborObjCopy[0]["labors"][i].priceBase * parseInt(qty);
        }

        console.log("laborObjCopy", laborObjCopy);
        this.setState({ items: laborObjCopy });
      }
      laborQuantityUpdate.push({
        laborQuantityUpdateDate: currDate,
        laborQuantityUpdateTime: currTime,
        userNameUpdate: this.state.userName ? this.state.userName : "-",
      });
      this.setState({ isEdit: true });
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].labors = laborObjCopy[0]["labors"];
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].babLaborQuantityUpdate =
        laborQuantityUpdate;
      this.getOrderPrice();
      this.getChangeOrderSummary();
    }
  };

  async getCatalogData(user) {
    let modifiedTimeStampObj = {};
    if (this.state.modifiedTimeStampObj) {
      modifiedTimeStampObj = this.state.modifiedTimeStampObj;
    } else {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      let path =  "/getDataModifiedTimestamp"
      const url = new URL(path, apiUrl).href;
      const modifiedTimeStampResponse = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
          User: user ? user : this.state.user,
          'Authorization': 'Bearer ' + this.state.accessToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
      });
      console.log("modifiedTimeStampResponse ok", modifiedTimeStampResponse);
      if (modifiedTimeStampResponse.ok) {
        modifiedTimeStampObj = await modifiedTimeStampResponse.json();
        console.log("modifiedTimeStampResponseJson", modifiedTimeStampObj);
        this.setState({ modifiedTimeStampObj: modifiedTimeStampObj });
      }
    }

    // Get series
    let responseString = "";
    let series;
    let material;
    let products = new Set();
    let productSeries = {};
    console.log("getUTCTimestamp first", getUTCTimestamp());
    console.log("localStorage", localStorage);

    let reloadData = true;
    let seriesData = localStorage.getItem("series");
    if (
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" &&
      seriesData
    ) {
      seriesData = JSON.parse(seriesData);

      reloadData = needsDataRefresh(
        seriesData["timestamp"],
        modifiedTimeStampObj["productSeriesModifiedOn"]
      );

      if (!reloadData) {
        series = seriesData["data"];
        material = JSON.parse(localStorage.getItem("material"));
        products = JSON.parse(localStorage.getItem("products"));
        productSeries = JSON.parse(localStorage.getItem("productSeries"));

        this.setState({ material: Array.from(material) });
        this.setState({ series: Array.from(series) });
        this.setState({ productSeries: productSeries });
        this.setState({ products: products });
      }
    }
    if (reloadData) {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      let path =  "/getSeries"
      const url = new URL(path, apiUrl).href;
      const response = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
          User: user ? user : this.state.user,
          'Authorization': 'Bearer ' + this.state.accessToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
      });
      //console.log("Product Series RESPONSE: ", response);

      if (response.ok) {
        // if HTTP-status is 200-299
        // get the response body (the method explained below)
        responseString = await response.json();
        productSeries = responseString["records"];
        this.setState({ productSeries: productSeries });
        console.log(" productsSeries response ", productSeries);
        let material = new Set();
        for (let record in productSeries) {
          console.log(" record ", productSeries[record]["Series_Material__c"]);
          if (productSeries[record]["Series_Material__c"] !== null) {
            material.add(productSeries[record]["Series_Material__c"]);
          }
        }
        localStorage.setItem("material", JSON.stringify(Array.from(material)));
        this.setState({ material: Array.from(material) });

        //Need to remove the hardcoding and handle it for all series
        //For now we have only one series in DB

        for (let record in productSeries) {
          if (productSeries[record]["Series__c"] !== "") {
            products.add(productSeries[record]["Description"]);
          }
        }
        var set = new Set(products);
        localStorage.setItem("products", JSON.stringify(Array.from(set)));
        this.setState({ products: products });
        let series = new Set();
        /* awd01o Translate material code to string value and get specific series of that material*/
        // if (this.state.vinylOrWood === 1) {
        //   this.setState({ selectedMaterial: "Vinyl" });

        //   //let productSeries = this.state.ProductSeries;
        //   //      let series = new Set();
        //   for (let record in productSeries) {
        //     //console.log("Product Series: ", productSeries[record]["Series_Material__c"]);
        //     if (productSeries[record]["Series_Material__c"] === "Vinyl") {
        //       series.add(productSeries[record]["Series__c"]);
        //     }
        //   }
        //   this.setState({ series: Array.from(series) });
        // }

        for (let record in productSeries) {
          //console.log(" record ", productSeries[record]["Series_Material__c"]);
          // if (
          //   productSeries[record]["Series_Material__c"] ===
          //   this.state.selectedMaterial
          // ) {
          series.add(productSeries[record]["Series__c"]);
          // }
        }
        //console.log(" Series ", Array.from(series));
        localStorage.setItem(
          "series",
          JSON.stringify({
            timestamp: getUTCTimestamp(),
            data: Array.from(series),
          })
        );
        this.setState({ series: Array.from(series) });

        localStorage.setItem(
          "productSeries",
          JSON.stringify(Array.from(productSeries))
        );
        this.setState({ productSeries: productSeries });
      } else {
        // if (response.status === 401) {
        //   window.location.href = ssoLoginUrl;
        // } else if (response.status === 403) {
        //   this.setState({ unauthorized: true, loadingIsActive: false });
        // }
      }
    }
    // get job labor data
    reloadData = true;
    var jobLaborData = [];
    var itemLabors = [];
    var defaultLabors = [];
    let miscLabor = [];
    let laborsData = localStorage.getItem(
      "labors-" + this.state.branch + "-" + this.state.priceList
    );
    if (
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" &&
      laborsData
    ) {
      laborsData = JSON.parse(laborsData);
      reloadData = needsDataRefresh(
        laborsData["timestamp"],
        modifiedTimeStampObj["priceListLaborsModifiedOn"]
      );
      if (!reloadData) {
        jobLaborData = laborsData["data"]["jobLaborData"];
        itemLabors = laborsData["data"]["itemLabors"];
        defaultLabors = laborsData["data"]["defaultLabors"];
        miscLabor = laborsData["data"]["miscLabor"];

        this.setState({
          jobLaborData: jobLaborData,
          itemLaborData: itemLabors,
          defaultLaborsData: defaultLabors,
          miscLabor: miscLabor,
        });
      }
    }
    if (reloadData) {
      const url =
        process.env.REACT_APP_BASE_URL +
        "/getLaborRates?" +
        "branchNumber=" +
        this.state.branch +
        "&priceListNumber=" +
        this.state.priceList;
      const jobLaborResponse = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
          User: user ? user : this.state.user,
          'Authorization': 'Bearer ' + this.state.accessToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
      });
      console.log("labor ok", jobLaborResponse);
      if (jobLaborResponse.ok) {
        var jobAddOnLaborResponseJson = await jobLaborResponse.json();
        console.log("jobAddOnLaborResponseJson", jobAddOnLaborResponseJson);
        var jobLabors = jobAddOnLaborResponseJson["jobLabors"];
        itemLabors = jobAddOnLaborResponseJson["itemLabors"];
        defaultLabors = jobAddOnLaborResponseJson["defaultLabors"];

        var jobLabor;

        var objectMap = {};

        var children = [];
        var i = 0;

        for (jobLabor in jobLabors) {
          children = [];
          var subLabors = jobLabors[jobLabor];
          var subLabor;

          var j = 0;
          for (subLabor in subLabors) {
            //console.log("subLabor ", subLabors )
            //if (subLabors[subLabor].toDimension == 0){
            children.push({
              title: subLabors[subLabor].jobDesc,
              value: 0 + "-" + i + "-" + j,
              key: 0 + "-" + i + "-" + j,
              price: subLabors[subLabor].laborRate,
              lmsItemNumber: subLabors[subLabor].lmsItemNumber,
              specCode: subLabors[subLabor].specCode,
              uom: subLabors[subLabor].uom
                ? subLabors[subLabor].uom
                : "Per Opening",
            });

            if (
              jobLabor === "MISCELLANEOUS LABOR OPTIONS" &&
              children[j].title === "VinylLB Miscellaneous Labor - Job Level"
            ) {
              miscLabor.push(subLabors[subLabor].jobDesc);
            }
            j++;
            // }
          }

          objectMap = {
            title: jobLabor,
            value: 0 + "-" + i,
            key: 0 + "-" + i,
            children: children,
            checkable: false,
          };
          jobLaborData.push(objectMap);
          i++;
        }

        console.log("jobLaborData", jobLaborData);
        console.log("itemLabors", itemLabors);
        if (this.state.isMounted === true) {
          this.setState({
            jobLaborData: jobLaborData,
            itemLaborData: itemLabors,
            defaultLaborsData: defaultLabors,
            miscLabor: miscLabor,
          });
        }
        if (
          jobLaborData.length > 0 &&
          itemLabors !== {} &&
          defaultLabors !== {}
        ) {
          localStorage.setItem(
            "labors-" + this.state.branch + "-" + this.state.priceList,
            JSON.stringify({
              timestamp: getUTCTimestamp(),
              data: {
                jobLaborData: jobLaborData,
                itemLabors: itemLabors,
                defaultLabors: defaultLabors,
                miscLabor: miscLabor,
              },
            })
          );
        }
      } else {
        if (jobLaborResponse.status === 401) {
          window.location.href = "ssoLoginUrl";
        } else if (jobLaborResponse.status === 403) {
          this.setState({ unauthorized: true, loadingIsActive: false });
        }
      }
    }

    // get pricing API
    reloadData = true;
    let priceTaxImuResponseJson;
    let pricingTaxForAllProducts = {};

    let pricingTaxData = localStorage.getItem(
      "pricingTaxForAllProducts-" +
        this.state.branch +
        "-" +
        this.state.priceList
    );
    if (
      process.env.REACT_APP_USE_LOCALSTORAGE_FOR_API === "true" &&
      pricingTaxData
    ) {
      pricingTaxData = JSON.parse(pricingTaxData);
      reloadData = needsDataRefresh(
        pricingTaxData["timestamp"],
        modifiedTimeStampObj["priceListLaborsModifiedOn"]
      );
      if (!reloadData) {
        pricingTaxForAllProducts = pricingTaxData["data"];
      }
    }

    if (reloadData) {
      let priceTaxImuResponse = await fetch(
        process.env.REACT_APP_BASE_URL +
          "/getPricing?" +
          "branchNumber=" +
          this.state.branch +
          "&priceListNumber=" +
          this.state.priceList,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            "Api-Key": process.env.REACT_APP_API_KEY,
            User: user ? user : this.state.user,
            'Authorization': 'Bearer ' + this.state.accessToken
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
        }
      );
      if (priceTaxImuResponse.ok) {
        priceTaxImuResponseJson = await priceTaxImuResponse.json();
        let pricingTaxApi = priceTaxImuResponseJson["products"];

        console.log("pricingTaxApi ", priceTaxImuResponseJson);

        products.forEach((product) => {
          for (let taxImu in pricingTaxApi) {
            if (pricingTaxApi[taxImu]["name"] === product) {
              let imu = pricingTaxApi[taxImu]["pricing"]["imu"];
              let tax = pricingTaxApi[taxImu]["pricing"]["tax"];
              let discount = pricingTaxApi[taxImu]["pricing"]["discount"];
              pricingTaxForAllProducts[product] = {
                imu: imu,
                tax: tax,
                discount: discount,
              };
            }
          }
        });
        console.log("pricingTaxForAllProducts ", pricingTaxForAllProducts);
        if (pricingTaxForAllProducts !== {}) {
          localStorage.setItem(
            "pricingTaxForAllProducts-" +
              this.state.branch +
              "-" +
              this.state.priceList,
            JSON.stringify({
              timestamp: getUTCTimestamp(),
              data: pricingTaxForAllProducts,
            })
          );
        }
      }
    }
    this.setState({ pricingTaxForAllProducts: pricingTaxForAllProducts });
  }

  // RXM8QHE: Getting inHome JSON

  async getInHomeJSON(leadId) {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    let path =  "/ContentVersion?leadId=" + leadId
    const fullUrl = new URL(path, apiUrl).href;
    const existingOrderResponse = await fetch(
      fullUrl,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",          
          User: this.state.user,  
          "Api-Key": process.env.REACT_APP_API_KEY,
          "Authorization": "Bearer " + this.state.accessToken
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    console.log("existingOrderResponse ", existingOrderResponse);
    if (existingOrderResponse.ok && existingOrderResponse.status === 200) {
      var responseJSON = await existingOrderResponse.json();
      this.setState({ isAddWindow: false });
      console.log("Existing inHome JSON : ", responseJSON);
      this.setState({ finalInHomeJSON: responseJSON });
    } else {
      if (existingOrderResponse.status === 403) {
        this.setState({ isLoading: true });
        this.setState({ isAddWindow: false });
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order getInHomeJSON",
          statusCode: existingOrderResponse.status,
          statusMessage: "Access Denied",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (existingOrderResponse.status === 408) {
        this.setState({ isLoading: true });
        this.setState({ isAddWindow: false });
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order getInHomeJSON",
          statusCode: existingOrderResponse.status,
          statusMessage: "Request Time Out",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (existingOrderResponse.status === 204) {
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order getInHomeJSON",
          statusCode: existingOrderResponse.status,
          statusMessage: "No data found for this lead",
        };
        this.setState({ isNoData: true });
        errorMessage = "No data found for this lead.";
        await errorHandler.report(JSON.stringify(errorJson));
        this.setState({ isLoading: false });
        setTimeout(() => this.toCloseAlert(), 2000);
      }
    }
  }

  /* awd01o get existing order for from Salesforce for Change Order Flow */
  async getExistingOrder(leadId, isFirstLoad) {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    let path =  "/changeOrderJSON?leadId=" + leadId
    const url = new URL(path, apiUrl).href;
    const existingOrderResponse = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
         User: this.state.user,
         "Api-Key": process.env.REACT_APP_API_KEY,
        'Authorization': 'Bearer ' + this.state.accessToken
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    if (existingOrderResponse.ok && existingOrderResponse.status === 200) {
      var responseJSON = await existingOrderResponse.json();
      this.setState({ isAddWindow: false });
      console.log("Existing Change Order JSON : ", responseJSON);
      this.setState({ existingOrderJSON: responseJSON });
      if (isFirstLoad) {
        this.setState({ configuredWindows: responseJSON.quotes[0].windows });
        this.setState({ configuredLabors: responseJSON.quotes[0].labors });
        this.setState({ outerJson: responseJSON });
        await this.getOrderItems(responseJSON);
      }

      let quotePrice = 0;
      let quoteBasePrice = 0;

      if (this.props.location.state) {
        for (let k = 0; k < this.props.location.state.windows.length; k++) {
          quoteBasePrice += this.props.location.state.windows[k]["priceBase"];
          quotePrice += this.props.location.state.windows[k]["priceTotal"];
          // eslint-disable-next-line
          this.state.selectedWrapColor =
            this.props.location.state.windows[0]["customWrapColor"];
          // eslint-disable-next-line
          this.state.isWrapsSelected = this.props.location.state.windows[0][
            "customWrapColor"
          ]
            ? true
            : false;
          if (this.state.isWrapsSelected) {
            this.setState({ showWrapColor: true });
          }
        }
        // eslint-disable-next-line
        this.state.outerJson.basePricePerMonth = quoteBasePrice * 0.02;
        // eslint-disable-next-line
        this.state.outerJson.price = quotePrice;
        // assumption only 1 quote per lead
        // eslint-disable-next-line
        this.state.outerJson.quotes[0].price = quotePrice;
        //  this.setState({ items: this.state.outerJson.quotes });
        await this.getOrderItems(this.state.outerJson);
      }
      console.log("in first load", this.state.outerJson.quotes);
      console.log("after search", this.state.configuredWindows);

      this.getChangeOrderSummary();
      this.setState({ isLoading: false });
    } else {
      if (existingOrderResponse.status === 403) {
        this.setState({ isLoading: true });
        this.setState({ isAddWindow: false });
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order getExistingOrder",
          statusCode: existingOrderResponse.status,
          statusMessage: "Access Denied",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (existingOrderResponse.status === 408) {
        this.setState({ isLoading: true });
        this.setState({ isAddWindow: false });
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order getExistingOrder",
          statusCode: existingOrderResponse.status,
          statusMessage: "Request Time Out",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (existingOrderResponse.status === 204) {
        this.setState({ isNoData: true });
        errorMessage = "No Change order data found for this lead.";
        this.setState({ isLoading: false });
        setTimeout(() => this.toCloseAlert(), 2000);
      }
    }
  }
  /************************************/

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  async createOrderInBackend(leadNumber, outerJson) {
    console.log("Saving Initiated", outerJson);
    this.setState({ isSaving: true });
    const apiUrl = process.env.REACT_APP_BASE_URL;
    let path =  "/changeOrderJSON"
    const url = new URL(path, apiUrl).href;
    const string = JSON.stringify(outerJson);

    let order = {};

    order.leadId = leadNumber;
    order.versionData = Buffer.from(string).toString("base64");

    //userSFID Hardcoded for oneQA

    order.userSFID = process.env.REACT_APP_SF_APP_ID;
    console.log(" order.SFID ", order.userSFID);
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
         User: this.state.user,
         "Api-Key": process.env.REACT_APP_API_KEY,
        'Authorization': 'Bearer ' + this.state.accessToken
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // body data type must match "Content-Type" header
      body: JSON.stringify(order),
    });

    //console.log("responseString ", response.text())  ;
    if (response.ok) {
      // if HTTP-status is 200-299
      // get the response body (the method explained below)
      //responseString = await response.json();
      //localStorage.setItem('orderJson',JSON.stringify(responseString));

      console.log(" Response ", await response.json());
      this.setState({
        isDisabled: true,
        isLoading: false,
      });

      // await this.getOrderedItemsForLead(leadNumber);
    } else {
      if (response.status === 403) {
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order createOrderInBackend",
          statusCode: response.status,
          statusMessage: "Access Denied",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
      if (response.status === 408) {
        let errorJson = {
          appName: "BAB",
          userName: this.state.user,
          functionName: "Change Order createOrderInBackend",
          statusCode: response.status,
          statusMessage: "Request Time Out",
        };
        await errorHandler.report(JSON.stringify(errorJson));
      }
    }
  }

  async getOrderItems(response) {
    console.log("quotes Response ", response);
    console.log("configuredOrderData", this.props.location.state?.windows);
    console.log("configured labors", this.props.location.state?.labors);
    console.log("updatedresponse" + response);
    var orderItems = [];

    let initialLabor = [];
    let defaultLaborValue = [];
    var configuredWindowsTotal = 0;
    var configuredWinLaborTotal = 0;
    var total = 0;
    //orderItems = responseString;
    if (response.quotes) {
      for (var i = 0; i < response.quotes.length; i++) {
        var cardObject1 = {};
        cardObject1.windows = response.quotes[i].windows;
        cardObject1.labors = response.quotes[i].labors;
        console.log("windows Response ", cardObject1.windows);
        if (cardObject1.windows) {
          for (var z = 0; z < cardObject1.windows.length; z++) {
            cardObject1.productStyle = cardObject1.windows[z].productStyle;
            configuredWindowsTotal += cardObject1.windows[z].priceTotal;

            if (cardObject1.windows[z].customWrapColor !== "") {
              this.setState({ showWrapColor: true });
              this.setState({
                selectedWrapColor: cardObject1.windows[z].customWrapColor,
              });

              this.setState({ isWrapsSelected: true });
            } else {
              this.setState({ showWrapColor: true });
            }
            for (var l = 0; l < cardObject1.windows[z].babData.length; l++) {
              this.setState({
                priceList: cardObject1.windows[z].babData[l].priceListNumber,
              });
              this.setState({
                branch: cardObject1.windows[z].babData[l].branchNumber,
              });
              this.setState({
                leadSafeFlag: cardObject1.windows[z].babData[l].leadSafeFlag,
              });
              this.setState({
                openingUuid: cardObject1.windows[z].babData[l].openingUuid,
              });
            }
          }
        }
        console.log("priceList", this.state.priceList);
        console.log("branch", this.state.branch);
        await this.getCatalogData(this.state.user);
        console.log(this.state.selectedWrapColor);
        if (cardObject1.windows && cardObject1.labors) {
          if (response.quotes[i].notes) {
            console.log("notes from JSON", response.quotes[i].notes);
            this.setState({
              itemMiscNotes: response.quotes[i].notes,
            });
          }

          for (var j = 0; j < cardObject1.labors.length; j++) {
            configuredWinLaborTotal += cardObject1.labors[j].priceTotal;

            if (
              cardObject1.labors[j].desc ===
              "VinylLB Miscellaneous Labor - Job Level"
            ) {
              let miscLabor = [];
              miscLabor.push(cardObject1.labors[j].desc);
              this.setState({ miscLabor: miscLabor });
              this.setState({ showLabor: true });
            }
            initialLabor.push(cardObject1.labors[j].desc);

            //this.onLaborChange(cardObject1.labors[j].desc);
          }
          console.log("set defaultLaborValue", initialLabor);
          this.setState({
            initialLabor: initialLabor,
          });
        }

        //cardObject.json =  responseString[i].ordersEntity.guid;
        console.log("configuredWindowsTotal ", configuredWindowsTotal);
        console.log("configuredWinLaborTotal ", configuredWinLaborTotal);
        console.log("card Response ", cardObject1);

        orderItems.push(cardObject1);
        // laborItems.push(cardObject2);
      }
    }

    total = configuredWindowsTotal + configuredWinLaborTotal;
    this.setState({ items: orderItems });
    // eslint-disable-next-line
    this.state.outerJson.price = Math.round(total * 100) / 100;
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].price = Math.round(total * 100) / 100;

    if (
      orderItems.length > 0 &&
      orderItems[0].labors &&
      this.state.jobLaborData
    ) {
      let joblaborRatesData = this.state.jobLaborData;

      for (let i = 0; i < joblaborRatesData.length; i++) {
        let childrenData = joblaborRatesData[i].children;
        console.log("onChange::childrenData", childrenData);

        for (let j = 0; j < childrenData.length; j++) {
          for (var k = 0; k < initialLabor.length; k++) {
            if (initialLabor[k] === childrenData[j].title) {
              defaultLaborValue.push(childrenData[j].key);
              break;
            }
          }
        }
      }
      console.log("final defaultLaborValue", defaultLaborValue);
      this.setState({
        value: defaultLaborValue,
        defaultLaborValue: defaultLaborValue,
      });
    }
    this.setState({ isLoading: false });
    console.log("items final", orderItems);
    // this.setState({ labor: laborItems });
  }

  onAddWraps = (checked) => {
    this.setState({ isWrapsSelected: checked });
    if (checked) {
      console.log(checked);
      this.setState({ showWrapColor: true });
    } else {
      console.log(checked);
      this.setState({ showWrapColor: false, selectedWrapColor: "" });

      let windows = this.state.outerJson.quotes[0].windows;
      for (let k = 0; k < windows.length; k++) {
        windows[k]["customWrapColor"] = "";
        if (windows[k].labors && windows[k].labors.length > 0) {
          for (let l = 0; l < windows[k].labors.length; l++) {
            // Removing wrap labor
            if (windows[k].labors[l].specCode === "WRAP") {
              // Subtracting labor price from window price
              windows[k].priceTotal =
                windows[k].priceTotal - windows[k].labors[l].priceTotal;
              windows[k].labors.splice(l, 1);
              windows[k].lmsWrapId = 0;
            }
          }
        }
      }
      // eslint-disable-next-line
      this.state.outerJson.quotes[0].windows = windows;

      this.setState({ isEdit: true });
      console.log("wrap toggle", this.state.outerJson.quotes[0].windows);
      this.getOrderPrice();
      this.getChangeOrderSummary();
    }
  };

  onMiscNotesUpdate = (notes) => {
    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();
    let miscNotesUpdate = [];
    this.setState({
      itemMiscNotes: notes.target.value,
    });
    miscNotesUpdate.push({
      miscNotesUpdateDate: currDate,
      miscNotesUpdateTime: currTime,
      userNameUpdate: this.state.userName ? this.state.userName : "-",
    });
    this.setState({ isEdit: true });
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].notes = notes.target.value;
    // eslint-disable-next-line
    this.state.outerJson.quotes[0].babMiscNotesUpdate = miscNotesUpdate;
    this.getChangeOrderSummary();
  };

  // RXM8QHE: Function to find the difference between the inHome attributes and change order attributes
  getChangeOrderSummary() {
    // debugger;
    console.log("change order json", this.state.outerJson);
    if (this.state.outerJson && this.state.finalInHomeJSON) {
      let inHomeWindows = this.state.finalInHomeJSON.quotes[0].windows;
      let inHomeLabors = this.state.finalInHomeJSON.quotes[0].labors;
      let inHomeNotes = this.state.finalInHomeJSON.quotes[0].notes;
      let changeWindowLabors = this.state.outerJson.quotes[0].labors;
      let changeNotes = this.state.outerJson.quotes[0].notes;
      let changeWindows = this.state.outerJson.quotes[0].windows;
      let jobLaborUpdates = this.state.outerJson.quotes[0].babJobLaborUpdates;
      let deleteLaborUpdates =
        this.state.outerJson.quotes[0].babDeleteLaborUpdates;
      let laborQuantityUpdate =
        this.state.outerJson.quotes[0].babLaborQuantityUpdate;
      let wrapColorUpdate = this.state.outerJson.quotes[0].babWrapColorUpdate;
      let deleteWindowUpdate =
        this.state.outerJson.quotes[0]?.babDeleteWindowUpdate;
      let miscNotesUpdate = this.state.outerJson.quotes[0].babMiscNotesUpdate;
      let index = 0;
      let summary = [];
      let totalCartPrice = this.state.finalInHomeJSON.price;
      let totalChangeCartPrice = this.state.outerJson.price;
      for (let l = 0; l < inHomeWindows.length; l++) {
        let totalAccessoryCost = 0;
        let totalChangedAccessoryCost = 0;
        let deleteWindow;
        let changeWindow = changeWindows.find(
          (item) => item.babWindowId === inHomeWindows[l].babWindowId
        );

        if (deleteWindowUpdate) {
          deleteWindow = deleteWindowUpdate.find(
            (item) => item.babWindowId === inHomeWindows[l].babWindowId
          );
        }

        if (!changeWindow) {
          summary.push({
            key: index++,
            LineId: l + 1,
            Category: "Window Deleted",
            ExistingValue: inHomeWindows[l].productStyle,
            NewValue: "-",
            DateUpdated: deleteWindow ? deleteWindow.deleteWindowDate : "-",
            TimeUpdated: deleteWindow ? deleteWindow.deleteWindowTime : "-",
            UserName: deleteWindow ? deleteWindow.userNameUpdate : "-",
          });
          continue;
        }
        {
          // comparing wrap color
          if (
            l === 0 &&
            inHomeWindows[l].customWrapColor !== changeWindow.customWrapColor
          ) {
            summary.push({
              key: index++,
              LineId: 0,
              Category:
                "Wrap color " +
                (changeWindow.customWrapColor ? "updated" : "removed"),
              ExistingValue: inHomeWindows[l].customWrapColor
                ? inHomeWindows[l].customWrapColor
                : "No Wrap color selected",
              NewValue: changeWindow.customWrapColor,
              DateUpdated: wrapColorUpdate
                ? wrapColorUpdate[0].wrapUpdateDate
                : "-",
              TimeUpdated: wrapColorUpdate
                ? wrapColorUpdate[0].wrapUpdateTime
                : "-",
              UserName: wrapColorUpdate
                ? wrapColorUpdate[0].userNameUpdate
                : "-",
            });
          }

          // comparing room
          let changedBabData = changeWindow.babData;

          if (inHomeWindows[l].room !== changeWindow.room) {
            summary.push({
              key: index++,
              LineId: l + 1,
              Category: "Room",
              ExistingValue: inHomeWindows[l].room,
              NewValue: changeWindow.room,
              DateUpdated: changedBabData[0].roomFloorUpdates
                ? changedBabData[0].roomFloorUpdates["updatedDate"]
                : "-",
              TimeUpdated: changedBabData[0].roomFloorUpdates
                ? changedBabData[0].roomFloorUpdates["updatedTime"]
                : "-",
              UserName: changedBabData[0].roomFloorUpdates
                ? changedBabData[0].roomFloorUpdates["userNameUpdate"]
                : "-",
            });
          }
          if (inHomeWindows[l].floor !== changeWindow.floor) {
            summary.push({
              key: index++,
              LineId: l + 1,
              Category: "Floor",
              ExistingValue: inHomeWindows[l].floor,
              NewValue: changeWindow.floor,
              DateUpdated: changedBabData[0].roomFloorUpdates
                ? changedBabData[0].roomFloorUpdates["updatedDate"]
                : "-",
              TimeUpdated: changedBabData[0].roomFloorUpdates
                ? changedBabData[0].roomFloorUpdates["updatedTime"]
                : "-",
              UserName: changedBabData[0].roomFloorUpdates
                ? changedBabData[0].roomFloorUpdates["userNameUpdate"]
                : "-",
            });
          }

          // comparing dimensions

          if (inHomeWindows[l].width !== changeWindow.width) {
            summary.push({
              key: index++,
              LineId: l + 1,
              Category: "Rough Opening Width",
              ExistingValue: inHomeWindows[l].width,
              NewValue: changeWindow.width,
              DateUpdated: changedBabData[0].roughOpeningUpdateDate
                ? changedBabData[0].roughOpeningUpdateDate
                : "-",
              TimeUpdated: changedBabData[0].roughOpeningUpdateTime
                ? changedBabData[0].roughOpeningUpdateTime
                : "-",
              UserName: changedBabData[0].roughOpeningUpdateTime
                ? changedBabData[0].userNameUpdate
                : "-",
            });
          }

          if (inHomeWindows[l].height !== changeWindow.height) {
            summary.push({
              key: index++,
              LineId: l + 1,
              Category: "Rough Opening Height",
              ExistingValue: inHomeWindows[l].height,
              NewValue: changeWindow.height,
              DateUpdated: changedBabData[0].roughOpeningUpdateDate
                ? changedBabData[0].roughOpeningUpdateDate
                : "-",
              TimeUpdated: changedBabData[0].roughOpeningUpdateTime
                ? changedBabData[0].roughOpeningUpdateTime
                : "-",
              UserName: changedBabData[0].roughOpeningUpdateTime
                ? changedBabData[0].userNameUpdate
                : "-",
            });
          }

          // comparing add on labors
          if (inHomeWindows[l].labors && changeWindow.labors) {
            let valuesRemoved = "";
            let valuesAdded = "";
            let changeBabData = changeWindow.babData;
            // eslint-disable-next-line
            inHomeWindows[l].labors.forEach((j) => {
              // eslint-disable-next-line
              if (j.specCode === "WRAP") {
                // eslint-disable-next-line
                return j;
              }
              let changeLabor = changeWindow.labors.find(
                (item) => item.desc === j.desc
              );
              if (!changeLabor) {
                valuesRemoved += j.desc + " ($" + j.priceTotal + ")\n";
              } else if (j.quantity !== changeLabor.quantity) {
                summary.push({
                  key: index++,
                  LineId: l + 1,
                  Category: "Add-on Item Labor Quantity",
                  ExistingValue:
                    j.desc + " ($" + j.priceTotal + ")--" + j.quantity,
                  NewValue:
                    changeLabor.desc +
                    " ($" +
                    changeLabor.priceTotal +
                    ")--" +
                    changeLabor.quantity,
                  DateUpdated: changeBabData[0].selectItemLaborUpdateDt
                    ? changeBabData[0].selectItemLaborUpdateDt
                    : "-",
                  TimeUpdated: changeBabData[0].selectItemLaborUpdateDt
                    ? changeBabData[0].selectItemLaborUpdateTm
                    : "-",
                  UserName: changeBabData[0].selectItemLaborUpdateDt
                    ? changeBabData[0].userNameUpdate
                    : "-",
                });
              }
            });
            changeWindow.labors.forEach((n) => {
              if (n.specCode === "WRAP") {
                return;
              }
              let changeLabor = inHomeWindows[l].labors.find(
                (item) => item.desc === n.desc
              );
              if (!changeLabor) {
                valuesAdded += n.desc + " ($" + n.priceTotal + ")\n";
              }
            });
            if (valuesAdded || valuesRemoved) {
              summary.push({
                key: index++,
                LineId: l + 1,
                Category:
                  "Add-on Item Labor " + (valuesRemoved ? "updated" : "added"),
                ExistingValue: valuesRemoved,
                NewValue: valuesAdded,
                DateUpdated: changeBabData[0].selectItemLaborUpdateDt
                  ? changeBabData[0].selectItemLaborUpdateDt
                  : "-",
                TimeUpdated: changeBabData[0].selectItemLaborUpdateDt
                  ? changeBabData[0].selectItemLaborUpdateTm
                  : "-",
                UserName: changeBabData[0].selectItemLaborUpdateDt
                  ? changeBabData[0].userNameUpdate
                  : "-",
              });
            }

            if (
              inHomeWindows[l].notes &&
              inHomeWindows[l].notes.includes("Item Labor Notes:") &&
              changeWindow.notes &&
              changeWindow.notes.includes("Item Labor Notes:")
            ) {
              let inHomeNotes = inHomeWindows[l].notes
                .split("Item Labor Notes:")[1]
                .split("\n")[0];
              let changeNotes = changeWindow.notes
                .split("Item Labor Notes:")[1]
                .split("\n")[0];
              if (inHomeNotes.trim() !== changeNotes.trim()) {
                summary.push({
                  key: index++,
                  LineId: l + 1,
                  Category: "Add-on Item Labor notes updated",
                  ExistingValue: inHomeNotes,
                  NewValue: changeNotes,
                  DateUpdated: changeBabData[0].miscNotesUpdateDt
                    ? changeBabData[0].miscNotesUpdateDt
                    : "-",
                  TimeUpdated: changeBabData[0].miscNotesUpdateTm
                    ? changeBabData[0].miscNotesUpdateTm
                    : "-",
                  UserName: changeBabData[0].selectItemLaborUpdateDt
                    ? changeBabData[0].userNameUpdate
                    : "-",
                });
              }
            }
          }

          // Comparing babdata
          let babData = inHomeWindows[l].babData;
          let changeBabData = changeWindow.babData;
          // mulling
          if (
            (babData[0].mullCharges
              ? babData[0].mullCharges["mullReinforcment"]
              : "N") !==
            (changeBabData[0].mullCharges
              ? changeBabData[0].mullCharges["mullReinforcment"]
              : "N")
          ) {
            summary.push({
              key: index++,
              LineId: l + 1,
              Category: "Mull Reinforcment",
              ExistingValue: babData[0].mullCharges
                ? babData[0].mullCharges["mullReinforcment"]
                : "N",
              NewValue: changeBabData[0].mullCharges
                ? changeBabData[0].mullCharges["mullReinforcment"]
                : "N",
              DateUpdated: changeBabData[0].mullCharges
                ? changeBabData[0].mullCharges["updatedDate"]
                : "-",
              TimeUpdated: changeBabData[0].mullCharges
                ? changeBabData[0].mullCharges["updatedTime"]
                : "-",
              UserName: changeBabData[0].mullCharges
                ? changeBabData[0].mullCharges["userNameUpdate"]
                : "-",
            });
          }
          for (let unit = 0; unit < changeBabData.length; unit++) {
            // comparing attributes
            // eslint-disable-next-line
            changeBabData[unit].attributes.forEach((attr) => {
              // eslint-disable-next-line
              let changedCategory = babData[unit].attributes.find(
                // eslint-disable-next-line
                (attribute) => attribute.category === attr.category
              );
              console.log("changedCategory", changedCategory);
              console.log("attr", attr);

              if (!changedCategory) {
                summary.push({
                  key: index++,
                  LineId: l + 1,
                  Category:
                    "Unit " + (unit + 1) + " Category Added " + attr.category,
                  ExistingValue: "-",
                  NewValue:
                    attr.category !== "Bars"
                      ? attr.attribute +
                        (attr.retailPrice !== undefined
                          ? " ($" + attr.retailPrice + ")\n"
                          : "\n")
                      : "Vertical Bars: " +
                        attr.numberOfBarsVertical +
                        ", Horizontal Bars: " +
                        attr.numberOfBarsHorizontal,
                  DateUpdated: attr.date,
                  TimeUpdated: attr.time,
                  UserName: attr.userNameUpdate,
                });
              }
            });
          }
          for (let unit = 0; unit < babData.length; unit++) {
            // comparing unit dimensions

            if (
              babData[unit].dimensions.width !==
              changeBabData[unit].dimensions.width
            ) {
              summary.push({
                key: index++,
                LineId: l + 1,
                Category: "Unit " + (unit + 1) + " Width",
                ExistingValue: babData[unit].dimensions.width,
                NewValue: changeBabData[unit].dimensions.width,
                DateUpdated: changeBabData[unit].dimensions.updateDate
                  ? changeBabData[unit].dimensions.updateDate
                  : "-",
                TimeUpdated: changeBabData[unit].dimensions.updateTime
                  ? changeBabData[unit].dimensions.updateTime
                  : "-",
                UserName: changeBabData[unit].dimensions.updateTime
                  ? changeBabData[unit].dimensions.userNameUpdate
                  : "-",
              });
            }

            if (
              babData[unit].dimensions.height !==
              changeBabData[unit].dimensions.height
            ) {
              summary.push({
                key: index++,
                LineId: l + 1,
                Category: "Unit " + (unit + 1) + " Height",
                ExistingValue: babData[unit].dimensions.height,
                NewValue: changeBabData[unit].dimensions.height,
                DateUpdated: changeBabData[unit].dimensions.updateDate
                  ? changeBabData[unit].dimensions.updateDate
                  : "-",
                TimeUpdated: changeBabData[unit].dimensions.updateTime
                  ? changeBabData[unit].dimensions.updateTime
                  : "-",
                UserName: changeBabData[unit].dimensions.updateTime
                  ? changeBabData[unit].dimensions.userNameUpdate
                  : "-",
              });
            }

            totalAccessoryCost += babData[unit].accessoryCost;
            totalChangedAccessoryCost += changeBabData[unit].accessoryCost;

            // comparing attributes
            // eslint-disable-next-line
            babData[unit].attributes.forEach((attr) => {
              // eslint-disable-next-line
              let changedCategory = changeBabData[unit].attributes.find(
                // eslint-disable-next-line
                (attribute) => attribute.category === attr.category
              );

              console.log("changedCategory", changedCategory);
              console.log("attr", attr);

              if (!changedCategory) {
                if (attr.category.startsWith("Grid")) {
                  changedCategory = changeBabData[unit].attributes.find(
                    (attribute) => attribute.category === "Add Grids"
                  );
                } else if (attr.category.startsWith("Spacer")) {
                  changedCategory = changeBabData[unit].attributes.find(
                    (attribute) => attribute.category === "Glass Pack"
                  );
                }

                summary.push({
                  key: index++,
                  LineId: l + 1,
                  Category:
                    "Category deleted " +
                    " Unit " +
                    (unit + 1) +
                    " " +
                    attr.category,
                  ExistingValue:
                    attr.category !== "Bars"
                      ? attr.attribute +
                        (attr.retailPrice !== undefined
                          ? " ($" + attr.retailPrice + ")\n"
                          : "\n")
                      : "Vertical Bars: " +
                        attr.numberOfBarsVertical +
                        ", Horizontal Bars: " +
                        attr.numberOfBarsHorizontal,
                  NewValue: "-",
                  DateUpdated: changedCategory ? changedCategory.date : "-",
                  TimeUpdated: changedCategory ? changedCategory.time : "-",
                  UserName: changedCategory
                    ? changedCategory.userNameUpdate
                    : "-",
                });
              } else {
                if (Array.isArray(attr.attribute)) {
                  let valuesRemoved = "";
                  let valuesAdded = "";
                  let aIndex = 0;
                  attr.attribute.forEach((a) => {
                    if (
                      !changedCategory.attribute.find(
                        (attribute) => attribute === a
                      )
                    ) {
                      valuesRemoved +=
                        a + " ($" + attr.retailPrice[aIndex] + ") \n";
                    }
                    aIndex++;
                  });
                  let cIndex = 0;
                  changedCategory.attribute.forEach((a) => {
                    if (!attr.attribute.find((attribute) => attribute === a)) {
                      valuesAdded +=
                        a +
                        " ($" +
                        changedCategory.retailPrice[cIndex] +
                        ") \n";
                    }
                    cIndex++;
                  });

                  if (valuesRemoved || valuesAdded) {
                    summary.push({
                      key: index++,
                      LineId: l + 1,
                      Category: "Unit " + (unit + 1) + " " + attr.category,
                      ExistingValue: valuesRemoved ? valuesRemoved : "-",
                      NewValue: valuesAdded ? valuesAdded : "-",
                      DateUpdated: changedCategory.date,
                      TimeUpdated: changedCategory.time,
                      UserName: changedCategory.userNameUpdate,
                    });
                  }
                } else if (
                  changedCategory.attribute !== attr.attribute &&
                  attr.category !== "Bars"
                ) {
                  summary.push({
                    key: index++,
                    LineId: l + 1,
                    Category: "Unit " + (unit + 1) + " " + attr.category,
                    ExistingValue:
                      attr.attribute +
                      (attr.retailPrice !== undefined
                        ? " ($" + attr.retailPrice + ")\n"
                        : "\n"),
                    NewValue:
                      changedCategory.attribute +
                      (changedCategory.retailPrice !== undefined
                        ? " ($" + changedCategory.retailPrice + ")\n"
                        : "\n"),
                    DateUpdated: changedCategory.date,
                    TimeUpdated: changedCategory.time,
                    UserName: changedCategory.userNameUpdate,
                  });
                } else if (
                  attr.category === "Bars" &&
                  (changedCategory.numberOfBarsVertical !==
                    attr.numberOfBarsVertical ||
                    changedCategory.numberOfBarsHorizontal !==
                      attr.numberOfBarsHorizontal)
                ) {
                  summary.push({
                    key: index++,
                    LineId: l + 1,
                    Category: "Unit " + (unit + 1) + " " + attr.category,
                    ExistingValue:
                      "Vertical Bars: " +
                      attr.numberOfBarsVertical +
                      ", Horizontal Bars: " +
                      attr.numberOfBarsHorizontal,
                    NewValue:
                      "Vertical Bars: " +
                      changedCategory.numberOfBarsVertical +
                      ", Horizontal Bars: " +
                      changedCategory.numberOfBarsHorizontal,
                    DateUpdated: changedCategory.date,
                    TimeUpdated: changedCategory.time,
                    UserName: changedCategory.userNameUpdate,
                  });
                }
              }
            });
          }
        }

        if (
          totalAccessoryCost &&
          totalChangedAccessoryCost &&
          totalAccessoryCost !== totalChangedAccessoryCost
        ) {
          summary.push({
            key: index++,
            LineId: l + 1,
            Category: "Accessory cost changed from ",
            ExistingValue: "$" + Math.round(totalAccessoryCost * 100) / 100,
            NewValue: "$" + Math.round(totalChangedAccessoryCost * 100) / 100,
          });
        }
        if (inHomeWindows[l].priceTotal !== changeWindow.priceTotal) {
          summary.push({
            key: index++,
            LineId: l + 1,
            Category: "Price total changed from ",
            ExistingValue:
              "$" + Math.round(inHomeWindows[l].priceTotal * 100) / 100,
            NewValue: "$" + Math.round(changeWindow.priceTotal * 100) / 100,
          });
        }
      }
      for (let l = 0; l < changeWindows.length; l++) {
        if (
          changeWindows[l].babWindowId &&
          changeWindows[l].babWindowId.includes("CHG")
        ) {
          summary.push({
            key: index++,
            LineId: changeWindows[l].line,
            Category: "New Window",
            ExistingValue: "",
            NewValue: changeWindows[l].productStyle,
            DateUpdated: changeWindows[l].babUpdateWindowDate
              ? changeWindows[l].babUpdateWindowDate
              : "-",
            TimeUpdated: changeWindows[l].babUpdateWindowTime
              ? changeWindows[l].babUpdateWindowTime
              : "-",
            UserName: changeWindows[l].babUserNameUpdate
              ? changeWindows[l].babUserNameUpdate
              : "-",
          });
        }
      }

      // comparing add on labors
      console.log("inHomeLabors", inHomeLabors);
      console.log("changeWindowLabors", changeWindowLabors);
      if (inHomeLabors && changeWindowLabors) {
        let valuesRemoved = "";
        let valuesAdded = "";
        let updatedDate;
        let updatedTime;
        let userNameUpdate;
        inHomeLabors.forEach((j) => {
          let changeLabor = changeWindowLabors.find(
            (item) => item.desc === j.desc
          );
          if (!changeLabor) {
            valuesRemoved += j.desc + " ($" + j.priceTotal + ")\n";
          } else if (j.quantity !== changeLabor.quantity) {
            summary.push({
              key: index++,
              LineId: 0,
              Category: "Job Labor Quantity",
              ExistingValue: j.desc + " ($" + j.priceTotal + ")--" + j.quantity,
              NewValue:
                changeLabor.desc +
                " ($" +
                changeLabor.priceTotal +
                ")--" +
                changeLabor.quantity,
              DateUpdated: laborQuantityUpdate
                ? laborQuantityUpdate[0].laborQuantityUpdateDate
                : "-",
              TimeUpdated: laborQuantityUpdate
                ? laborQuantityUpdate[0].laborQuantityUpdateTime
                : "-",
              UserName: laborQuantityUpdate
                ? laborQuantityUpdate[0].userNameUpdate
                : "-",
            });
          }
        });
        changeWindowLabors.forEach((n) => {
          let changeLabor = inHomeLabors.find((item) => item.desc === n.desc);
          if (!changeLabor) {
            valuesAdded +=
              n.desc !== undefined ? n.desc + " ($" + n.priceTotal + ")" : "\n";
          }
        });
        if (valuesAdded !== "") {
          updatedDate = jobLaborUpdates
            ? jobLaborUpdates[0].updateLaborDate
            : "-";
          updatedTime = jobLaborUpdates
            ? jobLaborUpdates[0].updateLaborTime
            : "-";
          userNameUpdate = jobLaborUpdates
            ? jobLaborUpdates[0].userNameUpdate
            : "-";
        }
        if (valuesRemoved !== "") {
          updatedDate = deleteLaborUpdates
            ? deleteLaborUpdates[0].deleteIndividualDate
            : "-";
          updatedTime = deleteLaborUpdates
            ? deleteLaborUpdates[0].deleteIndividualTime
            : "-";
          userNameUpdate = jobLaborUpdates
            ? jobLaborUpdates[0].userNameUpdate
            : "-";
        }
        if (valuesAdded || valuesRemoved) {
          summary.push({
            key: index++,
            LineId: 0,
            Category: "Job Labor " + (valuesAdded ? "updated" : "deleted"),
            ExistingValue: valuesRemoved,
            NewValue: valuesAdded,
            DateUpdated: updatedDate,
            TimeUpdated: updatedTime,
            UserName: userNameUpdate,
          });
        }
      }

      if (inHomeNotes !== changeNotes) {
        summary.push({
          key: index++,
          LineId: 0,
          Category: "Misc. notes updated",
          ExistingValue: inHomeNotes,
          NewValue: changeNotes,
          DateUpdated: miscNotesUpdate
            ? miscNotesUpdate[0].miscNotesUpdateDate
            : "-",
          TimeUpdated: miscNotesUpdate
            ? miscNotesUpdate[0].miscNotesUpdateTime
            : "-",
          UserName: miscNotesUpdate ? miscNotesUpdate[0].userNameUpdate : "-",
        });
      }

      if (totalCartPrice !== totalChangeCartPrice) {
        summary.push({
          key: index++,
          LineId: 0,
          Category: "Total cart price changed from ",
          ExistingValue: "$" + Math.round(totalCartPrice * 100) / 100,
          NewValue: "$" + Math.round(totalChangeCartPrice * 100) / 100,
        });
      }

      this.setState({ dataSource: summary.flat() });
    }
  }

  // RXM8QHE: To calculate total accessory cost from babdata
  getAccessoryCost(item) {
    let accessoryCost = 0;
    if (item.babData) {
      item.babData.forEach((unit, i) => {
        if (unit["accessoryCost"]) {
          accessoryCost += unit["accessoryCost"];
        }
      });
    }
    return Math.round(accessoryCost * 100) / 100;
  }

  // Added this value to total in stepper
  // getLaborRateAndImuCalculation(item) {
  //   let laborRate = item.babDefaultLabor;
  //   let laborImu = item.babLaborImu;
  //   console.log(" getLaborRateAndImuCalculation  ", laborRate," , ", laborImu )
  //   //this.setState({laborRate, laborImu})
  //   return (laborRate /
  //   (1 - laborImu ))
  // }

  render() {
    // eslint-disable-next-line
    const { dataSource, columns } = this.state;

    if (this.state.authenticated) {
      //let installationAccessories = this.state.categoryAttributeMap['Installation Accessories']
      return (
        <Spin tip="Loading..." spinning={this.state.isLoading}>
          <PageHeader>
            <img
              className="homedepot-logo"
              alt="homedepot-logo"
              src={LogoName}
            ></img>
            HDE Configurator{" "}
            {this.state.leadNumber ? "(" + this.state.leadNumber + ")" : ""}
          </PageHeader>

          {this.state.isSearch && this.state.isNoData ? (
            <Alert
              message="Error"
              description={errorMessage}
              type="error"
              showIcon
            />
          ) : (
            <div></div>
          )}

          <div className="main">
            {
              <Search
                //disabled
                placeholder="Please enter Lead Number"
                enterButton="Search"
                size="large"
                prefix={<FolderOpenOutlined />}
                onSearch={this.onSearch}
              />
            }
            <br />
            <div>
              {this.state.items.length > 0 && this.state.items[0].windows ? (
                <div>
                  <br />
                  <br />
                  <Card>
                    {" "}
                    <h2
                      style={{
                        fontWeight: 700,
                        fontSize: "1.6em",
                        color: "#f96302",
                        width: 280,
                      }}
                    >
                      {" "}
                      Line Items
                    </h2>
                    <List
                      grid={{ gutter: 10, column: 6 }}
                      dataSource={this.state.items}
                      renderItem={(item) => (
                        <List.Item>
                          {item.windows.map((item) => (
                            <Card
                              title={
                                <span>
                                  <b>Line#</b>
                                  {item.line}: {item.productStyle}
                                  <br />
                                  <b>Room:</b>
                                  {"   "}
                                  {item.room}
                                  <br />
                                  <b>Floor:</b>
                                  {"   "}
                                  {item.floor}
                                  <br />
                                  <b>Interior Color:</b>
                                  {"   "}
                                  {item.interiorWindowColorDesc}
                                  <br />
                                  <b>Exterior Color:</b>
                                  {"   "}
                                  {item.exteriorWindowColorDesc}
                                </span>
                              }
                              style={
                                item.babWindowId &&
                                item.babWindowId.includes("CHG")
                                  ? this.state.newWindowInExistingOrderStyle
                                  : this.state.existingWindowStyle
                              }
                              headStyle={
                                item.babWindowId &&
                                item.babWindowId.includes("CHG")
                                  ? this.state.newWindowInExistingOrderStyle
                                  : this.state.existingWindowStyle
                              }
                              bodyStyle={
                                item.babWindowId &&
                                item.babWindowId.includes("CHG")
                                  ? this.state.newWindowInExistingOrderStyle
                                  : this.state.existingWindowStyle
                              }
                            >
                              <h3>Base Window Price:${item.priceBase}</h3>
                              <h3>
                                Accessories:$
                                {this.getAccessoryCost(item)}
                              </h3>
                              <h3>Discount: TBD</h3>
                              <h3>
                                Total Price:$
                                {Math.round(item.priceTotal * 100) / 100}
                              </h3>
                              {item.productStyle.includes("Transom") ? (
                                <Col gutter={24}>
                                  {item.productStyle
                                    .replace("Transom", "")
                                    .trim()
                                    .split("|")
                                    .reverse()
                                    .map((img) => (
                                      <Row
                                        span={
                                          this.state.items.length > 2
                                            ? 8
                                            : this.state.items.length === 1
                                            ? 24
                                            : 12
                                        }
                                      >
                                        <Image
                                          width={50}
                                          src={imageMap.get(img)}
                                        />
                                      </Row>
                                    ))}
                                </Col>
                              ) : (
                                item.productStyle
                                  .trim()
                                  .split("|")
                                  .map((img) => (
                                    <Image width={50} src={imageMap.get(img)} />
                                  ))
                              )}
                              <br />
                              <br />
                              <InputNumber
                                disabled
                                min={1}
                                max={10}
                                defaultValue={1}
                              />
                              <Button
                                type="primary"
                                style={{ margin: "0 8px" }}
                                onClick={(e) =>
                                  this.editOrder(
                                    item,
                                    item.line,
                                    this.getAccessoryCost(item),
                                    item.priceTotal
                                  )
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                type="primary"
                                shape="circle"
                                icon={<DeleteFilled />}
                                onClick={(e) =>
                                  this.deleteWindow(item, item.line)
                                }
                              ></Button>
                            </Card>
                          ))}
                        </List.Item>
                      )}
                    />
                  </Card>
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>

            {this.state.items.length > 0 &&
            this.state.items[0].labors &&
            this.state.items[0].labors.length > 0 ? (
              <div>
                <Card>
                  {" "}
                  <h2
                    style={{
                      fontWeight: 700,
                      fontSize: "1.6em",
                      color: "#f96302",
                    }}
                  >
                    {" "}
                    Job Labor
                  </h2>
                  <br />
                  <div>
                    <List
                      grid={{ gutter: 16, column: 2 }}
                      dataSource={this.state.items}
                      renderItem={(items) => (
                        <List.Item>
                          {items.labors.map((labor) => (
                            <Card title={labor.desc}>
                              <h3>
                                ${labor.priceTotal}
                                &nbsp;
                                <Button
                                  type="primary"
                                  shape="circle"
                                  style={{ marginTop: "-180px" }}
                                  onClick={(e) =>
                                    this.deleteIndividualLabor(labor)
                                  }
                                  icon={<DeleteFilled />}
                                ></Button>
                              </h3>

                              <br />

                              {this.state.miscLabor.includes(labor.desc) ? (
                                <Select
                                  style={{ width: 50 }}
                                  optionFilterProp="children"
                                  defaultValue={labor.quantity}
                                  onChange={this.onQuantityChange.bind(this)}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.quantity.map((index) => {
                                    return (
                                      <Option
                                        key={index}
                                        value={labor.desc + "---" + index}
                                      >
                                        {index}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <div>&nbsp;</div>
                              )}
                            </Card>
                          ))}
                        </List.Item>
                      )}
                    />
                  </div>
                  &nbsp;&nbsp;
                  <Button
                    type="primary"
                    icon={<DeleteFilled />}
                    onClick={this.deleteLabor.bind(this)}
                  >
                    {" "}
                    Clear All
                  </Button>{" "}
                </Card>
              </div>
            ) : (
              <div>&nbsp;</div>
            )}
            <br />

            {this.state.showLabor ? (
              <div>
                <Card>
                  <Row>
                    {" "}
                    <h2
                      style={{
                        fontWeight: 700,
                        fontSize: "1.6em",
                        color: "#f96302",
                      }}
                    >
                      {" "}
                      Misc. Labor Notes
                    </h2>
                    <TextArea
                      id="miscText"
                      defaultValue={this.state.itemMiscNotes}
                      style={{
                        width: 700,
                        marginTop: "60px",
                        marginLeft: "-130px",
                      }}
                      onBlur={this.onMiscNotesUpdate.bind(this)}
                      allowClear={true}
                      rows={4}
                    >
                      {" "}
                    </TextArea>
                  </Row>
                </Card>
              </div>
            ) : (
              <br />
            )}

            {!this.state.isAddWindow ? (
              <Card>
                <Space>
                  <br />

                  <div>&nbsp;</div>
                  <br />

                  <Row justify="space-between">
                    <h3 style={{ marginRight: "8px", marginTop: "60px" }}>
                      {" "}
                      Add Wraps{" "}
                    </h3>
                    <Switch
                      style={{ marginTop: "60px" }}
                      checked={this.state.isWrapsSelected}
                      size="large"
                      onChange={this.onAddWraps.bind(this)}
                    />
                  </Row>
                  {this.state.isWrapsSelected ? (
                    <Row justify="space-between">
                      <h3 style={{ marginRight: "18px", marginTop: "60px" }}>
                        {" "}
                        Select Wrap{" "}
                      </h3>
                      <Select
                        placeholder="Select a Wrap color"
                        optionFilterProp="children"
                        onChange={this.onChangeWrapColor.bind(this)}
                        value={this.state.selectedWrapColor}
                        style={{ width: 200, marginTop: "60px" }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        <Option value="White">White</Option>
                        <Option value="Jefferson Tan">Jefferson Tan</Option>
                        <Option value="Aspen Gray">Aspen Gray</Option>
                        <Option value="Autumn Gold">Autumn Gold</Option>
                        <Option value="Autumn Harvest">Autumn Harvest</Option>
                        <Option value="Black">Black</Option>
                        <Option value="Brandy Wood">Brandy Wood</Option>
                        <Option value="Cameo">Cameo</Option>
                        <Option value="Cedar">Cedar</Option>
                        <Option value="Classic Cream">Classic Cream</Option>
                        <Option value="Colonial Yellow">Colonial Yellow</Option>
                        <Option value="Cape Grey">Cape Grey</Option>
                        <Option value="Corn Silk">Corn Silk</Option>
                        <Option value="Deep Granite">Deep Granite</Option>
                        <Option value="Dark Bronze">Dark Bronze</Option>
                        <Option value="Desert Sand">Desert Sand</Option>
                        <Option value="English Wedgewood">
                          English Wedgewood
                        </Option>
                        <Option value="Everest">Everest</Option>
                        <Option value="Evergreen">Evergreen</Option>
                        <Option value="Glacier Blue">Glacier Blue</Option>
                        <Option value="Harbor Grey">Harbor Grey</Option>
                        <Option value="Hazelwood">Hazelwood</Option>
                        <Option value="London Brown">London Brown</Option>
                        <Option value="Linen">Linen</Option>
                        <Option value="Lakeshore Fern">Lakeshore Fern</Option>
                        <Option value="Mahogany">Mahogany</Option>
                        <Option value="Misty Shadow">Misty Shadow</Option>
                        <Option value="Montana Suede">Montana Suede</Option>
                        <Option value="Musket Brown">Musket Brown</Option>
                        <Option value="Natural Slate">Natural Slate</Option>
                        <Option value="Newport Bay">Newport Bay</Option>
                        <Option value="Pebblestone Clay">
                          Pebblestone Clay
                        </Option>
                        <Option value="Portsmouth Blue">Portsmouth Blue</Option>
                        <Option value="Quirt Willow">Quirt Willow</Option>
                        <Option value="Red Brick">Red Brick</Option>
                        <Option value="Red Cedar">Red Cedar</Option>
                        <Option value="Russett Red">Russett Red</Option>
                        <Option value="Rugged Canyon">Rugged Canyon</Option>
                        <Option value="Sage">Sage</Option>
                        <Option value="Scottish Thistle">
                          Scottish Thistle
                        </Option>
                        <Option value="Silver Grey">Silver Grey</Option>
                        <Option value="Sandstone">Sandstone</Option>
                        <Option value="Timber">Timber</Option>
                        <Option value="Terra Cotta">Terra Cotta</Option>
                        <Option value="Tuscan Olive">Tuscan Olive</Option>
                        <Option value="Victorian Grey">Victorian Grey</Option>
                        <Option value="Vineyard Grove">Vineyard Grove</Option>
                        <Option value="Walnut">Walnut</Option>
                        <Option value="Window Almond">Window Almond</Option>
                        <Option value="Window Brown">Window Brown</Option>
                        <Option value="Window White">Window White</Option>
                        <Option value="Wicker">Wicker</Option>
                        <Option value="Woodland Green">Woodland Green</Option>
                      </Select>
                    </Row>
                  ) : (
                    <div></div>
                  )}
                </Space>{" "}
              </Card>
            ) : (
              <div></div>
            )}

            <div>
              {this.state.dataSource ? (
                <div class="float-child">
                  <Divider />
                  <br />
                  <br />
                  <Card>
                    {" "}
                    <h2
                      style={{
                        fontWeight: 700,
                        fontSize: "1.6em",
                        color: "#f96302",
                      }}
                    >
                      {" "}
                      Change Order Summary
                    </h2>
                    <Table
                      dataSource={this.state.dataSource}
                      columns={columns}
                      pagination={false}
                    />
                  </Card>
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
            <Divider />
            {!this.state.isAddWindow ? (
              <h1>Total Cart Price: ${this.state.outerJson.price}</h1>
            ) : (
              <div>&nbsp;</div>
            )}

            <Button
              type="primary"
              size="large"
              style={{ margin: "0 8px" }}
              disabled={this.state.isAddWindow}
              onClick={this.showModal}
            >
              {" "}
              Add Window
            </Button>

            <Button
              disabled={this.state.isAddWindow}
              type="primary"
              size="large"
              style={{ float: "right" }}
              onClick={this.generateVOS.bind(this)}
            >
              {" "}
              Generate VOS
            </Button>
            {this.state.items.length > 0 && this.state.items[0].windows ? (
              <Button
                type="primary"
                size="large"
                style={{ margin: "0 8px" }}
                onClick={this.showLaborModal}
              >
                {" "}
                {this.state.items[0].labors &&
                this.state.items[0].labors.length > 0
                  ? "Update Job Labor"
                  : "Add Job Labor"}
              </Button>
            ) : (
              <div>&nbsp;</div>
            )}

            {this.state.items.length > 0 && this.state.items[0].windows ? (
              <Button
                disabled={this.state.isDisabled}
                type="primary"
                size="large"
                style={{ float: "right", margin: "0 8px" }}
                onClick={this.submitOrder.bind(this)}
              >
                {" "}
                Save Order
              </Button>
            ) : (
              <div>&nbsp;</div>
            )}

            <br />
            <br />

            <Modal
              title="Configure New Product"
              visible={this.state.isModalVisible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <p>Let's configure a new product! Redirecting new page !!</p>
              {/* awd01o disabled, didn't want, can add back later if needed.*/}
              {/* <Switch checkedChildren="Combo Unit" unCheckedChildren="Single Unit" onChange={this.handleComboVsSingleUnitToggle} /> */}
              <br />
            </Modal>

            <Modal
              title="Add Labor"
              visible={this.state.isLaborModalVisible}
              destroyOnClose={true}
              onOk={this.handleLaborOk}
              onCancel={this.handleLaborCancel}
            >
              <p>Select Job Labor</p>
              <Space>
                {
                  <Row justify="space-between">
                    <TreeSelect
                      style={{ width: "Auto", minWidth: 450 }}
                      treeDefaultExpandAll
                      treeData={this.state.jobLaborData}
                      value={this.state.value}
                      //selected = {this.state.value}
                      //defaultValue={this.state.defaultLaborValue}
                      onChange={this.onLaborChange.bind(this)}
                      onDeselect={this.onLaborDeselect.bind(this)}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_PARENT}
                      placeholder="Select Labor"
                    />
                  </Row>
                }
              </Space>
              <br />
            </Modal>
          </div>
          {this.state.generateVOSFlag ? (
            <Redirect
              push
              to={{
                pathname: "/vosGenerate",
                leadNumber: this.state.leadNumber,
                state: {
                  windows: this.state.configuredWindows,
                  isEdit: this.state.isEdit,
                  lineId: this.state.lineId,
                  changeOrderFlowVos: true,
                  branchNumber: this.state.branch,
                  storeNumber: this.state.storeNumber,
                  priceList: this.state.priceList,
                  leadNumber: this.state.leadNumber,
                  leadSafeFlag: this.state.leadSafeFlag,
                  openingUuid: this.state.openingUuid,
                  authenticated: this.state.authenticated,
                  addedWindowInExistingOrder: !this.state.isEdit,
                },
              }}
            />
          ) : null}
          {this.state.redirect ? (
            <Redirect
              push
              to={{
                pathname: "/changeOrderConfig",
                accessToken: this.state.accessToken,
                branchNumber: this.state.branch,
                storeNumber: this.state.storeNumber,
                priceList: this.state.priceList,
                leadNumber: this.state.leadNumber,
                leadSafeFlag: this.state.leadSafeFlag,
                openingUuid: this.state.openingUuid,

                windows: this.state.configuredWindows,
                labors: this.state.items[0].labors,
                itemLaborData: this.state.itemLaborData,
                defaultLaborsData: this.state.defaultLaborsData,
                /* awd01o pass existing order to Stepper class */
                oldBabData: this.state.oldBabData,
                existingItemToEdit: this.state.isEdit
                  ? this.state.existingItemToEdit
                  : undefined,
                finalInHomeJSON: this.state.finalInHomeJSON,
                isEdit: this.state.isEdit,
                selectedWrapColor: this.state.selectedWrapColor,
                lineId: this.state.lineId,
                babWindowId: this.state.babWindowId,
                jobMiscNotes: this.state.itemMiscNotes,
                authenticated: this.state.authenticated,
                jobLaborUpdates:
                  this.state.outerJson.quotes[0].babJobLaborUpdates,
                deleteLaborUpdates:
                  this.state.outerJson.quotes[0].babDeleteLaborUpdates,
                laborQuantityUpdate:
                  this.state.outerJson.quotes[0].babLaborQuantityUpdate,
                wrapColorUpdate:
                  this.state.outerJson.quotes[0].babWrapColorUpdate,
                deleteWindowUpdate:
                  this.state.outerJson.quotes[0].babDeleteWindowUpdate,
                miscNotesUpdate:
                  this.state.outerJson.quotes[0].babMiscNotesUpdate,
                userNameUpdate: this.state.userName ? this.state.userName : "-",
                user: this.state.user,
                series: this.state.series,
                material: this.state.material,
                products: this.state.products,
                productSeries: this.state.productSeries,
                pricingTaxForAllProducts: this.state.pricingTaxForAllProducts,
                modifiedTimeStampObj: this.state.modifiedTimeStampObj,
                /************************************/
              }}
            />
          ) : null}
        </Spin>
      );
    } else {
      /*awd01o render blank screen if correct entitlement role is not present */
      return (
        <div>
          <PageHeader>
            <img
              className="homedepot-logo"
              alt="homedepot-logo"
              src={LogoName}
            ></img>
            HDE Configurator
          </PageHeader>
          <div
            style={{
              marginTop: 100,
              marginLeft: 500,
              fontWeight: 700,
              fontSize: "1.8em",
              color: "#f96302",
            }}
          ></div>
        </div>
      );
    }
    /************************************/
  }
}
